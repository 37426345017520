import { DataGrid as _DataGrid } from '@vooban/datagrid';
import React from 'react';
import { DndContext } from 'react-dnd';
import Autosizer from 'react-virtualized-auto-sizer';

const DataGrid = (props) => {
  return (
    <div className="flex-grow" style={{ overflow: 'hidden' }}>
      <Autosizer style={{ minHeight: 300, width: '100%' }}>
        {({ height, width }) => (
          <DndContext.Consumer>
            {({ dragDropManager }) =>
              React.createElement(_DataGrid, {
                ...props,
                height,
                width,
                dragDropManager: dragDropManager,
              })
            }
          </DndContext.Consumer>
        )}
      </Autosizer>
    </div>
  );
};

export default DataGrid;
