import PropTypes from 'prop-types';
import ClientInventory from './ClientInventory';
import Dimension from './Dimension';
import InventoryOrder from './InventoryOrder';
import Location from './Location';
import ProductType from './ProductType';

export const InventoryItemIdentifiers = {
  identifierLabel1: PropTypes.string,
  identifierValue1: PropTypes.string,
  identifierLabel2: PropTypes.string,
  identifierValue2: PropTypes.string,
  identifierLabel3: PropTypes.string,
  identifierValue3: PropTypes.string,
  identifierLabel4: PropTypes.string,
  identifierValue4: PropTypes.string,
};

export const IdentifiersAsProperties = {
  barcode: PropTypes.string,
  billOfLading: PropTypes.string,
  booking: PropTypes.string,
  description: PropTypes.string,
  heatNumber: PropTypes.string,
  lotNumber: PropTypes.string,
  poNumber: PropTypes.string,
  mark: PropTypes.string,
  scope: PropTypes.string,
  serialNumber: PropTypes.string,
  other: PropTypes.string,
};

export const InventoryItem = {
  inventoryItemId: PropTypes.number,
  id: PropTypes.number,
  clientInventory: PropTypes.shape(ClientInventory),
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Location)]),
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(InventoryOrder)]),
  productType: PropTypes.shape(ProductType),
  packaging: PropTypes.string,
  quantityByCount: PropTypes.number,
  bookQuantity: PropTypes.number,
  unitOfMass: PropTypes.string,
  weightPerUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unitOfWeight: PropTypes.string,
  unitOfDimension: PropTypes.number,
  productSubtypeId: PropTypes.number,
  productSubtypeName: PropTypes.string,
  ...InventoryItemIdentifiers,
  ...IdentifiersAsProperties,
  diameter: PropTypes.shape(Dimension),
  length: PropTypes.shape(Dimension),
  height: PropTypes.shape(Dimension),
  thickness: PropTypes.shape(Dimension),
  width: PropTypes.shape(Dimension),
  dimensionOrders: PropTypes.arrayOf(PropTypes.string),
  isStuffableContainer: PropTypes.bool,
  isNonStuffableContainer: PropTypes.bool,
  manuallyEnteredStuffedWeight: PropTypes.number,
  imoDgcAttachmentIds: PropTypes.arrayOf(PropTypes.string),
  msdsAttachmentIds: PropTypes.arrayOf(PropTypes.string),
  isHazmatAttachmentMissing: PropTypes.bool,
  originalInventoryItemId: PropTypes.number,
  addedBy: PropTypes.string,
  addedOn: PropTypes.string,
};
