import { useRouteMatch } from 'react-router-dom';
import { Form, Formik } from 'formik';
import useAgent from '../../hooks/useAgent';
import { useEffect } from 'react';
import Loader from '@vooban/loader';
import TailgateRportFormFields from './TailgateReportFormFields';
import { CARGO_TYPE } from '../../cargoType';
import { getTailgateReportSites } from '../agents/tailgateAgents';

const TailgateReportsContent = () => {
  let { path } = useRouteMatch();
  const cargoType = path.includes(CARGO_TYPE.breakbulk.toLowerCase()) ? CARGO_TYPE.breakbulk : CARGO_TYPE.bulk;

  const [getSites, mySites = [], isLoadingSites] = useAgent(getTailgateReportSites);

  useEffect(() => {
    getSites(cargoType);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargoType]);

  if (isLoadingSites) return <Loader />;
  const sites = mySites?.map((site) => ({ label: site.name, value: site.id, key: site.id }));

  return (
    <Formik initialValues={{ dateFrom: null, dateTo: null, inventoriesIds: [], sites: [] }}>
      <Form>
        <TailgateRportFormFields type={cargoType} sites={sites}></TailgateRportFormFields>
      </Form>
    </Formik>
  );
};

export default TailgateReportsContent;
