import axios from 'axios';
import useAgent from '../../../hooks/useAgent';

const getMyCompanies = (parameters = {}) =>
  axios.get('/api/v1/my-companies', {
    params: parameters,
  });
const useGetMyCompanies = () => useAgent(getMyCompanies);

export default useGetMyCompanies;
