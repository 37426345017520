import axios from 'axios';
import { url as tailgateBaseUrl } from '../agents/tailgateAgents';
import { url as inventoryByDateBaseUrl } from '../agents/inventoryByDateAgents';

export const totalCountKey = 'x-total-count';

export const clientOnlyReceiverKey = 'x-client-only-receiver';

export const getClientInventoriesHeader = async (cargoType) => axios.head(`${inventoryByDateBaseUrl}/client-inventories`, { params: { cargoType } });

export const getSitesHeader = async (cargoType) => axios.head(`${tailgateBaseUrl}/sites`, { params: { cargoType } });
