export const trailerTypesByKey = {
  barge: 'barge',
  breakbulk: 'breakbulk',
  bTrain: 'bTrain',
  bulk: 'bulk',
  dryBox: 'dryBox',
  flatBed: 'flatBed',
  frame: 'frame',
  gooseneck: 'gooseneck',
  other: 'box',
  railcar: 'railcar',
  truck: 'truck',
  pick: 'pick',
  drop: 'drop',
  curtainSide: 'curtainSide',
  dropDeck: 'dropDeck',
  endDump: 'endDump',
  goldhofer: 'goldhofer',
  hopper: 'hopper',
  liveBottom: 'liveBottom',
  triAxle: 'triAxle',
  boxcars: 'boxcars',
  bulkhead: 'bulkhead',
  centerBeams: 'centerBeams',
  flatCars: 'flatCars',
  gondolasOpen: 'gondolasOpen',
  gondolasCovered: 'gondolasCovered',
  tanker: 'tanker',
};

export const trailerTypes = Object.values(trailerTypesByKey);
