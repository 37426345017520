import PropTypes from 'prop-types';

export const CarrierInformation = {
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  contactId: PropTypes.string,
  contactName: PropTypes.string,
  contactPhoneNumber: PropTypes.string,
  trailerType: PropTypes.string,
  trackingCargoCode: PropTypes.string,
};

export const CarrierInformationFormik = {
  company: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  trailerType: PropTypes.string,
  trackingCargoCode: PropTypes.string,
};

export default CarrierInformation;
