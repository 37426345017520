import { Decimal } from 'decimal.js';
import { get as _get } from 'lodash';
import { CARGO_TYPE } from '../../../cargoType';
import itemMovementType from '../itemMovementType/itemMovementType';

const appendTotalQuantity = (acc = [], itemMovement) => {
  const lastItem = acc[acc.length - 1];
  if (!lastItem) {
    return [{ ...itemMovement, totalQuantity: itemMovement.movedQuantity }];
  }
  return [...acc, { ...itemMovement, totalQuantity: new Decimal(lastItem.totalQuantity).add(itemMovement.movedQuantity).toNumber() }];
};

const getMovementType = ({ itemMovementType: itemMovement, quantity, ...movement }, cargoType, t) => {
  if (itemMovement === itemMovementType.Stuff && quantity > 0) {
    return [t(`item.itemMovementType.Unstuff`)];
  }
  if (itemMovement === itemMovementType.StuffForTransfer && quantity > 0) {
    return [t(`item.itemMovementType.UnstuffForTransfer`)];
  }
  const sameInventoryOrNull = _get(movement.sourceInventory, 'id') === _get(movement.destinationInventory, 'id');
  const sameOrderOrNull = _get(movement.sourceOrder, 'id') === _get(movement.destinationOrder, 'id');
  if (itemMovement === itemMovementType.Move && sameInventoryOrNull && sameOrderOrNull) {
    return [t(cargoType === CARGO_TYPE.breakbulk ? `batchActionTypes.Relocate` : `batchActionTypes.Move`)];
  }
  return [t(`item.itemMovementType.${itemMovement}`)];
};

function getLinkAnchorAsInnerText(label, link) {
  return link ? `<a class="-ref-link" target="_blank" rel="noreferrer noopener" href="${link}">${label}</a>` : label;
}

const getBreakbulkMovementsProps = ({ quantity, ...movement }) => {
  const getProps = (
    o,
    { prefixForBatchActionInventory: prefixBAI, prefixForBatchAction: prefixBA, prefixForItemInventory: prefixII, prefixForItem: prefixI }
  ) => ({
    inventoryId: _get(o, `${prefixII.toLowerCase()}Inventory.id`),
    inventoryName: _get(o, `${prefixII.toLowerCase()}Inventory.name`),
    actionId: _get(o, `movement${prefixBA}.${prefixBA.toLowerCase()}Id`),
    actionInventoryId: _get(o, `movement${prefixBAI}.${prefixBAI.toLowerCase()}InventoryId`),
    previousItemId: _get(o, `movement${prefixI}.${prefixI.toLowerCase()}InventoryItemsIds.0`),
  });

  const isDefaultOrientation = (isSource) => (isSource ? 'Source' : 'Destination');

  switch (movement.itemMovementType) {
    case itemMovementType.Stuff:
      return getProps(movement, {
        prefixForBatchActionInventory: isDefaultOrientation(quantity > 0),
        prefixForBatchAction: isDefaultOrientation(quantity < 0),
        prefixForItemInventory: isDefaultOrientation(true),
        prefixForItem: isDefaultOrientation(true),
      });
    case itemMovementType.Transfer:
      return getProps(movement, {
        prefixForBatchActionInventory: isDefaultOrientation(quantity > 0),
        prefixForBatchAction: isDefaultOrientation(quantity < 0),
        prefixForItemInventory: isDefaultOrientation(true),
        prefixForItem: isDefaultOrientation(true),
      });
    case itemMovementType.Move:
      return getProps(movement, {
        prefixForBatchActionInventory: isDefaultOrientation(quantity > 0),
        prefixForBatchAction: isDefaultOrientation(quantity < 0),
        prefixForItemInventory: isDefaultOrientation(true),
        prefixForItem: isDefaultOrientation(true),
      });
    case itemMovementType.Unstuff:
    default:
      return getProps(movement, {
        prefixForBatchActionInventory: isDefaultOrientation(quantity < 0),
        prefixForBatchAction: isDefaultOrientation(quantity > 0),
        prefixForItemInventory: isDefaultOrientation(true),
        prefixForItem: isDefaultOrientation(true),
      });
  }
};

const getBulkMovementsProps = (movement, quantity) => {
  const getProps = (o, prefixForItem = 'Source') => {
    const prefixForItemLowerCase = prefixForItem.toLocaleLowerCase();
    return {
      inventoryName: _get(o, `${prefixForItemLowerCase}Inventory.name`),
      previousItemId: _get(o, `movement${prefixForItem}.${prefixForItemLowerCase}InventoryItemsIds.0`),
    };
  };

  switch (movement.itemMovementType) {
    case itemMovementType.Stuff:
      return getProps(movement, 'Destination');
    case itemMovementType.Transfer:
    case itemMovementType.Move:
      return getProps(movement, quantity > 0 ? 'Source' : 'Destination');
    case itemMovementType.Unstuff:
    default:
      return getProps(movement, 'Source');
  }
};

const getBreakbulkMovementDescription = (movement, t) => {
  const { itemMovementType: movementType, projectName, quantity, destinationOrder, sourceOrder, linkInventoryItemId, linkInventoryItemName } = movement;
  const movementTypePart = getMovementType(movement, CARGO_TYPE.breakbulk, t);
  const { inventoryName, inventoryId } = getBreakbulkMovementsProps(movement);

  switch (movementType) {
    case itemMovementType.OrderTransfer: {
      const sourceOrderName = sourceOrder && sourceOrder.name;
      const destOrderName = destinationOrder && destinationOrder.name;
      return [
        movementTypePart,
        t(`item.itemMovement.${sourceOrderName ? 'fromOrder' : 'fromNoOrder'}`, { orderName: sourceOrderName }),
        t(`item.itemMovement.${destOrderName ? 'toOrder' : 'toNoOrder'}`, { orderName: destOrderName }),
      ];
    }
    case itemMovementType.Transfer:
    case itemMovementType.Move: {
      return [
        movementTypePart,
        t(`item.itemMovement.${quantity > 0 ? 'from' : 'to'}`, {
          entity: inventoryName,
          movementType: movementTypePart,
        }),
      ];
    }
    case itemMovementType.Stuff:
    case itemMovementType.Unstuff: {
      const itemLink = inventoryId && linkInventoryItemId && `/inventory/${inventoryId}/items/${linkInventoryItemId}/general-information`;
      return [
        movementTypePart,
        t(`item.itemMovement.${quantity > 0 ? 'from' : 'to'}`, {
          entity: getLinkAnchorAsInnerText(linkInventoryItemName || t('item.itemMovement.container'), itemLink),
          movementType: movementTypePart,
          interpolation: { escapeValue: false },
        }),
      ];
    }
    case itemMovementType.ShipmentByVessel:
    case itemMovementType.ReceptionByVessel:
      return [[movementTypePart, projectName].filter(Boolean).join(' - ')];
    default:
      return [movementTypePart];
  }
};

const getBulkMovementDescription = (movement, t) => {
  const { itemMovementType: movementType, projectName, quantity } = movement;
  const movementTypePart = getMovementType(movement, CARGO_TYPE.bulk, t);
  const { inventoryName } = getBulkMovementsProps(movement, quantity);

  switch (movementType) {
    case itemMovementType.Transfer:
      return [
        movementTypePart,
        t(`item.itemMovement.${quantity > 0 ? 'from' : 'to'}`, {
          entity: inventoryName,
          movementType: movementTypePart,
        }),
      ];
    case itemMovementType.ShipmentByVessel:
    case itemMovementType.ReceptionByVessel:
      return [[movementTypePart, projectName].filter(Boolean).join(' - ')];
    default:
      return [movementTypePart];
  }
};

const toRow = (itemMovement, options, t) => ({
  actionNumber: itemMovement.actionNumber,
  itemId: itemMovement.inventoryItemId,
  effectiveDate: itemMovement.effectiveDate,
  movementDescription: itemMovement.description,
  movementType: itemMovement.itemMovementType,
  movementSourceId: itemMovement.movementSource && itemMovement.movementSource.sourceId,
  movementSourceType: itemMovement.movementSource && itemMovement.movementSource.sourceType,
  sourceInventoryId: itemMovement.movementSource && itemMovement.movementSource.sourceInventoryId,
  movedQuantity: itemMovement.quantity,
  projectName: itemMovement.projectName,
  totalQuantity: 0,
  username: itemMovement.createdByUserName,
  timestamp: itemMovement.creationDate,
  authorizedBy: itemMovement.authorizedBy,
  reason: itemMovement.reason,
  itemAction: itemMovement.itemAction,
  noRowLink: !!options.hasLink, // text links will open a new tab, row links will open a modal
  className: options.className,
});

const toBreakbulkRow = (itemMovement, t, options = {}) => {
  const description = getBreakbulkMovementDescription(itemMovement, t).join(' ');
  return toRow({ ...itemMovement, description }, options);
};

const toBulkRow = (itemMovement, t, options = {}) => {
  const description = getBulkMovementDescription(itemMovement, t).join(' ');
  return toRow({ ...itemMovement, description }, options);
};

const toBulkList = (itemMovements, t) =>
  itemMovements
    .map((itemMovement) => toBulkRow(itemMovement, t))
    .reduceRight(appendTotalQuantity, [])
    .reverse();

const toBulkPreviousList = (itemMovements, t) =>
  itemMovements
    .map((itemMovement) => toBulkRow(itemMovement, t, { className: '-offset' }, t))
    .reduceRight(appendTotalQuantity, [])
    .reverse();

const toBreakbulkList = (itemMovements, t) =>
  itemMovements
    .map((itemMovement) => toBreakbulkRow(itemMovement, t))
    .reduceRight(appendTotalQuantity, [])
    .reverse();

const toBreakbulkPreviousList = (itemMovements, t) =>
  itemMovements
    .map((itemMovement) => toBreakbulkRow(itemMovement, t, { className: '-offset' }))
    .reduceRight(appendTotalQuantity, [])
    .reverse();

// eslint-disable-next-line import/no-anonymous-default-export
export default { toBreakbulkList, toBreakbulkPreviousList, toBulkList, toBulkPreviousList };
