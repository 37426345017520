import { useAuth0 } from '@auth0/auth0-react';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import HashedRouter, { UrlBuilder } from './common/HashedRouter';
import ErrorBoundary from './ErrorBoundary';
import { InventoryPage } from './inventory';
import { DocumentTitle, DocumentTitleProvider } from './layout/navigation/DocumentTitle';
import RoutedModal from './layout/RoutedModal';
import ProfilePage from './profile/ProfilePage';
import Modal from './layout/Modal';
import TermsAndConditions from './termsAndConditions/TermsAndConditions';
import WithAcceptation from './termsAndConditions/WithAcceptation';
import { TransactionPage } from './transaction';
import AppointmentPage from './appointment/AppointmentPage';
import LayoutPage from './layout/LayoutPage';
import MaintenancePage from './maintenance/MaintenancePage';
import axios from 'axios';
import { CompaniesContextProvider } from './layout/navigation/CompaniesContextProvider';
import viewAsCompanyRoles from './common/helpers/viewAsCompanyRoles';
import ExcelReportDashboard from './excelReports/components/ExcelReportsPage';

export const ROUTES = {
  transaction: {
    component: TransactionPage,
    path: '/transaction',
    i18nKey: 'transaction',
    icon: AccountBalanceIcon,
  },
  inventory: {
    component: InventoryPage,
    path: '/inventory',
    i18nKey: 'inventory',
    icon: ListAltIcon,
  },
  reports: {
    component: ExcelReportDashboard,
    path: '/reports',
    i18nKey: 'reports',
    icon: AssessmentOutlinedIcon,
  },
  appointments: {
    component: AppointmentPage,
    path: '/appointments',
    i18nKey: 'appointments',
    icon: CalendarIcon,
  },
  profile: {
    component: ProfilePage,
    componentProps: {
      LayoutComponent: LayoutPage,
    },
    path: '/profile',
    i18nKey: 'profile',
    icon: PermIdentityIcon,
  },
  maintenance: {
    component: MaintenancePage,
    componentProps: {
      mainMessageKey: 'clientPortalMainMessage',
      secondaryMessageKey: 'clientPortalSecondaryMessage',
      module: 'client',
    },
    path: '/maintenance',
    i18nKey: 'maintenance',
  },

  // ANY ROUTE AFTER THIS ONE WILL NOT BE REACHED
};

const MyRoute = ({ Component, title, ...rest }) => {
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === '/maintenance') return;

    axios.get(`/api/v1/maintenance/client`).catch((err) => {
      if (err.response.status === 503) {
        history.push({ state: { secondaryMessage: err.response.data }, pathname: '/maintenance' });
      }
    });
  }, [history]);

  const { t } = useTranslation();

  return (
    <>
      <DocumentTitle title={t(title)} />
      <ErrorBoundary>
        <Component {...rest} />
      </ErrorBoundary>
    </>
  );
};

MyRoute.propTypes = {
  Component: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const termsAndConditionsAcceptedDate = 'http://qsl.com/termsAndConditionsAcceptedDate';

const Router = ({ groups }) => {
  const { user, logout } = useAuth0();
  const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] = useState(user && user[termsAndConditionsAcceptedDate]);
  const handleAccept = () => setHasAcceptedTermsAndConditions(true);
  const handleRefuse = () => logout({ returnTo: window.location.origin });

  const canViewAsCompany = groups.some((g) => viewAsCompanyRoles.includes(g));
  const canAccessApplication = hasAcceptedTermsAndConditions || canViewAsCompany;

  return (
    <DocumentTitleProvider>
      <CompaniesContextProvider canViewAsCompany={canViewAsCompany}>
        <BrowserRouter>
          {canAccessApplication && (
            <Switch>
              {Object.values(ROUTES).map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  render={(routeProps) => <MyRoute {...routeProps} {...route.componentProps} title={route.i18nKey} Component={route.component} />}
                />
              ))}
              <Redirect from="/" to={ROUTES.inventory.path} />
            </Switch>
          )}
          <HashedRouter
            routes={[
              {
                path: UrlBuilder.buildPattern('terms-and-conditions'),
                // eslint-disable-next-line react/prop-types
                component: () => (
                  <RoutedModal fullWidth maxWidth="lg">
                    <TermsAndConditions />
                  </RoutedModal>
                ),
              },
            ]}
          />
          <Modal open={!canAccessApplication}>
            <WithAcceptation onRefuse={handleRefuse} onAccept={handleAccept}>
              <TermsAndConditions />
            </WithAcceptation>
          </Modal>
        </BrowserRouter>
      </CompaniesContextProvider>
    </DocumentTitleProvider>
  );
};

Router.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default React.memo(Router);
