import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import getActiveFiltersCount from '../../common/filters/getActiveFiltersCount';
import { getSafeQueryStringArray } from '../../common/helpers/searchParamsHelper';
import Badge from '../../layout/Badge';
import Collapsible from '../../layout/Collapsible/Collapsible';
import MultiSelectField from '../../layout/fields/MultiSelectField';
import IdentifiersFilter from '../../layout/Identifiers/IdentifiersFilter';
import queryStringOption from '../../layout/ListPage/headers/queryStringOption';
import WeightAndDimensionsFilter from '../../weightAndDimensions/WeightAndDimensionsFilter';
import { getPackagingTypeOptions } from './packagingTypes';
import useAdvancedFiltersValidationSchema from './useAdvancedFiltersValidationSchema';

const makeRowStyles = makeStyles(() => ({
  root: {
    marginBottom: '1rem',
  },
}));

const getInitialValues = (params) => {
  const searchValues = qs.parse(params.toString(), queryStringOption);
  return {
    weightAndDimensions: getSafeQueryStringArray(searchValues?.weightAndDimensions),
    identifiers: getSafeQueryStringArray(searchValues?.identifiers),
    packaging: getSafeQueryStringArray(searchValues?.packaging),
  };
};

const AdvancedFiltersSection = ({ onSearch }) => {
  const { t } = useTranslation('transaction');
  const { search } = useLocation();

  const initialValues = getInitialValues(new URLSearchParams(search));

  const validationSchema = useAdvancedFiltersValidationSchema();
  const rowStyles = makeRowStyles();
  const packagingOptions = useMemo(() => getPackagingTypeOptions(t), [t]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSearch} validationSchema={validationSchema} enableReinitialize>
      {({ submitForm, initialValues }) => (
        <Collapsible title={t('advancedFilters')} titleBadge={<Badge content={getActiveFiltersCount(initialValues)} />}>
          <Grid container>
            <Grid classes={rowStyles} item xs={12} md={12}>
              <MultiSelectField options={packagingOptions} name="packaging" label={t('packagingType')} />
            </Grid>
            <Grid classes={rowStyles} item xs={12} md={12}>
              <FieldArray name="weightAndDimensions">
                {({ push, remove }) => <WeightAndDimensionsFilter push={push} remove={remove} name="weightAndDimensions" />}
              </FieldArray>
            </Grid>
            <Grid classes={rowStyles} item xs={12} md={12}>
              <FieldArray name="identifiers">{({ push, remove }) => <IdentifiersFilter push={push} remove={remove} name="identifiers" />}</FieldArray>
            </Grid>
            <Grid classes={rowStyles} item xs={12} md={12}>
              <Button onClick={submitForm} variant="contained" color="primary">
                {t('search')}
              </Button>
            </Grid>
          </Grid>
        </Collapsible>
      )}
    </Formik>
  );
};

AdvancedFiltersSection.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default AdvancedFiltersSection;
