import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import InputListSearch from '../../InputListSearch/InputListSearch';
import Title from '../../Title';
import ClearFilterButton from './component/ClearFilterButton';
import DateFilter from './component/DateFilter';
import { dateFilterDropDownOptions, dateFilterDropDownValues } from './component/DateFilterValues';
import ListPageHeaderLayout from './component/ListPageHeaderLayout';
import ListPageHeaderPills from './component/ListPageHeaderPills';
import withClearFilter from './hoc/withClearFilter';
import queryStringOption from './queryStringOption';

const EmptyComponent = () => <div />;

const dropDownValueKey = 'dropDownValue';
const periodKey = 'period';
const textQueryKey = 'textQuery';
const orderByKey = 'orderby';

const ListPageHeaderWithDateFilter = ({ title, actions, advancedFilterSection, onParamChange, pills, subscript, dateFilterOptions }) => {
  dateFilterOptions = {
    propertyNames: { from: 'startDateValue', to: 'endDateValue' },
    canClearFilter: false,
    ...dateFilterOptions,
  };
  const { from: fromKey, to: toKey } = dateFilterOptions.propertyNames;
  const dateFilterFields = useMemo(() => [fromKey, toKey, dropDownValueKey, periodKey], [fromKey, toKey]);
  const location = useLocation();

  const params = useMemo(() => {
    const updatedParams = new URLSearchParams(location.search);
    const dropDownValue = updatedParams.get(dropDownValueKey);

    if (!dropDownValue || !Object.values(dateFilterDropDownOptions).includes(dropDownValue)) {
      updatedParams.set(dropDownValueKey, dateFilterDropDownOptions.AllDate);
      return updatedParams;
    }

    const { startDate, endDate, period } = dateFilterDropDownValues[dropDownValue];

    if (period) {
      updatedParams.set(fromKey, startDate.format());
      updatedParams.set(toKey, endDate.format());
      updatedParams.set(periodKey, period);
    } else {
      updatedParams.has(fromKey) || updatedParams.has(toKey)
        ? updatedParams.set(dropDownValueKey, dateFilterDropDownOptions.Custom)
        : updatedParams.set(dropDownValueKey, dateFilterDropDownOptions.AllDate);
    }

    return updatedParams;
  }, [fromKey, toKey, location.search]);

  const defaultQuery = params.get(textQueryKey) || '';

  const handleSearchEnter = useCallback(
    ({ target: { value } }) => {
      if (defaultQuery !== value) {
        value ? params.set(textQueryKey, value) : params.delete(textQueryKey);
        onParamChange(params);
      }
    },
    [params, onParamChange, defaultQuery]
  );

  const handleSearch = useCallback(
    (values) => {
      if (!values) return;
      const searchValues = qs.parse(params.toString(), { ...queryStringOption, arrayFormat: 'repeat' });
      const otherSearchValues = qs.stringify(_.omit(searchValues, Object.keys(values)), { ...queryStringOption, arrayFormat: 'repeat' });
      const valuesSearchParams = qs.stringify(
        _.omitBy(values, (v) => v === undefined || v === null || v === '' || ((Array.isArray(v) || _.isObject(v)) && _.isEmpty(v))),
        queryStringOption
      );

      onParamChange(new URLSearchParams(`${otherSearchValues}&${valuesSearchParams}`));
    },
    [params, onParamChange]
  );

  const handleDateChanged = useCallback(
    ({ startDateValue, endDateValue }) => handleSearch({ [fromKey]: startDateValue, [toKey]: endDateValue }),
    [fromKey, handleSearch, toKey]
  );

  const handleClearFilter = useCallback(() => {
    const urlParams = new URLSearchParams();
    if (!dateFilterOptions.canClearFilter) {
      dateFilterFields.forEach((fieldName) => {
        if (params.has(fieldName)) {
          urlParams.set(fieldName, params.get(fieldName));
        }
      });
    }
    if (params.has(orderByKey)) urlParams.set(orderByKey, params.get(orderByKey));
    onParamChange(urlParams);
  }, [dateFilterOptions.canClearFilter, params, onParamChange, dateFilterFields]);

  const handleDropDownChanged = useCallback(
    ({ dropDownValue, period }) => {
      params.set(dropDownValueKey, dropDownValue);
      period ? params.set(periodKey, period) : params.delete(periodKey);
    },
    [params]
  );

  const shouldShowClearFilters = useMemo(() => {
    const excludedParams = [orderByKey, ...(!dateFilterOptions.canClearFilter ? dateFilterFields : [])];
    return Array.from(params.keys()).some((param) => !excludedParams.includes(param));
  }, [dateFilterFields, dateFilterOptions.canClearFilter, params]);

  return (
    <>
      <ListPageHeaderLayout>
        <div className="flex items-start mb-4">
          <Title title={title} />
          <InputListSearch onEnterPressed={handleSearchEnter} defaultValue={defaultQuery} />
          <div className={`inline flex-initial ml-auto`}>
            <DateFilter
              handleDateChanged={handleDateChanged}
              handleDropDownChanged={handleDropDownChanged}
              startDate={params.get(fromKey)}
              endDate={params.get(toKey)}
              dropDownValue={params.get(dropDownValueKey)}
              period={params.get(periodKey)}
            />
          </div>
        </div>

        {subscript}
        {advancedFilterSection ? React.createElement(advancedFilterSection, { onSearch: handleSearch }) : undefined}
      </ListPageHeaderLayout>

      <div className={`flex flex-wrap items-baseline`}>
        <div className="flex flex-wrap">
          <ListPageHeaderPills pills={pills} />
        </div>

        <div className={`inline flex-initial ml-auto`}>
          {shouldShowClearFilters && <ClearFilterButton onClick={handleClearFilter} />}
          {actions}
        </div>
      </div>
    </>
  );
};

ListPageHeaderWithDateFilter.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
  advancedFilterSection: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object, PropTypes.func]),
  onParamChange: PropTypes.func,
  pills: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  query: PropTypes.string,
  subscript: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
  hasDateFilter: PropTypes.bool,
  dateFilterOptions: PropTypes.shape({
    propertyNames: PropTypes.shape({ from: PropTypes.string, to: PropTypes.string }),
    canClearFilter: PropTypes.bool,
  }),
};

ListPageHeaderWithDateFilter.defaultProps = {
  actions: null,
  advancedFilterSection: EmptyComponent,
  onParamChange: () => undefined,
  pills: [],
  subscript: null,
  title: null,
  hasDateFilter: false,
};

export default withClearFilter(ListPageHeaderWithDateFilter);
