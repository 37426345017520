import PropTypes from 'prop-types';
import Icon, { iconSizes } from './Icon';
import svg from '../../common/icons/assistance.svg';

const AssistanceIcon = ({ size, styles }) => (
  <Icon size={size} styles={styles}>
    <img alt="" src={svg} />
  </Icon>
);

AssistanceIcon.propTypes = {
  size: PropTypes.string,
  styles: PropTypes.shape(),
};

AssistanceIcon.defaultProps = {
  size: iconSizes.medium,
  styles: {},
};

export default AssistanceIcon;
