import { Grid, useMediaQuery } from '@material-ui/core';
import i18n from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import useGetAppointments from '../appointment/list/useGetAppointments';
import useGetSites from '../appointment/list/useGetSites';
import ListPageHeaderWithSiteFilter from '../layout/ListPage/headers/ListPageHeaderWithSiteFilter';
import LoadingIndicator from '../layout/loading/LoadingIndicator';
import Breakpoint from '../layout/mediaQuery';
import { statusesByKey } from './dataTypes';
import TrafficHeader from './TrafficHeader';
import TrafficStatusSection from './TrafficStatusSection';
import { maximumAppointmentItems } from '../constant';

const BECANCOUR_SITE_ID = '5b7eeee414e179040614d526';
const REFRESH_INTERVAL_IN_SECONDS = 120;
const GRID_WIDTH = 12;

const BECANCOUR_TERMINAL_STATUSES = [
  [statusesByKey.Scheduled],
  [statusesByKey.InQueue],
  [statusesByKey.OnTerminal],
  [statusesByKey.InProcessing],
  [statusesByKey.Loaded, statusesByKey.Unloaded],
  [statusesByKey.Departed],
];

const TERMINAL_STATUSES = [[statusesByKey.Scheduled], [statusesByKey.OnTerminal], [statusesByKey.InProcessing], [statusesByKey.Loaded, statusesByKey.Unloaded]];

const TrafficPage = (props) => {
  const [refreshing, setRefreshing] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(new Date());

  const [getAppointments, data = {}] = useGetAppointments();
  const [fetchSites, sites = []] = useGetSites();

  const getDefaultSelection = useCallback(() => {
    const storageSite = localStorage.getItem('selectedSiteName');
    if (props.allowedSites.includes(storageSite)) {
      return storageSite;
    }
    localStorage.setItem('selectedSiteName', props.allowedSites[0]); //Cleanup value in localstorage if not in the allowed sites
    return props.allowedSites[0];
  }, [props.allowedSites]);

  const [selectedSiteName, setSelectedSiteName] = useState(getDefaultSelection());

  const becancourSiteName = sites.find((s) => s.id === BECANCOUR_SITE_ID)?.name;
  const terminalStatuses = becancourSiteName && selectedSiteName === becancourSiteName ? BECANCOUR_TERMINAL_STATUSES : TERMINAL_STATUSES;
  const appointments = data.items || [];

  useEffect(() => {
    (async () => await fetchSites())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshAppointments = useCallback(async () => {
    const todayDate = moment(new Date()).local().startOf('day');

    const params = new URLSearchParams({
      sites: selectedSiteName,
      startDateValue: todayDate.format(),
      endDateValue: todayDate.format(),
      take: maximumAppointmentItems,
    });

    terminalStatuses
      .flatMap((x) => x)
      .forEach((status) => {
        params.append('status', status);
      });

    setRefreshing(true);
    try {
      await getAppointments(params);
      setLastUpdate(new Date());
    } catch {}
    setRefreshing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSiteName]);

  useEffect(() => {
    (async () => await refreshAppointments())();
    const id = setInterval(refreshAppointments, REFRESH_INTERVAL_IN_SECONDS * 1000);
    return () => clearInterval(id);
  }, [refreshAppointments]);

  const handleSiteChange = (value) => {
    localStorage.setItem('selectedSiteName', value);
    setSelectedSiteName(value);
  };

  let numberOfItemsPerRow = 2;
  if (useMediaQuery(Breakpoint.tablet)) numberOfItemsPerRow = 3;
  if (useMediaQuery(Breakpoint.computer)) numberOfItemsPerRow = 4;
  const numberOfRows = terminalStatuses.length / numberOfItemsPerRow;

  return (
    <>
      <ListPageHeaderWithSiteFilter title={i18n.t('appointments')} allowedSites={props.allowedSites} onChangeSite={handleSiteChange} subscript={props.tabs} />

      {refreshing && <LoadingIndicator />}
      <TrafficHeader onRefreshClick={refreshAppointments} selectedSiteId={selectedSiteName} lastUpdate={lastUpdate} />

      <Grid container spacing={3}>
        {terminalStatuses.map((unitedStatuses) => (
          <TrafficStatusSection
            key={unitedStatuses.join('-')}
            span={Math.floor(GRID_WIDTH / Math.ceil(terminalStatuses.length / numberOfRows))}
            status={unitedStatuses.join('-')}
            currentTimeZone={sites.find((s) => s.name === selectedSiteName)?.timezone}
            appointments={appointments.filter((a) => unitedStatuses.includes(a.status))}
          />
        ))}
      </Grid>
    </>
  );
};

TrafficPage.propTypes = {
  allowedSites: PropTypes.arrayOf(PropTypes.string).isRequired,
  header: PropTypes.node,
  tabs: PropTypes.node,
};

export default TrafficPage;
