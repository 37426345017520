import PropTypes from 'prop-types';
import Location from './Location';

export const LocationHistory = {
  ...Location,
  createdByUserId: PropTypes.string,
  createdByUserName: PropTypes.string,
  creationDate: PropTypes.string,
};

export default LocationHistory;
