const transportation = {
  appointment: 'Appointment',
  arrival: 'Arrival',
  arrivalAtFleet: 'Arrival at Fleet',
  arrivalDate: 'Arrival Date',
  bargeNumber: 'Barge Number',
  billable: 'Billable',
  carrier: 'Carrier',
  carrierBill: "Carrier's Bill",
  client: 'Client',
  completion: 'Completion',
  confirmation: 'Confirmation',
  confirmedBy: 'Confirmed By',
  departure: 'Departure',
  driverName: 'Driver Name',
  finishedBy: 'Finished By',
  loadingEndDate: 'Loading End Date',
  loadingStartDate: 'Loading Start Date',
  mainInformation: 'Main Information',
  multipleTrucks: 'Multiple Trucks',
  numberOfRailcars: 'Number of Railcars',
  numberOfTrucks: 'Number of Trucks',
  paperworkReceivedDate: 'Paperwork Received Date',
  pickup: 'Pickup',
  plannedDate: 'Planned Date & Time',
  plateNumber: 'Plate Number',
  pointOfOrigin: 'Point of Origin',
  railcarNumber: 'Railcar Number',
  receivedFrom: 'Received From',
  receivedTo: 'Received To',
  reception: 'Reception',
  release: 'Release',
  requestedSpotting: 'Requested Spotting',
  takenBy: 'Taken By',
  transloadStevedore: 'Transload Stevedore',
  transportation: 'Transportation',
  vessel: 'Vessel',
  numberOfHolds: 'Number of Holds',
  shippedFrom: 'Shipped From',
  shippedTo: 'Shipped To',
  singleTruck: 'Single Truck',
  type: 'Type',
};

export default transportation;
