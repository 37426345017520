import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const useTitleStyles = makeStyles((theme) => ({
  root: { color: theme.palette.primary.main, padding: theme.spacing(2) },
}));

export const useDialogActionsStyles = makeStyles({
  root: {
    justifyContent: 'center',
  },
});

export const useModalStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

const RoutedModal = ({ children, title, maxWidth, fullWidth }) => {
  const { t } = useTranslation('layout');

  const {
    replace,
    location: { pathname, search },
  } = useHistory();

  const handleClose = () => replace(`${pathname}${search}`);

  return (
    <Dialog open={true} maxWidth={maxWidth} fullWidth={fullWidth} classes={useModalStyles()} onClose={handleClose}>
      <DialogTitle onClose={handleClose} classes={useTitleStyles()}>
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions classes={useDialogActionsStyles()}>
        <Button onClick={handleClose} color="primary" variant="contained">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RoutedModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  maxWidth: PropTypes.oneOf(['lg', 'md', 'xl', 'sm', 'xs']),
  fullWidth: PropTypes.bool,
};

RoutedModal.defaultProps = {
  title: '',
  fullWidth: false,
};

export default RoutedModal;
