import PropTypes from 'prop-types';

const Transportation = {
  driverName: PropTypes.string,
  carrierBill: PropTypes.string,
  transportationNumber: PropTypes.string,
  arrivalDate: PropTypes.string,
  portCardNumber: PropTypes.string,
};

export default Transportation;
