/* eslint-disable react/jsx-no-target-blank */
import { Chip, Grid, Typography } from '@material-ui/core';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../layout';
import TransportationTrafficIcon from '../layout/icons/transportations/TransportationIconTraffic';
import { getTimeSlotTime, getTimeSlotToText } from './helpers';
import { Appointment } from './types';

const TrafficStatusSection = (props) => {
  const { t } = useTranslation('traffic');
  const { t: i18nAppointment } = useTranslation('appointment');

  const getTooltip = memoizeOne((data, timeZone) => {
    const { cargoType, number, actionType, carrierInformation, transportation } = data;
    const { trailerType, companyName } = carrierInformation || {};
    const { transportationNumber, driverName } = transportation || {};

    const startTime = getTimeSlotTime(data.startDate, timeZone);
    const endTime = getTimeSlotTime(data.endDate, timeZone);

    return Object.entries({
      cargoType,
      appointment: number,
      actionType,
      timeSlot: getTimeSlotToText(startTime, endTime),
      carrierName: companyName,
      driverName,
      vehicleIdentifier: transportationNumber,
      trailerType,
    })
      .filter(([, value]) => !!value)
      .map(([key, value]) => {
        const i18nKey = `dataTypes.${key}.${value}`;
        const translatableProperties = ['cargoType', 'actionType', 'trailerType'];
        const propertyValue = translatableProperties.includes(key) ? t(i18nKey) : value;

        const propertyName = i18nAppointment(`list.${key}`);
        return <div>{`${propertyName}: ${propertyValue}`}</div>;
      });
  });

  const { appointments, status } = props;

  return (
    <Grid item xs={props.span} className="flex flex-col" data-testid={`grid_${status}`}>
      <Grid container>
        <Grid item xs={12} className="flex justify-between pb-4">
          <Typography variant="h2" color="textSecondary">
            {i18nAppointment(`statuses.${status}`)}
          </Typography>
          <Chip style={{ lineHeight: 'normal' }} size="small" label={`${t('count')}: ${appointments.length}`} />
        </Grid>
      </Grid>
      <Grid container className="tile__traffic">
        <Grid item xs={3} className="appointment-row-cell -header">
          <Typography variant="h3" color="textSecondary">
            {t('appointmentNumber')}
          </Typography>
        </Grid>
        <Grid item xs={1} /> {/* empty space to force wrap in first cell */}
        <Grid item xs={8} className="appointment-row-cell -header">
          <Typography variant="h3" color="textSecondary">
            {t('vehicleIdentifier')}
          </Typography>
        </Grid>
        {appointments.map((a) => {
          const { id, number, actionType, carrierInformation, transportation, transportationType } = a;
          const { trailerType } = carrierInformation || {};
          const { transportationNumber } = transportation || {};
          const transportationIcon = (
            <TransportationTrafficIcon actionType={actionType} transportationType={transportationType} trailerType={trailerType} size={60} />
          );

          return (
            <Tooltip key={id} title={getTooltip(a, props.currentTimeZone)} placement="top">
              <Grid container className="appointment-row">
                <Grid item xs={4} className="appointment-row-cell" data-testid={`appointmentNumber_${number}`}>
                  {number || '-'}
                </Grid>
                <Grid item xs={6} className="appointment-row-cell" data-testid={`appointmentTransportationNumber_${transportationNumber}`}>
                  {transportationNumber || '-'}
                </Grid>
                <Grid item xs={2}>
                  {transportationIcon}
                </Grid>
              </Grid>
            </Tooltip>
          );
        })}
      </Grid>
    </Grid>
  );
};

TrafficStatusSection.propTypes = {
  span: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  currentTimeZone: PropTypes.string,
  appointments: PropTypes.arrayOf(PropTypes.shape(Appointment)).isRequired,
};

TrafficStatusSection.defaultProps = {
  currentTimeZone: 'America/Toronto',
};

export default TrafficStatusSection;
