import Icon from '@vooban/icon';
import _, { isEqual } from 'lodash';
import _sortBy from 'lodash.sortby';
import _uniqWith from 'lodash.uniqwith';
import React from 'react';
import { Tooltip } from '../../layout';

export const getHazardousMaterialInfoLine = (hmiEntry, t) => {
  const line = [];
  const { hazardClass, unNumber, packagingGroup, hasLimitedQuantity } = hmiEntry;
  if (hasLimitedQuantity) line.push(t('item.limitedQuantity'));
  if (hazardClass) line.push(`${t('list.class')}: ${hazardClass}`);
  if (unNumber) line.push(`${t('list.hazardousMaterialInformation.unNumber')}: ${unNumber}`);
  if (packagingGroup) line.push(`${t('list.hazardousMaterialInformation.packagingGroup')}: ${packagingGroup}`);
  return line;
};

export const sortHazardousMaterialInformation = (hazardousMaterialInformation) => {
  if (!hazardousMaterialInformation) return undefined;
  if (!Array.isArray(hazardousMaterialInformation)) return hazardousMaterialInformation;
  const distinctHazardousMaterialInformation = hazardousMaterialInformation.map((hmi) => _.omit(hmi, ['id']));
  return _sortBy(_.uniqWith(distinctHazardousMaterialInformation, _.isEqual), (hmi) => [hmi.hasLimitedQuantity, hmi.hazardClass]);
};

export const getHazardousMaterialInfo = (hmi, t) => {
  return sortHazardousMaterialInformation(hmi).reduce((acc, entry) => {
    const line = getHazardousMaterialInfoLine(entry, t);
    if (line.length > 0) acc.push(line.join(' '));
    return acc;
  }, []);
};

export const getHazardousIcon = (hazardousMaterialInformation, t, className) => {
  const result = _uniqWith(getHazardousMaterialInfo(hazardousMaterialInformation, t), isEqual);
  const tooltipText = result.join('<br />');
  return (
    <Tooltip arrow spanStyle={{ cursor: 'allowed' }} title={<span dangerouslySetInnerHTML={{ __html: tooltipText }} />} placement="top">
      <Icon id="error" className={`icon -error ${className}`} />
    </Tooltip>
  );
};
