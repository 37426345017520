import axios from 'axios';

const url = `/api/v1/projects`;

export const getProjectsWithResourceRequirements = (startDate, endDate, siteId, projectType) => {
  return axios.get(`${url}/include-resources`, {
    params: {
      startDate,
      endDate,
      siteId,
      projectTypes: [projectType],
    },
  });
};
