import PropTypes from 'prop-types';
import Icon from '../Icon';
import defaultTrailerIconSize from './constants';

const TrailerIcon = ({ size, styles, alt, svg }) => (
  <Icon style={styles}>
    <img alt={alt} src={svg} style={{ width: size, display: 'inline' }} />
  </Icon>
);

TrailerIcon.propTypes = {
  size: PropTypes.number,
  styles: PropTypes.shape(),
  alt: PropTypes.string,
  svg: PropTypes.string,
};

TrailerIcon.defaultProps = {
  size: defaultTrailerIconSize,
  styles: {},
  alt: '',
  svg: '',
};

export default TrailerIcon;
