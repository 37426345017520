import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { LayoutPageWithTabs } from '../../../layout';
import useGetMyCompanies from '../breakbulk/useGetMyCompanies';
import { ItemMediaFiles } from '../mediaFiles';
import { InventoryItem } from '../types';
import AgnicoEagleAssignationsPage from './AgnicoEagleAssignationsPage';
import ContainedItemsPage from './containedItems/ContainedItemsPage';
import ContainersPage from './containers/ContainersPage';
import GeneralInformationPage from './GeneralInformationPage';
import ItemMovementsPage from './ItemMovementsPage';
import { CARGO_TYPE } from '../../../cargoType';

const ROUTES = {
  movements: {
    component: ItemMovementsPage,
    path: '/movements',
    i18nKey: 'item.movements',
  },
  mediaFiles: {
    component: ItemMediaFiles,
    path: '/media-files',
    i18nKey: 'item.mediaFiles',
  },
  generalInformation: {
    component: GeneralInformationPage,
    path: '/general-information',
    i18nKey: 'item.generalInformation',
  },
  containedItems: {
    component: ContainedItemsPage,
    path: '/contained-items',
    i18nKey: 'item.containedItems',
  },
  containers: {
    component: ContainersPage,
    path: '/containers',
    i18nKey: 'item.containers',
  },
  agnicoEagleAssignations: {
    component: AgnicoEagleAssignationsPage,
    path: '/aem-po',
    i18nKey: 'item.aemPo',
  },
};

const ItemDetailsRouter = ({ item }) => {
  const { path, url } = useRouteMatch();

  const { t } = useTranslation('inventory');

  const [getMyCompanies, myCompanies = []] = useGetMyCompanies();

  const isAgnicoContact = myCompanies.some((c) => c.name.toLowerCase().includes('agnico'));

  useEffect(() => {
    getMyCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = useMemo(() => {
    const baseTabs = [
      { label: t('item.generalInformation'), path: `${url}/general-information` },
      { label: t('item.movements'), path: `${url}/movements` },
    ];

    const conditionalTabs = [
      ...(item?.cargoType === CARGO_TYPE.breakbulk ? [{ label: t('item.mediaFiles'), path: `${url}/media-files` }] : []),
      ...(item?.isStuffableContainer ? [{ label: t('item.containedItems'), path: `${url}/contained-items` }] : []),
      ...(item?.canBeContainerized ? [{ label: t('item.containers'), path: `${url}/containers` }] : []),
      ...(isAgnicoContact ? [{ label: t('item.aemPo'), path: `${url}/aem-po` }] : []),
    ];

    return [...baseTabs, ...conditionalTabs];
  }, [isAgnicoContact, item, t, url]);

  return (
    <>
      <Switch>
        {Object.values(ROUTES).map((route) => (
          <Route
            key={route.path}
            path={`${path}${route.path}`}
            render={(routeProps) => (
              <LayoutPageWithTabs currentTab={routeProps.match.url} tabs={tabs}>
                {React.createElement(route.component, {
                  ...routeProps,
                  item,
                })}
              </LayoutPageWithTabs>
            )}
          />
        ))}
        <Route path="/">
          <Redirect to={`${url}/general-information`} />
        </Route>
      </Switch>
    </>
  );
};

ItemDetailsRouter.propTypes = {
  item: PropTypes.shape(InventoryItem),
};

export default ItemDetailsRouter;
