import { Typography } from '@material-ui/core';
import { get as _get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CARGO_TYPE } from '../../../cargoType';
import LoadingIndicator from '../../../layout/loading/LoadingIndicator';
import ItemMovementListHelper from '../itemMovement/itemMovementHelpers';
import ItemMovementList from '../itemMovement/ItemMovementList';
import { InventoryItem } from '../types/InventoryItem';
import { useGetItemMovements } from './useGetItem';

// buffer of 30 secs to include those that were executed during the same operation
const OPERATION_TIME_BUFFER_SECONDS = 30;

const getPreviousMovementProps = (movement) => {
  const getProps = (object, prefix = 'Destination') => {
    const prefixLowerCase = prefix.toLocaleLowerCase();
    return {
      order: _get(object, `${prefixLowerCase}Order`),
      locationName: _get(object, `${prefixLowerCase}LocationName`),
      inventoryId: _get(object, `${prefixLowerCase}Inventory.id`),
      inventoryName: _get(object, `${prefixLowerCase}Inventory.name`),
      inventoryItemId: _get(object, `movement${prefix}.${prefixLowerCase}InventoryItemsIds.0`),
    };
  };
  return getProps(movement, 'Destination');
};

const getPreviousMovementsHeader = ([prevItemMovement], t) => {
  const movementProps = getPreviousMovementProps(prevItemMovement);
  const { inventoryName, order, locationName } = movementProps;
  const { name: orderName = t('item.noOrder') } = order || {};
  return <span>{[inventoryName, orderName, locationName].filter(Boolean).join(' - ')}</span>;
};

const ItemMovementsPage = ({ item }) => {
  const { inventoryId, itemId } = useParams();
  const [fetchItemMovements, itemMovementsData = {}, isLoading] = useGetItemMovements();
  const { t, i18n } = useTranslation('inventory');

  const previousMovements = useMemo(() => {
    return (itemMovementsData.previousMovements || []).reduce((acc, movementList) => {
      const latestMovements = acc.length ? acc.flat() : itemMovementsData.movements;

      const latestMovementDates = latestMovements.map((x) => x.effectiveDate);
      latestMovementDates.sort();
      const [firstDate] = latestMovementDates;

      return [...acc, movementList.filter((m) => moment(firstDate).diff(m.effectiveDate) > -OPERATION_TIME_BUFFER_SECONDS)];
    }, []);
  }, [itemMovementsData]);

  const getHasPreviousMovements = () => previousMovements.length > 0;

  useEffect(() => {
    if (inventoryId && itemId && item) {
      fetchItemMovements(inventoryId, itemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const movements = useMemo(() => {
    if (!item || !itemMovementsData.movements?.length) return [];
    return item.cargoType === CARGO_TYPE.breakbulk
      ? ItemMovementListHelper.toBreakbulkList(itemMovementsData.movements, t)
      : ItemMovementListHelper.toBulkList(itemMovementsData.movements, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, itemMovementsData, i18n.language]);

  const getPrevMovements = (prevMovements) => {
    if (!item || !prevMovements.length) return [];
    return item.cargoType === CARGO_TYPE.breakbulk
      ? ItemMovementListHelper.toBreakbulkPreviousList(prevMovements, t)
      : ItemMovementListHelper.toBulkPreviousList(prevMovements, t);
  };

  return (
    <>
      <LoadingIndicator show={isLoading} />
      <div className="pb-4 flex justify-between">
        <h1 className="text-lg inline-block" data-testid="title_movements">
          {t('item.movements')}
        </h1>
      </div>
      <ItemMovementList inventoryId={inventoryId} itemMovements={movements} cargoType={item?.cargoType} height={getHasPreviousMovements() ? 0 : undefined} />
      {getHasPreviousMovements() && (
        <div className="rdg-hide-header">
          <div className="pb-3 pt-3 flex justify-between">
            <Typography style={{ textTransform: 'uppercase' }}>{t('item.previously')}</Typography>
          </div>
          {previousMovements
            .filter((x) => x.length > 0)
            .map((prevItemMovements, index) => (
              <div key={String(index)}>
                <div className="pb-2">
                  <Typography>{getPreviousMovementsHeader(prevItemMovements, t)}</Typography>
                </div>
                <ItemMovementList inventoryId={inventoryId} itemMovements={getPrevMovements(prevItemMovements)} cargoType={item?.cargoType} height={0} />
              </div>
            ))}
        </div>
      )}
    </>
  );
};

ItemMovementsPage.propTypes = {
  item: PropTypes.shape(InventoryItem).isRequired,
};

export default ItemMovementsPage;
