import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Attachments } from '../../../attachment';
import HashedRouter, { UrlBuilder } from '../../../common/HashedRouter';
import LoadingIndicator from '../../../layout/loading/LoadingIndicator';
import { DocumentTitle } from '../../../layout/navigation/DocumentTitle';
import RoutedModal from '../../../layout/RoutedModal';
import url from '../attachments/url';
import ClientInventoryItemHeader from './ClientInventoryItemHeader';
import ItemDetailsRouter from './ItemDetailsRouter';
import { useGetItem } from './useGetItem';

const attachmentPattern = UrlBuilder.buildPattern('/attachments/:inventoryId/item/:itemId');

const InventoryItemPage = () => {
  const { inventoryId, itemId } = useParams();
  const { t } = useTranslation('inventory');
  const [getItem, item, isGetItemLoading] = useGetItem();
  const isLoading = isGetItemLoading;

  useEffect(() => {
    getItem(inventoryId, itemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryId, itemId]);

  return (
    <>
      {!!item && <DocumentTitle title={`${t('title', { context: item.cargoType, id: item.id })}`} />}
      <LoadingIndicator show={isLoading} />
      <ClientInventoryItemHeader item={item} />
      {!!item && <ItemDetailsRouter item={item} />}
      <HashedRouter
        routes={[
          {
            path: attachmentPattern,
            component: () => (
              <RoutedModal maxWidth="lg" fullWidth title={t('attachments')}>
                <Attachments resourceUrl={url(inventoryId, itemId)} />
              </RoutedModal>
            ),
          },
        ]}
      />
    </>
  );
};

export default InventoryItemPage;
