import { toOption } from '../../../common/helpers/list/options';
import identifierLabels from './identifierLabels';

export const sortOptionByLabel = (a, b) => a.label.localeCompare(b.label);

export const getIdentifierLabelOptions = ({ currentIdentifier = null, identifiers, translator, translationPrefix = 'identifiers', hasEmptyOption = false }) => {
  const selectedLabels = (identifiers || []).map((d) => d.label);

  const options = Object.values(identifierLabels)
    .filter((t) => !selectedLabels.includes(t) || (currentIdentifier?.label && currentIdentifier.label === t))
    .map(toOption(translator, translationPrefix))
    .sort(sortOptionByLabel);

  return hasEmptyOption ? [{ label: translator('common.all'), value: ' ' }, ...options] : options;
};
