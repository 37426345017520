import useGlobalHook from 'use-global-hook';

const initialState = { errorMessage: null };

export const setErrorMessage = async (store, errorMessage) => {
  store.setState({ errorMessage });
};
const removeErrorMessage = async (store) => store.setState(initialState);

// eslint-disable-next-line react-hooks/rules-of-hooks
const useErrorStore = useGlobalHook(initialState, { setErrorMessage, removeErrorMessage });

export default useErrorStore;
