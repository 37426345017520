import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toLocalDateTime } from '../common/helpers/date';
import columnsSetting from '../layout/Datagrid/ColumnSetting';
import DataGrid from '../layout/Datagrid/Datagrid';
import LoadingIndicator from '../layout/loading/LoadingIndicator';
import useGetAttachment from './useGetAttachment';
import { useGetAttachments, useGetHazardousAttachments } from './useGetAttachments';

const Attachments = ({ resourceUrl, isHazardous }) => {
  const { t } = useTranslation('attachment');
  const [getAttachment, , isLoading] = useGetAttachment();
  const [getAttachments, attachments = [], isAttachmentsLoading] = useGetAttachments();
  const [getHazardousAttachments, hazardousAttachments = [], isHazardousAttachmentsLoading] = useGetHazardousAttachments();
  const [params, setParams] = useState();

  const columns = [
    columnsSetting('additionalInformation.category').withColName('category'),
    columnsSetting('additionalInformation.description').withColName('description'),
    columnsSetting('fileName').withColName('file'),
    columnsSetting('updatedOn').withColName('upload').withCustomFormat(toLocalDateTime).withoutSort(),
    columnsSetting('authorName').withColName('uploadedBy'),
  ].map((x) => x.withIsVisible().toColumns(t));

  const fetcher = (params) => {
    if (isHazardous) {
      getHazardousAttachments(resourceUrl);
    } else {
      setParams(params);
      getAttachments(resourceUrl, params);
    }
  };

  useEffect(() => {
    isHazardous ? getHazardousAttachments(resourceUrl) : getAttachments(resourceUrl, new URLSearchParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ['resourceUrl', isHazardous]);

  return (
    <>
      <LoadingIndicator show={isLoading || isAttachmentsLoading || isHazardousAttachmentsLoading} />
      <DataGrid
        defaultColumnWidth={150}
        defaultColumns={columns.map((x) => x.key)}
        onSearchParamsChanged={fetcher}
        data={isHazardous ? hazardousAttachments : attachments}
        columns={columns}
        searchParams={params}
        rowKey="id"
        onRowClick={({ row }) => {
          return getAttachment(resourceUrl, row.id, row.fileName);
        }}
      />
    </>
  );
};

Attachments.propTypes = {
  resourceUrl: PropTypes.string.isRequired,
  isHazardous: PropTypes.bool,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      category: PropTypes.string,
      description: PropTypes.string,
      file: PropTypes.string,
      upload: PropTypes.string,
      uploadedBy: PropTypes.string,
    })
  ),
};

Attachments.defaultProps = {
  isHazardous: false,
};

export default Attachments;
