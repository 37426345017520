import { Button, makeStyles, Paper, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import Breakpoint from './mediaQuery';

const buttonStyles = makeStyles({
  root: {
    padding: 0,
  },
});

const paperStyle = (isTabletOrLarger) =>
  makeStyles({
    root: {
      padding: isTabletOrLarger ? '2.5rem' : '0.625rem',
      backgroundColor: '#F2F5F7',
      marginBottom: isTabletOrLarger ? '2.5rem' : '1.25rem',
    },
  });

const Section = ({ title, children, actions }) => {
  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);
  return (
    <>
      <section className={`pb-4 flex justify-between`}>
        <h1 className="text-lg inline-block">{title}</h1>
        {actions.map((action, index) => (
          <Button classes={buttonStyles()} color="primary" onClick={action.onClick} key={String(index)}>
            {action.label}
          </Button>
        ))}
      </section>

      <Paper elevation={0} classes={paperStyle(isTabletOrLarger)()}>
        {children}
      </Paper>
    </>
  );
};

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

Section.defaultProps = {
  actions: [],
  title: '',
};
export default Section;
