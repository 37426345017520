import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Tooltip from '../../Tooltip';

const ListToggler = ({ options }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const currentSection = useMemo(() => {
    const elements = pathname.split('/');
    return elements[elements.length - 1];
  }, [pathname]);

  const handleSectionChange = useCallback(
    (event, newSection) => {
      if (newSection && newSection !== currentSection) {
        if (['bulk', 'breakbulk'].includes(newSection)) {
          localStorage.setItem('listType', newSection);
        }
        push(newSection);
      }
    },
    [currentSection, push]
  );

  return (
    <ToggleButtonGroup className="pb-4" exclusive onChange={handleSectionChange}>
      {options.map(({ tooltipValue, icon, value }) => (
        <Tooltip key={value} title={tooltipValue}>
          <ToggleButton value={value} selected={currentSection === value} data-testid={`toggle_${tooltipValue}`}>
            {React.createElement(icon)}
          </ToggleButton>
        </Tooltip>
      ))}
    </ToggleButtonGroup>
  );
};

ListToggler.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ tooltipValue: PropTypes.string, icon: PropTypes.func.isRequired, value: PropTypes.string.isRequired }))
    .isRequired,
};

export default ListToggler;
