import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const Title = ({ title }) => (
  <span className="pr-5">
    <Typography variant="h1" color="textSecondary">
      {title}
    </Typography>
  </span>
);

Title.propTypes = {
  title: PropTypes.string,
};

Title.defaultProps = {
  title: '',
};

export default Title;
