import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import DataGrid from '../Datagrid/Datagrid';
import useVisibleColumns from '../hooks/useVisibleColumns';

const changeVisibility = (key, visible) => (col) => {
  if (col.key === key) return col.withVisible(visible);
  return col;
};

const StaticListPage = ({
  columnSettings,
  header,
  height,
  title,
  data,
  hasLoadMore,
  rowKey,
  searchTake,
  onRowClick,
  defaultColumns,
  otherColumns,
  setColumnSettings,
  translater,
  noRowLink,
}) => {
  const [visibleColumns, setVisibleColumns] = useVisibleColumns([...defaultColumns, ...otherColumns]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    setVisibleColumns([...defaultColumns, ...otherColumns]);
  }, [defaultColumns, otherColumns, setVisibleColumns]);

  const handleColumnVisibilityChange = useCallback(
    (key, visible) => {
      setVisibleColumns(visibleColumns.map(changeVisibility(key, visible)));
    },
    [visibleColumns, setVisibleColumns]
  );

  const handleResetDefault = useCallback(() => {
    setVisibleColumns(
      visibleColumns.map((col) => {
        if (defaultColumns.some((x) => x.key === col.key)) return col.withIsVisible();
        return col.withIsInvisible();
      })
    );
  }, [visibleColumns, defaultColumns, setVisibleColumns]);

  const cols = useMemo(() => visibleColumns.map((x) => x.toColumns(translater)), [visibleColumns, translater]);

  return (
    <>
      {React.createElement(header, { title })}
      <DataGrid
        checkable={false}
        columnSettings={columnSettings}
        data={data.items}
        columns={cols}
        defaultColumnKeys={defaultColumns.map((x) => x.key)}
        defaultColumnWidth={150}
        height={height}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        onRowClick={onRowClick}
        rowKey={rowKey}
        onResetDefault={handleResetDefault}
        hasLoadMore={hasLoadMore && data && data?.totalItems > data?.items?.length}
        searchTake={searchTake}
        searchParams={searchParams}
        setColumnSettings={setColumnSettings}
        translater={translater}
        noRowLink={noRowLink}
      />
    </>
  );
};

const configPropTypes = PropTypes.arrayOf(PropTypes.object);

StaticListPage.propTypes = {
  header: PropTypes.elementType,
  height: PropTypes.number,
  title: PropTypes.string,
  defaultColumns: configPropTypes.isRequired,
  hasLoadMore: PropTypes.bool,
  otherColumns: configPropTypes,
  data: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.any), totalItems: PropTypes.number }),
  onRowClick: PropTypes.func,
  translater: PropTypes.func.isRequired,
  searchTake: PropTypes.number,
  rowKey: PropTypes.string,
  columnSettings: PropTypes.any,
  setColumnSettings: PropTypes.func,
  noRowLink: PropTypes.bool,
};

const NoHeader = () => <div></div>;

StaticListPage.defaultProps = {
  columnSettings: {},
  header: NoHeader,
  height: undefined,
  onRowClick: () => undefined,
  data: {},
  hasLoadMore: true,
  otherColumns: [],
  rowKey: 'id',
  searchTake: 25,
  setColumnSettings: () => undefined,
  title: '',
  noRowLink: false,
};

export default StaticListPage;
