import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useModal from '../../../../hooks/useModal';
import Output from '../../../../layout/Output';
import { toLocalDate, toLocalDateTime } from '../../../../common/helpers/date';
import { Location, LocationHistory } from '../../types';

const DialogActionsStyles = makeStyles({
  root: {
    justifyContent: 'center',
  },
});
const LocationHistoryModalContent = ({ location, t }) => (
  <>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <Output id="output-facility" label={t('item.facility')} defaultValue={location?.storageFacility?.name} />
        </Grid>
        <Grid item xs={6} md={6}>
          <Output id="output-area" label={t('item.area')} defaultValue={location?.storageArea?.name} />
        </Grid>
        <Grid item xs={6} md={6}>
          <Output id="output-effective-date" label={t('item.effectiveDate')} defaultValue={toLocalDate(location?.effectiveDate)} />
        </Grid>
        <Grid item xs={6} md={6}>
          <Output id="output-user" label={t('item.user')} defaultValue={location?.createdByUserName} />
        </Grid>
        <Grid item xs={6} md={6}>
          <Output id="output-date-and-time" label={t('item.dateAndTime')} defaultValue={toLocalDateTime(location?.creationDate)} />
        </Grid>
      </Grid>
    </DialogContent>
    <Divider variant="middle" />
  </>
);

LocationHistoryModalContent.propTypes = {
  location: PropTypes.shape(Location).isRequired,
  t: PropTypes.func.isRequired,
};

const LocationHistoryModal = ({ locationHistory, onExited }) => {
  const { t } = useTranslation('inventory');
  const [isModalOpen, , closeModal] = useModal(true);

  return (
    <Dialog open={isModalOpen} onExited={onExited} scroll="body">
      <DialogTitle id="locationHistoryModal-title" onClose={closeModal}>
        {t('item.locationHistory')}
      </DialogTitle>

      {locationHistory.map((location, index) => (
        <LocationHistoryModalContent key={String(index)} location={location} t={t} />
      ))}

      <DialogActions classes={DialogActionsStyles()}>
        <Button onClick={closeModal} color="primary" variant="contained">
          {t('item.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LocationHistoryModal.propTypes = {
  locationHistory: PropTypes.arrayOf(PropTypes.shape(LocationHistory)),
  onExited: PropTypes.func,
};

LocationHistoryModal.defaultProps = {
  locationHistory: [],
  open: false,
};

export default LocationHistoryModal;
