import axios from 'axios';
import { useTranslation } from 'react-i18next';
import download from '../../../common/helpers/download';
import useAgent, { PUSH_NOTIFICATION } from '../../../hooks/useAgent';

const url = (inventoryId, itemId) => `/api/v1/inventory/${inventoryId}/inventory-items/${itemId}/packing-list-pdf`;

const getItemPackingListDocument = async (inventoryId, itemId, filename) => {
  const response = await axios.get(url(inventoryId, itemId), { responseType: 'blob' });

  return download(response.data, filename);
};

const useGetItemPackingListDocument = () => {
  const { t } = useTranslation('transaction');
  return useAgent(getItemPackingListDocument, { errorStatusToAction: { 404: { type: PUSH_NOTIFICATION, payload: t('error.missingDocument') } } });
};

export default useGetItemPackingListDocument;
