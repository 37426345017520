import { FormControl, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Title from '../../Title';
import ListPageHeaderLayout from './component/ListPageHeaderLayout';
import ListPageHeaderPills from './component/ListPageHeaderPills';

const ListPageHeaderWithSiteFilter = ({ title, pills, subscript, allowedSites, onChangeSite }) => {
  const handleChange = (event) => {
    setDropDownValue(event.target.value);
    onChangeSite(event.target.value);
  };

  const [dropDownValue, setDropDownValue] = useState(localStorage.getItem('selectedSiteName') || allowedSites[0]);

  return (
    <>
      <ListPageHeaderLayout>
        <div className="flex items-start mb-4">
          <Title title={title} />
          {allowedSites?.length > 1 ? (
            <FormControl>
              <Select native value={dropDownValue} onChange={handleChange}>
                {allowedSites.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </div>
        {subscript}
      </ListPageHeaderLayout>

      <div className={`flex flex-wrap items-baseline`}>
        <div className="flex flex-wrap">
          <ListPageHeaderPills pills={pills} />
        </div>
      </div>
    </>
  );
};

ListPageHeaderWithSiteFilter.propTypes = {
  allowedSites: PropTypes.arrayOf(PropTypes.string).isRequired,
  pills: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onChangeSite: PropTypes.func.isRequired,
  subscript: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
};

ListPageHeaderWithSiteFilter.defaultProps = {
  pills: [],
  subscript: null,
  title: null,
};

export default ListPageHeaderWithSiteFilter;
