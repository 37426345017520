import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import units from '../../../common/dimension/units';
import Output from '../../../layout/Output';
import Section from '../../../layout/Section';
import { formatAsDimension } from '../helpers/itemHelpers';
import { InventoryItem } from '../types';

const DimensionWeightSection = ({ item }) => {
  const { t } = useTranslation('inventory');

  const formatWeight = (item) => {
    if (!item.weightPerUnit) return '';

    const unit = t(`units.${item.unitOfWeight}`);
    const asPerScale = item.weightHasBeenVerified ? ` (${t('item.asPerScale')})` : '';

    return `${formatAsDimension(item.weightPerUnit)} ${unit}${asPerScale}`;
  };

  const formatStuffedWeight = (item) => {
    if (!(item.manuallyEnteredStuffedWeight || item.grossWeightPerUnit)) return '';

    const unit = t(`units.${item.unitOfWeight ? item.unitOfWeight : units.mass.mt}`);
    const asPerScale = item.manuallyEnteredStuffedWeight ? ` (${t('item.asPerScale')})` : '';

    return `${formatAsDimension(item.manuallyEnteredStuffedWeight || item.grossWeightPerUnit)} ${unit}${asPerScale}`;
  };

  return (
    <Section title={t('item.dimensionsWeightPerItem')}>
      <Grid container spacing={2}>
        {item.dimensionOrders
          .filter((dimension) => item[dimension])
          .map((dimensionOrder) => (
            <Grid item xs={6} sm={3}>
              <Output
                id={`output-dimension-${dimensionOrder}-dimension`}
                defaultValue={item[dimensionOrder].value ? `${formatAsDimension(item[dimensionOrder].value)} ${item[dimensionOrder].unit}` : ''}
                label={t(`item.dimensionTypes.${dimensionOrder}`)}
              />
            </Grid>
          ))}
        <Grid item xs={12}>
          <Grid container spacing={2} wrap="wrap">
            <Grid item xs={6} sm={3}>
              <Output
                id={`output-dimension-volumePerUnit`}
                defaultValue={item.volumePerUnit ? `${formatAsDimension(item.volumePerUnit)} ${t(`units.${item.unitOfVolume}`)}` : ''}
                label={t('item.volume')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Output
                id={`output-dimension-surfaceAreaPerUnit`}
                defaultValue={item.surfaceAreaPerUnit ? `${formatAsDimension(item.surfaceAreaPerUnit)} ${t(`units.${item.unitOfSurfaceArea}`)}` : ''}
                label={t('item.surfaceArea')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <Output
                id={`output-dimension-weightPerUnit`}
                data-testid={`caption-dimension-weightHasBeenVerified`}
                defaultValue={formatWeight(item)}
                label={t('item.weight')}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              {(item.isStuffableContainer || item.isNonStuffableContainer) && (
                <Output
                  id={`output-dimension-stuffedContainerWeight`}
                  data-testid="caption-dimension-manuallyEnteredStuffedWeight"
                  defaultValue={formatStuffedWeight(item)}
                  label={t('item.stuffedContainerWeight')}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};

DimensionWeightSection.propTypes = {
  item: PropTypes.shape(InventoryItem),
};

DimensionWeightSection.defaultProps = {
  item: {},
};

export default DimensionWeightSection;
