import { default as format } from './format';
import { default as types } from './types';
import { default as units } from './units';

const Dimension = {
  format,
  types,
  units,
};
export default Dimension;
