import { Chip, Link, Tooltip } from '@material-ui/core';
import { TagInputFilter } from '@vooban/datagrid';
import DistinctTrailerTypesFilter from '../../distinctValueFilters/DistinctTrailerTypesFilter';
import columnsSetting from '../../layout/Datagrid/ColumnSetting';
import { formatTimePeriodUsingSiteTimezone } from '../../common/helpers/timeSlotHelper';
import moment from 'moment';
import { ActionTypeIcon, CargoTypeBadgeIcon, getHazardousIcon, TrailerTypeIcon } from './IconHelper';

const TrailerTypeFilter = (options, t) => DistinctTrailerTypesFilter(options, t);

const getColumns = (
  {
    actionTypes = [],
    cargoTypes = [],
    transportationTypes = [],
    carrierNames = [],
    sites = [],
    status = [],
    trailerTypes = [],
    supplierBookings = [],
    parkings = [],
    hazardousMaterials = [],
    productTypes = [],
    productSubtypes = [],
    receiverNames = [],
    inventoryNames = [],
    orderNames = [],
    bookers = [],
  },
  advancedSiteInfos = [],
  fetchDocument,
  t = () => {}
) => [
  columnsSetting('site.name')
    .withColName('site')
    .withSortKey('site')
    .withFilter({ component: TagInputFilter, keyFilter: 'sites', options: sites })
    .withIsDefault(),
  columnsSetting('startDate')
    .withCustomKey('date')
    .withColName('date')
    .withCustomFormat((_, row) => row.startDate && moment(row.startDate).format('YYYY-MM-DD'))
    .withoutSort()
    .withIsDefault(),
  columnsSetting('startDate')
    .withColName('timeSlot')
    .withCustomFormat((_, row) => {
      return formatTimePeriodUsingSiteTimezone(row, advancedSiteInfos);
    })
    .withIsDefault(),
  columnsSetting('number').withSortKey('number').withIsDefault(),
  columnsSetting('actionType')
    .withSortKey('actionType')
    .withCustomFormat((value) => ActionTypeIcon(value, t))
    .withFilter({ component: TagInputFilter, keyFilter: 'actionTypes', options: getTranslatedFilter(actionTypes, 'actionType', t) })
    .withIsDefault(),
  columnsSetting('cargoType')
    .withSortKey('cargoType')
    .withColName('cargo')
    .withFilter({ component: TagInputFilter, keyFilter: 'cargoTypes', options: cargoTypes })
    .withCustomFormat((value) => CargoTypeBadgeIcon(value, 'badge__icon -textless'))
    .withIsDefault(),
  columnsSetting('bookerInformation.companyName')
    .withColName('booker')
    .withSortKey('booker')
    .withFilter({ component: TagInputFilter, keyFilter: 'bookers', options: bookers })
    .withIsDefault(),
  columnsSetting('carrierInformation.companyName')
    .withColName('carrierName')
    .withSortKey('carrierName')
    .withFilter({ component: TagInputFilter, keyFilter: 'carrierNames', options: carrierNames })
    .withIsDefault(),
  columnsSetting('description').withoutSort().withIsDefault(),
  columnsSetting('romSomNumbers')
    .withSortKey('romSomNumbers')
    .withCustomFormat((value, row) => {
      return (
        <Tooltip
          title={
            isRowConfirmed(row)
              ? t('tooltips.downloadDocument')
              : row.actionType === 'drop'
              ? t('tooltips.receptionNotAvailable')
              : t('tooltips.shipmentNotAvailable')
          }>
          {isRowConfirmed(row) ? (
            <Link component="button" className="link -bold" onClick={(event) => handleGetDocument(value[0], row, fetchDocument)}>
              {value[0]}
            </Link>
          ) : (
            <div>{value[0]}</div>
          )}
        </Tooltip>
      );
    }),
  columnsSetting('transportationType')
    .withSortKey('transportationType')
    .withFilter({
      component: TagInputFilter,
      keyFilter: 'transportationTypes',
      options: getTranslatedFilter(transportationTypes, 'transportationType', t),
    })
    .withCustomFormat((value) => CargoTypeBadgeIcon(value, 'badge__icon -textless'))
    .withIsDefault(),
  columnsSetting('supplierBooking')
    .withSortKey('supplierBooking')
    .withFilter({ component: TagInputFilter, keyFilter: 'supplierBookings', options: supplierBookings }),
  columnsSetting('carrierInformation.trailerType')
    .withColName('trailerType')
    .withSortKey('trailerType')
    .withFilter({ component: TrailerTypeFilter(trailerTypes, t), keyFilter: 'trailerTypes' })
    .withCustomFormat((value) => TrailerTypeIcon(value))
    .withIsDefault(),
  columnsSetting('calculatedClientInventoryNames')
    .withColName('inventories')
    .withSortKey('inventoryName')
    .withFilter({ component: TagInputFilter, keyFilter: 'inventoryNames', options: inventoryNames })
    .withCustomFormat((value) => {
      return [...new Set(value)].join(', ');
    }),
  columnsSetting('calculatedOrderNames')
    .withColName('orders')
    .withSortKey('orderName')
    .withFilter({ component: TagInputFilter, keyFilter: 'orderNames', options: orderNames })
    .withCustomFormat((value) => {
      return [...new Set(value)].filter((x) => x).join(', ');
    }),
  columnsSetting('calculatedReceiverNames')
    .withColName('receivers')
    .withSortKey('receiverName')
    .withFilter({ component: TagInputFilter, keyFilter: 'receiverNames', options: receiverNames })
    .withCustomFormat((value) => {
      return value.join(', ');
    }),
  columnsSetting('forWhom').withSortKey('forWhom'),
  columnsSetting('purchaseOrder').withSortKey('purchaseOrder'),
  columnsSetting('productTypes')
    .withColName('productType')
    .withSortKey('productType')
    .withFilter({ component: TagInputFilter, keyFilter: 'productTypes', options: productTypes })
    .withCustomFormat((value) => {
      return value.join(', ');
    })
    .withIsDefault(),
  columnsSetting('productSubtypes')
    .withColName('productSubtype')
    .withSortKey('productSubtype')
    .withFilter({ component: TagInputFilter, keyFilter: 'productSubtypes', options: productSubtypes })
    .withCustomFormat((value) => {
      return value.join(', ');
    }),
  columnsSetting('quantities')
    .withoutSort()
    .withCustomFormat((value) => {
      return value.join(', ');
    }),
  columnsSetting('calculatedHasHazardousMaterial')
    .withColName('hazardousMaterial')
    .withSortKey('hazardousMaterial')
    .withFilter({ component: TagInputFilter, keyFilter: 'hazardousMaterial', options: hazardousMaterials })
    .withCustomFormat((value) => {
      return getHazardousIcon(value);
    }),
  columnsSetting('specialInstructions').withoutSort(),
  columnsSetting('dropDetail.cargoDestination').withColName('cargoDestination').withSortKey('cargoDestination'),
  columnsSetting('transportation.driverName').withColName('driverName').withSortKey('driverName'),
  columnsSetting('transportation.carrierBill').withColName('carrierBill').withSortKey('carrierBill'),
  columnsSetting('transportation.transportationNumber').withColName('vehicleIdentifier').withSortKey('transportationNumber'),
  columnsSetting('parking').withSortKey('parking').withFilter({ component: TagInputFilter, keyFilter: 'parkings', options: parkings }),
  columnsSetting('status')
    .withSortKey('status')
    .withFilter({
      component: TagInputFilter,
      keyFilter: 'status',
      options: getTranslatedFilter(status, 'statuses', t),
    })
    .withCustomFormat((value) => <Chip label={t(`statuses.${value}`)} data-testid={`chip_${value}`} />)
    .withIsDefault(),
];

const handleGetDocument = (id, row, fetchDocument) => {
  fetchDocument(id, getActionType(row));
};

const getActionType = (row) => {
  return `${row.actionType === 'drop' ? 'receptionby' : 'shipmentby'}${row.transportationType.toLowerCase()}`;
};

const isRowConfirmed = (row) => {
  return (row.actionType === 'drop' && row.romStatuses[0] === 'Confirmed') || (row.actionType === 'pick' && row.somStatuses[0] === 'Confirmed');
};

const getTranslatedFilter = (options, translationKey, t) => {
  return options.map((option) => {
    return { value: option.value, label: t(`${translationKey}.${option.label}`) };
  });
};

export default getColumns;
