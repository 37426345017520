import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePickerField, SelectField } from '../../layout/fields';
import { Button, Grid, useMediaQuery } from '@material-ui/core';
import DayJsUtils from '@date-io/dayjs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import useAgent from '../../hooks/useAgent';
import LoadingIndicator from '../../layout/loading/LoadingIndicator';
import Breakpoint from '../../layout/mediaQuery';
import { getReport } from '../agents/tailgateAgents';
import { CARGO_TYPE } from '../../cargoType';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import useErrorStore from '../../hooks/useErrorStore';
import { getSitesHeader, totalCountKey } from '../helpers/reportHelpers';

function TailgateReportFormFields({ sites, type }) {
  const { t } = useTranslation('reports');
  const { values } = useFormikContext();

  const location = useLocation();
  const { push } = useHistory();
  const [, errorActions] = useErrorStore();

  useEffect(() => {
    const cargoType = localStorage.getItem('listType');

    if (cargoType) return;
    getSitesHeader(type).then((res) => {
      if (res.headers[totalCountKey] === '0') {
        localStorage.setItem('listType', CARGO_TYPE.bulk.toLowerCase());
        push('/reports/tailgate/bulk');
      }
    });
  }, [sites, type, location.search, push]);

  const [generateReport, , isGeneratingReport] = useAgent(getReport, {
    errorStatusToAction: {},
    successHandler: (result) => {
      if (result === undefined) {
        errorActions.setErrorMessage(t('noContentAvailable'));
      }
    },
  });

  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);

  const onGenerateReportClick = () => {
    generateReport(values.site, values.dateFrom, values.dateTo, type);
  };

  return (
    <>
      {isGeneratingReport && <LoadingIndicator />}
      <Grid container justifyContent="flex-start" spacing={2} direction={isTabletOrLarger ? 'row' : 'column'}>
        <Grid item xs={12} md={3} xl={3}>
          <SelectField label={t('site')} name="site" options={sites} data-testid="select_site" />
        </Grid>
        <Grid container item xs={12} md={6} xl={6} direction={'row'} spacing={1}>
          <Grid item xs={4} md={4} xl={4} direction={'row'}>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <KeyboardDatePickerField label={t('dateFrom')} name="dateFrom" fullWidth />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={4} md={4} xl={4} direction={'row'}>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <KeyboardDatePickerField label={t('dateTo')} name="dateTo" fullWidth />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={4} md={4} xl={4}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ fontSize: 12, marginTop: 15 }}
              disabled={!values.site || values.dateFrom === null || values.dateTo === null}
              onClick={onGenerateReportClick}>
              {t('generateReport')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

TailgateReportFormFields.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string, key: PropTypes.string })),
  type: PropTypes.oneOf([CARGO_TYPE.bulk, CARGO_TYPE.breakbulk]),
};

export default TailgateReportFormFields;
