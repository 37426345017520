import accounting from 'accounting';

const nonBreakingSpace = '\u00A0';
const narrowNonBreakingSpace = '\u202F';

const numberFormatOptions = {
  decimal: '.',
  format: `%s%v`,
  thousand: narrowNonBreakingSpace,
};

export const formatNumberWithAccounting = (value, precision = 2, options = numberFormatOptions) => {
  if (value === '') return value;
  if (!value && value !== 0) return `$${nonBreakingSpace}-`;

  const cleanedNumber = accounting.unformat(String(value));
  return accounting.formatNumber(cleanedNumber, { ...options, precision });
};

export const formatNumber = (value, precision = 4) => accounting.unformat(accounting.toFixed(value, precision), '.');

export default formatNumberWithAccounting;
