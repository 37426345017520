const profile = {
  changePassword: 'Changer mon mot de passe',
  email: 'Courriel',
  name: 'Nom complet',
  passwordChangeEmailMessage: 'Un courriel pour réinitialiser votre mot de passe vous a été envoyé.',
  passwordExpired: 'Mot de passe expiré',
  passwordExpiredMessage: 'Votre mot de passe est expiré. Nous vous avons envoyé un courriel avec les instructions pour changer votre mot de passe.',
  profile: 'Mon profil',
  profileInformation: 'Information du profil',
};

export default profile;
