import { enTranslation } from '@vooban/tc3-core';

const laborAvailability = {
  site: 'Site',
  projectName: 'Nom de projet',
  projectNumber: 'Numéro de projet',
  cargo: 'Cargo',
  planningCalendar: 'Calendrier de planification',
  productCategory: 'Catégorie de produit',
  productSubtype: 'Sous-type de produit',
  productType: 'Type de produit',
  quantity: 'Quantité',
  totalWeightInMT: 'Poids total (MT)',
  more: 'supplémentaires',
  remark: 'Remarque',
  units: {
    ...enTranslation.units,
    SquareMeter: 'sq.m.',
    SquareFoot: 'sq.ft.',
    Meter: 'm',
    Foot: 'ft',
    TwentyFootEquivalentUnit: 'TEU',
  },
  unavailableSite: 'Calendrier non disponible pour ce site',
  workerAvailability: {
    maximumNonAvailabilityDays: 'Nombre de jours non disponible atteint: {{maximumNonAvailabilityDays}} jours',
  },
  selectAvailability: 'Sélectionnez disponibilité',
  save: 'Sauvegarder',
  cancel: 'Annuler',
  available: 'Disponible',
  unavailable: 'Non disponible',
  reason: 'Raison',
  required: 'Obligatoire',
};

export default laborAvailability;
