import { Container, makeStyles, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useErrorStore from '../hooks/useErrorStore';
import useSuccessStore from '../hooks/useSuccessStore';
import { ROUTES } from '../Router';
import Footer from './Footer';
import Breakpoint from './mediaQuery';
import FooterMobile from './navigation/footerMobile/FooterMobile';
import Navigation from './navigation/Navigation';
import NavigationTopMobile from './navigation/NavigationTopMobile';
import ErrorToast from './toast/ErrorToast';
import SuccessToast from './toast/SuccessToast';

const containerStyle = (isTabletOrLarger) =>
  makeStyles({
    root: {
      marginLeft: isTabletOrLarger ? '0' : 'auto',
      paddingLeft: isTabletOrLarger ? '0' : '0.625rem',
      paddingRight: isTabletOrLarger ? '0' : '0.625rem',
      padding: 0,
      marginBottom: '48px',
      display: 'flex',
      flexDirection: 'column',
    },
  });

const LayoutPage = ({ children }) => {
  const { t } = useTranslation();
  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);
  const [{ errorMessage }, errorsActions] = useErrorStore();
  const [{ successMessage }, successAction] = useSuccessStore();

  const menuOptions = useMemo(
    () => [ROUTES.appointments, ROUTES.inventory, ROUTES.transaction, ROUTES.reports].filter(Boolean).map((route) => ({ ...route, label: t(route.i18nKey) })),
    [t]
  );

  return (
    <>
      {isTabletOrLarger ? <Navigation options={menuOptions} /> : <NavigationTopMobile />}
      <div className={`flex flex-grow ${isTabletOrLarger ? 'pl-14 pr-14' : 'pt-14'}`}>
        <Container classes={containerStyle(isTabletOrLarger)()} maxWidth={false}>
          {children}
        </Container>
        {!isTabletOrLarger ? <FooterMobile options={menuOptions} /> : <Footer />}
      </div>
      <ErrorToast message={errorMessage} onClose={errorsActions.removeErrorMessage} />
      <SuccessToast message={successMessage} onClose={successAction.removeSuccessMessage} />
    </>
  );
};

LayoutPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default LayoutPage;
