import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePickerField, SelectField } from '../../layout/fields';
import { Button, Grid, useMediaQuery } from '@material-ui/core';
import MultiSelectField from '../../layout/fields/MultiSelectField';
import DayJsUtils from '@date-io/dayjs';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import useAgent from '../../hooks/useAgent';
import LoadingIndicator from '../../layout/loading/LoadingIndicator';
import Breakpoint from '../../layout/mediaQuery';
import { getClientInventories, getReceivers, getReport, headClientOnlyReceiver } from '../agents/inventoryByDateAgents';
import ClientInventoriesTable from './ClientInventoriesTable';
import { CARGO_TYPE } from '../../cargoType';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import useErrorStore from '../../hooks/useErrorStore';
import { getClientInventoriesHeader, totalCountKey } from '../helpers/reportHelpers';

function InventoryByDateReportFormFields({ sitesOptions, type }) {
  const { t } = useTranslation('reports');
  const { values, setFieldValue } = useFormikContext();
  const [, errorActions] = useErrorStore();

  const [getInventories, inventories = [], isLoadingInventories] = useAgent(getClientInventories);

  const [getFilteredReceivers, filteredReceivers, isLoadingReceivers] = useAgent(getReceivers);

  const [headIsOnlyReceiver, isOnlyReceiver, isLoadingHeader] = useAgent(headClientOnlyReceiver);

  const [generateReport, , isGeneratingReport] = useAgent(getReport, {
    errorStatusToAction: {},
    successHandler: (result) => {
      if (result === undefined) {
        errorActions.setErrorMessage(t('noContentAvailable'));
      }
    },
  });

  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);

  const isLoading = isLoadingInventories || isGeneratingReport || isLoadingReceivers || isLoadingHeader;

  useEffect(() => {
    headIsOnlyReceiver(undefined, undefined, type);
  }, [headIsOnlyReceiver, type]);

  useEffect(() => {
    getInventories(values.sitesOptions, values.receiver, type);
  }, [values.sitesOptions, values.receiver, getInventories, type]);

  useEffect(() => {
    const params = {
      siteIds: values.sitesOptions,
      cargoType: type,
    };

    getFilteredReceivers(params);
  }, [values.sitesOptions, getFilteredReceivers, type]);

  useEffect(() => {
    if (filteredReceivers && filteredReceivers.length === 1 && isOnlyReceiver) {
      setFieldValue('receiver', filteredReceivers[0].id);
    }
  }, [filteredReceivers, setFieldValue, isOnlyReceiver]);

  const receiverOptions = useMemo(() => {
    return filteredReceivers?.map((receiver) => ({ label: receiver.name, value: receiver.id, key: receiver.id })) ?? [];
  }, [filteredReceivers]);

  const location = useLocation();
  const { push } = useHistory();

  useEffect(() => {
    const cargoType = localStorage.getItem('listType');
    if (cargoType) return;

    getClientInventoriesHeader(type).then((res) => {
      if (res.headers[totalCountKey] === '0') {
        localStorage.setItem('listType', CARGO_TYPE.bulk.toLowerCase());
        push('/reports/inventoryByDate/bulk');
      }
    });
  }, [inventories, type, location.search, push]);

  useEffect(() => {
    if (isLoadingInventories) return;

    setFieldValue(
      'inventoriesIds',
      values.inventoriesIds.filter((invId) => inventories.some((inv) => inv.id === invId))
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventories, setFieldValue]);

  const onGenerateReportClick = () => {
    generateReport(values.sitesOptions, values.receiver, values.date, values.inventoriesIds, type);
  };

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <Grid container justifyContent="flex-end" spacing={2} direction={isTabletOrLarger ? 'row' : 'column'}>
        <Grid item xs={12} md={3} xl={3}>
          <MultiSelectField label={t('site')} name="sitesOptions" options={sitesOptions} fullWidth data-testid="select_site" />
        </Grid>
        {type === CARGO_TYPE.breakbulk && (
          <Grid item xs={12} md={3} xl={3}>
            <SelectField label={t('receiver')} name="receiver" options={receiverOptions} required={isOnlyReceiver} data-testid="select_receiver" />
          </Grid>
        )}
        <Grid item xs={12} md={6} xl={3} container direction={'row'} spacing={1}>
          <Grid item xs={8} md={6} xl={6}>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <KeyboardDatePickerField label={t('date')} name="date" />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={4} md={6} xl={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ fontSize: 12, marginTop: 15 }}
              disabled={!values.inventoriesIds.length || values.date === null || (isOnlyReceiver && !values.receiver)}
              onClick={onGenerateReportClick}>
              {t('generateReport')}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ClientInventoriesTable inventories={inventories} />
        </Grid>
      </Grid>
    </>
  );
}

InventoryByDateReportFormFields.propTypes = {
  sitesOptions: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string, key: PropTypes.string })),
  receivers: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string, key: PropTypes.string })),
  type: PropTypes.oneOf([CARGO_TYPE.bulk, CARGO_TYPE.breakbulk]),
};

export default InventoryByDateReportFormFields;
