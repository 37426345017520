import { Button } from '@material-ui/core';
import i18n from 'i18next';
import moment from 'moment';
import 'moment/locale/en-ca';
import 'moment/locale/fr';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSecondsPassed } from '../hooks/useSecondsPassed';
import ListPageHeaderLayout from '../layout/ListPage/headers/component/ListPageHeaderLayout';
import ListPageHeaderPills from '../layout/ListPage/headers/component/ListPageHeaderPills';
import { actionTypes } from './dataTypes';

const TrafficHeader = ({ onRefreshClick, lastUpdate, subscript }) => {
  const { t } = useTranslation('traffic');

  // needed to translate moment.fromNow()
  if (i18n.language.includes('fr')) {
    moment.locale('fr');
  } else {
    moment.locale('en');
  }

  const formattedLastUpdatedValue = moment().subtract(useSecondsPassed(lastUpdate), 's').fromNow();
  const lastUpdatedLabel = `${t('lastUpdated')}: ${formattedLastUpdatedValue}`;

  return (
    <ListPageHeaderLayout>
      <div className="flex items-start">
        <ListPageHeaderPills pills={[lastUpdatedLabel]} />
        <div className="inline flex-initial ml-auto pb-4">
          <div className="flex">
            {actionTypes.map((a) => (
              <div key={a} className="flex mr-6 items-center">
                <div className={`badge__traffic -${a}`} />
                <span className="drop-pick">{t(`dataTypes.actionType.${a}`).toUpperCase()}</span>
              </div>
            ))}
            <Button className="flex" variant="outlined" color="primary" onClick={onRefreshClick}>
              {t('refresh')}
            </Button>
          </div>
        </div>
      </div>
      {subscript}
    </ListPageHeaderLayout>
  );
};

TrafficHeader.propTypes = {
  onRefreshClick: PropTypes.func.isRequired,
  lastUpdate: PropTypes.shape(Date).isRequired,
  subscript: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
};

TrafficHeader.defaultProps = {
  subscript: null,
};

export default TrafficHeader;
