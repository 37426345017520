import flatten from 'flat';
import _ from 'lodash';

const getForComparison = (object, path) => {
  const value = _.get(object, path);
  return value === '' ? null : value;
};

const isDifferent = (b, a, path) => getForComparison(b, path) !== getForComparison(a, path);

export const isNullOrUndefined = (value) => value === null || value === undefined;

const isDifferentValueUnit = (b, a, valuePath, unitPath) => {
  if (isNullOrUndefined(_.get(b, valuePath)) && isNullOrUndefined(_.get(a, valuePath))) return false;
  return isDifferent(b, a, unitPath);
};

const getIdentifier = (item, [labelPath, valuePath]) => {
  return { label: _.get(item, labelPath, ''), value: _.get(item, valuePath, '') };
};

const dimensionValueUnit = (item, path) => ({ value: _.get(item, `${path}.value`, ''), unit: _.get(item, `${path}.unit`) });

const defaultMainInformationPath = [
  'productType.name',
  'productSubtypeName',
  'packaging',
  'quantityPerPackage',
  'remark',
  'isStackable',
  'location.storageFacility.name',
  'location.storageArea.name',
  'quantity',
  'availableQuantity',
  'containerStuffingCapacity',
];

export const getMainInformationDifferences = (before, after) => {
  return Object.keys(flatten(after))
    .filter((key) => defaultMainInformationPath.includes(key) && isDifferent(before, after, key))
    .map((key) => ({ key: key, before: _.get(before, key), after: _.get(after, key) }));
};

export const getIdentifierDifferences = (before, after) =>
  Array.from(Array(4), (_, i) => i + 1)
    .map((number) => ({ number, paths: [`identifierLabel${number}`, `identifierValue${number}`] }))
    .filter(({ paths: [labelPath, valuePath] }) => isDifferent(before, after, labelPath) || isDifferent(before, after, valuePath))
    .map(({ number, paths: identifierPaths }, index) => ({
      key: `identifiers`,
      number: number,
      before: getIdentifier(before, identifierPaths),
      after: getIdentifier(after, identifierPaths),
    }));

export const getWeightDifferences = (before, after) => {
  const weightHasBeenVerified = isDifferent(before, after, 'weightHasBeenVerified')
    ? [
        {
          key: `weightAndDimensions.${'weightHasBeenVerified'}`,
          before: { value: '', unit: _.get(before, 'weightHasBeenVerified') },
          after: { value: '', unit: _.get(after, 'weightHasBeenVerified') },
        },
      ]
    : [];
  return [
    ...weightHasBeenVerified,
    ...[
      { paths: { value: 'weightPerUnit', unit: 'unitOfWeight' }, labelKey: 'weight' },
      {
        paths: { value: 'manuallyEnteredStuffedWeight', fallbackValue: 'grossWeightPerUnit', unit: 'unitOfWeight' },
        labelKey: 'stuffedContainerWeight',
      },
      { paths: { value: 'volumePerUnit', unit: 'unitOfVolume' }, labelKey: 'volume' },
      { paths: { value: 'surfaceAreaPerUnit', unit: 'unitOfSurfaceArea' }, labelKey: 'surfaceArea' },
    ]
      .filter(({ paths: { value, unit } }) => isDifferent(before, after, value) || isDifferentValueUnit(before, after, value, unit))
      .map(({ paths: { value, unit, fallbackValue }, labelKey }) => {
        return {
          key: `weightAndDimensions.${labelKey}`,
          before: { value: _.get(before, value, _.get(before, fallbackValue)), unit: _.get(before, unit) },
          after: { value: _.get(after, value, _.get(before, fallbackValue)), unit: _.get(after, unit) },
        };
      }),
  ];
};

export const getDimensionsDifferences = (before, after) => {
  return _.uniq([...before.dimensionOrders, ...after.dimensionOrders])
    .filter(
      (path, i) =>
        isDifferent(before, after, `dimensionOrders.${i}`) || isDifferent(before, after, `${path}.value`) || isDifferent(before, after, `${path}.unit`)
    )
    .map((path) => ({
      key: `weightAndDimensions.${path}`,
      before: dimensionValueUnit(before, path),
      after: dimensionValueUnit(after, path),
    }));
};

export const getHazardousMaterialInformationDifferences = (before, after) => {
  const hazardousIds = [...before.hazardousMaterialInformation, ...after.hazardousMaterialInformation].map((x) => x.id);
  return _.uniq(hazardousIds).reduce((hmis, id) => {
    const hmiBefore = before.hazardousMaterialInformation.find((x) => x.id === id);
    const hmiAfter = after.hazardousMaterialInformation.find((x) => x.id === id);
    if (Object.keys(hmiBefore || hmiAfter).some((key) => isDifferent(hmiBefore, hmiAfter, key)))
      return [
        ...hmis,
        {
          before: hmiBefore,
          after: hmiAfter,
          key: 'hazardous',
          diff: Object.keys(hmiBefore || hmiAfter).filter((key) => isDifferent(hmiBefore, hmiAfter, key)),
        },
      ];

    return hmis;
  }, []);
};
