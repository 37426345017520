import _ from 'lodash';

export const formatClass = (hazardous) => {
  if (!hazardous || !hazardous.length) return null;
  // eslint-disable-next-line array-callback-return
  const distinctHazardous = _.uniqWith(
    hazardous.map((x) => (x.hasLimitedQuantity ? `LTD ${x.hazardClass}` : x.hazardClass)),
    _.isEqual
  );
  return distinctHazardous.join('; ');
};

export const formatUnNumber = (hazardous = []) => {
  return _.uniq(hazardous.map((x) => x.unNumber)).join('; ');
};

export const formatPackagingGroup = (hazardous = []) => {
  return _.uniq(hazardous.map(({ packagingGroup }) => packagingGroup))
    .filter(Boolean)
    .join('; ');
};
