import { TagInputFilter } from '@vooban/datagrid';
import { formatStringArrayToColumn } from '../../common/helpers/list/format';
import { transactionStatusEnum } from '../../constant';
import columnsSetting from '../../layout/Datagrid/ColumnSetting';
import units from '../../common/dimension/units';
import { getStatusPill } from '../helpers/listHelpers';

const getColumns = (options, t) => [
  columnsSetting('site.name').withSortKey('site').withFilter({ component: TagInputFilter, keyFilter: 'site', options: options.sites }).withIsDefault(),
  columnsSetting('actionTypeTranslatedValue')
    .withSortKey('actionType')
    .withFilter({ component: TagInputFilter, keyFilter: 'actionType', options: options.actionTypes })
    .withIsDefault(),
  columnsSetting('number').withIsDefault(),
  columnsSetting('effectiveDate').withFormatDate().withIsDefault(),
  columnsSetting('carrier').withIsDefault(),
  columnsSetting('carrierBill'),
  columnsSetting('transportationNumber').withColName('vehicleIdentifier').withIsDefault(),
  columnsSetting('mainInventoriesNames')
    .withSortKey('mainInventory')
    .withFilter({ component: TagInputFilter, keyFilter: 'mainInventory', options: options.mainInventories })
    .withCustomFormat(formatStringArrayToColumn)
    .withIsDefault(),
  columnsSetting('mainOrdersNames')
    .withCustomFormat(formatStringArrayToColumn)
    .withSortKey('mainOrder')
    .withFilter({ component: TagInputFilter, keyFilter: 'mainOrder', options: options.mainOrders })
    .withIsDefault(),
  columnsSetting('receiver').withSortKey('destination').withFilter({ component: TagInputFilter, options: options.receivers }).withIsDefault(),
  columnsSetting('productTypeNames')
    .withSortKey('productType')
    .withCustomFormat(formatStringArrayToColumn)
    .withFilter({ component: TagInputFilter, keyFilter: 'productType', options: options.productTypes })
    .withIsDefault(),
  columnsSetting('productSubtypeNames')
    .withSortKey('productSubtype')
    .withCustomFormat(formatStringArrayToColumn)
    .withFilter({ component: TagInputFilter, keyFilter: 'productSubtype', options: options.productSubtypes })
    .withIsDefault(),
  columnsSetting('quantity')
    .withAlignRight()
    .withCustomFormat((value, row) => (row.status === transactionStatusEnum.Confirmed ? `${value} ${units.mass.mt}` : ''))
    .withIsDefault(),
  columnsSetting('projectNumberAndName')
    .withSortKey('projectNumber')
    .withFilter({ component: TagInputFilter, keyFilter: 'project', options: options.projects }),
  columnsSetting('appointment'),
  columnsSetting('remarks'),
  columnsSetting('status')
    .withoutSort()
    .withFilter({ component: TagInputFilter, keyFilter: 'status', options: options.statuses })
    .withCustomFormat((status, row) => getStatusPill(status, row, t))
    .withIsDefault(),
  columnsSetting('origin').withFilter({ component: TagInputFilter, keyFilter: 'origin', options: options.origins }),
  columnsSetting('referenceNumber').withFilter({ component: TagInputFilter, keyFilter: 'referenceNumber', options: options.referenceNumbers }),
];

export default getColumns;
