import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { secondaryMain } from '../../../CssTemplate';
import { ROUTES } from '../../../Router';
import LanguageInline from '../Language/LanguageInline';

const Profile = ({ classes, onBack }) => {
  const { t } = useTranslation();
  const { logout, user } = useAuth0();
  const { push } = useHistory();

  const onLogout = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  return (
    <div role="presentation" className={classes.drawerContent}>
      <div className={classes.homeButton}>
        <Button onClick={onBack} classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}>
          <ArrowBackIcon style={{ color: '#fff', fontSize: 24 }} />
        </Button>
      </div>
      <div className="flex justify-center items-center flex-col text-white">
        <div className="flex items-center">
          <span className={`flex justify-center items-center bg-white h-14 w-14 rounded-full`} style={{ color: secondaryMain }}>
            <PersonIcon style={{ fontSize: 45 }} />
          </span>
          <div className="flex flex-col">
            <span className="pl-5">{user.name}</span>
            <button className="pl-5 mt-3" onClick={() => push(ROUTES.profile.path)}>
              <p className="underline">{t('profile')}</p>
            </button>
          </div>
        </div>
        <span className="pt-5">
          <LanguageInline />
        </span>
        <button className="pt-5" onClick={onLogout}>
          <ExitToAppIcon />
          {t('logout')}
        </button>
      </div>
    </div>
  );
};

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default Profile;
