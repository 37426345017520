import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const useAdvancedFilterValidationSchema = () => {
  const { t } = useTranslation();
  const requiredFieldMessage = t('validation.required');
  const invalidValueMessage = t('validation.invalidValue');

  return yup.object().shape(
    {
      weightAndDimensions: yup.array(
        yup.object().shape(
          {
            type: yup.string().nullable().required(requiredFieldMessage),
            minimum: yup
              .number()
              .nullable()
              .when('maximum', (maximum) => {
                return maximum != null
                  ? yup.number().nullable().required(requiredFieldMessage).max(maximum, invalidValueMessage)
                  : yup.number().nullable().required(requiredFieldMessage);
              }),
            maximum: yup
              .number()
              .nullable()
              .when('minimum', (minimum) => {
                return minimum != null
                  ? yup.number().nullable().required(requiredFieldMessage).min(minimum, invalidValueMessage)
                  : yup.number().nullable().required(requiredFieldMessage);
              }),
            unit: yup.string().nullable().required(requiredFieldMessage),
          },
          ['minimum', 'maximum']
        )
      ),
      identifiers: yup.array(
        yup.object().shape({
          label: yup.string().nullable().required(requiredFieldMessage),
          value: yup.string().nullable().required(requiredFieldMessage),
        })
      ),
    },
    []
  );
};

export default useAdvancedFilterValidationSchema;
