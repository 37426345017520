import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const useAdvancedFilterValidationSchema = () => {
  const { t } = useTranslation();
  const requiredFieldMessage = t('validation.required');

  return yup.object().shape(
    {
      identifiers: yup.array(
        yup.object().shape({
          label: yup.string().nullable().required(requiredFieldMessage),
          value: yup.string().nullable().required(requiredFieldMessage),
        })
      ),
      remark: yup.string().nullable(),
    },
    []
  );
};

export default useAdvancedFilterValidationSchema;
