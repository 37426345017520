const download = (blob, fileName, fileExtension = '') => {
  const objectUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = objectUrl;
  a.download = fileExtension ? `${fileName}.${fileExtension}` : fileName;
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(objectUrl);
  document.body.removeChild(a);
};

export default download;
