const tabs = {
  list: 'List',
  traffic: 'Traffic',
};

const list = {
  appointment: 'Appointment',
  actionType: 'Action Type',
  booker: 'Booker',
  cargo: 'Cargo',
  cargoDestination: 'Cargo Destination',
  cargoType: 'Cargo Type',
  carrierBill: 'Carrier Bill',
  carrierName: 'Carrier',
  company: 'Company',
  date: 'Date',
  description: 'Description',
  driverName: 'Driver Name',
  forWhom: 'For Whom',
  hazardousMaterial: 'Hazardous Material',
  inventories: 'Inventories',
  number: 'Appointment #',
  orders: 'Orders',
  parking: 'Parking',
  productSubtype: 'Product Subtype',
  productType: 'Product Type',
  purchaseOrder: 'Purchase Order',
  quantities: 'Quantities',
  receivers: 'Receivers',
  romSomNumbers: 'ROM/SOM Numbers',
  site: 'Site',
  specialInstructions: 'Special Instructions',
  status: 'Status',
  supplierBooking: 'Supplier',
  time: 'Time',
  timeSlot: 'Time Slot',
  trailerType: 'Trailer Type',
  transportationNumber: 'Transportation Number',
  transportationType: 'Transport',
  vehicleIdentifier: 'Vehicle Identifier',
};

const transportationType = {
  Barge: 'Barge',
  Other: 'Other',
  Railcar: 'Railcar',
  Truck: 'Truck',
  Vessel: 'Vessel',
};

const trailerTypes = {
  bTrain: 'B-Train',
  dryBox: 'Dry Box',
  flatBed: 'Flat Bed',
  frame: 'Frame',
  gooseneck: 'Gooseneck',
  curtainSide: 'Curtain Side',
  dropDeck: 'Drop Deck',
  goldhofer: 'Goldhofer',
  hopperTruck: 'Hopper',
  hopperRailcar: 'Hopper',
  liveBottom: 'Live Bottom',
  triAxle: 'Tri-Axle',
  endDump: 'End Dump',
  boxcars: 'Boxcars',
  bulkhead: 'Bulkhead',
  centerBeams: 'Center Beams',
  flatCars: 'Flat cars',
  gondolasOpen: 'Gondolas Open',
  gondolasCovered: 'Gondolas Covered',
  tanker: 'Tanker',
};

const trailerTypeGroup = {
  truck: 'Truck',
  railCar: 'Railcar',
};

const statuses = {
  Cancelled: 'Cancelled',
  Departed: 'Departed',
  InProcessing: 'In processing',
  InQueue: 'In queue',
  Loaded: 'Loaded',
  'Loaded-Unloaded': 'Loaded/Unloaded',
  NoShow: 'No-Show',
  OnTerminal: 'On terminal',
  Pending: 'Pending',
  Refused: 'Refused',
  Revoked: 'Revoked',
  Scheduled: 'Scheduled',
  Unloaded: 'Unloaded',
  Unscheduled: 'Unscheduled',
};

const actionType = {
  drop: 'Drop',
  pick: 'Pick',
};

const tooltips = {
  downloadDocument: 'Download Document.',
  receptionNotAvailable: 'Reception not confirmed. Document not available.',
  shipmentNotAvailable: 'Shipment not confirmed. Document not available.',
};

const hazardousMaterialExcelValues = {
  yes: 'Yes',
  no: 'No',
};

const appointment = {
  actionType,
  attachments: 'Attachments',
  appointments: 'My Appointments',
  exportToExcel: 'Export to Excel',
  hazardous: 'Hazardous',
  viewAttachments: 'View attached documents',
  hazardousMaterialExcelValues,
  list,
  statuses,
  tabs,
  tooManyResultsMessage: "You can't have more than {{number}} search results. Please refine your search.",
  trailerTypes,
  trailerTypeGroup,
  transportationType,
  tooltips,
};

export default appointment;
