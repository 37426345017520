import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toLocalDateTime } from '../../../common/helpers/date';
import LoadingIndicator from '../../../layout/loading/LoadingIndicator';
import Output from '../../../layout/Output';
import { getStuffingCapacity } from '../helpers/itemHelpers';
import Identifier from '../Identifiers/Identifier';
import {
  getDimensionsDifferences,
  getHazardousMaterialInformationDifferences,
  getIdentifierDifferences,
  getMainInformationDifferences,
  getWeightDifferences,
  isNullOrUndefined,
} from './ItemDiffHelper';
import useGetBeforeAfter from './useGetBeforeAfter';

const MainSection = ({ title, children }) => (
  <section className="pt-5">
    <Typography color="primary" variant="h5" className="pb-3">
      {title}
    </Typography>
    {children}
  </section>
);

MainSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

const Section = ({ title, children }) => (
  <section className="pt-5">
    {title && <h1 className="text-sm font-black pb-6 uppercase">{title}</h1>}
    {children}
  </section>
);

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

Section.defaultProps = {
  title: '',
};

const Diff = ({ title, data, render }) => !!data.length && <Section title={title}>{data.map(render)}</Section>;

Diff.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  render: PropTypes.func.isRequired,
};

Diff.defaultProps = {
  title: '',
};

const BreakbulkItemDiff = ({ inventoryId, itemId, date }) => {
  const [getBeforeAndAfter, responses = {}, isLoading] = useGetBeforeAfter();
  const { t } = useTranslation('inventory');

  const diff = useMemo(() => {
    if (!(responses && responses.before && responses.after))
      return {
        mainInformations: [],
        identifiers: [],
        dimensions: [],
        weights: [],
        hazardousMaterialInformations: [],
      };
    const before = { ...responses.before, containerStuffingCapacity: t(`item.stuffingCapacity.${getStuffingCapacity(responses.before)}`) };
    const after = { ...responses.after, containerStuffingCapacity: t(`item.stuffingCapacity.${getStuffingCapacity(responses.after)}`) };

    return {
      mainInformations: getMainInformationDifferences(before, after),
      identifiers: getIdentifierDifferences(before, after),
      dimensions: getDimensionsDifferences(before, after),
      weights: getWeightDifferences(before, after),
      hazardousMaterialInformations: getHazardousMaterialInformationDifferences(before, after),
    };
  }, [responses, t]);

  useEffect(() => {
    getBeforeAndAfter(inventoryId, itemId, date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEmptyOrTranslate = (value) => {
    if (value === false || value) return t(`item.${value}`);
    return '-';
  };

  const getEmptyOrValue = (value) => (isNullOrUndefined(value) ? '-' : value);

  const dimAndWeight = useMemo(() => [...diff.dimensions, ...diff.weights], [diff.dimensions, diff.weights]);

  const toLabelValue = (label, value, key) => {
    if (!label) return '-';
    return `${t(`${key}.${label}`)}: ${value || '-'}`;
  };

  const identifiersToText = (item) => {
    const toLabelValueOrEmpty = ([label, value]) => toLabelValue(label, value, 'item.diff.identifierLabelShort');
    return Identifier.toTupleArray(item).map(toLabelValueOrEmpty).join('\n');
  };

  const dimAndWeightToText = ({ value, unit }) => {
    if (isNullOrUndefined(value)) return '-';
    if (value === '') return unit ? t(`item.diff.weightAndDimensions.${unit}`) : '-';
    return `${getEmptyOrValue(value)} ${t(`item.diff.weightAndDimensions.${unit}`)}`;
  };

  const { after: item } = responses;
  return (
    <>
      <LoadingIndicator show={isLoading} />
      <Section>
        <div className="flex flex-wrap">
          <span>
            <Output label={t(`item.clientInventory`)} defaultValue={item?.clientInventory?.name} />
          </span>
          <span>
            <Output label={t(`item.actionDate`)} defaultValue={toLocalDateTime(responses.actionDate)} />
          </span>
          <span>
            <Output label={t(`item.user`)} defaultValue={responses.updatedBy} />
          </span>
        </div>
      </Section>

      <MainSection title={t('item.modifiedItem')}>
        <div className="flex flex-wrap w-full">
          <span>
            <Output label={t(`item.order`)} defaultValue={item?.order?.name} />
          </span>
          <span>
            <Output label={t(`item.productType`)} defaultValue={item?.productType?.name} />
          </span>
          <span>
            <Output label={t(`item.productSubtype`)} defaultValue={item?.productSubtypeName} />
          </span>
          <span>
            <Output label={t(`item.identifiers`)} defaultValue={identifiersToText(responses.after)} />
          </span>
          <span className="flex-grow w-72">
            <Output label={t(`item.dimensions`)} defaultValue={item?.dimension} />
          </span>
        </div>
      </MainSection>
      <MainSection title={t('item.diff.modifications')}>
        <Diff
          title={t('item.diff.mainInformation')}
          data={diff.mainInformations}
          render={(x, index) => (
            <div className="flex" key={`${x.key}${index}`}>
              <Output label={t(`item.diff.${x.key}`)} defaultValue={x.before} />
              <Output label={t(`item.diff.${x.key}`)} defaultValue={x.after} />
            </div>
          )}
        />
        <Diff
          title={t('item.diff.identifiers')}
          data={diff.identifiers}
          render={({ before, after, key, number }) => (
            <div className="flex" key={`${key}${number}`}>
              <Output label={`${t(`item.diff.${key}`)} ${number}`} defaultValue={toLabelValue(before.label, before.value, 'item.identifierLabel')} />
              <Output label={`${t(`item.diff.${key}`)} ${number}`} defaultValue={toLabelValue(after.label, after.value, 'item.identifierLabel')} />
            </div>
          )}
        />
        <Diff
          title={t('item.diff.weightAndDimensions.weightAndDimensions')}
          data={dimAndWeight}
          render={(x) => (
            <div className="flex" key={x.key}>
              <Output label={t(`item.diff.${x.key}`)} defaultValue={dimAndWeightToText(x.before)} />
              <Output label={t(`item.diff.${x.key}`)} defaultValue={dimAndWeightToText(x.after)} />
            </div>
          )}
        />
        <Diff
          title={t('item.diff.hazardousModifications')}
          data={diff.hazardousMaterialInformations}
          render={(x, index) => (
            <div key={`${x.key}${index}`}>
              <div className="flex">
                <Output defaultValue={`${t('item.limitedQuantity')}: ${getEmptyOrTranslate(x.before?.hasLimitedQuantity)}`} />
                <Output
                  highlight={x.diff.includes('hasLimitedQuantity')}
                  defaultValue={`${t('item.limitedQuantity')}: ${getEmptyOrTranslate(x.after?.hasLimitedQuantity)}`}
                />
              </div>
              <div className="flex">
                <Output defaultValue={`${t('item.class')}: ${x.before?.hazardClass || '-'}`} />
                <Output highlight={x.diff.includes('hazardClass')} defaultValue={`${t('item.class')}: ${getEmptyOrValue(x.after?.hazardClass)}`} />
              </div>
              <div className="flex">
                <Output defaultValue={`${t('item.un')}: ${x.before?.unNumber || '-'}`} />
                <Output highlight={x.diff.includes('unNumber')} defaultValue={`${t('item.un')}: ${getEmptyOrValue(x.after?.unNumber)}`} />
              </div>
              <div className="flex">
                <Output defaultValue={`${t('item.pg')}: ${x.before?.packagingGroup || '-'}`} />
                <Output highlight={x.diff.includes('packagingGroup')} defaultValue={`${t('item.pg')}: ${getEmptyOrValue(x.after?.packagingGroup)}`} />
              </div>
            </div>
          )}
        />
      </MainSection>
    </>
  );
};

BreakbulkItemDiff.propTypes = {
  inventoryId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default BreakbulkItemDiff;
