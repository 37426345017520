import { toOption } from '../../common/helpers/list/options';

const packagingTypes = {
  barrelDrum: 'barrelDrum',
  bigBag: 'bigBag',
  box: 'box',
  crate: 'crate',
  loose: 'loose',
  pallet: 'pallet',
  strappedBundle: 'strappedBundle',
  unit: 'unit',
  reel: 'reel',
  tote: 'tote',
};

export const getPackagingTypeOptions = (translator, translatorPrefix = 'packagings') => {
  return Object.values(packagingTypes)
    .map(toOption(translator, translatorPrefix))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export default packagingTypes;
