import PropTypes from 'prop-types';
import { CARGO_TYPE } from '../../cargoType';
import BreakbulkIcon from './BreakbulkIcon';
import BulkIcon from './BulkIcon';
import { iconSizes } from './Icon';

const CargoTypeIcon = ({ size, styles, type }) => {
  switch (type) {
    case CARGO_TYPE.breakbulk:
      return <BreakbulkIcon size={size} styles={styles} />;
    case CARGO_TYPE.bulk:
      return <BulkIcon size={size} styles={styles} />;
    default:
      return null;
  }
};

CargoTypeIcon.propTypes = {
  size: PropTypes.oneOf(Object.values(iconSizes)),
  styles: PropTypes.shape(),
  type: PropTypes.oneOf(Object.values(CARGO_TYPE)).isRequired,
};

CargoTypeIcon.defaultProps = {
  size: iconSizes.medium,
  styles: {},
};

export default CargoTypeIcon;
