import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Attachments } from '../../attachment';
import { UrlBuilder } from '../../common/HashedRouter';
import HashedRouter from '../../common/HashedRouter/HashedRouter';
import RoutedModal from '../../layout/RoutedModal';
import MediaFiles from '../../mediaFile/MediaFiles';
import url from './attachments/url';

const AttachmentsAndMediaFiles = (props) => {
  const attachmentPattern = UrlBuilder.buildPattern('/attachments/:inventoryId/item/:itemId');
  const mediaFilePattern = UrlBuilder.buildPattern('/media-files/:inventoryId/item/:itemId');

  const { push, location } = useHistory();
  const { t } = useTranslation('inventory');

  const actions = [
    {
      key: 'view-attachments',
      text: t('viewAttachments'),
      icon: 'attachment',
      onClick: ({ id, clientInventory: { id: inventoryId } }) => {
        push(UrlBuilder.buildUrl(location, attachmentPattern, { inventoryId, itemId: id }));
      },
      renderWhen: ({ attachmentIds }) => attachmentIds && attachmentIds.length,
    },
    {
      key: 'view-media-file',
      text: t('viewMediaFile'),
      icon: 'camera',
      onClick: ({ id, clientInventory: { id: inventoryId } }) => {
        push(UrlBuilder.buildUrl(location, mediaFilePattern, { inventoryId, itemId: id }));
      },
      renderWhen: ({ mediaFiles }) => mediaFiles && mediaFiles.length,
    },
  ];
  return (
    <>
      {React.createElement(props.component, { actions })}
      <HashedRouter
        routes={[
          {
            path: attachmentPattern,
            // eslint-disable-next-line react/prop-types
            component: ({ params }) => (
              <RoutedModal maxWidth="lg" fullWidth title={t('attachments')}>
                {/* eslint-disable-next-line react/prop-types */}
                <Attachments resourceUrl={url(params.inventoryId, params.itemId)} />
              </RoutedModal>
            ),
          },
          {
            path: mediaFilePattern,
            // eslint-disable-next-line react/prop-types
            component: ({ params }) => (
              <RoutedModal maxWidth="lg" fullWidth title={t('attachments')}>
                {/* eslint-disable-next-line react/prop-types */}
                <MediaFiles resourceUrl={url(params.inventoryId, params.itemId)} />;
              </RoutedModal>
            ),
          },
        ]}
      />
    </>
  );
};

AttachmentsAndMediaFiles.propTypes = {
  component: PropTypes.any.isRequired,
};

const withAttachmentsAndMediaFiles = (component) => () => {
  return <AttachmentsAndMediaFiles component={component} />;
};

export default withAttachmentsAndMediaFiles;
