export const containerStatusEnum = {
  Empty: 'Empty',
  Stuffed: 'Stuffed',
  Sealed: 'Sealed',
};

export const errorCode = 400;

export const conflictCode = 409;

export const noContentCode = 204;

export const isoDateTimeFormat = 'YYYY-MM-DD HH:mm';

export const maxNumberOfIdentifiers = 4;

export const shipmentStatusEnum = {
  OnBoard: 'OnBoard',
  PartiallyOnBoard: 'PartiallyOnBoard',
  None: 'None',
};

export const maximumAppointmentItems = 10000;

export const theoreticalMaximumItems = 50000;

export const truckRailCarTrailerTypeGroupsEnum = {
  truck: 'truck',
  railCar: 'railCar',
};

export const supportEmailAddress = 'client.portail@qsl.com';
export const supportPhoneNumber = '+1 418-522-4702 #2232';

export const transactionStatusEnum = {
  Cancelled: 'Cancelled',
  Confirmed: 'Confirmed',
  Draft: 'Draft',
  InProgress: 'InProgress',
  PaperworkReceived: 'PaperworkReceived',
  Rectification: 'Rectification',
  Loaded: 'Loaded',
  Unloaded: 'Unloaded',
};
const defaultTransactionStatusColor = '#DBE7EA';
export const transactionStatusColor = {
  [transactionStatusEnum.Cancelled]: '#C2C2C2',
  [transactionStatusEnum.Confirmed]: '#D0EFD3',
  [transactionStatusEnum.Draft]: defaultTransactionStatusColor,
  [transactionStatusEnum.InProgress]: defaultTransactionStatusColor,
  [transactionStatusEnum.Loaded]: defaultTransactionStatusColor,
  [transactionStatusEnum.PaperworkReceived]: defaultTransactionStatusColor,
  [transactionStatusEnum.Rectification]: '#F7ADAD',
  [transactionStatusEnum.Unloaded]: defaultTransactionStatusColor,
};
