import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';
import { englishLocalesCode, frenchLocalesCode } from '../../../i18n';
import useChangeLanguage from '../../../user/useChangeLanguage';

const LanguageInline = () => {
  const { t, i18n } = useTranslation();

  const [changeUserLanguage] = useChangeLanguage();
  return (
    <>
      <LanguageIcon />
      {i18n.language === englishLocalesCode && (
        <button className="ml-2" onClick={() => changeUserLanguage(frenchLocalesCode)}>
          {t('french')}
        </button>
      )}
      {i18n.language === frenchLocalesCode && (
        <button className="ml-2" onClick={() => changeUserLanguage(englishLocalesCode)}>
          {t('english')}
        </button>
      )}
    </>
  );
};

export default LanguageInline;
