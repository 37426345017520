import { i18nChangeLanguage } from '@vooban/datagrid';
import AgnicoEagleEn from './agnicoEagle/__i18n__/en';
import AgnicoEagleFr from './agnicoEagle/__i18n__/fr';
import Attachment from './attachment/__i18n__';
import CargoTypeEn from './cargoType/__i18n__/en';
import CargoTypeFr from './cargoType/__i18n__/fr';
import InventoryEn from './inventory/__i18n__/en';
import InventoryFr from './inventory/__i18n__/fr';
import LayoutEn from './layout/__i18n__/en';
import LayoutFr from './layout/__i18n__/fr';
import MaintenanceEn from './maintenance/__i18n__/en';
import MaintenanceFr from './maintenance/__i18n__/fr';
import MediaFileEn from './mediaFile/__i18n__/en';
import MediaFileFr from './mediaFile/__i18n__/fr';
import ProfileEn from './profile/__i18n__/en';
import ProfileFr from './profile/__i18n__/fr';
import ReceptionEn from './reception/__i18n__/en';
import ReceptionFr from './reception/__i18n__/fr';
import ShipmentEn from './shipment/__i18n__/en';
import ShipmentFr from './shipment/__i18n__/fr';
import TermsAndConditionsEn from './termsAndConditions/__i18n__/en';
import TermsAndConditionsFr from './termsAndConditions/__i18n__/fr';
import WeightAndDimensionsEn from './weightAndDimensions/__i18n__/en';
import WeightAndDimensionsFr from './weightAndDimensions/__i18n__/fr';
import TransactionEn from './transaction/__i18n__/en';
import TransactionFr from './transaction/__i18n__/fr';
import TransportationEn from './transportation/__i18n__/en';
import TransportationFr from './transportation/__i18n__/fr';
import AppointmentEn from './appointment/__18n__/en';
import AppointmentFr from './appointment/__18n__/fr';
import TrafficEn from './traffic/__i18n__/en';
import TrafficFr from './traffic/__i18n__/fr';
import LaborAvailabilityEn from './worker/laborAvailability/__i18n__/en';
import LaborAvailabilityFr from './worker/laborAvailability/__i18n__/fr';
import ReportEn from './excelReports/__i18n__/en';
import ReportFr from './excelReports/__i18n__/fr';
import rootEn from './__i18n__/en';
import rootFr from './__i18n__/fr';

export const lngLocalStorageKey = 'lng';
export const englishLocalesCode = 'en';
export const frenchLocalesCode = 'fr-CA';

const config = (lng) => {
  i18nChangeLanguage(lng);
  return {
    lng,
    fallbackLng: 'en',
    defaultNS: 'layout',
    resources: {
      en: {
        agnicoEagle: AgnicoEagleEn,
        cargoType: CargoTypeEn,
        inventory: InventoryEn,
        layout: LayoutEn,
        profile: ProfileEn,
        reception: ReceptionEn,
        transaction: TransactionEn,
        transportation: TransportationEn,
        shipment: ShipmentEn,
        attachment: Attachment.en,
        maintenance: MaintenanceEn,
        mediaFile: MediaFileEn,
        termsAndConditions: TermsAndConditionsEn,
        weightAndDimensions: WeightAndDimensionsEn,
        appointment: AppointmentEn,
        traffic: TrafficEn,
        laborAvailability: LaborAvailabilityEn,
        root: rootEn,
        reports: ReportEn,
      },
      fr: {
        agnicoEagle: AgnicoEagleFr,
        cargoType: CargoTypeFr,
        inventory: InventoryFr,
        layout: LayoutFr,
        profile: ProfileFr,
        reception: ReceptionFr,
        transaction: TransactionFr,
        shipment: ShipmentFr,
        transportation: TransportationFr,
        attachment: Attachment.fr,
        maintenance: MaintenanceFr,
        mediaFile: MediaFileFr,
        termsAndConditions: TermsAndConditionsFr,
        weightAndDimensions: WeightAndDimensionsFr,
        appointment: AppointmentFr,
        traffic: TrafficFr,
        laborAvailability: LaborAvailabilityFr,
        root: rootFr,
        reports: ReportFr,
      },
    },
  };
};

export default config;
