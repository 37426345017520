import { Button } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import * as PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CARGO_TYPE } from '../cargoType';
import { toLocalDateTime } from '../common/helpers/date';
import { Tooltip } from '../layout';
import ExcelIcon from '../layout/icons/ExcelIcon';
import LoadingIndicator from '../layout/loading/LoadingIndicator';
import DropdownButton from './DropdownButton';
import useGenerateTransactionsExcelReport from './hooks/useGenerateTransactionsExcelReport';
import { useGetMergedTransactionDocuments, useGetSeparateTransactionDocuments } from './hooks/useGetBatchTransactionDocuments';
import ConfirmModal from '../layout/modals/ConfirmModal';
import { useAuth0 } from '@auth0/auth0-react';
import useSendTransactionExcelReportByEmail from './hooks/useSendTransactionExcelReportByEmail';

const maxTransactionsForExcelExport = 10000;
const maxTransactionsForGetDocuments = 100;

const TransactionHeaderActions = ({ totalItemsCount, cargoType }) => {
  const { t } = useTranslation('transaction');
  const { search } = useLocation();
  const [getMergedTransactionDocuments, , isFetchingMergedDocuments] = useGetMergedTransactionDocuments();
  const [getSeparateTransactionDocuments, , isFetchingSeparateDocuments] = useGetSeparateTransactionDocuments();
  const [generateTransactionsExcelReport, , isGeneratingExcelReport] = useGenerateTransactionsExcelReport();
  const [sendTransactionExcelReportByEmail, reportSent, isSendingExcelReport] = useSendTransactionExcelReportByEmail();
  const [showExcelConfirmationModal, setShowExcelConfirmationModal] = React.useState(false);
  const { user } = useAuth0();

  useEffect(() => {
    if (reportSent) {
      setShowExcelConfirmationModal(true);
    }
  }, [reportSent]);

  const handleExportToExcelClick = useCallback(() => {
    const fileName = t('list.headerActions.excelFileName', { date: toLocalDateTime(new Date()) });
    if (cargoType === CARGO_TYPE.bulk) {
      generateTransactionsExcelReport(new URLSearchParams(search), fileName, cargoType);
    } else {
      sendTransactionExcelReportByEmail(new URLSearchParams(search), t);
    }
  }, [generateTransactionsExcelReport, search, t, cargoType, sendTransactionExcelReportByEmail]);

  const handleSingleDocumentClick = useCallback(() => {
    const parameters = new URLSearchParams(search);
    getMergedTransactionDocuments(parameters, cargoType);
  }, [getMergedTransactionDocuments, search, cargoType]);

  const handleSeparateDocumentsClick = useCallback(() => {
    const parameters = new URLSearchParams(search);
    getSeparateTransactionDocuments(parameters, cargoType);
  }, [getSeparateTransactionDocuments, search, cargoType]);

  const exportDocumentsTooltipTitle = useMemo(() => {
    if (totalItemsCount > maxTransactionsForGetDocuments) return t('list.headerActions.getDocumentsTooManyItemsWarning');
    if (!totalItemsCount) return t('list.headerActions.noResultsWarning');
    return '';
  }, [t, totalItemsCount]);

  const exportExcelTooltipTitle = useMemo(() => {
    if (totalItemsCount > maxTransactionsForExcelExport)
      return t('list.headerActions.tooManyResultsToExportWarning', { number: process.env.REACT_APP_MAX_EXCEL_ENTRIES_TO_EXPORT });
    if (!totalItemsCount) return t('list.headerActions.noResultsWarning');
    return '';
  }, [t, totalItemsCount]);

  const isLoading = isFetchingMergedDocuments || isFetchingSeparateDocuments || isGeneratingExcelReport || isSendingExcelReport;
  const isGetDocumentButtonDisabled = totalItemsCount > maxTransactionsForGetDocuments || !totalItemsCount || isFetchingSeparateDocuments;
  const isExportExcelButtonDisabled = totalItemsCount > maxTransactionsForExcelExport || !totalItemsCount || isGeneratingExcelReport;

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <Tooltip arrow placement="top" spanStyle={{ cursor: 'not-allowed' }} title={exportDocumentsTooltipTitle}>
        <DropdownButton
          title={t('list.headerActions.getDocuments')}
          buttonProps={{ startIcon: <ArchiveIcon />, endIcon: <KeyboardArrowDownIcon /> }}
          isDisabled={isGetDocumentButtonDisabled}
          items={[
            {
              label: t('list.headerActions.singleFile'),
              onClick: handleSingleDocumentClick,
              isDisabled: isGetDocumentButtonDisabled,
            },
            {
              label: t('list.headerActions.separateDocuments'),
              onClick: handleSeparateDocumentsClick,
              isDisabled: isGetDocumentButtonDisabled,
            },
          ]}
        />
      </Tooltip>
      <Tooltip arrow placement="top" spanStyle={{ cursor: 'not-allowed' }} title={exportExcelTooltipTitle}>
        <Button
          disabled={isExportExcelButtonDisabled}
          variant="text"
          color="primary"
          size="small"
          startIcon={<ExcelIcon disabled={isExportExcelButtonDisabled} />}
          onClick={handleExportToExcelClick}>
          {t('list.headerActions.exportToExcel')}
        </Button>
      </Tooltip>
      <ConfirmModal
        open={showExcelConfirmationModal}
        handleClose={() => setShowExcelConfirmationModal(false)}
        title={t('excelConfirmationModal.title')}
        message={t('excelConfirmationModal.message', { email: user.email })}
        confirmButtonLabel={t('excelConfirmationModal.ok')}></ConfirmModal>
    </>
  );
};
TransactionHeaderActions.propTypes = {
  cargoType: PropTypes.oneOf([CARGO_TYPE.bulk, CARGO_TYPE.breakbulk]).isRequired,
  totalItemsCount: PropTypes.number,
};

export default TransactionHeaderActions;
