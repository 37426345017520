import axios from 'axios';
import { CARGO_TYPE } from '../../../../cargoType';
import useAgent from '../../../../hooks/useAgent';

const maxNumberOfContainedItems = '1000';
const getUrl = ({ inventoryId, itemId }) => `/api/v1/inventory/${inventoryId}/inventory-items/${itemId}/containerized-items`;

const useGetContainedItems = ({ inventoryId, itemId }) => {
  return useAgent((params = new URLSearchParams()) => {
    params.set('cargoType', CARGO_TYPE.breakbulk);
    params.set('take', maxNumberOfContainedItems);
    return axios.get(getUrl({ inventoryId, itemId }), { params });
  });
};

export default useGetContainedItems;
