import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toLocalDateTime } from '../../common/helpers/date';
import Project from './types/Project';
import { getProjectsForWeek } from '@vooban/tc3-core/laborAvailability';
import Moment from 'moment';

const LaborAvailabilityProjectTooltipContent = ({ projects = [], startDate }) => {
  const { t } = useTranslation('laborAvailability');

  const weekProjects = getProjectsForWeek(startDate, projects);

  return (
    <div className="mb-4">
      <div className="mb-4 font-semibold">{t('planningCalendar')}</div>
      {weekProjects.map(({ id, name, number, startDate, endDate, cargo, remark }, index) => (
        <div className="tile__labor-availability" key={`${id.toString()}th`} style={{ marginTop: index > 0 ? 18 : 0 }}>
          <div className="m-3">
            <div className="font-semibold mb-2">{name}</div>
            <div className="label">{`${toLocalDateTime(startDate)} -\n${toLocalDateTime(endDate)}`}</div>
            <div>
              <span className="label">{t('projectNumber')}: </span>
              <span>{number}</span>
            </div>
            {remark && (
              <div>
                <span className="label">{t('remark')}: </span>
                <span>{remark}</span>
              </div>
            )}
          </div>
          <div>
            {cargo.map(({ productCategory, productTypeName, productSubtypeName, quantity, totalWeightInMT, unit }, index) => (
              <React.Fragment key={productTypeName + index}>
                <div className="separator" />
                <div className="m-3">
                  <div>
                    <span className="label">{t('productCategory')}: </span>
                    <span>{productCategory}</span>
                  </div>
                  <div>
                    <span className="label">{t('productType')}: </span>
                    <span>{productTypeName}</span>
                  </div>
                  <div>
                    <span className="label">{t('productSubtype')}: </span>
                    <span>{productSubtypeName}</span>
                  </div>
                  <div>
                    <span className="label">{t('quantity')}: </span>
                    <span>{[quantity || 0, unit ? t(`units.${unit}`) : '-'].join(' ')}</span>
                  </div>
                  <div>
                    <span className="label">{t('totalWeightInMT')}: </span>
                    <span>{`${totalWeightInMT}`}</span>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

LaborAvailabilityProjectTooltipContent.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape(Project)).isRequired,
  startDate: PropTypes.objectOf(Moment).isRequired,
};

export default LaborAvailabilityProjectTooltipContent;
