import * as PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { CARGO_TYPE } from '../../cargoType';
import CargoTypeListToggler from '../../cargoType/CargoTypeListToggler';
import { ActionPropTypes } from '../../layout/Datagrid';
import { ListPageWithPersisted } from '../../layout/ListPage';
import LoadingIndicator from '../../layout/loading/LoadingIndicator';
import { getTransactionListOptions } from '../helpers/listHelpers';
import TransactionHeaderActions from '../TransactionHeaderActions';
import AdvancedFiltersSection from './AdvancedFiltersSection';
import getColumns from './columnsSettings';
import useGetBreakbulkTransactions from './useGetBreakbulkTransactions';
import useGetBreakbulkTransactionsOptions from './useGetBreakbulkTransactionsOptions';
import ListPageHeaderWithDateFilter from '../../layout/ListPage/headers/ListPageHeaderWithDateFilter';

const header = (props) => {
  const dateFilterOptions = {
    propertyNames: { from: 'effectiveDateFrom', to: 'effectiveDateTo' },
    canClearFilter: false,
  };
  return <ListPageHeaderWithDateFilter {...props} dateFilterOptions={dateFilterOptions}></ListPageHeaderWithDateFilter>;
};

const BreakbulkTransactionList = ({ actions }) => {
  const { t, i18n } = useTranslation('transaction');
  const [fetchTransactions, data = {}, isLoading] = useGetBreakbulkTransactions();

  const [fetchTransactionsOptions, options] = useGetBreakbulkTransactionsOptions();

  const columns = useMemo(() => {
    const listOptions = getTransactionListOptions(options, t);
    return getColumns(listOptions, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, i18n.language]);
  const location = useLocation();
  const { push } = useHistory();

  useEffect(() => {
    fetchTransactionsOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    const listType = localStorage.getItem('listType');
    if (!listType && location.search === '' && data?.items?.length === 0) {
      localStorage.setItem('listType', 'bulk');
      push('/inventory/items/bulk');
    }
  }, [data, location.search, push]);

  return (
    <>
      <LoadingIndicator show={isLoading} />
      <ListPageWithPersisted
        header={header}
        title={t('transactions')}
        defaultColumnWidth={75}
        checkable={false}
        data={data}
        noRowLink
        columns={columns}
        translater={t}
        fetcher={fetchTransactions}
        actions={actions}
        headerProps={{
          actions: <TransactionHeaderActions totalItemsCount={data?.totalItems || 0} cargoType={CARGO_TYPE.breakbulk} />,
          advancedFilterSection: AdvancedFiltersSection,
          subscript: <CargoTypeListToggler />,
        }}
      />
    </>
  );
};

BreakbulkTransactionList.propTypes = {
  actions: PropTypes.arrayOf(ActionPropTypes),
  rowIsNavigablePredicate: PropTypes.func,
};

export default BreakbulkTransactionList;
