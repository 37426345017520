import axios from 'axios';
import { CARGO_TYPE } from '../../../cargoType';
import useAgent from '../../../hooks/useAgent';

const url = `/api/v1/inventory-items`;

export const getItems = (parameters = {}) => {
  parameters.set('cargoType', CARGO_TYPE.bulk);
  return axios.get(url, { params: parameters });
};

const useGetItems = () => useAgent(getItems);

export default useGetItems;
