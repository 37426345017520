import * as PropTypes from 'prop-types';
import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import CompanySelectionModal from './CompanySelectionModal';

const viewAsCompanyHeader = 'X-View-As-Company-Id';
const storageKey = 'viewAsCompanies';

const CompaniesContext = createContext({ companies: [], viewAsCompany: false, selectCurrentCompanies: () => undefined });

const CompaniesContextProvider = ({ children, canViewAsCompany }) => {
  const [currentCompanies, setCurrentCompanies] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [containerKey, setContainerKey] = useState(0);

  const handleCloseDialog = () => setIsDialogOpen(false);
  const selectCurrentCompanies = () => setIsDialogOpen(true);

  const updateCompanies = (companies) => {
    setCurrentCompanies(companies);
    axios.defaults.headers[viewAsCompanyHeader] = companies.map((c) => c.id);
    // Change the key to force a reload of the child components
    setContainerKey(containerKey + 1);
  };

  useEffect(() => {
    if (!canViewAsCompany) return;
    const storedCompanies = JSON.parse(sessionStorage.getItem(storageKey));
    if (storedCompanies) {
      updateCompanies(storedCompanies);
    } else {
      setIsDialogOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canViewAsCompany]);

  const handleConfirm = (companies) => {
    sessionStorage.setItem(storageKey, JSON.stringify(companies));
    localStorage.removeItem('listType');
    updateCompanies(companies);
    handleCloseDialog();
  };

  return (
    <>
      <CompaniesContext.Provider key={containerKey} value={{ currentCompanies, viewAsCompany: canViewAsCompany, selectCurrentCompanies }}>
        {children}
      </CompaniesContext.Provider>
      <CompanySelectionModal open={isDialogOpen} onClose={handleCloseDialog} onConfirm={handleConfirm} currentCompanies={currentCompanies} />
    </>
  );
};
CompaniesContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  canViewAsCompany: PropTypes.bool,
};

export { CompaniesContext, CompaniesContextProvider };
