import PropTypes from 'prop-types';
import React from 'react';
import Toast from './Toast';

const SuccessToast = ({ message, onClose }) => {
  return <Toast message={message} severity="success" onClose={onClose} />;
};

SuccessToast.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
};

export default SuccessToast;
