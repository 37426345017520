import { generatePath } from 'react-router';

const buildUrl = (location, pattern, params) => `${location.pathname}${location.search}${generatePath(pattern, params)}`;

const buildPattern = (matchPath) => `#${matchPath}`;

const UrlBuilder = {
  buildUrl,
  buildPattern,
};

export default UrlBuilder;
