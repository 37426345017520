import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoadingIndicator from '../../../layout/loading/LoadingIndicator';
import AgnicoEagleAssignationsList from '../agnicoEagle/AgnicoEagleAssignationsList';
import { InventoryItem } from '../types/InventoryItem';
import { useGetItemAgnicoEagleAssignations } from './useGetItem';
import _ from 'lodash';

const AgnicoEagleAssignationsPage = ({ item }) => {
  const { inventoryId, itemId } = useParams();
  const [fetchItemAgnicoEagleAssignations, itemAgnicoEagleAssignations = [], isLoading] = useGetItemAgnicoEagleAssignations();

  useEffect(() => {
    if (inventoryId && itemId && item) {
      fetchItemAgnicoEagleAssignations(inventoryId, itemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryId, itemId, item]);

  // https://gist.github.com/joyrexus/9837596
  const nestBy = (seq, keys) => {
    if (!keys.length) {
      return seq;
    }
    const [first, ...rest] = keys;
    return _.mapValues(_.groupBy(seq, first), (value) => nestBy(value, rest));
  };

  return (
    <>
      <LoadingIndicator show={isLoading} />
      <AgnicoEagleAssignationsList
        itemAgnicoEagleAssignationsByPriorityShippingConditions={nestBy(itemAgnicoEagleAssignations, ['priority', 'shippingCondition'])}
      />
    </>
  );
};

AgnicoEagleAssignationsPage.propTypes = {
  item: PropTypes.shape(InventoryItem).isRequired,
};

export default AgnicoEagleAssignationsPage;
