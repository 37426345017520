import { Grid, IconButton, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField } from '../layout/fields';
import NumberInput from '../layout/fields/NumberInput';
import SelectField from '../layout/fields/SelectField';
import SelectInput from '../layout/fields/SelectInput';
import Breakpoint from '../layout/mediaQuery';
import { getWeightAndDimensionsTypeOptions, getWeightAndDimensionsUnitOptions } from './helpers/optionsHelper';

const baseNewLine = {
  type: null,
  minimum: '',
  maximum: '',
  unit: null,
};

const makeDeleteButtonStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 0,
  },
}));

const makeDeleteIconStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

const makeInputStyles = makeStyles((theme) => ({
  root: {
    paddingRight: '1rem',
  },
}));

const makeRowStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1rem',
  },
}));

const WeightAndDimensionsFilter = ({ name, push, remove }) => {
  const typeRef = React.useRef();
  const minRef = React.useRef();
  const maxRef = React.useRef();

  const { setFieldValue, values } = useFormikContext();
  const { t } = useTranslation('weightAndDimensions');
  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);

  const deleteButtonStyles = makeDeleteButtonStyles();
  const deleteIconStyles = makeDeleteIconStyles();
  const inputStyles = makeInputStyles();
  const rowStyles = makeRowStyles();

  const [newLine, setNewLine] = useState(baseNewLine);

  const dimensions = values[name] || [];
  const weightAndDimensionsTranslation = isTabletOrLarger ? t('weightAndDimensions') : t('weightAndDimensionsAcronym');

  const handleNewRow = useCallback(
    (inputRef) => async (event) => {
      if (event.target.value !== null && event.target.value !== '') {
        await push({ ...baseNewLine, [event.target.name]: event.target.value });
        setNewLine(baseNewLine);
        inputRef.current.focus();
      }
    },
    [push]
  );

  const handleNewLineValueChange = useCallback(
    (name) => (event) => {
      setNewLine({ [name]: event.target.value });
    },
    []
  );

  const handleTypeChange = useCallback(
    (index) => () => {
      setFieldValue(`${name}.${index}.unit`, '');
    },
    [name, setFieldValue]
  );

  const availableTypeOptions = getWeightAndDimensionsTypeOptions({ dimensions, translator: t });

  return (
    <Grid container>
      {dimensions.map((dimension, index) => {
        const isLastLine = index === dimensions.length - 1;
        return (
          <Grid classes={rowStyles} key={`${name}.${index}`} container>
            <Grid classes={inputStyles} item xs={3} md={2}>
              <SelectField
                label={weightAndDimensionsTranslation}
                name={`${name}.${index}.type`}
                options={getWeightAndDimensionsTypeOptions({ currentDimension: dimension, dimensions, translator: t })}
                onChange={handleTypeChange(index)}
                inputRef={isLastLine ? typeRef : null}
              />
            </Grid>
            <Grid classes={inputStyles} item xs={3} md={1}>
              <NumberField label={t('min')} name={`${name}.${index}.minimum`} inputRef={isLastLine ? minRef : null} />
            </Grid>
            <Grid classes={inputStyles} item xs={3} md={1}>
              <NumberField label={t('max')} name={`${name}.${index}.maximum`} inputRef={isLastLine ? maxRef : null} />
            </Grid>
            <Grid classes={inputStyles} item xs={2} md={1}>
              <SelectField label={t('unit')} name={`${name}.${index}.unit`} options={getWeightAndDimensionsUnitOptions(dimension.type, t)} />
            </Grid>
            <Grid classes={deleteIconStyles} item xs={1} md={1}>
              <IconButton classes={deleteButtonStyles} size="small" aria-label="delete" onClick={() => remove(index)}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
      {!!availableTypeOptions?.length && (
        <Grid container>
          <Grid classes={inputStyles} item xs={3} md={2}>
            <SelectInput
              disableCloseOnSelect
              label={weightAndDimensionsTranslation}
              name="type"
              onChange={handleNewRow(typeRef)}
              options={availableTypeOptions}
              value={newLine.type}
            />
          </Grid>
          <Grid classes={inputStyles} item xs={3} md={1}>
            <NumberInput label={t('min')} name="minimum" onBlur={handleNewRow(minRef)} onChange={handleNewLineValueChange('minimum')} value={newLine.minimum} />
          </Grid>
          <Grid classes={inputStyles} item xs={3} md={1}>
            <NumberInput label={t('max')} name="maximum" onBlur={handleNewRow(maxRef)} onChange={handleNewLineValueChange('maximum')} value={newLine.maximum} />
          </Grid>
          <Grid classes={inputStyles} item xs={3} md={1}>
            <SelectInput label={t('unit')} name="unit" />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

WeightAndDimensionsFilter.propTypes = {
  name: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};

export default WeightAndDimensionsFilter;
