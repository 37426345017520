import axios from 'axios';
import useAgent from '../hooks/useAgent';

const getAttachmentsCountInHead = (resourceUrl, params) =>
  axios.head(`${resourceUrl}/attachments`, { params }).then(({ headers }) => (headers ? Number(headers['x-total-count']) ?? 0 : 0));

const getAttachments = (resourceUrl, params) => axios.get(`${resourceUrl}/attachments`, { params });

const getHazardousAttachments = (resourceUrl) => axios.get(`${resourceUrl}/attachments/hazardous`);

export const useAttachmentsCount = () => useAgent(getAttachmentsCountInHead);
export const useGetAttachments = () => useAgent(getAttachments);
export const useGetHazardousAttachments = () => useAgent(getHazardousAttachments);
