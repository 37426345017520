import PropTypes from 'prop-types';
import React from 'react';
import Toast from './Toast';

const ErrorToast = ({ message, onClose }) => {
  return <Toast message={message} severity="error" onClose={onClose} />;
};

ErrorToast.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
};

export default ErrorToast;
