import axios from 'axios';
import useAgent from '../../hooks/useAgent';
import { appointmentOptionsUrl as url } from '../urls';

export const getOptions = (parameters = new URLSearchParams()) => {
  return axios.get(url, {
    params: parameters,
  });
};

export const useAppointmentGetOptions = () =>
  useAgent((params) => {
    const urlParams = new URLSearchParams(params);
    if (urlParams.has('dropDownValue')) urlParams.delete('dropDownValue');
    if (urlParams.has('period')) urlParams.delete('period');

    return getOptions(urlParams);
  });

const useGetOptions = () => useAgent(getOptions);

export default useGetOptions;
