import { useParams } from 'react-router-dom';
import MediaFiles from '../../../mediaFile/MediaFiles';
import url from './url';

const ItemMediaFiles = () => {
  const { inventoryId, itemId } = useParams();

  return <MediaFiles resourceUrl={url(inventoryId, itemId)} />;
};

export default ItemMediaFiles;
