const mediaFiles = {
  photos: 'Photos',
  videos: 'Vidéos',
  empty: 'Aucune donnée',
  list: {
    creator: 'Créer par',
    note: 'Note',
    datetime: 'Date & Heure',
    videosFiles: 'Fichiers vidéos',
  },
};

export default mediaFiles;
