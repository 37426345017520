const attachment = {
  list: {
    category: 'Category',
    description: 'Description',
    file: 'File',
    upload: 'Upload',
    uploadedBy: 'Uploaded By',
  },
};

export default attachment;
