import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { LayoutPage } from './layout';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static propTypes = {
    children: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
    t: PropTypes.func.isRequired,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    return { hasError: true };
  }

  render() {
    if (!this.state.hasError) return this.props.children;
    return (
      <LayoutPage>
        <div className="flex h-full justify-center items-center top-1/2">
          <div className="flex flex-col justify-center items-center my-auto">
            <Typography variant="h4" className="pb-2">
              {this.props.t('ohoh')}
            </Typography>
            <Typography className="pb-4">{this.props.t('letUsHelpYou')}</Typography>
            <span className="pb-4">
              <Button color="primary" size="medium" variant="contained" onClick={this.props.history.goBack}>
                {this.props.t('back')}
              </Button>
            </span>
            <Typography className="text-center pb-2">{this.props.t('errorMessage.technicalError')}</Typography>
          </div>
        </div>
      </LayoutPage>
    );
  }
}

export default withTranslation()(withRouter(ErrorBoundary));
