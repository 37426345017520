const identifierLabels = {
  barcode: 'barcode',
  billOfLading: 'billOfLading',
  booking: 'booking',
  description: 'description',
  heatNumber: 'heatNumber',
  lotNumber: 'lotNumber',
  mark: 'mark',
  poNumber: 'poNumber',
  scope: 'scope',
  serialNumber: 'serialNumber',
  other: 'other',
};

export default identifierLabels;
