import { AppBar, makeStyles } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    bottom: 0,
    top: 'auto',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    borderTop: '1px solid rgba(72, 84, 101, 0.1)',
  },
}));

const Footer = () => {
  const styles = useStyles();
  return (
    <AppBar position="fixed" classes={{ root: styles.root }}>
      <div className="flex justify-around items-center h-10">
        <span className="w-1/3 flex justify-end">
          <a className="pl-2" href="https://www.facebook.com/QSLcom" target="_blank" rel="noreferrer">
            <FacebookIcon />
          </a>
          <a className="pl-2" href="https://www.linkedin.com/company/10005468/" target="_blank" rel="noreferrer">
            <LinkedInIcon />
          </a>
          <a className="pl-2" href=" https://www.youtube.com/channel/UCoq7tpHzq1cw7UcpqyspzHw" target="_blank" rel="noreferrer">
            <YouTubeIcon />
          </a>
        </span>
        <a className="w-1/3 flex justify-center" href="mailto:info@qsl.com" target="_blank" rel="noreferrer">
          info @qsl.com
        </a>
        <a className="w-1/3 flex justify-start" href="tel:1 418 522 4701">
          418-522-4701
        </a>
      </div>
    </AppBar>
  );
};

export default Footer;
