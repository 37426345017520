import identifiers from '../Identifiers/__i18n__/fr';
import list from '../ListPage/__i18n__/fr';

const listHeader = {
  clearFilters: 'Effacer les filtres',
  count: 'Nombre',
  search: 'Rechercher',
};

const listContextMenu = {
  open: 'Ouvrir',
  openInNewTab: 'Ouvrir dans un nouvel onglet',
};

const header = {
  backToList: 'Retour vers la liste',
};

const validation = {
  invalidValue: 'Valeur invalide',
  required: 'Champ requis',
};

const common = {
  all: 'Tous',
};

const errorMessage = {
  networkError: 'Serveur non accessible. Veuillez contacter le soutien technique.',
  noInternet: "Pas d'internet",
  technicalError: 'Une erreur technique est survenue, veuillez contacter le soutien technique',
  503: "L'opération demandée n'a pu se compléter avec succès. Veuillez réessayer plus tard et contacter le support si la situation persiste.",
};

const button = {
  noSpecificStartDate: 'Pas date de début précise',
  noSpecificEndDate: 'Pas date de fin précise',
};

const dropDownValues = {
  today: "Aujourd'hui",
  thisWeek: 'Cette semaine',
  thisMonth: 'Ce mois-ci',
  allDates: 'Toutes les dates',
  custom: 'Personnalisé',
  daily: 'Journalier',
  weekly: 'Hebdomadaire',
  monthly: 'Mensuel',
};

const layout = {
  appointments: 'Mes rendez-vous',
  asCompany: 'En tant que:',
  back: 'Retour',
  clickHereToGoToTc3: 'Cliquez ici pour aller sur TC3',
  close: 'Fermer',
  common,
  confirm: 'Confirmer',
  english: 'English',
  errorMessage,
  french: 'Français',
  header,
  inventory: 'Mon Inventaire',
  letUsHelpYou: 'Laissez nous vous aider',
  laborAvailabilityTab: "Disponibilités de la main-d'œuvre",
  listHeader,
  logout: 'Se déconnecter',
  maintenance: 'Maintenance',
  none: 'Aucun',
  needAssistance: "Besoin d'aide?",
  ohoh: 'Oh Oh',
  profile: 'Mon Profil',
  reception: 'Réception',
  reports: 'Mes Rapports',
  shipment: 'Expédition',
  support: 'Soutien',
  supportPhoneNumber: 'Téléphone: {{phone}}',
  supportEmailAddress: 'Courriel: {{email}}',
  thisIsTheClientPortal: 'Vous êtes sur le Portail Client',
  traffic: 'Traffic',
  transaction: 'Mes Transactions',
  termsAndConditions: 'Termes et Conditions',
  userGuide: 'Guide d’utilisateur',
  validation,
  viewAs: 'Voir en tant que',
  identifiers,
  listContextMenu,
  list: list,
  button,
  dropDownValues,
};

export default layout;
