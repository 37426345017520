import PropTypes from 'prop-types';

export const DimensionUnitEnum = {
  millimeter: 'mm',
  centimeter: 'cm',
  meter: 'm',
  inch: 'in',
  feet: 'ft',
};

export const FootPoundUnitEnum = {
  poundsPerFoot: 'lbs/ft',
  kilogramsPerMeter: 'kg/m',
};

const Dimension = {
  type: PropTypes.string,
  value: PropTypes.number,
  unit: PropTypes.oneOf([...Object.values(DimensionUnitEnum), ...Object.values(FootPoundUnitEnum)]),
};

export default Dimension;
