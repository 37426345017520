import axios from 'axios';
import { useTranslation } from 'react-i18next';
import useAgent from '../hooks/useAgent';
import useSuccessStore from '../hooks/useSuccessStore';

const url = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`;
const connection = 'Username-Password-Authentication';

const changePassword = (user) => {
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    url,
    data: {
      client_id: process.env.REACT_APP_AUTH0_CLIENTID,
      email: user.email,
      connection,
    },
  };

  return axios.request(options);
};

const useChangePassword = () => {
  const { t } = useTranslation('profile');
  const [, successActions] = useSuccessStore();
  const successHandler = () => successActions.setSuccessMessage(t('passwordChangeEmailMessage'));
  return useAgent(changePassword, { successHandler });
};

export default useChangePassword;
