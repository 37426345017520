import { Button, Divider, ListItemText, Menu, MenuItem } from '@material-ui/core';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import PropTypes from 'prop-types';

const DropdownButton = (props) => (
  <PopupState variant="popover">
    {(popupState) => (
      <>
        <Button disabled={props.isDisabled} variant="text" color="primary" size="small" {...props.buttonProps} {...bindTrigger(popupState)}>
          {props.title}
        </Button>
        <Menu {...bindMenu(popupState)}>
          {props.items.map((item, index) => (
            <div key={item.label}>
              <MenuItem
                disabled={item.isDisabled}
                onClick={() => {
                  popupState.close();
                  item.onClick();
                }}>
                <ListItemText>{item.label}</ListItemText>
              </MenuItem>
              {props.items.length !== index + 1 && <Divider />}
            </div>
          ))}
        </Menu>
      </>
    )}
  </PopupState>
);

DropdownButton.propTypes = {
  buttonProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired, onClick: PropTypes.func.isRequired, isDisabled: PropTypes.bool })).isRequired,
  title: PropTypes.string.isRequired,
};

DropdownButton.defaultProps = {
  isDisabled: false,
};

export default DropdownButton;
