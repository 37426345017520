import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { SET_COLUMN_SETTINGS } from '../../../reducers';

// eslint-disable-next-line react/prop-types
const withPersistedColumns = (component) => (props) => {
  const { path } = useRouteMatch();

  const columnSettings = useSelector((state) => state.dataGrid.columnSettings[path]);
  const dispatch = useDispatch();
  const setColumnSettings = useCallback(
    (payload) =>
      dispatch({
        type: SET_COLUMN_SETTINGS,
        payload: { pathKey: path, columnSettings: payload },
      }),
    [dispatch, path]
  );

  return React.createElement(component, {
    ...props,
    setColumnSettings: setColumnSettings,
    columnSettings: columnSettings,
  });
};

export default withPersistedColumns;
