import axios from 'axios';
import useAgent from '../../hooks/useAgent';

const batchActionsUrl = (clientInventoryId, id) => `/api/v1/inventory/${clientInventoryId}/batch-actions/${id}`;

const getBatchAction = (clientInventoryId, batchActionId) => axios.get(batchActionsUrl(clientInventoryId, batchActionId)).then(({ data }) => data);

const useGetBatchAction = () => useAgent(getBatchAction);

export default useGetBatchAction;
