import { Chip, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CARGO_TYPE } from '.';
import CargoTypeIcon from '../layout/icons/CargoTypeIcon';

export const defaultBadgeColor = '#F2F5F7';

const chipStyles = (marginBottom, marginTop, backgroundColor = defaultBadgeColor) =>
  makeStyles((theme) => ({
    root: {
      backgroundColor,
      marginRight: '0.5rem',
      marginBottom,
      marginTop,
      color: theme.palette.text.secondary,
    },
  }));

const iconStyles = {
  marginLeft: '0.5rem',
};

const CargoTypeBadge = ({ marginBottom, marginTop, cargoType }) => {
  const { t } = useTranslation('cargoType');

  return (
    <Chip
      classes={chipStyles(marginBottom, marginTop)()}
      size="small"
      label={t(cargoType)}
      icon={<CargoTypeIcon styles={iconStyles} type={cargoType} data-testid={`badge_${t(cargoType)}`} />}
    />
  );
};

CargoTypeBadge.propTypes = {
  cargoType: PropTypes.oneOf(Object.values(CARGO_TYPE)).isRequired,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
};

CargoTypeBadge.defaultProps = {
  marginBottom: null,
  marginTop: null,
};

export default CargoTypeBadge;
