import download from './download';

export const preview = (blob, fileName) => {
  // For preview to work we need content-type with proper value instead of application/octet-stream
  // window might be null if user has an ad blocker or had disable pop-up
  const newWindow = window.open();
  if (newWindow) {
    newWindow.location.assign(window.URL.createObjectURL(blob));
  } else {
    download(blob, fileName);
  }
};
