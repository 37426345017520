import { useEffect, useState } from 'react';
import { formatNumber } from '../../../common/helpers/numbers';

const isUndefinedOrNull = (value) => value === undefined || value === null;

const useGetBreakbulkListPills = ({ totalQuantity, totalVolume, totalWeight }, t) => {
  const [quantity, setQuantity] = useState(totalQuantity);
  const [volume, setVolume] = useState(totalVolume);
  const [weight, setWeight] = useState(totalWeight);

  useEffect(() => {
    if (!isUndefinedOrNull(totalQuantity)) setQuantity(totalQuantity);
    if (!isUndefinedOrNull(totalVolume)) setVolume(totalVolume);
    if (!isUndefinedOrNull(totalWeight)) setWeight(totalWeight);
  }, [totalQuantity, totalVolume, totalWeight]);

  const totalQuantityPill = `${t('item.quantity')}: ${isUndefinedOrNull(quantity) ? '-' : formatNumber(quantity, 0)}`;
  const totalVolumePill = `${t('item.volume')}: ${isUndefinedOrNull(volume) ? '-' : formatNumber(volume)} m³`;
  const totalWeightPill = `${t('item.weight')}: ${isUndefinedOrNull(weight) ? '-' : formatNumber(weight)} MT`;

  return [totalQuantityPill, totalWeightPill, totalVolumePill];
};

export default useGetBreakbulkListPills;
