import axios from 'axios';
import download from '../../common/helpers/download';
import { preview } from '../../common/helpers/preview';
import useAgent from '../../hooks/useAgent';

const mediaFileUrl = (resourceUrl) => `${resourceUrl}/media-files`;

const mediaFileDownloadUrl = (resourceUrl, id) => `${resourceUrl}/media-files/${id}/download`;

const getMediaFiles = (resourceUrl, params) => axios.get(mediaFileUrl(resourceUrl), { params });

const downloadMediaFile = async (resourceUrl, id, filename) => {
  const response = await axios.get(mediaFileDownloadUrl(resourceUrl, id), { responseType: 'blob' });
  return download(response.data, filename);
};
const previewMediaFile = async (resourceUrl, id, filename) => {
  const response = await axios.get(mediaFileDownloadUrl(resourceUrl, id), { responseType: 'blob' });
  return preview(response.data, filename);
};

export const useGetMediaFiles = () => useAgent(getMediaFiles);
export const useDownloadMediaFile = () => useAgent(downloadMediaFile);
export const usePreviewMediaFile = () => useAgent(previewMediaFile);
