import axios from 'axios';
import useAgent from '../../hooks/useAgent';
import { appointmentsUrl as url } from '../urls';

export const getAppointments = (parameters = {}) => {
  return axios.get(url, {
    params: parameters,
  });
};

const useGetAppointments = (defaultParameters) =>
  useAgent((params) => {
    if (defaultParameters) {
      const urlParams = new URLSearchParams(defaultParameters);

      if (params instanceof URLSearchParams) params.forEach((v, k) => urlParams.append(k, v));
      else Object.entries(params).forEach(([k, v]) => urlParams.append(k, v));

      return getAppointments(urlParams);
    }

    return getAppointments(params);
  });

export default useGetAppointments;
