import PropTypes from 'prop-types';

export const productTypeEntityName = 'productType';

const ProductType = {
  id: PropTypes.string,
  name: PropTypes.string,
};

export default ProductType;
