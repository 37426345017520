import React, { useEffect, useState, useMemo } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import MultiSelectInput from './MultiSelectInput';

const MultiSearchInput = ({ search, onChange, ...props }) => {
  const [inputValue, setInputValue] = useState('');
  const searchDebounced = useMemo(() => debounce(search, 500), [search]);
  useEffect(() => searchDebounced(inputValue), [searchDebounced, inputValue]);

  const handleChange = (value) => {
    onChange(value);
    setInputValue('');
  };

  return (
    <MultiSelectInput
      {...props}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={(_, newInputValue, reason) => {
        // The input resets every time the options change. Instead, it should reset on selection.
        if (reason !== 'reset') {
          setInputValue(newInputValue);
        }
      }}
    />
  );
};

MultiSearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
};

export default MultiSearchInput;
