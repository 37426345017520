import PropTypes from 'prop-types';

const Cargo = {
  productCategory: PropTypes.string,
  productType: PropTypes.string,
  productTypeName: PropTypes.string,
  productSubtypeId: PropTypes.number,
  productSubtypeName: PropTypes.string,
  quantity: PropTypes.number,
  totalWeightInMT: PropTypes.number,
  unit: PropTypes.string,
};

export const Project = {
  id: PropTypes.string,
  name: PropTypes.string,
  number: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  cargo: PropTypes.arrayOf(PropTypes.shape(Cargo)),
  remark: PropTypes.string,
};

export default Project;
