import PropTypes from 'prop-types';

export const Seal = {
  billableInventoryName: PropTypes.string,
  createdByUserName: PropTypes.string,
  creationDate: PropTypes.string,
  isBillable: PropTypes.bool,
  isSealAction: PropTypes.bool,
  number: PropTypes.string,
  remark: PropTypes.string,
  remarkUpdatedBy: PropTypes.string,
};

export default Seal;
