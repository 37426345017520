const itemMovement = {
  bookQuantity: 'Book Quantity',
  actionNumber: 'Action Number',
  container: 'container',
  effectiveDate: 'Effective Date',
  from: 'from {{entity}}',
  fromNoOrder: 'from no order',
  fromOrder: 'from Order {{orderName}}',
  movedQuantity: 'Moved Quantity',
  movement: 'Movement',
  movements: 'Movements',
  previously: 'Previously',
  timestamp: 'Timestamp',
  to: 'to {{entity}}',
  toNoOrder: 'to no order',
  toOrder: 'to Order {{orderName}}',
  user: 'User',
};

export default itemMovement;
