import { ErrorModal } from '@vooban/modals';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { isLaborAvailabilityCalendarEnabled } from '../../common/helpers/featureToggleHelpers';
import { conflictCode, errorCode } from '../../constant';
import { availabilityTypeAvailable, availabilityTypeNotAvailable, availabilityTypeOnlyCalendarAccess } from '@vooban/tc3-core';
import useModal from '../../hooks/useModal';
import LoadingIndicator from '../../layout/loading/LoadingIndicator';
import Modal from '../../layout/Modal';
import WorkerLayoutPage from '../WorkerLayout';
import { Select, FormControl, Grid, useMediaQuery } from '@material-ui/core';
import useAgent from '../../hooks/useAgent';
import { getSites, getSiteConfiguration, getWorkerAvailability, createOrUpdateWorkerAvailability, getWorkerByEmail } from '../agents/siteAgents';
import { useTranslation } from 'react-i18next';
import { getProjectsWithResourceRequirements } from '../agents/projectAgents';
import { LaborAvailabilityCalendar } from '@vooban/tc3-core/laborAvailability';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import DateNavigator from './calendar/DateNavigator';
import { useAuth0 } from '@auth0/auth0-react';
import Breakpoint from '../../layout/mediaQuery';
import { getMaximumNonAvailabilityDays, filterOutOnlyCalendarAccessWorkers } from './helpers/workerAvailabilityHelpers';
import { projectTypeVessel, projectTypeBarge } from '@vooban/tc3-core/src/laborAvailability/constants';
import LaborAvailabilityProjectTooltipContent from './LaborAvailabilityProjectTooltipContent';
import { isoDateFormat, isoFullDateFormat } from '@vooban/tc3-core/src/laborAvailability/helpers/momentHelpers';
import LaborAvailabilityModal from './LaborAvailabilityModal';

const LaborAvailabilityPage = ({ workerSiteIds }) => {
  const [fetchSites, sites = [], isFetchingSites] = useAgent(getSites);
  const [workerAvailability, setWorkerAvailability] = useState({ result: [], isPending: true });
  const [fetchSiteConfiguration, siteConfiguration, isFetchingSiteConfiguration] = useAgent(getSiteConfiguration);
  const [fetchProjectsWithResourceRequirements, projectsWithResourcesRequirements = [], isFetchingProjects] = useAgent(getProjectsWithResourceRequirements);
  const [fetchWorkerByEmail, workers] = useAgent(getWorkerByEmail);

  const available = siteConfiguration?.allowWorkerAvailability;

  const availabilityTypes = siteConfiguration?.availabilityTypes ?? [];

  const errorModalRef = React.createRef();

  const { user } = useAuth0();
  const history = useHistory();
  const location = useLocation();
  const isTabletOrLarger = useMediaQuery(Breakpoint.phone);
  const isDesktopOrLarger = useMediaQuery(Breakpoint.tablet);

  const [startDate, setStartDate] = useState(moment(new URLSearchParams(location.search).get('date') ?? new Date()).startOf('week'));
  const [endDate, setEndDate] = useState(moment(new URLSearchParams(location.search).get('date') ?? new Date()).endOf('week'));
  const [selectedSiteId, setSelectedSiteId] = useState(new URLSearchParams(location.search).get('site') ?? '');
  const [modalMessage, setModalMessage] = useState({ message: '', isWarning: true });
  const [isOnlyCalendarAccess, setIsOnlyCalendarAccess] = useState(false);

  const [isProjectModalOpen, openProjectModal, closeProjectModal] = useModal(false);
  const [isAvailabilityModalOpen, openAvailabilityModal, closeAvailabilityModal] = useModal(false);

  const [availabilityConfigurations, setAvailabilityConfigurations] = useState([]);

  const [informations, setInformations] = useState({
    worker: undefined,
    availability: undefined,
    date: undefined,
    group: undefined,
  });

  const { t, i18n } = useTranslation(['layout']);

  useEffect(() => {
    fetchSites();
    const siteId = workerSiteIds[0];
    setSelectedSiteId(siteId);
    fetchSiteConfiguration(siteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerSiteIds]);

  useEffect(() => {
    if (available && selectedSiteId) {
      fetchProjectsWithResourceRequirements(startDate.format(isoFullDateFormat), endDate.format(isoFullDateFormat), selectedSiteId, [
        projectTypeVessel,
        projectTypeBarge,
      ]);
      fetchWorkerAvailability(startDate.format(isoDateFormat), endDate.format(isoDateFormat), selectedSiteId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [available, i18n.language, selectedSiteId, siteConfiguration]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.append('site', selectedSiteId);
    params.append('date', getDateTextFromSpecificDate(startDate));
    history.push({ pathname: location.pathname, search: params.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, selectedSiteId]);

  useEffect(() => {
    if (modalMessage && modalMessage.message) {
      errorModalRef.current.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalMessage]);

  const handleSiteChange = (site) => {
    setSelectedSiteId(site);
  };

  useEffect(() => {
    if (!informations.worker) return [];
    const availableTypes =
      workerAvailability.result
        ?.find(({ workers }) => workers.map(({ id }) => id).includes(informations.worker.id))
        ?.availabilityTypeConfigurations.map((config) => config.availabilityType) ?? [];
    const unavailableTypes = siteConfiguration?.availabilityTypes?.filter(({ availability }) => availability === availabilityTypeNotAvailable) ?? [];
    setAvailabilityConfigurations([...availableTypes, ...unavailableTypes]);
  }, [informations.worker, workerAvailability, siteConfiguration]);

  const getDateTextFromSpecificDate = (date) => (date ? moment(date).format('YYYY-MM-DD') : '\u221e');

  const handleErrorModalClose = () => setModalMessage({ message: '', isWarning: true });

  const handleAvailabilityClick = (worker, availability, date, group) => {
    if (group.availabilityTypeConfigurations.length || availabilityTypes?.some(({ availability }) => availability === availabilityTypeNotAvailable)) {
      setInformations({ worker, availability, date, group });
      openAvailabilityModal();
    } else {
      const availabilityType = availability.availability === availabilityTypeAvailable ? availabilityTypeNotAvailable : availabilityTypeAvailable;
      submitAvailability(worker, availabilityType, date);
    }
  };

  const handleSubmitAvailability = ({ description, type, availability }) => {
    closeAvailabilityModal();
    const { worker, date } = informations;
    const idAvailability = Number.parseInt(type);
    const availabilityType = availabilityTypes.find(({ id }) => id === idAvailability) ?? {
      nameEn: undefined,
      nameFr: undefined,
      descriptionEn: undefined,
      descriptionFr: undefined,
    };
    submitAvailability(worker, availabilityType?.availability ?? availability, date, { description, ...availabilityType });
  };

  const submitAvailability = (
    worker,
    availability,
    date,
    details = {
      description: '',
      nameFr: '',
      nameEn: '',
      descriptionFr: '',
      descriptionEn: '',
    }
  ) => {
    closeAvailabilityModal();
    const { description, nameEn, nameFr, descriptionEn, descriptionFr } = details;
    const selectedAvailability = {
      date: date.format(isoDateFormat),
      workerId: worker.id,
      availability,
      updatedOn: availability.updatedOn,
      availabilityReason: description,
      availabilityTypeNameFr: nameFr,
      availabilityTypeNameEn: nameEn,
      availabilityTypeDescriptionFr: descriptionFr,
      availabilityTypeDescriptionEn: descriptionEn,
    };
    const { isMaxNonAvailableDaysReached, maximumNonAvailabilityDays } = getMaximumNonAvailabilityDays({
      selectedAvailability,
      workerAvailability,
      user,
    });
    if (isMaxNonAvailableDaysReached) {
      setModalMessage({
        message: t('workerAvailability.maximumNonAvailabilityDays', { maximumNonAvailabilityDays, ns: 'laborAvailability' }),
        isWarning: true,
      });
      return;
    }
    createOrUpdateWorkerAvailability(selectedSiteId, selectedAvailability).then((response) => {
      if (response.status === conflictCode || response.status === errorCode) {
        const { issues } = response.data;
        if (!issues || (Array.isArray(issues) && !issues.length)) {
          setModalMessage({ message: response.data, isWarning: true });
        } else {
          const messages = issues.map((issue) => `${issue.errorMessage} `);
          setModalMessage({ message: messages, isWarning: false });
        }
      } else {
        fetchWorkerAvailability(startDate.format(isoDateFormat), endDate.format(isoDateFormat), selectedSiteId);
      }
    });

    setInformations({ worker: undefined, availability: undefined, date: undefined });
  };

  const handleDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    fetchProjectsWithResourceRequirements(startDate.format(isoFullDateFormat), endDate.format(isoFullDateFormat), selectedSiteId, [
      projectTypeVessel,
      projectTypeBarge,
    ]);
    fetchWorkerAvailability(startDate.format(isoDateFormat), endDate.format(isoDateFormat), selectedSiteId);
  };

  const fetchWorkerAvailability = async (startDate, endDate, siteId) => {
    setWorkerAvailability({ result: workerAvailability.result, isPending: true });
    try {
      const result = await getWorkerAvailability(startDate, endDate, siteId);
      const data = result && result.data ? result.data : result;
      setWorkerAvailability({ result: filterOutOnlyCalendarAccessWorkers(data), isPending: false });
    } catch (error) {
      setWorkerAvailability({ result: [], isPending: false });

      throw error;
    }
  };

  useEffect(() => {
    fetchWorkerByEmail(user.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    workers?.find((worker) => worker.groupConfigurationDefaultAvailability === availabilityTypeOnlyCalendarAccess) && setIsOnlyCalendarAccess(true);
  }, [workers]);

  const showContent = !(
    isFetchingSites ||
    isFetchingSiteConfiguration ||
    isFetchingProjects ||
    (workerAvailability.isPending && workerAvailability.result.length === 0)
  );

  return (
    <WorkerLayoutPage>
      <LoadingIndicator show={!showContent} />
      <Grid container>
        <Grid item xs={3}>
          <FormControl variant="standard">
            <Select native fullWidth={false} onChange={handleSiteChange}>
              {sites
                .filter((x) => workerSiteIds.includes(x.id))
                .map((option) => {
                  return (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={9} justifyContent="flex-end">
          {available && (
            <DateNavigator
              date={startDate.locale(i18n.language.substring(0, 2))}
              locale={i18n.language}
              displayDate={`${startDate.format('DD-MM-YYYY')} - ${endDate.format('DD-MM-YYYY')}`}
              onDateChange={handleDateChange}
              dateIncrement="w"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {available ? (
            <LaborAvailabilityCalendar
              projects={projectsWithResourcesRequirements}
              workerAvailability={workerAvailability.result}
              isFetchingProjects={isFetchingProjects}
              startDate={startDate}
              endDate={endDate}
              onClickCalendar={openProjectModal}
              locale={i18n.language.substring(0, 2)}
              onAvailabilityClick={handleAvailabilityClick}
              user={user}
              hideClients={true}
              isDesktopOrLarger={isDesktopOrLarger}
              isTabletOrLarger={isTabletOrLarger}
              showLaborAvailabilityGroup={isLaborAvailabilityCalendarEnabled}
              isReadVesselCalendarOnly={isOnlyCalendarAccess}
            />
          ) : (
            <div style={{ marginTop: '20px' }}>{t('unavailableSite', { ns: 'laborAvailability' })}</div>
          )}
        </Grid>
      </Grid>
      {workerAvailability.result.length === 0 && (
        <figure className="placeholder">
          <figcaption>{t('siteDoesntHaveConfiguration', { ns: 'laborAvailability' })}</figcaption>
        </figure>
      )}
      <Modal open={isProjectModalOpen} onClose={closeProjectModal} rotate>
        <LaborAvailabilityProjectTooltipContent projects={projectsWithResourcesRequirements} startDate={startDate} />
      </Modal>
      <LaborAvailabilityModal
        open={isAvailabilityModalOpen}
        handleClose={closeAvailabilityModal}
        handleSubmit={handleSubmitAvailability}
        availabilityTypes={availabilityConfigurations}
        descriptionRequired={informations.group?.descriptionRequired}
        availability={informations.availability}
      />
      <ErrorModal
        ref={errorModalRef}
        rootModifierClasses={['-always-on-top', 'worker-mobile', '-landscape-rotate']}
        onOkClick={handleErrorModalClose}
        text={modalMessage.message}
        isWarning={modalMessage.isWarning}
      />
    </WorkerLayoutPage>
  );
};

LaborAvailabilityPage.propTypes = {
  workerSiteIds: PropTypes.arrayOf(PropTypes.string),
};

export default LaborAvailabilityPage;
