import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary';
import { DocumentTitle, DocumentTitleProvider } from '../layout/navigation/DocumentTitle';
import ProfilePage from '../profile/ProfilePage';
import LaborAvailabilityPage from './laborAvailability/LaborAvailabilityPage';
import WorkerLayoutPage from './WorkerLayout';
import MaintenancePage from '../maintenance/MaintenancePage';

export const ROUTES = {
  laborAvailability: {
    component: LaborAvailabilityPage,
    path: '/labor-availability',
    i18nKey: 'laborAvailabilityTab',
    icon: AccountBalanceIcon, // TODO Set a Proper Icon for Mobile Mode.
  },
  profile: {
    component: ProfilePage,
    componentProps: {
      LayoutComponent: WorkerLayoutPage,
    },
    path: '/profile',
    i18nKey: 'profile',
    icon: PermIdentityIcon,
  },
  maintenance: {
    component: MaintenancePage,
    componentProps: {
      mainMessageKey: 'workerPortalMainMessage',
      secondaryMessageKey: 'workerPortalSecondaryMessage',
      module: 'worker',
    },
    path: '/maintenance',
    i18nKey: 'maintenance',
  },

  // ANY ROUTE AFTER THIS ONE WILL NOT BE REACHED
};

const MyRoute = ({ Component, title, ...rest }) => {
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === '/maintenance') return;

    axios.get(`/api/v1/maintenance/worker`).catch((err) => {
      if (err.response.status === 503) {
        history.push({ state: { secondaryMessage: err.response.data }, pathname: '/maintenance' });
      }
    });
  }, [history]);

  const { t } = useTranslation();

  return (
    <>
      <DocumentTitle title={t(title)} />
      <ErrorBoundary>
        <Component {...rest} />
      </ErrorBoundary>
    </>
  );
};

MyRoute.propTypes = {
  Component: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  workerSiteIds: PropTypes.arrayOf(PropTypes.string),
};

const WorkerRouter = ({ workerSiteIds }) => (
  <DocumentTitleProvider>
    <BrowserRouter>
      <Switch>
        {Object.values(ROUTES).map((route) => (
          <Route
            key={route.path}
            path={route.path}
            render={(routeProps) => (
              <MyRoute workerSiteIds={workerSiteIds} {...routeProps} {...route.componentProps} title={route.i18nKey} Component={route.component} />
            )}
          />
        ))}
        <Redirect from="/" to={ROUTES.laborAvailability.path} />
      </Switch>
    </BrowserRouter>
  </DocumentTitleProvider>
);

WorkerRouter.propTypes = {
  workerSiteIds: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(WorkerRouter);
