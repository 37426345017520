const attachment = {
  list: {
    category: 'Catégorie',
    description: 'Description',
    file: 'Fichier',
    upload: 'Téléversé',
    uploadedBy: 'Téléversé par',
  },
};

export default attachment;
