import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import InputListSearch from '../../InputListSearch/InputListSearch';
import Title from '../../Title';
import ClearFilterButton from './component/ClearFilterButton';
import ListPageHeaderLayout from './component/ListPageHeaderLayout';
import ListPageHeaderPills from './component/ListPageHeaderPills';
import withClearFilter from './hoc/withClearFilter';
import queryStringOption from './queryStringOption';

const EmptyComponent = () => <div></div>;

const ListPageHeaderWithSearch = ({ title, actions, advancedFilterSection, onParamChange, onClearFilter, pills, subscript }) => {
  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const defaultQuery = params.get('textQuery') || '';

  const handleSearchEnter = useCallback(
    ({ target: { value } }) => {
      if (defaultQuery !== value) {
        if (value) params.set('textQuery', value);
        else params.delete('textQuery');
        onParamChange(params);
      }
    },
    [params, onParamChange, defaultQuery]
  );

  const handleAdvancedFilterSearch = useCallback(
    (values) => {
      if (!values) return;

      const searchValues = qs.parse(params.toString(), { ...queryStringOption, arrayFormat: 'repeat' });
      const otherSeachValues = qs.stringify(_.omit(searchValues, Object.keys(values)), { ...queryStringOption, arrayFormat: 'repeat' });
      const valuesSearchParams = qs.stringify(
        _.omitBy(values, (x) => x === undefined || x === null || x === '' || ((Array.isArray(x) || _.isObject(x)) && _.isEmpty(x))),
        queryStringOption
      );

      onParamChange(new URLSearchParams(`${otherSeachValues}&${valuesSearchParams}`));
    },
    [params, onParamChange]
  );

  return (
    <>
      <ListPageHeaderLayout>
        <div className="flex items-start">
          <Title title={title} />
          <InputListSearch onEnterPressed={handleSearchEnter} defaultValue={defaultQuery} />
        </div>
        {subscript}
        {React.createElement(advancedFilterSection, { onSearch: handleAdvancedFilterSearch })}
      </ListPageHeaderLayout>

      <div className={`flex flex-wrap items-baseline`}>
        <div className="flex flex-wrap">
          <ListPageHeaderPills pills={pills} />
        </div>
        <div className={`inline flex-initial ml-auto`}>
          <ClearFilterButton onClick={onClearFilter} />
          {actions}
        </div>
      </div>
    </>
  );
};

ListPageHeaderWithSearch.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
  advancedFilterSection: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object, PropTypes.func]),
  onClearFilter: PropTypes.func.isRequired,
  onParamChange: PropTypes.func,
  pills: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  query: PropTypes.string,
  subscript: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
};

ListPageHeaderWithSearch.defaultProps = {
  actions: null,
  advancedFilterSection: EmptyComponent,
  onParamChange: () => undefined,
  pills: [],
  subscript: null,
  title: null,
};

export default withClearFilter(ListPageHeaderWithSearch);
