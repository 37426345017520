import useGlobalHook from 'use-global-hook';

const initialState = { successMessage: null };

const setSuccessMessage = async (store, successMessage) => store.setState({ successMessage });

const removeSuccessMessage = async (store) => store.setState(initialState);

// eslint-disable-next-line react-hooks/rules-of-hooks
const successStore = useGlobalHook(initialState, { setSuccessMessage, removeSuccessMessage });

export default successStore;
