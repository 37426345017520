import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { BreakbulkItemListPage } from './breakbulk';
import { BulkItemListPage } from './bulk';
import InventoryItemPage from './details/InventoryItemPage';

const ROUTES = {
  breakbulkList: {
    component: BreakbulkItemListPage,
    path: '/breakbulk',
    i18nKey: 'inventory.myItemsInventory',
  },
  bulkList: {
    component: BulkItemListPage,
    path: '/bulk',
    i18nKey: 'inventory.myItemsInventory',
  },
  details: {
    component: InventoryItemPage,
    path: '/:itemId',
    i18nKey: 'inventory.inventoryItem',
  },
};

const ItemRouter = () => {
  let { path } = useRouteMatch();

  const getRoute = () => {
    const type = localStorage.getItem('listType');
    if (type === 'bulk') {
      return `${path}${ROUTES.bulkList.path}`;
    }
    return `${path}${ROUTES.breakbulkList.path}`;
  };

  return (
    <Switch>
      {Object.values(ROUTES).map((route) => (
        <Route key={route.path} path={`${path}${route.path}`} component={route.component} />
      ))}
      <Redirect from="/" to={getRoute()} />
    </Switch>
  );
};

export default ItemRouter;
