const transportation = {
  appointment: 'Rendez-vous',
  arrival: 'Arrivée',
  arrivalAtFleet: 'Arrivée à la flotte',
  arrivalDate: "Date d'arrivée",
  bargeNumber: 'Numéro de barge',
  billable: 'Facturable',
  carrier: 'Transporteur',
  carrierBill: 'Facture du transporteur',
  client: 'Client',
  completion: 'Complétion',
  confirmation: 'Confirmation',
  confirmedBy: 'Confirmée par',
  departure: 'Départ',
  driverName: 'Nom du conducteur',
  finishedBy: 'Fini par',
  loadingEndDate: 'Date de fin de chargement',
  loadingStartDate: 'Date de début de chargement',
  mainInformation: 'Information principale',
  multipleTrucks: 'Plusieurs camions',
  numberOfRailcars: 'Nombre de trains',
  numberOfTrucks: 'Nombre de camions',
  paperworkReceivedDate: 'Date de réception des documents',
  pickup: 'Récupération',
  plannedDate: 'Date planifiée',
  plateNumber: 'Numéro de plaque',
  pointOfOrigin: "Point d'origine",
  railcarNumber: 'Railcar Number',
  receivedFrom: 'Reçu de',
  receivedTo: 'Reçu à',
  reception: 'Réception',
  release: 'Relâche',
  requestedSpotting: 'Repérage demandé',
  takenBy: 'Pris par',
  transloadStevedore: 'Débardeur de transbordement',
  transportation: 'Transport',
  vessel: 'Bateau',
  numberOfHolds: 'Nombre de cales',
  shippedFrom: 'Envoyé de',
  shippedTo: 'Envoyé à',
  singleTruck: 'Un seul camion',
  type: 'Type',
};

export default transportation;
