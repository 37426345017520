import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { isEqual } from 'lodash';

const getOptionSelected = (option, value) => isEqual(option.value, value.value);
const getOptionLabel = (option) => option?.label || '';

const MultiSelectInput = ({ classes, error, helperText, inputRef, label, name, onChange, options, value, fullWidth, ...props }) => {
  const inputValue = useMemo(() => options.filter((o) => value.some((v) => isEqual(o.value, v))), [options, value]);
  return (
    <Autocomplete
      {...props}
      classes={classes}
      clearOnBlur
      multiple
      name={name}
      value={inputValue}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={(event, selectedOptions) =>
        onChange({
          target: {
            name: name,
            value: selectedOptions.map((o) => o.value),
          },
        })
      }
      options={options}
      fullWidth={fullWidth}
      renderInput={(params) => (
        <TextField {...params} style={{ minWidth: '15em' }} fullWidth={fullWidth} label={label} error={error} helperText={helperText} inputRef={inputRef} />
      )}
      renderOption={(option) => <span>{option.label}</span>}
    />
  );
};

MultiSelectInput.propTypes = {
  classes: PropTypes.object,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  inputRef: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), value: PropTypes.any, label: PropTypes.string })
  ),
  value: PropTypes.any,
};

MultiSelectInput.defaultProps = {
  classes: {},
  error: false,
  helperText: null,
  inputRef: null,
  label: null,
  name: null,
  onChange: () => {},
  options: [],
};

export default MultiSelectInput;
