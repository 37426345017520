import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { axiosErrorInterceptor } from '@vooban/helpers';
import React, { useEffect } from 'react';
import AuthErrors from './AuthErrors';
import CssTemplate from './CssTemplate';
import useI18next from './hooks/useI18next';
import useSetupAxios from './hooks/useSetupAxios';
import i18nConfig, { englishLocalesCode, lngLocalStorageKey } from './i18n';
import { migrateQslStore } from './reducers';
import Router from './Router';
import datadog from './datadog';
import { useState, useCallback } from 'react';
import { UserGroupContext } from './UserGroupContext';
import WorkerRouter from './worker/WorkerRouter';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import viewAsCompanyRoles from './common/helpers/viewAsCompanyRoles';
import UnauthorizedPage from './UnauthorizedPage';
import LoadingIndicator from './layout/loading/LoadingIndicator';

const languageClaimKey = 'http://qsl.com/language';

axios.defaults.paramsSerializer = { indexes: null };
axios.interceptors.response.use(undefined, axiosErrorInterceptor);

window.dataLayer = window.dataLayer || [];

const isGoogleAnalyticsActive = () => !!process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
const isDatadogRumActive = datadog.rum !== undefined;

const authorizedRoles = ['worker', 'client', ...viewAsCompanyRoles];

function gtag(..._params) {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

const appendUserIdGoogleAnalyticsTag = (user) => {
  if (user) {
    gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
      userId: user.sub,
      sessionId: user.sid,
      custom_map: { dimension1: 'userId', dimension2: 'sessionId' },
    });
  }
};

const appendGoogleAnalyticsTags = () => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`;
  script.async = true;
  document.head.appendChild(script);

  gtag('js', new Date());
};

isGoogleAnalyticsActive() && appendGoogleAnalyticsTags();

const App = () => {
  const [initI18n, isI18nConfigurated] = useI18next();

  const { loginWithRedirect, getAccessTokenSilently, isAuthenticated, isLoading, user, error } = useAuth0();

  const [isAxiosReady] = useSetupAxios(axios);

  const [groups, setGroups] = useState([]);
  const [workerSiteIds, setWorkerSiteIds] = useState([]);

  useEffect(() => {
    migrateQslStore();
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) loginWithRedirect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (!isI18nConfigurated) {
      const lng = (!!user && user[languageClaimKey]) || window.localStorage.getItem(lngLocalStorageKey) || englishLocalesCode;
      axios.defaults.headers['Accept-Language'] = lng;
      initI18n(i18nConfig(lng));
      moment.updateLocale(lng.substring(0, 2), {
        week: {
          dow: 0, // First day of week is Sunday
          doy: 6, // First week of year must contain 1 January (7 + 0 - 1)
        },
      });
    }
  }, [initI18n, isI18nConfigurated, user]);

  const setInfosFromToken = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    var infos = jwtDecode(token);
    setGroups(infos['https://qsl.com/groups/']);
    setWorkerSiteIds(infos['http://qsl.com/workerSites']?.map((x) => x.siteId));
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && isGoogleAnalyticsActive()) appendUserIdGoogleAnalyticsTag(user);
    if (isAuthenticated && user) {
      setInfosFromToken();
    }
  }, [isAuthenticated, user, setInfosFromToken]);

  useEffect(() => {
    if (isAuthenticated && isDatadogRumActive) {
      datadog.rum.setUser({
        email: user.email,
        name: user.name,
        picture: user.picture,
      });
      datadog.rum.startSessionReplayRecording();
    }
  }, [isAuthenticated, user]);

  if (!isLoading && error) {
    return (
      <CssTemplate>
        <AuthErrors error={error} />
      </CssTemplate>
    );
  }

  const userIsUnauthorized = groups.length && groups.every((group) => !authorizedRoles.includes(group));
  if (userIsUnauthorized) {
    return (
      <CssTemplate>
        <UnauthorizedPage />
      </CssTemplate>
    );
  }
  const isWorker = groups.includes('worker');

  if (isWorker) {
    // Set worker manifest for pwa.
    const manifestElement = document.getElementById('manifest');
    manifestElement?.setAttribute('href', `${process.env.PUBLIC_URL}/worker-manifest.json`);

    // Set 90 degree rotate for worker mobile forced landscape
    const rootElement = document.getElementById('root');
    rootElement?.setAttribute('class', 'worker-mobile -landscape-rotate flex flex-grow flex-col');
  }

  return (
    <CssTemplate>
      {!userIsUnauthorized && isI18nConfigurated && isAuthenticated && isAxiosReady && !isLoading && user && groups.length ? (
        <UserGroupContext.Provider value={{ groups }}>
          {isWorker ? <WorkerRouter workerSiteIds={workerSiteIds} /> : <Router groups={groups} />}
        </UserGroupContext.Provider>
      ) : (
        <LoadingIndicator />
      )}
    </CssTemplate>
  );
};

export default App;
