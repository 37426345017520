import { Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePreviewMediaFile } from './hooks/useGetMediaFiles';
import MediaPhoto from './MediaFilePhoto';

const useStyles = makeStyles((template) => ({
  root: {
    backgroundColor: template.palette.primary.light,
    marginBottom: '1rem',
  },
  emptyText: {
    fontSize: '0.8125rem',
    fontWeight: 600,
    color: '#7F7F7F',
  },
}));

const MediaFilesPhotosSection = ({ resourceUrl, mediaFiles }) => {
  const { t } = useTranslation('mediaFile');
  const [previewMediaFile] = usePreviewMediaFile();
  const classes = useStyles();
  return (
    <div>
      <div className="pb-4 flex justify-between">
        <h1 className="text-lg inline-block">{t('photos')}</h1>
      </div>
      <Grid justifyContent="flex-start" container spacing={2} className={classes.root}>
        {mediaFiles.length === 0 && (
          <Grid item xs={12} className="text-center">
            <Typography className={classes.emptyText + ' font-bold'} variant="caption">
              {t('empty')}
            </Typography>
          </Grid>
        )}
        {mediaFiles.map((photo, i) => (
          <Grid key={i} item xs={4} sm="auto">
            <MediaPhoto {...photo} onClick={() => previewMediaFile(resourceUrl, photo.id, photo.fileName)} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

MediaFilesPhotosSection.propTypes = {
  resourceUrl: PropTypes.string.isRequired,
  mediaFiles: PropTypes.arrayOf(
    PropTypes.shape({
      authorName: PropTypes.string,
      createdOn: PropTypes.string,
      fileName: PropTypes.string,
      id: PropTypes.string,
      note: PropTypes.string,
      thumbnail: PropTypes.string,
    })
  ),
};

export default MediaFilesPhotosSection;
