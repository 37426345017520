import { Button, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ViewAsCompanyButton from '../ViewAsCompanyButton';

const Navigation = ({ toggleDrawer, classes, options, openProfile }) => (
  <div role="presentation" className={classes.drawerContent}>
    <div className={classes.homeButton}>
      <Button onClick={toggleDrawer} classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}>
        <CloseIcon style={{ color: '#fff', fontSize: 24 }} />
      </Button>
    </div>
    <Grid container spacing={3}>
      {options.map((opt) => (
        <Grid key={opt.label} item xs={4} className={classes.gridItem}>
          <Link to={opt.path} className="flex justify-center items-center bg-white h-14 w-14 rounded-full">
            <opt.icon style={{ fontSize: 45 }}></opt.icon>
          </Link>
        </Grid>
      ))}
      <Grid item xs={12} className={classes.gridItem}>
        <button data-testid="button-nav-profile" className="flex justify-center items-center bg-white h-14 w-14 rounded-full" onClick={openProfile}>
          <PersonIcon style={{ fontSize: 45 }} />
        </button>
        <ViewAsCompanyButton />
      </Grid>
    </Grid>
  </div>
);

Navigation.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  classes: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      component: PropTypes.func,
      i18nKey: PropTypes.string,
      icon: PropTypes.object,
      path: PropTypes.string,
    })
  ).isRequired,
  openProfile: PropTypes.func.isRequired,
};

export default Navigation;
