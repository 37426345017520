import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toLocalDateTime } from '../../../../common/helpers/date';
import useModal from '../../../../hooks/useModal';
import Output from '../../../../layout/Output';

const useStyles = makeStyles((theme) => ({
  root: { margin: 0, padding: theme.spacing(2) },
}));

const useTitleStyles = makeStyles((theme) => ({
  root: { color: theme.palette.primary.main, padding: theme.spacing(2) },
}));

const DialogActionsStyles = makeStyles({
  root: {
    justifyContent: 'center',
  },
});

const SealNumbersModal = ({ seal, onExited }) => {
  const { t } = useTranslation('inventory');
  const [isModalOpen, , closeModal] = useModal(true);
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  return (
    <Dialog open={isModalOpen} onExited={onExited}>
      <DialogTitle classes={titleClasses}>{t('item.seal.sealContainer')}</DialogTitle>
      <DialogContent classes={classes}>
        <Grid container spacing={4}>
          {seal.numbers?.map((sealNumber, i) => (
            <Grid key={i} item xs={6}>
              <Output label={t('item.seal.sealNumber')} defaultValue={sealNumber} />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Output label={t('item.seal.remark')} defaultValue={seal.sealRemark} />
          </Grid>
          <Grid item xs={6}>
            <Output label={t('item.seal.remarkUpdatedBy')} defaultValue={seal.sealRemarkUpdatedByUserName} />
          </Grid>
          <Grid item xs={6}>
            <Output label={t('item.seal.createdByName')} defaultValue={seal.createdByUserName} />
          </Grid>
          <Grid item xs={6}>
            <Output label={t('item.seal.date')} defaultValue={toLocalDateTime(seal.creationDate)} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions classes={DialogActionsStyles()}>
        <Button onClick={closeModal} color="primary" variant="contained">
          {t('item.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SealNumbersModal.propTypes = {
  seal: PropTypes.shape({
    numbers: PropTypes.arrayOf(PropTypes.string),
    sealRemark: PropTypes.string,
    sealRemarkUpdatedByUserName: PropTypes.string,
    createdByUserName: PropTypes.string,
    creationDate: PropTypes.string,
  }).isRequired,
  onExited: PropTypes.func,
};
export default SealNumbersModal;
