import { AppBar, Tab, Tabs, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Breakpoint from '../mediaQuery';
import ElevationScroll from './ElevationScroll';
import ProfileMenu from './ProfileMenu';
import ViewAsCompanyButton from './ViewAsCompanyButton';

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const tabStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    maxWidth: 300,
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
}));

const useAppBarStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));

const Navigation = ({ options }) => {
  const { path } = useRouteMatch();
  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);

  return (
    <>
      <ElevationScroll>
        <AppBar classes={useAppBarStyles()} position="sticky" className={`${isTabletOrLarger ? 'pl-14 pr-14' : ''}`}>
          <div className="flex justify-between items-center">
            <img src="/qsl-logo.jpeg" alt="qsl-logo" className="w-30 h-10" />
            <Tabs value={path} classes={{ root: 'ml-auto h-20 items-center' }} textColor="primary" indicatorColor="primary">
              {options.map((option) => (
                <Tab
                  classes={tabStyles()}
                  key={option.path}
                  value={option.path}
                  label={
                    <Link to={option.path} key={option.path} data-testid={`link_${option.label}`}>
                      {option.label}
                    </Link>
                  }
                  {...a11yProps(option.path)}
                />
              ))}
            </Tabs>
            <span className="flex">
              <ProfileMenu />
              <ViewAsCompanyButton />
            </span>
          </div>
        </AppBar>
      </ElevationScroll>
    </>
  );
};

Navigation.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Navigation;
