import weightAndDimensions from '../../../weightAndDimensions/__i18n__/en';
import itemMovement from '../itemMovement/__i18n__/en';
import itemMovementType from '../itemMovementType/__i18n__/en';

const identifierLabel = {
  Barcode: 'Barcode',
  BillOfLading: 'Bill of Lading',
  Description: 'Description',
  HeatNumber: 'Heat Number',
  LotNumber: 'Lot Number',
  Mark: 'Mark',
  Other: 'Other',
  PoNumber: 'PO Number',
  Scope: 'Scope',
  SerialNumber: 'Serial Number',
};

const dimensionTypes = {
  height: 'Height',
  width: 'Width',
  length: 'Length',
  footPound: 'Foot Pound',
  surfaceArea: 'Surface Area',
  thickness: 'Thickness',
  diameter: 'Diameter',
};

const seal = {
  billable: { true: 'Billable', false: 'Not Billable' },
  billableInventory: 'Billable Inventory',
  action: 'Action',
  seal: 'Seal',
  unseal: 'Unseal',
  user: 'User',
  by: 'By',
  date: 'Date & Time',
  noHistory: 'This container has no seal history',
  sealContainer: 'Seal Container',
  sealNumber: 'Seal Number',
  remark: 'Remark',
  remarkUpdatedBy: 'Remark Updated By',
  createdByName: 'Name',
  viewSealNumbers: 'View Seal Numbers',
  viewSealHistory: 'View Seal History',
};

const diff = {
  mainInformation: 'Main Information',
  modifications: 'Modifications',
  productType: { name: 'Product Type' },
  productSubtypeName: 'Subtype',
  packaging: 'Packaging',
  quantityPerPackage: 'Quantity Per Package',
  remark: 'Remark',
  isStackable: 'Is Stackable',
  location: { storageFacility: { name: 'Storage Facility' }, storageArea: { name: 'Storage Area' } },
  quantity: 'Quantity',
  availableQuantity: 'Available Quantity',
  containerStuffingCapacity: 'Container Stuffing Capacity',
  hazardousModifications: 'Hazardous Modifications',
  stuffingCapacity: {
    none: 'None',
    containerizable: 'Can be containerized',
    stuffable: 'Is a stuffable container',
    nonStuffableContainer: 'Is a prestuffed/sealed container',
  },
  identifiers: 'Identifiers',
  identifierLabelShort: {
    Barcode: 'BC',
    BillOfLading: 'B/L',
    Description: 'Desc',
    HeatNumber: 'H/N',
    LotNumber: 'L/N',
    Mark: 'Mk',
    Other: 'Other',
    PoNumber: 'PO',
    Scope: 'Scope',
    SerialNumber: 'S/N',
  },
  weightAndDimensions,
};

const item = {
  actionDate: 'Action Date',
  advancedFilters: 'Advanced Filters',
  aemPo: 'AEM P.O',
  area: 'Area',
  asPerScale: 'As per Scale',
  bookQuantity: 'Book Quantity',
  class: 'Class',
  clientInventory: 'Client Inventory',
  close: 'Close',
  containedItems: 'Contained Items',
  containers: 'Container',
  containerStuffingCapacity: 'Container Stuffing Capacity',
  countryOfOrigin: 'Country of Origin',
  dateAndTime: 'Date & Time',
  density: 'Density',
  diff: diff,
  dimension: 'Dimension',
  dimensions: 'Dimensions',
  dimensionTypes,
  dimensionsWeightPerItem: 'Dimensions & Weight per Item',
  effectiveDate: 'Effective Date',
  excelFileName: 'InventoryItems-$t(cargoType:{{cargoType}})-{{date}}.xlsx',
  excludeEmptyContainers: 'Exclude Empty Stuffable Containers',
  facility: 'Facility',
  false: 'No',
  generalInformation: 'General Information',
  generatePackingList: 'Generate Packing List',
  hazardous: 'Hazardous',
  hazardousAttachments: 'Hazardous Attachments',
  history: 'History',
  identifierLabel,
  identifiers: 'Identifiers',
  includeContainerizedItems: 'Include Containerized Items',
  includeEmptyItems: 'Include Items with 0 Qty',
  itemMovement,
  itemMovementType,
  limitedQuantity: 'LTD Qty',
  location: 'Location',
  locationHistory: 'Location History',
  mainInformation: 'Main Information',
  mediaFiles: 'Media Files',
  modifiedItem: 'Modified Item',
  movements: 'Movements',
  no: 'No',
  noOrder: 'No Order',
  order: 'Order',
  origin: 'Origin',
  originalVesselProject: 'Original Vessel Project',
  packaging: 'Packaging',
  packagingGroup: 'Packaging Group',
  pg: 'PG',
  previously: 'Previously',
  productCharacteristics: 'Product Characteristics',
  productSubtype: 'Product Subtype',
  productType: 'Product Type',
  qtyPerPackage: 'Qty Per Package',
  quantity: 'Quantity',
  released: 'Released',
  remarks: 'Remarks',
  seal,
  search: 'Search',
  showOnlyHazmat: 'Show Only Hazmat',
  storageArea: 'Storage Area',
  storageFacility: 'Storage Facility',
  stuffedContainerWeight: 'Stuffed Container Weight',
  stuffingCapacity: {
    none: 'None',
    containerizable: 'Can be containerized',
    stuffable: 'Is a stuffable container',
    nonStuffableContainer: 'Is a prestuffed/sealed container',
  },
  surfaceArea: 'Surface Area',
  un: 'UN',
  unNumber: 'UN Number',
  unit: 'Unit',
  user: 'User',
  volume: 'Volume',
  weight: 'Weight',
  receptionFrom: 'Reception From',
  true: 'Yes',
  to: 'To',
  yes: 'Yes',
};

export default item;
