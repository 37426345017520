const identifiers = {
  barcode: 'Barcode',
  billOfLading: 'Bill of Lading',
  booking: 'Booking',
  description: 'Description',
  heatNumber: 'Heat Number',
  identifiers: 'Identifiers',
  lotNumber: 'Lot Number',
  mark: 'Mark',
  poNumber: 'PO Number',
  scope: 'Scope',
  serialNumber: 'Serial Number',
  other: 'Other',
};

export default identifiers;
