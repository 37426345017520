import PropTypes from 'prop-types';
import React from 'react';
import MediaFiles from '../mediaFile/MediaFiles';
import url from './hooks/url';

const TransactionMediaFiles = ({ id }) => <MediaFiles resourceUrl={`${url}/${id}`} />;

TransactionMediaFiles.propTypes = {
  id: PropTypes.string,
};

export default TransactionMediaFiles;
