import axios from 'axios';
import useAgent from '../../../hooks/useAgent';

const url = (inventoryId, itemId) => `/api/v1/inventory/${inventoryId}/inventory-items/${itemId}`;

const getItem = (inventoryId, itemId) => axios.get(url(inventoryId, itemId));

export const useGetItem = () => useAgent(getItem);

const getItemMovements = (inventoryId, itemId) => axios.get(`${url(inventoryId, itemId)}/movements`);

export const useGetItemMovements = () => useAgent(getItemMovements);

const getItemAgnicoEagleAssignations = (inventoryId, itemId) => axios.get(`${url(inventoryId, itemId)}/agnico-eagle/assignations`);

export const useGetItemAgnicoEagleAssignations = () => useAgent(getItemAgnicoEagleAssignations);
