import { TagInputFilter } from '@vooban/datagrid';
import Dimension from '../../../common/dimension';
import { formatNumber } from '../../../common/helpers/numbers';
import columnsSetting from '../../../layout/Datagrid/ColumnSetting';

const round = (value) => value !== null && value !== undefined && formatNumber(value);

const productTypeFilter = (productTypes) => ({
  keyFilter: 'productType',
  component: TagInputFilter,
  options: productTypes,
});

const productSubtypeFilter = (productSubtypes) => ({
  keyFilter: 'productSubtype',
  component: TagInputFilter,
  options: productSubtypes,
});

const clientInventoryFilter = (clientInventories) => ({
  keyFilter: 'clientInventory',
  component: TagInputFilter,
  options: clientInventories,
});

const getColumns = ({ clientInventories = [], productTypes = [], productSubtypes = [], sites = [] }) => [
  columnsSetting('clientInventory.site.name').withSortKey('site').withFilter({ component: TagInputFilter, keyFilter: 'site', options: sites }).withIsDefault(),
  columnsSetting('clientInventory.name').withFilter(clientInventoryFilter(clientInventories)).withIsDefault(),
  columnsSetting('productType.name').withFilter(productTypeFilter(productTypes)).withIsDefault(),
  columnsSetting('productSubtypeName').withFilter(productSubtypeFilter(productSubtypes)).withIsDefault(),
  columnsSetting('availableQuantity').withColName('bookQuantity').withCustomFormat(round).withAlignRight().withIsDefault(),
  columnsSetting('length').withCustomFormat(Dimension.format).withAlignRight(),
  columnsSetting('width').withCustomFormat(Dimension.format).withAlignRight(),
  columnsSetting('height').withCustomFormat(Dimension.format).withAlignRight(),
  columnsSetting('itemLocation').withSortKey('location').withIsDefault(),
  ...['barcode', 'booking', 'heatNumber', 'lotNumber', 'mark', 'scope', 'serialNumber', 'other', 'description', 'poNumber', 'billOfLading'].map(columnsSetting),
];

export default getColumns;
