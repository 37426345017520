import formatData from './formatData';
import { mapIdentifiersAsProperties, itemToInventoryItemDataList } from '../helpers/shippedItemsToExcelHelper';

const useExportShippedItemsToExcel = (itemResponse, additionalColumns = [], isVessel, t) => {
  const dataList = mapIdentifiersAsProperties(itemResponse.data.items).map(itemToInventoryItemDataList);
  const customListColumns = [
    {
      key: isVessel ? 'shipmentDestination.destinationName' : 'shipmentDestination.receiver.name',
      name: t('destinationName'),
    },
    { key: 'dockLocation', name: t('vesselLocation') },
    { key: 'clientName', name: t('clientName') },
    { key: 'clientInventory.name', name: t('clientInventory') },
    { key: 'order.name', name: t('order') },
    { key: 'productTypeName', name: t('productTypeName') },
    { key: 'productSubtypeName', name: t('productSubtypeName') },
    { key: 'quantity', name: t('quantity') },
    { key: 'parentContainerIdentifier', name: t('parentContainerIdentifier') },
    { key: 'containerStatus', name: t(`containerStatus`) },
    { key: 'sealNumbers', name: t(`sealNumbers`) },
    { key: 'description', name: t('description') },
    { key: 'poNumber', name: t('poNumber') },
    { key: 'lotNumber', name: t('lotNumber') },
    { key: 'serialNumber', name: t('serialNumber') },
    { key: 'heatNumber', name: t('heatNumber') },
    { key: 'mark', name: t('mark') },
    { key: 'scope', name: t('scope') },
    { key: 'billOfLading', name: t('billOfLading') },
    { key: 'barcode', name: t('barcode') },
    { key: 'booking', name: t('booking') },
    { key: 'other', name: t('other') },
    { key: 'weightPerUnit', name: t('weightPerUnit') },
    { key: 'totalWeight', name: t('totalWeight') },
    { key: 'unitOfWeight', name: t('unitOfWeight') },
    { key: 'volumePerUnit', name: t('volumePerUnit') },
    { key: 'totalVolume', name: t('totalVolume') },
    { key: 'unitOfVolume', name: t('unitOfVolume') },
    { key: 'surfaceAreaPerUnit', name: t('surfaceAreaPerUnit') },
    { key: 'unitOfSurfaceArea', name: t('unitOfSurfaceArea') },
    { key: 'freightTon', name: t('freightTon') },
    { key: 'dimension', name: t('dimension') },
    { key: 'diameter.value', name: t('diameter') },
    { key: 'diameter.unit', name: t('diameterUnit') },
    { key: 'footPound.value', name: t('footPound') },
    { key: 'footPound.unit', name: t('footPoundUnit') },
    { key: 'length.value', name: t('length') },
    { key: 'length.unit', name: t('lengthUnit') },
    { key: 'width.value', name: t('width') },
    { key: 'width.unit', name: t('widthUnit') },
    { key: 'height.value', name: t('height') },
    { key: 'height.unit', name: t('heightUnit') },
    { key: 'thickness.value', name: t('thickness') },
    { key: 'thickness.unit', name: t('thicknessUnit') },
    { key: 'quantityPerPackage', name: t('quantityPerPackage') },
    { key: 'packaging', name: t('packaging') },
    { key: 'ltdClass', name: 'LTD QTY + Class' },
    { key: 'un', name: 'UN' },
    { key: 'pg', name: 'PG' },
    { key: 'weightHasBeenVerified', name: t('asPerScale') },
    { key: 'customsRelease.number', name: t('customsRelease') },
    { key: 'shippingLineRelease', name: t('shippingLineRelease') },
    { key: 'financialRelease', name: t('financialRelease') },
    { key: 'customerRelease', name: t('customerRelease') },
    { key: 'addedBy', name: t('addedBy') },
    {
      key: 'addedOn',
      name: t('addedOn'),
      dataOptions: { format: 'YYYY-MM-DD HH:mm', type: 'd' },
    },
  ];

  if (additionalColumns.includes('vesselInformation')) {
    customListColumns.push({ key: 'vesselInformation', name: t('vessel') });
  }

  const data = formatData(dataList, customListColumns, t);

  return { data, columns: customListColumns };
};

export default useExportShippedItemsToExcel;
