import { Box, Dialog, DialogContent, DialogContentText, Grid, IconButton, Link, Typography, makeStyles } from '@material-ui/core';
import AssistanceIcon from '../icons/AssistanceIcon';
import { useTranslation } from 'react-i18next';
import { supportEmailAddress, supportPhoneNumber } from '../../constant';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Email, Phone } from '@material-ui/icons';

const AssistanceModal = ({ onClose, open }) => {
  const { t } = useTranslation('layout');

  const useStyles = makeStyles((theme) => ({
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    icon: {
      marginRight: '1rem',
      color: theme.palette.primary.main,
    },
    title: {
      marginTop: '2rem',
      marginBottom: '2rem',
      fontSize: '28px',
      color: theme.palette.primary.main,
    },
    box: {
      height: '296px',
      width: '525px',
    },
  }));

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent className={useStyles().box}>
        <IconButton style={{ position: 'absolute', top: 5, right: 5 }} onClick={onClose} aria-label="delete">
          <CloseIcon />
        </IconButton>
        <Grid container style={{ color: 'primary' }} direction="column" justifyContent="center" alignItems="center">
          <Grid item style={{ marginTop: '1rem' }}>
            <AssistanceIcon></AssistanceIcon>
          </Grid>
          <Grid item>
            <Typography variant="h1" style={{ color: 'secondary' }} className={useStyles().title} id="alert-dialog-title">
              {t('needAssistance')}
            </Typography>
          </Grid>
          <Grid item>
            <Box className={useStyles().flexRow}>
              <Phone className={useStyles().icon} />
              <DialogContentText id="alert-dialog-description">
                {t('supportPhoneNumber', {
                  phone: supportPhoneNumber,
                })}
              </DialogContentText>
            </Box>
            <Grid item>
              <Box className={useStyles().flexRow}>
                <Email className={useStyles().icon} />
                <DialogContentText id="alert-dialog-description">
                  {t('supportEmailAddress')}
                  <Link href={`mailto:${supportEmailAddress}`}>{supportEmailAddress}</Link>
                </DialogContentText>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

AssistanceModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default AssistanceModal;
