import { Checkbox, makeStyles, useTheme } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';

const checkedIconStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
}));

const CheckboxField = ({ classes, label, name }) => {
  const theme = useTheme();
  const { values, handleChange } = useFormikContext();

  const value = _get(values, name);

  return (
    <span>
      <Checkbox
        checked={value}
        checkedIcon={<CheckBoxOutlinedIcon classes={checkedIconStyles()} />}
        color="secondary"
        size="small"
        classes={classes}
        name={name}
        value={value}
        onChange={handleChange}
      />
      <span className="text-sm" style={{ color: theme.palette.text.secondary, paddingRight: '0.5rem' }}>
        {label}
      </span>
    </span>
  );
};

CheckboxField.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

CheckboxField.defaultProps = {
  classes: {},
  label: '',
};

export default CheckboxField;
