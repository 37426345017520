import { makeStyles, Snackbar, useMediaQuery } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Breakpoint from '../mediaQuery';

const alertStyles = makeStyles({
  root: {
    bottom: '4.5rem',
  },
});

const Toast = ({ message, severity, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
    setIsOpen(false);
  };

  useEffect(() => {
    if (message) setIsOpen(true);
  }, [message]);

  return (
    <Snackbar
      classes={alertStyles()}
      open={isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: isTabletOrLarger ? 'right' : 'center' }}>
      <Alert onClose={handleClose} severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

Toast.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.string,
  onClose: PropTypes.func,
};

export default Toast;
