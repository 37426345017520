import { TagInputFilter } from '@vooban/datagrid';
import { get } from 'lodash';
import Dimension from '../../../common/dimension';
import { formatClass, formatPackagingGroup, formatUnNumber } from '../../../common/hazardous';
import { formatNumber } from '../../../common/helpers/numbers';
import columnsSetting from '../../../layout/Datagrid/ColumnSetting';
import { getHazardousIcon } from '../../helpers/hazardousMaterialHelpers';

const round = (value) => value !== null && value !== undefined && formatNumber(value);

const released = (value, _, t) => value && t('item.released');

const volumeFormatter = (volume, _, t) => volume && volume.value !== null && volume.unit && `${round(volume.value)} ${t(`units.${volume.unit}`)}`;

const surfaceFormatter = (surface, _, t) => surface && surface.value !== null && surface.unit && `${round(surface.value)} ${t(`units.${surface.unit}`)}`;

const productTypeFilter = (productTypes) => ({
  keyFilter: 'productType',
  component: TagInputFilter,
  options: productTypes,
});

const productSubtypeFilter = (productSubtypes) => ({
  keyFilter: 'productSubtype',
  component: TagInputFilter,
  options: productSubtypes,
});

const packagingFilter = (packagingOptions) => ({
  component: TagInputFilter,
  options: packagingOptions,
});

const formatTotalWeight = (_, row) => {
  const { unit, value } = row.grossWeightPerUnit || {};
  return Dimension.format({
    value: value * Math.abs(row.availableQuantity),
    unit,
  });
};

const clientInventoryFilter = (clientInventories) => ({
  keyFilter: 'clientInventory',
  component: TagInputFilter,
  options: clientInventories,
});

const formatTotalVolume = (_, row, t) => {
  const { unit, value } = row.volumePerUnit || {};
  return volumeFormatter(
    {
      value: value * Math.abs(row.availableQuantity),
      unit,
    },
    null,
    t
  );
};

const containerStatusesFilter = (containerStatuses) => ({
  component: TagInputFilter,
  options: containerStatuses,
});

const vesselFilter = (originalVesselProjects) => ({
  component: TagInputFilter,
  options: originalVesselProjects,
});

const getColumns = ({
  clientInventories = [],
  productTypes = [],
  productSubtypes = [],
  packagingOptions = [],
  sites = [],
  containerStatuses = [],
  originalVesselProjects = [],
  orders = [],
  t,
}) => [
  columnsSetting('clientInventory.site.name').withSortKey('site').withFilter({ component: TagInputFilter, keyFilter: 'site', options: sites }).withIsDefault(),
  columnsSetting('clientInventory.name').withFilter(clientInventoryFilter(clientInventories)).withIsDefault(),
  columnsSetting('order.name').withFilter({ component: TagInputFilter, keyFilter: 'order', options: orders }).withIsDefault(),
  columnsSetting('order.receiver.name').withSortKey('receiver').withIsDefault(),
  columnsSetting('productType.name').withFilter(productTypeFilter(productTypes)).withIsDefault(),
  columnsSetting('productSubtypeName').withFilter(productSubtypeFilter(productSubtypes)).withIsDefault(),
  columnsSetting('dimension').withAlignRight().withIsDefault(),
  columnsSetting('quantityPerPackage').withAlignRight().withIsDefault(),
  columnsSetting('availableQuantity').withAlignRight().withIsDefault(),
  columnsSetting('stuffedQuantity').withAlignRight(),
  columnsSetting('stuffedIn'),
  columnsSetting('sourceContainer'),
  columnsSetting('grossWeightPerUnit').withColName('weightPerUnit').withCustomFormat(Dimension.format).withAlignRight().withIsDefault(),
  columnsSetting('totalWeight').withCustomFormat(formatTotalWeight).withAlignRight().withIsDefault(),
  columnsSetting('weightHasBeenVerified'),
  columnsSetting('surfaceAreaPerUnit').withCustomFormat(surfaceFormatter).withAlignRight(),
  columnsSetting('volumePerUnit').withCustomFormat(volumeFormatter).withAlignRight(),
  columnsSetting('totalVolume').withCustomFormat(formatTotalVolume).withAlignRight(),
  columnsSetting('freightTon').withCustomFormat(round).withAlignRight(),
  ...['length', 'width', 'height', 'diameter', 'thickness', 'footPound'].map((x) => columnsSetting(x).withCustomFormat(Dimension.format).withAlignRight()),
  columnsSetting('itemLocation').withSortKey('location').withIsDefault(),
  ...['barcode', 'booking', 'heatNumber', 'lotNumber', 'mark', 'scope', 'serialNumber', 'other', 'description', 'remark'].map(columnsSetting),
  columnsSetting('packaging').withFilter(packagingFilter).withTranslationValue('packagings'),
  columnsSetting('hazardousMaterialInformation')
    .withColName('hazardous')
    .withoutSort()
    .withCustomFormat((_, row) => {
      const hazardousMaterialInformation = [
        ...get(row, 'hazardousMaterialInformation', []),
        ...get(row, 'contentInformation.hazardousMaterialInformation', []),
      ];
      return hazardousMaterialInformation.length > 0 ? getHazardousIcon(hazardousMaterialInformation, t) : '-';
    })
    .withIsDefault(),
  columnsSetting('hazardousMaterialInformation.class').withCustomFormat((_, row) => formatClass(row['hazardousMaterialInformation'])),
  columnsSetting('hazardousMaterialInformation.unNumber').withCustomFormat((_, row) => formatUnNumber(row['hazardousMaterialInformation'])),
  columnsSetting('hazardousMaterialInformation.packagingGroup').withCustomFormat((_, row) => formatPackagingGroup(row['hazardousMaterialInformation'])),
  columnsSetting('containerStatus').withFilter(containerStatusesFilter(containerStatuses)).withTranslationValue('containerStatuses'),
  columnsSetting('originalVesselProject').withFilter(vesselFilter(originalVesselProjects)),
  ...['originalReceptionInformation.appointmentNumber', 'poNumber', 'billOfLading'].map(columnsSetting),
  ...['customsRelease.number', 'financialRelease.released', 'customerRelease.released', 'shippingLineRelease.released'].map((x) =>
    columnsSetting(x).withCustomFormat(released)
  ),
  columnsSetting('originalReceptionInformation.receptionNumber').withSortKey('receptionNumber'),
  columnsSetting('originalReceptionInformation.receptionDate').withSortKey('receptionDate').withFormatDate(),
];

export default getColumns;
