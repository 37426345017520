const itemMovementType = {
  Adjustment: 'Ajustment',
  CancelledReception: 'Réception annulée',
  CancelledShipment: 'Expédition annulé',
  ContainerTransfer: 'Conteneur transferé',
  Merge: 'Fusion',
  MissingItemResolution: "Résolution d'item manquant",
  Move: 'Mouvement',
  OrderTransfer: 'Transfert',
  ReceptionByBarge: 'Réception par barge',
  ReceptionByContainer: 'Réception par conteneur',
  ReceptionByOther: 'Réception par autre',
  ReceptionByRailcar: 'Réception par train',
  ReceptionByTruck: 'Réception par camion',
  ReceptionByVessel: 'Réception par navire',
  Seal: 'Scellé',
  Split: 'Divisé',
  ShipmentByBarge: 'Expédition par barge',
  ShipmentByOther: 'Expédition par autre',
  ShipmentByRailcar: 'Expédition par train',
  ShipmentByTruck: 'Expédition par camion',
  ShipmentByVessel: 'Expédition par navire',
  Stuff: 'Remplissage',
  StuffForTransfer: 'Remplissage pour transfert',
  Transfer: 'Transfert',
  TransferredFromTC1: 'Transfert de TC1',
  Transform: 'Transformation',
  Unseal: 'Descellé',
  Unstuff: 'Vidage',
  UnstuffForTransfer: 'Vidage pour transfert',
};

export default itemMovementType;
