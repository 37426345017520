import { useTranslation } from 'react-i18next';
import useAgent, { PUSH_NOTIFICATION } from '../../hooks/useAgent';
import _get from 'lodash.get';
import { transportationTypes } from '../../transportation';
import useExportShippedItemsToExcel from './useExportShippedItemsToExcel';
import { generateShippedItemsSpreadsheet, promptGeneratedXlsx } from '../../common/helpers/xlsxHelpers';
import { getShipmentOfMaterial, getShippedItems } from './useGetShipmentOfMaterial';

const GetTransactionExcel = async (props, t) => {
  const { shipmentOfMaterialId, shipmentDestinationId, clientId } = props;

  const response = await getShipmentOfMaterial(shipmentOfMaterialId);
  const itemResponse = await getShippedItems(shipmentOfMaterialId, shipmentDestinationId, clientId);

  const isVessel = response.data.transportationType === transportationTypes.vessel;
  const additionalColumns = isVessel ? [] : ['vesselInformation'];

  const { data, columns } = useExportShippedItemsToExcel(itemResponse, additionalColumns, isVessel, t);

  const vesselName = isVessel ? ` - ${_get(response, 'data.project.name')}` : '';

  const description = `Shipment per ${response.data.transportationType} - ${response.data.numberVersion}${vesselName}`;

  const sheet = generateShippedItemsSpreadsheet(data, columns, description);

  promptGeneratedXlsx([sheet], ['Report'], description);
};

const useGetTransactionExcel = () => {
  const { t } = useTranslation('transaction');
  return useAgent(GetTransactionExcel, { errorStatusToAction: { 404: { type: PUSH_NOTIFICATION, payload: t('error.missingExcel') } } });
};

export default useGetTransactionExcel;
