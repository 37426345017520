import moment from 'moment';

export const dateFilterDropDownOptions = {
  Today: 'today',
  ThisWeek: 'thisWeek',
  ThisMonth: 'thisMonth',
  AllDate: 'allDate',
  Custom: 'custom',
};

export const periodToDropDownOptions = {
  day: dateFilterDropDownOptions.Today,
  week: dateFilterDropDownOptions.ThisWeek,
  month: dateFilterDropDownOptions.ThisMonth,
};

export const dateFilterDropDownValues = {
  today: { startDate: moment(new Date()).startOf('day'), endDate: moment(new Date(Date.now())).startOf('day'), period: 'day' },
  thisWeek: { startDate: moment(new Date()).day(0).startOf('day'), endDate: moment(new Date(Date.now())).day(6).startOf('day'), period: 'week' },
  thisMonth: {
    startDate: moment(new Date()).startOf('month').startOf('day'),
    endDate: moment(new Date(Date.now())).endOf('month').startOf('day'),
    period: 'month',
  },
  allDate: { startDate: undefined, endDate: undefined, period: undefined },
  custom: { startDate: undefined, endDate: undefined, period: undefined },
};
export const getQueryFiltersFromDateDropdownSelection = (dateDropdownSelection) => {
  const filters = new URLSearchParams();
  const dateValues = dateFilterDropDownValues[dateDropdownSelection];
  filters.set('startDateValue', dateValues.startDate.format());
  filters.set('endDateValue', dateValues.endDate.format());
  filters.set('dropDownValue', dateDropdownSelection);
  filters.set('period', dateValues.period);
  return filters.toString();
};
