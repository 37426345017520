import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import LoadingIndicator from '../layout/loading/LoadingIndicator';
import { useGetMediaFiles } from './hooks/useGetMediaFiles';
import MediaFilesPhotosSection from './MediaFilesPhotosSection';
import MediaFilesVideosSection from './MediaFilesVideosSection';

const MediaFiles = ({ resourceUrl }) => {
  const [fetchMediaFiles, mediaFiles, isLoading] = useGetMediaFiles();

  const fetch = (params) => fetchMediaFiles(resourceUrl, params);

  useEffect(() => {
    fetch(new URLSearchParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceUrl]);

  const splitPhotosVideos = (obj, m) => ({
    photos: m.type === 'photo' ? [...obj.photos, m] : obj.photos,
    videos: m.type === 'video' ? [...obj.videos, m] : obj.videos,
  });

  const { photos, videos } = useMemo(() => (mediaFiles || []).reduce(splitPhotosVideos, { photos: [], videos: [] }), [mediaFiles]);

  return (
    <>
      <LoadingIndicator show={isLoading} />
      <MediaFilesPhotosSection resourceUrl={resourceUrl} mediaFiles={photos} />
      <MediaFilesVideosSection fetcher={fetch} resourceUrl={resourceUrl} mediaFiles={videos} />
    </>
  );
};
MediaFiles.propTypes = {
  resourceUrl: PropTypes.string.isRequired,
};

export default MediaFiles;
