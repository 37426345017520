import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toLocalDateTime } from '../../../common/helpers/date';
import ExcelIcon from '../../../layout/icons/ExcelIcon';
import LoadingIndicator from '../../../layout/loading/LoadingIndicator';
import Tooltip from '../../../layout/Tooltip';
import useGenerateBulkExcelReport from './useGenerateBulkExcelReport';

const maximumNumberOfItemsToExport = 10000;

const BulkListActions = ({ totalCount }) => {
  const [generateExcelReport, , isGeneratingExcelReport] = useGenerateBulkExcelReport();
  const { t } = useTranslation('inventory');
  const { search } = useLocation();

  const handleExportToExcelClick = useCallback(() => {
    const parameters = new URLSearchParams(search);
    const fileName = t('item.excelFileName', { cargoType: 'Bulk', date: toLocalDateTime(new Date()) });

    generateExcelReport(parameters, fileName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateExcelReport, search, t]);

  const getTooltip = useCallback(() => {
    if (totalCount > maximumNumberOfItemsToExport) return t('tooManyResultsMessage', { number: process.env.REACT_APP_MAX_EXCEL_ENTRIES_TO_EXPORT });
    if (!totalCount) return t('noResultToExportMessage');
    return '';
  }, [t, totalCount]);

  const excelButtonDisabled = !totalCount || isGeneratingExcelReport || totalCount > maximumNumberOfItemsToExport;

  return (
    <>
      <LoadingIndicator show={isGeneratingExcelReport} />
      <Tooltip arrow spanStyle={{ cursor: 'not-allowed' }} title={getTooltip()} placement="top">
        <Button
          disabled={excelButtonDisabled}
          onClick={handleExportToExcelClick}
          color="primary"
          variant="text"
          size="small"
          startIcon={<ExcelIcon disabled={excelButtonDisabled} />}>
          {t('exportToExcel')}
        </Button>
      </Tooltip>
    </>
  );
};

BulkListActions.propTypes = {
  totalCount: PropTypes.number,
};

BulkListActions.defaultProps = {
  totalCount: 0,
};

export default BulkListActions;
