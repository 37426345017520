import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import getActiveFiltersCount from '../../common/filters/getActiveFiltersCount';
import Badge from '../../layout/Badge';
import Collapsible from '../../layout/Collapsible/Collapsible';
import { TextField } from '../../layout/fields';
import IdentifiersFilter from '../../layout/Identifiers/IdentifiersFilter';
import queryStringOption from '../../layout/ListPage/headers/queryStringOption';
import useAdvancedFiltersValidationSchema from './useAdvancedFiltersValidationSchema';

const makeRowStyles = makeStyles(() => ({
  root: {
    marginBottom: '1rem',
  },
}));

const makeRemarkStyles = makeStyles(() => ({
  root: {
    width: '450px',
  },
}));

const getInitialValues = (params) => {
  const searchValues = qs.parse(params.toString(), queryStringOption);
  return {
    identifiers: searchValues?.identifiers || [],
    remark: searchValues?.remark || '',
  };
};

const AdvancedFiltersSection = ({ onSearch }) => {
  const { t } = useTranslation('transaction');
  const { search } = useLocation();

  const validationSchema = useAdvancedFiltersValidationSchema();

  const rowStyles = makeRowStyles();
  const remarkStyles = makeRemarkStyles();

  return (
    <Formik initialValues={getInitialValues(new URLSearchParams(search))} onSubmit={onSearch} validationSchema={validationSchema} enableReinitialize>
      {({ submitForm, initialValues }) => (
        <Collapsible title={t('advancedFilters')} titleBadge={<Badge content={getActiveFiltersCount(initialValues)} />}>
          <Grid container>
            <Grid classes={rowStyles} item xs={12} md={12}>
              <Grid item xs={12} md={12}>
                <FieldArray name="identifiers">{({ push, remove }) => <IdentifiersFilter push={push} remove={remove} name="identifiers" />}</FieldArray>
              </Grid>
              <Grid classes={rowStyles} item xs={12} md={12}>
                <TextField name="remark" label={t('remark')} classes={remarkStyles} />
              </Grid>
            </Grid>
            <Grid classes={rowStyles} item xs={12} md={12}>
              <Button onClick={submitForm} variant="contained" color="primary">
                {t('search')}
              </Button>
            </Grid>
          </Grid>
        </Collapsible>
      )}
    </Formik>
  );
};

AdvancedFiltersSection.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default AdvancedFiltersSection;
