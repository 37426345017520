import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '1rem',
    height: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '50%',
    fontSize: '0.725rem',
    lineHeight: 1,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    textAlign: 'center',
  },
}));

const Badge = ({ content }) => {
  const classes = useStyles();
  return !!content ? <div className={classes.root}>{content}</div> : <></>;
};

Badge.propTypes = {
  content: PropTypes.number.isRequired,
};

Badge.defaultProps = {};

export default Badge;
