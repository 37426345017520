import xlsx from 'xlsx';
import fileSaver from 'file-saver';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const extension = 'xlsx';
const indexA = 1;
const indexZ = 26;
const charCodeA = 65;

const toColumnName = (number) => {
  let columnName = '';
  let currentLoopIndex = indexA;
  let nextLoopIndex = indexZ;
  let currentNumber = number - currentLoopIndex;
  while (currentNumber >= 0) {
    columnName = String.fromCharCode((currentNumber % nextLoopIndex) / currentLoopIndex + charCodeA) + columnName;
    currentLoopIndex = nextLoopIndex;
    nextLoopIndex *= indexZ;
    currentNumber -= currentLoopIndex;
  }
  return columnName;
};

export const generateSpreadsheet = (data, columns, description) => {
  const dataStartRow = description ? 2 : 1;
  const sheet = xlsx.utils.json_to_sheet(data, { header: columns.map((c) => c.key), origin: `A${dataStartRow}` });
  columns.forEach((c, i) => {
    const keyHeader = `${toColumnName(i + 1)}${dataStartRow}`;
    if (!sheet[keyHeader]) return;
    sheet[keyHeader].v = c.name;
  });

  if (description) {
    xlsx.utils.sheet_add_aoa(sheet, [[description]], { origin: 'A1' });
  }
  return sheet;
};

const getFilteredData = (data, columns) => {
  const filteredData = [];
  const columnsKeys = columns.map((c) => c.key);
  data.forEach((x) => {
    const row = {};
    Object.entries(x).forEach(([key, value]) => {
      if (columnsKeys.includes(key)) row[key] = value;
    });
    filteredData.push(row);
  });

  return filteredData;
};

export const generateShippedItemsSpreadsheet = (data, columns, description) => {
  const filteredData = getFilteredData(data, columns);
  const headerRow = 2;
  const sheet = xlsx.utils.json_to_sheet(filteredData, { header: columns.map((c) => c.key), origin: `A${headerRow}` });
  columns.forEach((c, i) => {
    const keyHeader = `${toColumnName(i + 1)}${headerRow}`;
    if (!sheet[keyHeader]) return;
    sheet[keyHeader].v = c.name;
    if (c.dataOptions) {
      for (let row = headerRow + 1; row <= filteredData.length + headerRow; row += 1) {
        const cellKey = `${toColumnName(i + 1)}${row}`;
        sheet[cellKey].z = c.dataOptions.format || '';
        sheet[cellKey].t = c.dataOptions.type || 's';
      }
    }
  });

  xlsx.utils.sheet_add_aoa(sheet, [[description]], { origin: 'A1' });
  return sheet;
};

export const promptGeneratedXlsx = (sheets, sheetNames, fileName) => {
  const writerBuffer = {
    Sheets: sheets.reduce((acc, s, i) => ({ ...acc, [sheetNames[i]]: s }), {}),
    SheetNames: sheetNames,
  };
  const buffer = xlsx.write(writerBuffer, {
    bookType: extension,
    type: 'array',
    Props: {
      Title: 'Report',
      Subject: 'Report',
      Author: 'QSL',
      Company: 'QSL',
    },
  });
  const blob = new Blob([buffer], { type: fileType });
  fileSaver.saveAs(blob, `${fileName}.${extension}`);
};
