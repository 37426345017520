const itemMovement = {
  bookQuantity: 'Quantité au livre',
  actionNumber: "Numéro d'action",
  container: 'conteneur',
  effectiveDate: 'Date effective',
  from: 'de {{entity}}',
  fromNoOrder: "d'aucune commande",
  fromOrder: 'de la commande {{orderName}}',
  movedQuantity: 'Quantité déplacée',
  movement: 'Mouvement',
  movements: 'Mouvements',
  previously: 'Auparavant',
  timestamp: 'Horodatage',
  to: 'vers {{entity}}',
  toNoOrder: 'vers aucune commande',
  toOrder: 'vers la commande {{orderName}}',
  user: 'Utilisateur',
};

export default itemMovement;
