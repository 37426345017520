import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';

const useSetupAxios = (instance) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isAxiosReady, setAxiosIsReady] = useState(false);

  instance.defaults.baseURL = process.env.REACT_APP_API_URL;

  const setAxios = useCallback(
    (getToken) => {
      instance.interceptors.request.use(
        async function (config) {
          try {
            const accessToken = await getToken();
            config.headers.common['Authorization'] = `Bearer ${accessToken}`;
            return config;
          } catch (e) {
            console.error(e);
          }
        },
        function (error) {
          return Promise.reject(error);
        }
      );
      setAxiosIsReady(true);
    },
    [instance.interceptors.request]
  );

  useEffect(() => {
    if (isAuthenticated) {
      setAxios(getAccessTokenSilently);
    }
  }, [isAuthenticated, setAxios, getAccessTokenSilently]);

  return [isAxiosReady];
};

export default useSetupAxios;
