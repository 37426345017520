import useAgent from '../../hooks/useAgent';
import qs from 'qs';
import moment from 'moment';
import axios from 'axios';
const url = `/api/v1/actions/reports/breakbulk`;

const ParseUrlSearchParamsIntoDescription = (key, value, t) => {
  let fieldLabel = t(`report.${key}`);
  let valueLabel;
  switch (key) {
    case 'effectiveDateFrom':
      valueLabel = moment(value).format('YYYY-MM-DD');
      return `${fieldLabel}: ${valueLabel} `;
    case 'effectiveDateTo':
      fieldLabel = t('to').toLowerCase();
      valueLabel = moment(value).format('YYYY-MM-DD');
      return `${fieldLabel} ${valueLabel}; `;
    case 'actionType':
      valueLabel = Array.isArray(value) ? value.map((type) => t(`report.${type}`)).join(', ') : t(`report.${value}`);
      break;
    case 'packaging':
      fieldLabel = t('packagingType');
      valueLabel = Array.isArray(value) ? value.map((type) => t(`packagings.${type}`)).join(', ') : '';
      break;
    case 'weightAndDimensions':
      return value
        .map(({ type, unit, maximum, minimum }) => {
          let valueDescription;
          if (unit) {
            const parts = minimum !== undefined ? [`${t('report.min')} ${minimum} ${unit}`] : [];
            if (maximum !== undefined) {
              parts.push(`${t('report.max')} ${maximum} ${unit}`);
            }
            valueDescription = parts.join(' ');
          } else {
            valueDescription = t('report.any');
          }

          return `${t(`dimensionTypes.${type}`)}: ${valueDescription}; `;
        })
        .join('');
    case 'identifiers':
      return value.map(({ label, value }) => `${t(`identifiers.${label}`)}: ${value}; `).join('');
    case 'orderby':
      return '';
    default:
      valueLabel = Array.isArray(value) ? value.join(', ') : value;
  }

  if (!valueLabel) return '';

  return `${fieldLabel}: ${valueLabel}; `;
};

const SendTransactionExcelReportByEmail = async (parameters = new URLSearchParams(), t) => {
  const params = qs.parse(parameters.toString(), { allowDots: true });
  const description = Object.keys(params)
    .reduce((acc, filter) => acc + ParseUrlSearchParamsIntoDescription(filter, params[filter], t), '')
    .trim()
    .slice(0, -1);
  const { textQuery } = params;

  return await axios.post(url, { textQuery, description, localTimeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone }, { params: parameters });
};

const useSendTransactionExcelReportByEmail = () => {
  return useAgent(SendTransactionExcelReportByEmail);
};

export default useSendTransactionExcelReportByEmail;
