import PropTypes from 'prop-types';
import { Attachments } from '../attachment';
import { appointmentsUrl as url } from './urls';

const AppointmentAttachments = ({ id }) => <Attachments resourceUrl={`${url}/${id}`} />;

AppointmentAttachments.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AppointmentAttachments;
