import PropTypes from 'prop-types';
import React from 'react';
import { useFormikContext } from 'formik';
import { get as _get } from 'lodash';
import SelectInput from './SelectInput';

const SelectField = ({ classes, inputRef, label, name, options, ...props }) => {
  const { errors, handleChange, touched, values } = useFormikContext();

  const isFieldTouched = _get(touched, name);
  const error = _get(errors, name);

  return (
    <SelectInput
      {...props}
      classes={classes}
      inputRef={inputRef}
      label={label}
      name={name}
      onChange={handleChange}
      options={options}
      value={_get(values, name)}
      error={isFieldTouched && Boolean(error)}
      helperText={isFieldTouched && error}
    />
  );
};

SelectField.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  inputRef: PropTypes.object,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), value: PropTypes.any, label: PropTypes.string })
  ),
};

SelectField.defaultProps = {
  classes: {},
  inputRef: null,
  label: null,
  name: null,
  options: [],
};

export default SelectField;
