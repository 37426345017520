import PropTypes from 'prop-types';

const MovementType = {
  actionNumber: PropTypes.string,
  cargoType: PropTypes.string,
  effectiveDate: PropTypes.string.isRequired,
  movementType: PropTypes.string.isRequired,
  movementSourceId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  movedQuantity: PropTypes.number.isRequired,
  movementDescription: PropTypes.string.isRequired,
  totalQuantity: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default MovementType;
