import axios from 'axios';
import { useTranslation } from 'react-i18next';
import download from '../../common/helpers/download';
import headers from '../../common/helpers/headers';
import useAgent, { PUSH_NOTIFICATION } from '../../hooks/useAgent';
import url from './url';

const documentUrl = (id) => `${url}/${id}/document`;

const getTransactionDocument = async (id, filename) => {
  const response = await axios.get(documentUrl(id), { responseType: 'blob' });
  const filenameFromHeader = headers.getFilename(response.headers);
  // const fileBlob = new Blob([response.data], { type: 'application/pdf' });
  return download(response.data, filenameFromHeader || filename);
};

const useGetTransactionDocument = () => {
  const { t } = useTranslation('transaction');
  return useAgent(getTransactionDocument, { errorStatusToAction: { 404: { type: PUSH_NOTIFICATION, payload: t('error.missingDocument') } } });
};

export default useGetTransactionDocument;
