import * as PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';

const DocumentTitleContext = createContext(['', () => {}]);

const DocumentTitleProvider = ({ children }) => {
  const titleContext = useState('');
  const [title] = titleContext;
  useEffect(() => {
    window.document.title = title;
  }, [title]);
  return <DocumentTitleContext.Provider value={titleContext}>{children}</DocumentTitleContext.Provider>;
};
DocumentTitleProvider.propTypes = { children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired };

const DocumentTitle = ({ title }) => {
  const [, setTitle] = useContext(DocumentTitleContext);
  useEffect(() => {
    setTitle(title);
  });
  return <></>;
};

DocumentTitle.propTypes = { title: PropTypes.string.isRequired };

export { DocumentTitle, DocumentTitleContext, DocumentTitleProvider };
