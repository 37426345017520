const reports = {
  inventories: 'Inventories',
  number: 'Number',
  name: 'Name',
  site: 'Site',
  date: 'Date',
  receiver: 'Receiver',
  bulk: 'Bulk',
  breakbulk: 'Breakbulk',
  generateReport: 'Generate Report',
  dateFrom: 'From',
  dateTo: 'To',
  tabs: {
    inventoryByDate: 'Inventory By Date',
    tailgate: 'Tailgate',
  },
  noContentAvailable: 'Request was successfully processed, but selected settings produced an empty report.',
};

export default reports;
