export const nonContainerStuffingCapacity = {
  none: 'none',
  containerizable: 'containerizable',
};

export const containerStuffingCapacity = {
  stuffable: 'stuffable',
  nonStuffableContainer: 'nonStuffableContainer',
};

export const itemStuffingCapacity = {
  ...nonContainerStuffingCapacity,
  ...containerStuffingCapacity,
};
