import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useInputValue from './useInputValue';

const inputStyles = makeStyles((theme) => ({
  root: { borderRadius: '1.25rem', height: '2rem', backgroundColor: theme.palette.primary.light, border: 0 },
  notchedOutline: { border: 0 },
}));

const textFieldStyle = makeStyles({ root: { height: '2rem' } });

const InputListSearch = ({ defaultValue, onEnterPressed }) => {
  const { t } = useTranslation('layout');
  const [inputValue, setInputValue] = useInputValue(defaultValue);

  useEffect(() => {
    if (defaultValue !== inputValue) setInputValue(defaultValue);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleKeyDown = (e) => {
    e.keyCode === 13 && onEnterPressed(e);
  };

  const onChange = useCallback((event) => {
    setInputValue(event.target.value);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TextField
      data-testid="test-input-list-search"
      variant="outlined"
      id="scoped-search"
      placeholder={t('listHeader.search')}
      value={inputValue}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      classes={textFieldStyle()}
      size="small"
      InputProps={{
        classes: inputStyles(),
        startAdornment: (
          <InputAdornment position="start" size="small">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

InputListSearch.propTypes = {
  defaultValue: PropTypes.string,
  onEnterPressed: PropTypes.func,
};

InputListSearch.defaultProps = {
  defaultValue: '',
  onEnterPressed: () => undefined,
};

export default InputListSearch;
