import { Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';

const KeyboardDatePickerField = ({ classes, label, labelClasses, maxDate, minDate, name, fullWidth = false }) => {
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const currentError = _get(errors, name);
  const isFieldTouched = _get(touched, name);

  const maxDateAsObject = maxDate ? dayjs(maxDate, 'YYYY-MM-DD') : undefined;
  const minDateAsObject = minDate ? dayjs(minDate, 'YYYY-MM-DD') : undefined;

  return (
    <KeyboardDatePicker
      maxDate={maxDateAsObject}
      minDate={minDateAsObject}
      classes={classes}
      clearable
      helperText={isFieldTouched && currentError}
      error={isFieldTouched && Boolean(currentError)}
      fullWidth={fullWidth}
      format="YYYY-MM-DD"
      label={
        <Typography classes={labelClasses} variant="body2">
          {label}
        </Typography>
      }
      onChange={(date) => {
        const formattedDate = date ? dayjs(date).format() : null;
        setFieldValue(name, formattedDate, true);
      }}
      value={_get(values, name)}
    />
  );
};

KeyboardDatePickerField.propTypes = {
  classes: PropTypes.object,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  labelClasses: PropTypes.object,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  name: PropTypes.string.isRequired,
};

KeyboardDatePickerField.defaultProps = {
  classes: {},
  label: '',
  labelClasses: {},
  maxDate: null,
  minDate: null,
};

export default KeyboardDatePickerField;
