const en = {
  button: {
    accept: 'Accept',
    refuse: 'Refuse',
  },
  termOfUse: 'Terms of Use',
  effectiveAsOf: 'Effective as of April 2021',
  definitions: 'Definitions',
  services: 'Services',
  servicesDefinition: 'The  Internet  Sites,  mobile  applications  published  and  operated  by  QSL  and  the  services  offered  and  provided therein.',
  qslOrWe: '"QSL" or "We"',
  qslOrWeDefinition: 'QSL Canada inc., QSL America inc. and their affiliates. ',
  informationDataContent: '"Information" or "Data" or "Content"',
  informationDataContentDefinition: 'All information or data made available through the Service',
  youUser: '"you" » or "User"',
  youUserDefinition: 'Any natural person that is authorised to use the Services and the any organisation he represents',
  object: 'Object',
  objectParagraphOne:
    'This Agreement between you and QSL binds you applies once you to access or use the Services. You represent and warrant that you are authorized to accept this Agreement both on your own behalf and on behalf of anyone you represent. If you do not agree to all the terms of this Agreement, you may not use the Services.',
  objectParagraphTwo:
    'QSL reserves the right to modify these Terms of use. Any changes will be notified. Your use of the Services after such notification constitutesacceptance of the changes',
  userAccountAndCredential: 'User Account & Credentials',
  userAccountAndCredentialParagraphOne:
    'Certain Services are only accessible to Users to whom a user name and password (the “Credentials”) have been issued in accordance with the applicable conditions. You are solely responsible for the use made of the Services with the Credentials, and any access or use of the Services through the Credentials will be deemed to have been by you.',
  userAccountAndCredentialParagraphTwo:
    'Each User is fully responsible for (a) preserving the confidentiality and security of the Credentials (b) not disclosing them to any third party or allowing any third party to use them; (c) using the Credentials in compliance with this Agreement; and (d) immediately informing QSL if you suspect that your Credentials have been lost, stolen or are known to or being used by a third party, by sending an email at info@qsl.com.',
  rightOfUseAndProhibitedUse: 'Right of use and prohibited use',
  rightOfUseAndProhibitedUseListTitle: 'You are authorized to access and use the Services solely:',
  rightOfUseAndProhibitedUseListItemOne: 'in connection with the activities of your business;',
  rightOfUseAndProhibitedUseListItemTwo: 'in accordance with the access rights granted to you and in accordance with this Agreement and with applicable law.',
  rightOfUseAndProhibitedUseParagraphOne:
    'Users must refrain from any action likely to affect the accessibility, security, integrity or functionality of the Services, or make any attempt to gain unauthorized access to the Information of other users.',
  rightOfUseAndProhibitedUseParagraphTwo:
    'QSL may, without prior notice and without becoming liable to you or any other person, revoke your authorization to access and use the Services and terminate this Agreement, in the event (i) you fail to comply with this Agreement, (ii) of inappropriate or illegal use, or (iii) that termination proves necessary in order to prevent or avoid the occurrence of prejudice.',
  informativeContent: 'Informative Content',
  informativeContentParagraphOne:
    'The documents and Information made available through the Services are provided for informational purposes only, are not originals, may not be complete and may contain errors. In the event of any discrepancy, the documents and information received, generated, processed, delivered or transmitted in connection with our stevedoring services, port terminal operations and transport solutions will prevail over documents and Information made available through the Services.',
  informativeContentParagraphTwo:
    'The Services may contain links to third party Internet sites and information for convenience purposes only. QSL has no control or influence over their content, and assumes no responsibility for your use thereof.',
  warrantyExclusionAndLiabilityExclusion: 'Warranty exclusion / Liability exclusion',
  warrantyExclusionAndLiabilityExclusionParagraphOne:
    'QSL undertakes to deploy appropriate resources and use appropriate processes and technology to optimally provide the Services and publish accurate and reliable data. QSL does not warrant that the Content ***',
  warrantyExclusionAndLiabilityExclusionParagraphTwo:
    'Given the nature of the Services, QSL provides no warranty whatsoever in respect of the Services and the Content, and no liability whatsoever shall be attributed to QSL for any damage or harm arising directly or indirectly from the use or unavailability of the Services in whole or in part, or from the inaccuracy or incompleteness of the Content made available through the Services, other than in the event of intentional fault or gross negligence on the part of QSL.',
  warrantyExclusionAndLiabilityExclusionParagraphThree:
    'You will be accessing and using the Services without the benefit of any warranty, and you accept them “as is”. QSL shall in no event become liable towards you or any other person for any harm or damage whatsoever, direct or indirect.',
  personalInformation: 'Personal Information',
  personalInformationParagraphOne:
    'QSL will only collect the personal information that you will provide to create a user account and in the course of your access and use of the Services, and will use them only for the purposes of providing the Services in accordance with applicable laws.',
  availabilityOfTheService: 'Availability of the Service',
  availabilityOfTheServiceListTitle:
    'QSL seeks to ensure the highest possible availability of the Services but cannot guarantee uninterrupted access to, and constant availability of the Services. QSL reserves the right to interrupt the provision of the Services and access thereto, including in the following circumstances:',
  availabilityOfTheServiceListItemOne: 'For maintenance purposes ;',
  availabilityOfTheServiceListItemTwo: 'To improve the Services and adding new features thereto;',
  availabilityOfTheServiceListItemThree: 'To verify and audit the proper performance and use of the Services;',
  availabilityOfTheServiceListItemFour: 'In the event of an actual or anticipated breakdown of the Services.',
  intellectualProperty: 'Intellectual property, and rights associated with the Services',
  intellectualPropertyParagraphOne:
    'Your access and use of the Services does not give you any property rights or any other right whatsoever in or in respect of the Services or its underlying technology. You may not copy, reproduce, republish, decompile, make a mirror image copy or distribute the Content of the Services or the information made available through the Services, in whole or in part, without the written consent of QSL..',
  intellectualPropertyParagraphTwo:
    'You are expressly prohibited from using the trademarks, trade names, logos, signatures, and slogans (the "Trademarks") displayed in the Services or other information or documents incorporated in the Content, except as permitted herein.',
  term: 'Term',
  termParagraphOne:
    'The rights of access and use of the Services are granted for an indefinite period starting from the access to the Services, or where applicable the creation of a User account.',
  applicableLaw: 'Applicable law',
  applicableLawParagraphOne:
    'This Agreement is governed by and shall be interpreted in accordance with the laws applicable in the Province of Québec. Any dispute between you and QSL arising out of this Agreement shall be submitted to a court of competent jurisdiction sitting in and for the judicial district of Québec, Province of Québec. The French version will prevail in case of discrepancies between the two versions.',
  contact: 'Contact',
  contactEmail: 'E-mail : info@qsl.com',
  contactParagraph:
    'I confirm that I have read and agree to the Terms of Use on behalf of the organization I represent. I recognize that in the event of any discrepancy, the documents and information received, generated, processed, delivered or transmitted in connection with QSL’s stevedoring services, port terminal operations and transport solution, will prevail over document and information made available through the Services.',
};

export default en;
