import axios from 'axios';
import download from '../../../common/helpers/download';
import useAgent from '../../../hooks/useAgent';

export const url = `/api/v1/inventory-items/reports/bulk`;

export const generateExcelReport = async (parameters = new URLSearchParams(), fileName) => {
  parameters.set('localTimezoneId', Intl.DateTimeFormat().resolvedOptions().timeZone);

  const response = await axios.get(url, {
    params: parameters,
    responseType: 'blob',
  });

  return download(response.data, fileName);
};

const useGenerateBulkExcelReport = () => useAgent(generateExcelReport);

export default useGenerateBulkExcelReport;
