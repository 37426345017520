import PropTypes from 'prop-types';
import React from 'react';
import AuthError from './AuthError';
import { useTranslation } from 'react-i18next';

const AuthErrors = ({ error }) => {
  const { t } = useTranslation('profile');

  const passwordExpired = error && error.error_description && error.error_description.includes('AUTH0PWE');
  const [title, description] = passwordExpired ? [t('passwordExpired'), t('passwordExpiredMessage')] : ['Authentification Error', error.error_description];

  return <AuthError title={title} description={description} />;
};

AuthErrors.propTypes = {
  error: PropTypes.object,
};

export default AuthErrors;
