import { availabilityTypeNotAvailable } from '@vooban/tc3-core';
import * as Yup from 'yup';

const LaborAvailabilityModalValidationSchema = (availabilityTypes, descriptionRequired, t) =>
  Yup.object().shape({
    availability: Yup.string().required(t('required')),
    type: Yup.string()
      .when(['availability'], (availability, schema) => {
        const hasTypeSet = availabilityTypes && availabilityTypes.some((availabilityType) => availabilityType.availability === availability);
        return hasTypeSet ? schema.required(t('required')) : schema;
      })
      .nullable(),
    description: Yup.string().when(['availability'], (availability, schema) =>
      availability === availabilityTypeNotAvailable && descriptionRequired ? schema.required(t('required')) : schema
    ),
  });

export default LaborAvailabilityModalValidationSchema;
