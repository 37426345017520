import PropTypes from 'prop-types';
import Icon, { iconSizes } from './Icon';
import svg from '../../common/icons/bulk.svg';

const BulkIcon = ({ size, styles }) => (
  <Icon size={size} styles={styles}>
    <img style={{ height: '100%' }} alt="" src={svg} />
  </Icon>
);

BulkIcon.propTypes = {
  size: PropTypes.string,
  styles: PropTypes.shape(),
};

BulkIcon.defaultProps = {
  size: iconSizes.medium,
  styles: {},
};

export default BulkIcon;
