import PropTypes from 'prop-types';
import { CARGO_TYPE } from '../../../cargoType';

export const ClientInventory = {
  cargoType: PropTypes.oneOf([...Object.values(CARGO_TYPE), '']),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
};

export default ClientInventory;
