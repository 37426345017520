import axios from 'axios';
import { CARGO_TYPE } from '../../../cargoType';
import useAgent from '../../../hooks/useAgent';

const url = `/api/v1/inventory-items/options`;

export const getOptions = () => axios.get(url, { params: { cargoType: CARGO_TYPE.bulk } });

const useGetOptions = () => useAgent(getOptions);

export default useGetOptions;
