import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxThunk from 'redux-thunk';
import reducers, { persistConfig } from './reducers';

const ReduxProvider = ({ children }) => {
  const persistedReducers = persistReducer(persistConfig, reducers);
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const store = configureStore({
    reducer: persistedReducers,
    middleware: [ReduxThunk],
    devTools: composeEnhancers,
  });
  const persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

ReduxProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.func, PropTypes.object]),
};

export default ReduxProvider;
