import { enTranslation } from '@vooban/tc3-core';

const laborAvailability = {
  site: 'Site',
  projectName: 'Project Name',
  projectNumber: 'Project Number',
  cargo: 'Cargo',
  planningCalendar: 'Planning Calendar',
  productCategory: 'Product Category',
  productSubtype: 'Product Subtype',
  productType: 'Product Type',
  quantity: 'Quantity',
  totalWeightInMT: 'Total Weight (MT)',
  more: 'more',
  remark: 'Remark',
  siteDoesntHaveConfiguration: 'No configuration found for this site',
  units: {
    ...enTranslation.units,
    SquareMeter: 'sq.m.',
    SquareFoot: 'sq.ft.',
    Meter: 'm',
    Foot: 'ft',
    TwentyFootEquivalentUnit: 'TEU',
  },
  unavailableSite: 'Calendar unavailable for this site',
  workerAvailability: {
    maximumNonAvailabilityDays: 'Maximum non-available days reached: {{maximumNonAvailabilityDays}} days',
  },
  selectAvailability: 'Select Availability',
  save: 'Save',
  cancel: 'Cancel',
  available: 'Available',
  unavailable: 'Unavailable',
  reason: 'Reason',
  required: 'Required',
};

export default laborAvailability;
