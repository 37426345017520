import axios from 'axios';
import { FileActions } from '@vooban/file';
import { englishLocalesCode } from '../../i18n';

const { preview } = FileActions;

const url = `/api/v1/user-guide/download`;

const getUserGuide = async (language) => {
  var response = await axios.get(url, {
    method: 'GET',
    responseType: 'blob',
  });
  preview(response.data, language === englishLocalesCode ? 'Customer_User Guide' : 'Portail client_Guide d’utilisateur');
};

const userGuideAgents = { getUserGuide };

export default userGuideAgents;
