import { availabilityTypeNotAvailable, availabilityStatusTypeInProgress, availabilityTypeOnlyCalendarAccess } from '@vooban/tc3-core';
import moment from 'moment';

export const getMaximumNonAvailabilityDays = ({
  selectedAvailability: { availability = availabilityTypeNotAvailable, date },
  workerAvailability: { result = [] },
  user: { email = '' },
}) => {
  const { group, worker } = result.reduce((acc, group) => {
    const worker = group.workers.find((w) => w.email === email);
    return worker ? { group, worker } : acc;
  }, {});

  if (!worker || !group) return { isMaxNonAvailableDaysReached: false };

  const { maximumNonAvailabilityDays } = group;
  const totalNonAvailableDays = worker.availabilities.filter(
    (availability) => !moment(date).isSame(moment(availability.date), 'day') && availability.availability === availabilityTypeNotAvailable
  ).length;

  const isInProgress =
    worker.availabilities.find((availability) => moment(date).isSame(moment(availability.date), 'day'))?.status === availabilityStatusTypeInProgress ||
    !!maximumNonAvailabilityDays;

  if (availability === availabilityTypeNotAvailable && maximumNonAvailabilityDays && isInProgress) {
    return { isMaxNonAvailableDaysReached: totalNonAvailableDays + 1 > maximumNonAvailabilityDays, maximumNonAvailabilityDays };
  }

  return { isMaxNonAvailableDaysReached: false };
};

export const filterOutOnlyCalendarAccessWorkers = (workers) => {
  return workers.filter((worker) => worker.defaultAvailability !== availabilityTypeOnlyCalendarAccess);
};
