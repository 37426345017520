import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import _sortBy from 'lodash.sortby';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import MultiSearchInput from '../fields/MultiSearchInput';
import useGetMyCompanies from '../../inventory/item/breakbulk/useGetMyCompanies';

const DialogContentStyles = makeStyles({
  root: {
    paddingBottom: '2em',
  },
});

const DialogHeaderStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1em 1em 0 0',
  },
});

const DialogTitleStyles = makeStyles({
  root: {
    fontSize: '1.2em',
  },
});

const CompanySelectionModal = ({ open, onClose, onConfirm, currentCompanies }) => {
  const { t } = useTranslation('layout');
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [getCompanies, companies = []] = useGetMyCompanies();
  const dialogHeaderClasses = DialogHeaderStyles();

  useEffect(() => {
    if (open) {
      setSelectedCompanies(currentCompanies);
    }
  }, [open, currentCompanies]);

  const handleSearch = useMemo(() => (query) => getCompanies({ query }), [getCompanies]);

  const companyOptions = useMemo(() => {
    const missingOptions = selectedCompanies.filter((c) => !companies.some((x) => x.id === c.id));
    return _sortBy(
      [...companies, ...missingOptions].map((company) => ({ key: company.id, label: company.name, value: company })),
      'label'
    );
  }, [companies, selectedCompanies]);

  const openTC3 = () => {
    window.open(process.env.REACT_APP_MIDDLEWARE_API_URL, '_self').focus();
  };

  const showPortalMessage = !currentCompanies.length;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      {showPortalMessage && (
        <>
          <Typography variant="h2" className="pt-4 pb-1 text-center">
            {t('thisIsTheClientPortal')}
          </Typography>
          <button className="underline" onClick={openTC3}>
            {t('clickHereToGoToTc3')}
          </button>
        </>
      )}
      <div className={dialogHeaderClasses.root}>
        <DialogTitle disableTypography={showPortalMessage} classes={DialogTitleStyles()}>
          {t('viewAs')}
        </DialogTitle>
        <DialogActions>
          <Button variant="contained" disabled={!currentCompanies.length} onClick={onClose}>
            {t('back')}
          </Button>
          <Button variant="contained" color="primary" disabled={!selectedCompanies.length} onClick={() => onConfirm(selectedCompanies)}>
            {t('confirm')}
          </Button>
        </DialogActions>
      </div>
      <DialogContent classes={DialogContentStyles()}>
        <MultiSearchInput
          fullWidth
          value={selectedCompanies}
          label="Company"
          options={companyOptions}
          search={handleSearch}
          onChange={({ target }) => setSelectedCompanies(target.value)}
        />
      </DialogContent>
    </Dialog>
  );
};

CompanySelectionModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  currentCompanies: PropTypes.arrayOf(PropTypes.object),
  companies: PropTypes.arrayOf(PropTypes.object),
};

export default CompanySelectionModal;
