import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const clearFiltersStyle = makeStyles({
  root: {
    marginLeft: 'auto',
  },
});

const ClearFilterButton = ({ onClick }) => {
  const { t } = useTranslation('layout');
  const { search } = useLocation();
  const styles = clearFiltersStyle();

  const params = new URLSearchParams(search);
  params.delete('orderby'); // We don't want to clear the orderby parameter

  const shouldShowClearFilters = params && Array.from(params).length > 0;

  return (
    <>
      {shouldShowClearFilters && (
        <Button
          data-testid="test-clear-filters-button"
          classes={styles}
          onClick={onClick}
          color="primary"
          startIcon={<CancelIcon color="primary" />}
          size="small">
          {t('listHeader.clearFilters')}
        </Button>
      )}
    </>
  );
};

ClearFilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ClearFilterButton;
