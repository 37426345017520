import PropTypes from 'prop-types';
import Icon, { iconSizes } from './Icon';
import svg from '../../common/icons/excel.svg';
import disabledSvg from '../../common/icons/excel-disabled.svg';

const ExcelIcon = ({ disabled, size, styles }) => (
  <Icon size={size} styles={styles}>
    <img style={{ width: '100%' }} alt="" src={disabled ? disabledSvg : svg} />
  </Icon>
);

ExcelIcon.propTypes = {
  disabled: PropTypes.bool,
  size: PropTypes.string,
  styles: PropTypes.object,
};

ExcelIcon.defaultProps = {
  disabled: false,
  size: iconSizes.medium,
  styles: {},
};

export default ExcelIcon;
