import PropTypes from 'prop-types';
import React from 'react';
import Title from '../../Title';
import ClearFilterButton from './component/ClearFilterButton';
import ListPageHeaderLayout from './component/ListPageHeaderLayout';
import ListPageHeaderPills from './component/ListPageHeaderPills';
import withClearFilter from './hoc/withClearFilter';

const ListPageHeader = ({ title, actions, onClearFilter, pills, subscript }) => (
  <>
    <ListPageHeaderLayout>
      <Title title={title} />
      {subscript}
    </ListPageHeaderLayout>
    <div className={`flex flex-wrap items-baseline`}>
      <div className="flex flex-wrap">
        <ListPageHeaderPills pills={pills} />
      </div>
      <div className={`inline flex-initial ml-auto`}>
        <ClearFilterButton onClick={onClearFilter} />
        {actions}
      </div>
    </div>
  </>
);

ListPageHeader.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
  onClearFilter: PropTypes.func.isRequired,
  onParamChange: PropTypes.func,
  pills: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  subscript: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
};

ListPageHeader.defaultProps = {
  actions: null,
  onParamChange: () => undefined,
  pills: [],
  subscript: null,
  title: null,
};
export default withClearFilter(ListPageHeader);
