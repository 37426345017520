const dataTypes = {
  cargoType: {
    Breakbulk: 'Breakbulk',
    Bulk: 'Vrac',
  },
  hazardClass: {
    class1: 'Classe 1',
    class2: 'Classe 2',
    class3: 'Classe 3',
    class4: 'Classe 4',
    class5: 'Classe 5',
    class6: 'Classe 6',
    class7: 'Classe 7',
    class8: 'Classe 8',
    class9: 'Classe 9',
  },
  volume: {
    cuFt: 'pied cube',
    cuM: 'mètre cube',
    cuYd: 'verge cube',
    liter: 'litre',
  },
  mass: {
    kg: 'kilogramme',
    lb: 'livre',
    MT: 'tonne métrique',
    NT: 'tonne courte',
  },
  trailerType: {
    bTrain: 'B-Train',
    dryBox: 'Dry Box',
    flatBed: 'Flat Bed',
    frame: 'Frame',
    gooseneck: 'Gooseneck',
  },
  siteConfigurationLimit: {
    cargo: 'Cargo',
    carrier: 'Transporteur',
    timeSlot: 'Plage horaire',
  },
  packagingGroup: {
    pgI: 'PG I',
    pgII: 'PG II',
    pgIII: 'PG III',
  },
  transportationType: {
    Barge: 'Barge',
    Other: 'Autre',
    Railcar: 'Train',
    Truck: 'Camion',
  },
};

const traffic = {
  appointmentNumber: 'Numéro de rendez-vous',
  count: 'Nombre',
  dataTypes,
  lastUpdated: 'Dernière mise à jour',
  listCountSingle: 'Nombre',
  refresh: 'Actualiser',
  traffic: 'Traffic',
  vehicleIdentifier: 'Identifiant du véhicule',
};

export default traffic;
