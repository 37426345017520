import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField, TextField } from '../layout/fields';

const makeInputStyles = makeStyles((theme) => ({
  root: {
    paddingRight: '1rem',
  },
}));

const AgnicoEagleFilter = () => {
  const { t } = useTranslation('agnicoEagle');
  const inputStyles = makeInputStyles();

  return (
    <Grid container item wrap="wrap" xs={12} md={6}>
      <Grid classes={inputStyles} item xs={4} md={4}>
        <NumberField name="purchaseOrderNumber" label={t('purchaseOrderNumber')} decimalScale={0} />
      </Grid>
      <Grid classes={inputStyles} item xs={4} md={4}>
        <TextField name="itemNumber" label={t('itemNumber')} fullWidth />
      </Grid>
      <Grid classes={inputStyles} item xs={4} md={4}>
        <TextField name="itemDescription" label={t('itemDescription')} fullWidth />
      </Grid>
    </Grid>
  );
};

AgnicoEagleFilter.propTypes = {};

export default AgnicoEagleFilter;
