import { useRouteMatch } from 'react-router-dom';
import { Form, Formik } from 'formik';
import useAgent from '../../hooks/useAgent';
import { useEffect } from 'react';
import Loader from '@vooban/loader';
import InventoryByDateReportFormFields from './InventoryByDateReportFormFields';
import { getSites } from '../agents/inventoryByDateAgents';
import { CARGO_TYPE } from '../../cargoType';

const InventoryByDateExcelReportContent = () => {
  let { path } = useRouteMatch();
  const type = path.includes('breakbulk') ? CARGO_TYPE.breakbulk : CARGO_TYPE.bulk;

  const [getInventoriesSites, sites = [], isLoadingSites] = useAgent(getSites);

  useEffect(() => {
    getInventoriesSites(type);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  if (isLoadingSites) return <Loader />;
  const sitesOptions = sites?.map((site) => ({ label: site.name, value: site.id, key: site.id }));

  return (
    <Formik initialValues={{ date: null, inventoriesIds: [], sitesOptions: [] }}>
      <Form>
        <InventoryByDateReportFormFields type={type} sitesOptions={sitesOptions}></InventoryByDateReportFormFields>
      </Form>
    </Formik>
  );
};

export default InventoryByDateExcelReportContent;
