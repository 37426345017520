import { LayoutPage } from '../../layout';
import React from 'react';
import ExcelReportsRouter from './ExcelReportsRouter';

const ExcelReportDashboard = () => {
  return (
    <LayoutPage>
      <ExcelReportsRouter />
    </LayoutPage>
  );
};

export default ExcelReportDashboard;
