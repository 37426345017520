import PropTypes from 'prop-types';
import StorageArea from './StorageArea';
import StorageFacility from './StorageFacility';

export const Location = {
  id: PropTypes.number,
  storageFacility: PropTypes.shape(StorageFacility),
  storageArea: PropTypes.shape(StorageArea),
  effectiveDate: PropTypes.date,
};

export default Location;
