import { useFormikContext } from 'formik';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import MultiSelectInput from './MultiSelectInput';

const MultiSelectField = ({ name, ...props }) => {
  const { errors, handleChange, touched, values } = useFormikContext();
  const isFieldTouched = _get(touched, name);
  const error = _get(errors, name);

  return <MultiSelectInput {...props} name={name} onChange={handleChange} value={_get(values, name)} error={isFieldTouched && Boolean(error)} />;
};

MultiSelectField.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    })
  ),
};

MultiSelectField.defaultProps = {
  classes: {},
  label: null,
  name: null,
  options: [],
};

export default MultiSelectField;
