import weightAndDimensions from '../../../weightAndDimensions/__i18n__/fr';
import itemMovement from '../itemMovement/__i18n__/fr';
import itemMovementType from '../itemMovementType/__i18n__/fr';

const identifierLabel = {
  Barcode: 'Code à barre',
  BillOfLading: 'Connaissement',
  Description: 'Description',
  HeatNumber: 'Numéro de coulée',
  LotNumber: 'Numéro de lot',
  Mark: 'Marque',
  Other: 'Autre',
  PoNumber: 'Numéro du BC',
  Scope: 'Portée',
  SerialNumber: 'Numéro de série',
};

const dimensionTypes = {
  height: 'Hauteur',
  width: 'Largeur',
  length: 'Longueur',
  footPound: 'Pied livres',
  surfaceArea: 'Superficie',
  thickness: 'Épaisseur',
  diameter: 'Diamètre',
};

const seal = {
  billable: { true: 'Facturable', false: 'Non facturable' },
  billableInventory: 'Inventaire facturable',
  action: 'Action',
  seal: 'Sceller',
  unseal: 'Désceller',
  user: 'Utilisateur',
  by: 'Par',
  date: 'Date & Heure',
  noHistory: "Ce conteneur n'a pas d'historique de sceau",
  sealContainer: 'Conteneur scellé',
  sealNumber: 'Numéro de sceau',
  remark: 'Remarque',
  remarkUpdatedBy: 'Remarque mise à jour par',
  createdByName: 'Nom',
  viewSealNumbers: 'Voir les numéros de sceau',
  viewSealHistory: "Voir l'historique des sceaux",
};

const diff = {
  mainInformation: 'Information principale',
  productType: { name: 'Product Type' },
  productSubtypeName: 'Subtype',
  packaging: 'Packaging',
  quantityPerPackage: 'Quantity Per Package',
  remark: 'Remark',
  isStackable: 'Is Stackable',
  location: { storageFacility: { name: 'Storage Facility' }, storageArea: { name: 'Storage Area' } },
  quantity: 'Quantity',
  availableQuantity: 'Quantity disponible',
  containerStuffingCapacity: 'Capacité du conteneur',
  stuffingCapacity: {
    none: 'Aucune',
    containerizable: 'Peut être mis dans un conteneur',
    stuffable: 'Conteneur empotable',
    nonStuffableContainer: 'Conteneur préempoté/scellé',
  },
  hazardousModifications: 'Modifications de matières dangereuses',
  identifiers: 'Identifiants',
  identifierLabelShort: {
    Barcode: 'BC',
    BillOfLading: 'B/L',
    Description: 'Desc',
    HeatNumber: 'H/N',
    LotNumber: 'L/N',
    Mark: 'Mk',
    Other: 'Other',
    PoNumber: 'PO',
    Scope: 'Scope',
    SerialNumber: 'S/N',
  },
  weightAndDimensions,
};

const item = {
  actionDate: "Date de l'action",
  advancedFilters: 'Filtres avancés',
  aemPo: 'AEM P.O',
  area: 'Zone',
  asPerScale: 'Pesé',
  bookQuantity: 'Quantité au livre',
  class: 'Classe',
  close: 'Fermer',
  containedItems: 'Items contenus',
  containers: 'Conteneur',
  containerStuffingCapacity: 'Capacité du conteneur',
  countryOfOrigin: "Pays d'origine",
  dateAndTime: 'Date & Heure',
  density: 'Densité',
  diff: diff,
  dimension: 'Dimension',
  dimensions: 'Dimensions',
  dimensionTypes,
  dimensionsWeightPerItem: 'Dimensions et poids par item',
  effectiveDate: 'Date effective',
  excelFileName: 'ItemsInventaire-$t(cargoType:{{cargoType}})-{{date}}.xlsx',
  excludeEmptyContainers: 'Exclure les conteneurs empotables vides',
  facility: 'Établissement',
  false: 'Non',
  generalInformation: 'Information générales',
  generatePackingList: "Générer le bordereau d'expédition",
  hazardous: 'Matières dangereuses',
  history: 'Historique',
  identifierLabel,
  identifiers: 'Identifiants',
  includeContainerizedItems: 'Inclure les items dans un conteneur',
  includeEmptyItems: 'Inclure les items avec une quantité à 0',
  itemMovement,
  itemMovementType,
  limitedQuantity: 'Qté ltée',
  location: 'Emplacement',
  locationHistory: "Historique d'emplacement",
  mainInformation: 'Information principale',
  mediaFiles: 'Fichiers multimédia',
  modifiedItem: 'Item modifié',
  movements: 'Mouvements',
  no: 'Non',
  noOrder: 'Sans commande',
  order: 'Commande',
  origin: 'Origine',
  originalVesselProject: 'Projet navire original',
  packaging: "Type d'emballage",
  packagingGroup: "Groupe de type d'emballage",
  pg: 'PG',
  previously: 'Auparavant',
  productCharacteristics: 'Caractéristiques du produit',
  productSubtype: 'Sous-type de produit',
  productType: 'Type de produit',
  qtyPerPackage: 'Qté par emballage',
  quantity: 'Quantité',
  released: 'Relâché',
  remarks: 'Remarques',
  seal,
  search: 'Rechercher',
  showOnlyHazmat: 'Matières dangereuses seulement',
  storageArea: "Zone d'entreposage",
  storageFacility: "Établissement d'entreposage",
  stuffedContainerWeight: 'Poids du conteneur rempli',
  stuffingCapacity: {
    none: 'Aucune',
    containerizable: 'Peut être mis dans un conteneur',
    stuffable: 'Conteneur empotable',
    nonStuffableContainer: 'Conteneur préempoté/scellé',
  },
  surfaceArea: 'Superficie',
  un: 'UN',
  unit: 'Unité',
  unNumber: 'Numéro ONU',
  user: 'Utilisateur',
  volume: 'Volume',
  weight: 'Poids',
  receptionFrom: 'Réception de',
  to: 'À',
  true: 'Oui',
  yes: 'Oui',
};

export default item;
