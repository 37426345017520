import { Grid, GridList } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GridHeaderLabel from '../../../layout/GridHeaderLabel';
import Output from '../../../layout/Output';
import Section from '../../../layout/Section';
import AgnicoEagleAssignationType from './agnicoEagleAssignationType';

const AgnicoEagleAssignationsList = ({ itemAgnicoEagleAssignationsByPriorityShippingConditions }) => {
  const { t } = useTranslation('inventory');

  return (
    <Section>
      <GridList cols={1} rowHeight="auto">
        <Grid container>
          <Grid item xs={1}>
            <GridHeaderLabel className="opacity-50" label={t('list.priority')} />
          </Grid>
          <Grid item xs={1}>
            <GridHeaderLabel className="opacity-50" label={t('list.shippingCondition')} />
          </Grid>
          <Grid item xs={1}>
            <GridHeaderLabel className="opacity-50" label={t('list.orderNumber')} />
          </Grid>
          <Grid item xs={1}>
            <GridHeaderLabel className="opacity-50" label={t('list.lineNumber')} />
          </Grid>
          <Grid item xs={3}>
            <GridHeaderLabel className="opacity-50" label={t('list.descriptionLines')} />
          </Grid>
          <Grid item xs={3}>
            <GridHeaderLabel className="opacity-50" label={t('list.itemNumber')} />
          </Grid>
          <Grid item xs={1}>
            <GridHeaderLabel className="opacity-50" label={t('list.receivedQuantity')} />
          </Grid>
          <Grid item xs={1}>
            <GridHeaderLabel className="opacity-50" label={t('list.branch')} />
          </Grid>

          {Object.keys(itemAgnicoEagleAssignationsByPriorityShippingConditions).map((priorityKey) => {
            const assignationsByShippingConditions = itemAgnicoEagleAssignationsByPriorityShippingConditions[priorityKey];
            return Object.keys(assignationsByShippingConditions).map((shipKey, shipIndex) => {
              const assignations = assignationsByShippingConditions[shipKey];
              return assignations.map((assignation, assignationIndex) => {
                return <AgnicoEagleAssignationsRow key={assignation.id} assignation={assignation} assignationIndex={assignationIndex} shipIndex={shipIndex} />;
              });
            });
          })}
        </Grid>
      </GridList>
    </Section>
  );
};

export const AgnicoEagleAssignationsRow = ({ assignation, shipIndex, assignationIndex }) => (
  <>
    <Grid item xs={1}>
      <Output defaultValue={(shipIndex === 0 && assignationIndex === 0 && assignation.priority) || ' '} />
    </Grid>
    <Grid item xs={1}>
      <Output defaultValue={(assignationIndex === 0 && assignation.shippingCondition) || ' '} />
    </Grid>
    <Grid item xs={1}>
      <Output defaultValue={assignation.orderNumber} />
    </Grid>
    <Grid item xs={1}>
      <Output defaultValue={assignation.lineNumber} />
    </Grid>
    <Grid item xs={3}>
      <Output defaultValue={assignation.description1} />
      <br />
      <Output defaultValue={assignation.description2} />
    </Grid>
    <Grid item xs={3}>
      <Output defaultValue={`${assignation.number2}, ${assignation.number3}`} />
    </Grid>
    <Grid item xs={1}>
      <Output defaultValue={assignation.quantity} />
    </Grid>
    <Grid item xs={1}>
      <Output defaultValue={`${assignation.shipToId}/${assignation.costCenter}`} />
    </Grid>
  </>
);

AgnicoEagleAssignationsList.propTypes = {
  itemAgnicoEagleAssignationsByPriorityShippingConditions: PropTypes.shape().isRequired,
};

AgnicoEagleAssignationsRow.propTypes = {
  assignation: PropTypes.shape(AgnicoEagleAssignationType).isRequired,
  assignationIndex: PropTypes.number.isRequired,
  shipIndex: PropTypes.number.isRequired,
};

export default AgnicoEagleAssignationsList;
