import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import NumberInput from './NumberInput';

const NumberField = ({ inputRef, label, name, decimalScale }) => {
  const { handleChange, errors, values, touched } = useFormikContext();

  const currentError = _get(errors, name);
  const isFieldTouched = _get(touched, name);

  return (
    <NumberInput
      decimalScale={decimalScale}
      inputRef={inputRef}
      label={label}
      name={name}
      onChange={handleChange}
      value={_get(values, name)}
      error={isFieldTouched && Boolean(currentError)}
      helperText={isFieldTouched && currentError}
    />
  );
};

NumberField.propTypes = {
  decimalScale: PropTypes.number,
  inputRef: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

NumberField.defaultProps = {
  inputRef: null,
  label: '',
};

export default NumberField;
