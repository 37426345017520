import { isEmpty, isObject, omitBy, reduce } from 'lodash';

const activeFiltersReducer = (acc, value, key) => acc + getFilterCount({ value, key });
const getFilterCount = ({ value, key }) => {
  // We want to count each identifiers & dimensions array object as 1 filter
  switch (key) {
    case 'identifiers':
    case 'weightAndDimensions':
      return Array.isArray(value) ? value.length : 0;
    // To count receptionFromDate and receptionToDate as 1 filter
    case 'receptionDateFrom':
    case 'effectiveDateFrom':
      return 0;
    default:
      return 1;
  }
};
export const getActiveFiltersCount = (filters) =>
  reduce(
    omitBy(filters, (x) => x === undefined || x === null || x === '' || x === false || ((Array.isArray(x) || isObject(x)) && isEmpty(x))),
    activeFiltersReducer,
    0
  );

export default getActiveFiltersCount;
