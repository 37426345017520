const identifiers = {
  barcode: 'Code barre',
  billOfLading: 'Connaissement',
  booking: 'Réservation',
  description: 'Description',
  heatNumber: 'Numéro de coulée',
  identifiers: 'Identifiants',
  lotNumber: 'Numéro de lot',
  mark: 'Marque',
  poNumber: 'Bon de commande',
  scope: 'Portée',
  serialNumber: 'Numéro de série',
  other: 'Autre',
};

export default identifiers;
