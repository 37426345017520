import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export const secondaryMain = '#8E7F58';
export const primaryMain = '#002144';
export const primaryLight = '#F2F5F7';

const theme = createTheme({
  overrides: {
    MuiTypography: {
      h1: {
        fontWeight: 600,
        fontSize: '1.875rem',
        lineHeight: '2.3125rem',
      },
      h2: {
        fontSize: '1.125rem',
        lineHeight: '1.375rem',
        fontWeight: 500,
      },
      h3: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        fontWeight: 500,
      },
      h4: {
        fontWeight: 600,
        fontSize: '1.575rem',
        lineHeight: '2.0000rem',
      },
    },
    MuiList: {
      padding: {
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: '0.875rem',
        fontWeight: 500,
      },
    },
    MuiMenuItem: { root: { backgroundColor: 'white' } },
    MuiPopover: {
      root: {
        marginTop: 40,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: primaryLight,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiChip: {
      root: {
        height: '1.5rem',
      },
      label: {
        fontSize: '10px',
        fontWeight: 600,
        paddingLeft: '0.625rem',
        paddingRight: '0.625rem',
      },
    },
  },
  palette: {
    primary: {
      main: primaryMain,
      light: primaryLight,
    },
    secondary: {
      main: secondaryMain,
    },
    background: {
      default: '#FFF',
    },
    text: {
      secondary: '#485465',
    },
  },
  typography: {
    fontFamily: ['Proxima Nova', 'sans-serif'].join(','),
  },
});

const CssTemplate = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

CssTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node]).isRequired,
};

export default CssTemplate;
