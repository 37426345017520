import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { ListPageWithPersisted, navigateTo } from '../../../../layout/ListPage';
import { ListPageHeader } from '../../../../layout/ListPage/headers';
import useGetOptions from '../../breakbulk/useGetOptions';
import getColumns from './columns';
import useGetContainers from './useGetContainers';
import useGetContainedItemsPills from './useGetContainersPills';

const ContainersPage = () => {
  const { itemId, inventoryId } = useParams();
  const [fetchItems, data = {}] = useGetContainers({ inventoryId, itemId });
  const [
    getOptions,
    options = {
      containerStatuses: [],
      packagingOptions: [],
      clientInventories: [],
      productTypes: [],
      productSubtypes: [],
      originalVesselProjects: [],
      sites: [],
    },
  ] = useGetOptions();
  const { t } = useTranslation('inventory');
  const { push } = useHistory();
  const { path } = useRouteMatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getOptions(), []);

  const columns = useMemo(() => {
    const translate = (prefix) => (str) => ({ key: str, value: str, label: t(`${prefix}.${str}`) });
    const fromString = (str) => ({ key: str, value: str, label: str });
    const translatedOption = {
      clientInventories: options.clientInventories.map(fromString),
      productTypes: options.productTypes.map(fromString),
      productSubtypes: options.productSubtypes.map(fromString),
      originalVesselProjects: options.originalVesselProjects.map(fromString),
      containerStatuses: options.containerStatuses.map(translate('containerStatuses')),
      packagingOptions: options.packagingOptions.map(translate('packagings')),
      sites: options.sites.map(fromString),
    };

    return getColumns(translatedOption);
  }, [options, t]);
  return (
    <>
      <div className="pb-4 flex justify-between">
        <h1 className="text-lg inline-block">{t('item.containers')}</h1>
      </div>
      <ListPageWithPersisted
        persistedKey={path}
        header={ListPageHeader}
        fetcher={fetchItems}
        data={data}
        columns={columns}
        translater={t}
        onRowClick={navigateTo((row) => `/inventory/${row.clientInventory.id}/items/${row.id}`, push)}
        pills={useGetContainedItemsPills(data, t)}
        headerProps={{}}
      />
    </>
  );
};

ContainersPage.propTypes = {};

export default ContainersPage;
