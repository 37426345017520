import { i18nChangeLanguage } from '@vooban/datagrid';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import useAgent from '../hooks/useAgent';
import { lngLocalStorageKey } from '../i18n';
import moment from 'moment';

const url = '/api/v1/user-preference';

const toUrl = `${url}/language`;

const changeLanguage = (language) => axios.patch(toUrl, { language });

const useChangeLanguage = () => {
  const { i18n } = useTranslation();

  return useAgent((lng) => {
    changeLanguage(lng);
    axios.defaults.headers['Accept-Language'] = lng;
    i18n.changeLanguage(lng);
    i18nChangeLanguage(lng);
    window.localStorage.setItem(lngLocalStorageKey, lng);

    moment.updateLocale(lng.substring(0, 2), {
      week: {
        dow: 0, // First day of week is Sunday
        doy: 6, // First week of year must contain 1 January (7 + 0 - 1)
      },
    });
  });
};

export default useChangeLanguage;
