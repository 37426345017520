import withPersistedColumns from './hoc/withPersistedColumns';
import withPersistedParameters from './hoc/withPersistedParameters';
import { default as _ListPage } from './ListPage';
import { default as _StaticListPage } from './StaticListPage';

export const ListPageWithPersisted = withPersistedColumns(withPersistedParameters(_ListPage));
export const ListPage = _ListPage;
export * from './helper';

export const StaticListPageWithPersisted = withPersistedColumns(withPersistedParameters(_StaticListPage));
export const StaticListPage = _StaticListPage;
