import { TagInputFilter } from '@vooban/datagrid';
import { formatStringArrayToColumn } from '../../common/helpers/list/format';
import { transactionStatusEnum } from '../../constant';
import columnsSetting from '../../layout/Datagrid/ColumnSetting';
import { getStatusPill } from '../helpers/listHelpers';

const inventoryFilter = (mainInventories) => ({ component: TagInputFilter, keyFilter: 'mainInventory', options: mainInventories });

const formatType = (type, { isPartialConfirmation }, t) => (isPartialConfirmation ? `${type} (${t('partiallyConfirmed')})` : type);

const getColumns = (
  {
    mainInventories = [],
    actionTypes = [],
    productTypes = [],
    productSubtypes = [],
    sites = [],
    otherInventories = [],
    projects = [],
    receivers = [],
    mainOrders = [],
    otherOrders = [],
    statuses = [],
  },
  t
) => [
  columnsSetting('site.name').withSortKey('site').withFilter({ component: TagInputFilter, keyFilter: 'site', options: sites }).withIsDefault(),
  columnsSetting('actionTypeTranslatedValue')
    .withSortKey('actionType')
    .withFilter({ component: TagInputFilter, keyFilter: 'actionType', options: actionTypes })
    .withCustomFormat(formatType)
    .withIsDefault(),
  columnsSetting('number').withIsDefault(),
  columnsSetting('effectiveDate').withFormatDate().withIsDefault(),
  columnsSetting('carrier').withIsDefault(),
  columnsSetting('carrierBill'),
  columnsSetting('transportationNumber').withColName('vehicleIdentifier').withIsDefault(),
  columnsSetting('mainInventoriesNames')
    .withSortKey('mainInventory')
    .withFilter(inventoryFilter(mainInventories))
    .withCustomFormat(formatStringArrayToColumn)
    .withIsDefault(),
  columnsSetting('mainOrdersNames')
    .withCustomFormat(formatStringArrayToColumn)
    .withSortKey('mainOrder')
    .withFilter({ component: TagInputFilter, keyFilter: 'mainOrder', options: mainOrders })
    .withIsDefault(),
  columnsSetting('otherInventoriesNames').withCustomFormat(formatStringArrayToColumn).withSortKey('otherInventory').withFilter({
    component: TagInputFilter,
    keyFilter: 'otherInventory',
    options: otherInventories,
  }),
  columnsSetting('otherOrdersNames')
    .withCustomFormat(formatStringArrayToColumn)
    .withFilter({ component: TagInputFilter, keyFilter: 'otherOrder', options: otherOrders })
    .withSortKey('otherOrder'),
  columnsSetting('receiver').withSortKey('destination').withFilter({ component: TagInputFilter, options: receivers }).withIsDefault(),
  columnsSetting('productTypeNames')
    .withSortKey('productType')
    .withCustomFormat(formatStringArrayToColumn)
    .withFilter({ component: TagInputFilter, keyFilter: 'productType', options: productTypes })
    .withIsDefault(),
  columnsSetting('productSubtypeNames')
    .withSortKey('productSubtype')
    .withCustomFormat(formatStringArrayToColumn)
    .withFilter({ component: TagInputFilter, keyFilter: 'productSubtype', options: productSubtypes })
    .withIsDefault(),
  columnsSetting('quantity')
    .withCustomFormat((quantity, row) => (row.status === transactionStatusEnum.Confirmed ? quantity : ''))
    .withIsDefault(),
  columnsSetting('projectNumberAndName').withSortKey('projectNumber').withFilter({ component: TagInputFilter, keyFilter: 'project', options: projects }),
  columnsSetting('appointment'),
  columnsSetting('containerIdentifier'),
  columnsSetting('remarks'),
  columnsSetting('status')
    .withoutSort()
    .withFilter({ component: TagInputFilter, keyFilter: 'status', options: statuses })
    .withCustomFormat((status, row) => getStatusPill(status, row, t))
    .withIsDefault(),
];

export default getColumns;
