import { CARGO_TYPE } from '../../cargoType';
import useAgent from '../../hooks/useAgent';
import { getTransactions } from '../hooks/useGetTransactions';

const useGetBulkTransactions = () =>
  useAgent((params) => {
    params.set('cargoType', CARGO_TYPE.bulk);
    return getTransactions(params);
  });

export default useGetBulkTransactions;
