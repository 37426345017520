import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';

const accordionStyles = makeStyles(() => ({
  root: {
    '&:before': {
      height: 0,
    },
    boxShadow: 'none',
    width: '100%',
    backgroundColor: 'transparent',
    expanded: {
      margin: 0,
    },
  },
}));

const accordionSummaryStyles = makeStyles(() => ({
  root: {
    padding: 0,
    width: 'fit-content',
  },
}));

const accordionDetailsStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
}));

const Collapsible = ({ children, title, titleBadge }) => (
  <Accordion classes={accordionStyles()}>
    <AccordionSummary classes={accordionSummaryStyles()} expandIcon={<ExpandMoreIcon color="primary" fontSize="small" />}>
      <Typography color="primary" variant="body2">
        {title}
      </Typography>
      {titleBadge && <div className="ml-2">{titleBadge}</div>}
    </AccordionSummary>
    <AccordionDetails classes={accordionDetailsStyles()}>{children}</AccordionDetails>
  </Accordion>
);

Collapsible.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.func, PropTypes.object]),
  title: PropTypes.string.isRequired,
  titleBadge: PropTypes.node,
};

Collapsible.defaultProps = {
  titleBadge: undefined,
};

export default Collapsible;
