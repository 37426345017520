import PropTypes from 'prop-types';

export const StorageFacilityIds = { id: PropTypes.integer, areaIds: PropTypes.arrayOf(PropTypes.string) };

export const StorageFacility = {
  id: PropTypes.number,
  name: PropTypes.string,
};

export default StorageFacility;
