import { ListPageWithPersisted } from '../../layout/ListPage';
import ListPageHeaderWithDateFilter from '../../layout/ListPage/headers/ListPageHeaderWithDateFilter';
import useGetAppointments from './useGetAppointments';
import useGetAppointmentDocument from './useGetAppointmentDocument';
import { Component, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import getColumns from './Columns';
import AppointmentListActions from './AppointmentListActions';
import { statusesByKey } from '../../traffic/dataTypes';
import { ActionPropTypes } from '../../layout/Datagrid';

const excludePendingAndUnscheduledAppointmentsSearchParams = new URLSearchParams(
  `excludeStatus=${statusesByKey.Pending}&excludeStatus=${statusesByKey.Unscheduled}`
);

const AppointmentList = ({ options, actions, sites, tabs }) => {
  const { t } = useTranslation('appointment');
  const [fetchAppointments, data = {}] = useGetAppointments(excludePendingAndUnscheduledAppointmentsSearchParams);
  const [fetchDocument] = useGetAppointmentDocument();

  const columns = useMemo(() => {
    const fromString = (str) => ({ key: str, value: str, label: str });
    const mappedOptions = {
      sites: options.sites.map(fromString),
      actionTypes: options.actionTypes.map(fromString),
      cargoTypes: options.cargoTypes.map(fromString),
      transportationTypes: options.transportationTypes.map(fromString),
      carrierNames: options.carrierNames.map(fromString),
      status: options.status.map(fromString).filter(({ key }) => key !== statusesByKey.Unscheduled),
      trailerTypes: options.trailerTypes,
      supplierBookings: options.supplierBookings.map(fromString),
      parkings: options.parkings.map(fromString),
      hazardousMaterials: [{ key: 'hazardous', label: t('hazardous'), value: 'true' }],
      productTypes: options.productTypes.map(fromString),
      productSubtypes: options.productSubtypes.map(fromString),
      receiverNames: options.receiverNames.map(fromString),
      inventoryNames: options.inventoryNames.map(fromString),
      orderNames: options.orderNames.map(fromString),
      bookers: options.bookers.map(fromString),
    };

    return getColumns(mappedOptions, sites, fetchDocument, t);
  }, [options, sites, fetchDocument, t]);

  return (
    <ListPageWithPersisted
      header={ListPageHeaderWithDateFilter}
      title={t('appointments')}
      fetcher={fetchAppointments}
      data={data}
      columns={columns}
      noRowLink
      translater={t}
      actions={actions}
      headerProps={{
        actions: <AppointmentListActions totalCount={data?.totalItems} />,
        subscript: tabs,
      }}
    />
  );
};

AppointmentList.propTypes = {
  options: PropTypes.object.isRequired,
  actions: PropTypes.arrayOf(ActionPropTypes),
  sites: PropTypes.arrayOf(PropTypes.any).isRequired,
  tabs: PropTypes.shape(Component).isRequired,
};

export default AppointmentList;
