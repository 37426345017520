import { formatTimePeriodUsingSiteTimezone } from './timeSlotHelper';
import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const formatAppointmentsForExcel = (appointments, advancedSiteInfos, t) => {
  const getTranslation = (key, value) => {
    if (!value) return null;
    return t(`${key}.${value}`);
  };

  return appointments.map((appointment) => {
    const { carrierInformation, dropDetail = {}, pickDestinations = [], transportation = {} } = appointment;

    return {
      ...appointment,
      startDate: appointment.startDate ? moment(appointment.startDate).format('YYYY-MM-DD') : '',
      timeSlot: formatTimePeriodUsingSiteTimezone(appointment, advancedSiteInfos),
      siteName: appointment.site.name,
      actionType: getTranslation('actionType', appointment.actionType),
      transportationType: getTranslation('transportationType', appointment.transportationType),
      cargoType: appointment.cargoType,
      inventories: appointment.calculatedClientInventoryNames.join(', '),
      orders: appointment.calculatedOrderNames.join(', '),
      quantities: appointment.quantities.join(', '),
      receivers: appointment.calculatedReceiverNames.join(', '),
      productTypes: appointment.productTypes.join(', '),
      productSubtypes: appointment.productSubtypes.join(', '),
      hazardousMaterial: appointment.calculatedHasHazardousMaterial
        ? getTranslation('hazardousMaterialExcelValues', 'yes')
        : getTranslation('hazardousMaterialExcelValues', 'no'),
      status: getTranslation('statuses', appointment.status),
      carrierInformation: {
        ...carrierInformation,
        trailerType: getTranslation('trailerTypes', carrierInformation.trailerType),
      },
      dropDetail: {
        ...dropDetail,
      },
      pickDestinations: pickDestinations.map((x) => ({
        ...x,
      })),
      transportation: {
        ...transportation,
      },
    };
  });
};
