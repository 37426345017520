import axios from 'axios';
import { useTranslation } from 'react-i18next';
import download from '../../common/helpers/download';
import headers from '../../common/helpers/headers';
import useAgent, { PUSH_NOTIFICATION } from '../../hooks/useAgent';
import { appointmentDocumentUrl as documentUrl } from '../urls';

const getAppointmentDocument = async (id, actionType, filename) => {
  const response = await axios.get(documentUrl, { params: { actionType, romSomNumber: id }, responseType: 'blob' });
  const filenameFromHeader = headers.getFilename(response.headers);
  // const fileBlob = new Blob([response.data], { type: 'application/pdf' });
  return download(response.data, filenameFromHeader || filename);
};

const useGetAppointmentDocument = () => {
  const { t } = useTranslation('transaction');
  return useAgent(getAppointmentDocument, { errorStatusToAction: { 404: { type: PUSH_NOTIFICATION, payload: t('error.missingDocument') } } });
};

export default useGetAppointmentDocument;
