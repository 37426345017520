import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import CargoTypeListToggler from '../../../cargoType/CargoTypeListToggler';
import { ActionPropTypes } from '../../../layout/Datagrid';
import { ListPageWithPersisted, navigateTo } from '../../../layout/ListPage';
import { ListPageHeaderWithSearch } from '../../../layout/ListPage/headers';
import LoadingIndicator from '../../../layout/loading/LoadingIndicator';
import BreakbulkListActions from './BreakbulkListActions';
import getColumns from './columns';
import ItemsAdvancedFilterSection from './ItemsAdvancedFilterSection';
import useGetBreakbulkListPills from './useGetBreakbulkListPills';
import useGetItems from './useGetItems';
import useGetOptions from './useGetOptions';

const BreakbulkItemListPage = (props) => {
  const [fetchItems, data = {}, isLoading] = useGetItems();
  const [
    getOptions,
    options = {
      containerStatuses: [],
      packagingOptions: [],
      clientInventories: [],
      productTypes: [],
      productSubtypes: [],
      originalVesselProjects: [],
      sites: [],
      orders: [],
    },
  ] = useGetOptions();
  const { t } = useTranslation('inventory');
  const { push } = useHistory();
  const location = useLocation();

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const listType = localStorage.getItem('listType');
    if (!listType && location.search === '' && data?.items?.length === 0) {
      localStorage.setItem('listType', 'bulk');
      push('/inventory/items/bulk');
    }
  }, [data, location.search, push]);

  const columns = useMemo(() => {
    const translate = (prefix) => (str) => ({ key: str, value: str, label: t(`${prefix}.${str}`) });
    const fromString = (str) => ({ key: str, value: str, label: str });
    const translatedOption = {
      clientInventories: options.clientInventories.map(fromString),
      productTypes: options.productTypes.map(fromString),
      productSubtypes: options.productSubtypes.map(fromString),
      originalVesselProjects: options.originalVesselProjects.map(fromString),
      containerStatuses: options.containerStatuses.map(translate('containerStatuses')),
      packagingOptions: options.packagingOptions.map(translate('packagings')),
      sites: options.sites.map(fromString),
      orders: options.orders.map(fromString),
      t,
    };

    return getColumns(translatedOption);
  }, [options, t]);

  return (
    <>
      <LoadingIndicator show={isLoading} />
      <ListPageWithPersisted
        header={ListPageHeaderWithSearch}
        title={t('inventory')}
        fetcher={fetchItems}
        data={data}
        columns={columns}
        translater={t}
        onRowClick={navigateTo((row) => `/inventory/${row.clientInventory.id}/items/${row.id}`, push)}
        pills={useGetBreakbulkListPills(data, t)}
        actions={props.actions}
        headerProps={{
          actions: <BreakbulkListActions totalCount={data?.totalItems} />,
          advancedFilterSection: ItemsAdvancedFilterSection,
          subscript: <CargoTypeListToggler />,
        }}
      />
    </>
  );
};

BreakbulkItemListPage.propTypes = {
  actions: PropTypes.arrayOf(ActionPropTypes),
};

BreakbulkItemListPage.defaultProps = {
  actions: [],
};

export default BreakbulkItemListPage;
