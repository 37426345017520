import { Popover, Checkbox, FormControlLabel, Grid, Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { InputDatePicker } from '@vooban/inputs';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import moment from 'moment';
import { useEffect } from 'react';
import { englishLocalesCode, lngLocalStorageKey } from '../../../../i18n';

const DatePopOver = ({ open, handleClose, anchorEl, initialStartDate, initialEndDate, onDatesChange }) => {
  const { t } = useTranslation('layout');
  const lng = window.localStorage.getItem(lngLocalStorageKey) || englishLocalesCode;

  useEffect(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const today = () => moment().local().format('YYYY-MM-DD');

  const handleStartDateChange = (startDate, e) => {
    setStartDate(startDate ?? null);
  };

  const handleEndDateChange = (endDate) => {
    setEndDate(endDate ?? null);
  };

  const onNoSpecificEndDate = ({ currentTarget: { checked } }) => {
    setEndDate(checked ? null : today());
  };

  const onNoSpecificStartDate = ({ currentTarget: { checked } }) => {
    setStartDate(checked ? null : today());
  };

  const toUtc = (date) => moment(date).utc().format();

  const handleApply = () => {
    if (startDate === initialStartDate && endDate === initialEndDate) {
      handleClose();
      return;
    }
    const startDateValue = startDate ? toUtc(startDate) : null;
    const endDateValue = endDate ? toUtc(endDate) : null;
    onDatesChange({ isRangeMode: true, startDateValue, endDateValue });
    handleClose();
  };

  const startDateValue = moment.isMoment(startDate) && moment(startDate).isValid() ? startDate.format() : startDate;

  const endDateValue = moment.isMoment(endDate) && moment(endDate).isValid() ? endDate.format() : endDate;

  const handleDoubleSelectionToSingleDate = (compareTo) => (date) => {
    if (date && moment(compareTo).toDate().valueOf() === date.valueOf()) {
      setStartDate(date);
      setEndDate(date);
    }
  };

  const useStyle = makeStyles({
    datePickerBox: {
      display: 'flex',
      flexDirection: 'column',
    },
    checkBoxMargin: {
      marginLeft: '1rem',
    },
    gridContainer: {
      padding: '0.5rem',
      background: 'white',
    },
  });

  const classes = useStyle();

  return (
    <Popover
      open={open}
      onClose={handleApply}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      <Grid container className={classes.gridContainer}>
        <Grid container>
          <Box className={classes.datePickerBox}>
            <InputDatePicker
              name="startDate"
              onChange={handleStartDateChange}
              className="datepicker__popdown"
              value={startDateValue}
              onSelect={handleDoubleSelectionToSingleDate(startDateValue)}
              endDate={endDateValue}
              inline
              locale={lng}
            />
            <Box className={classes.checkBoxMargin}>
              <FormControlLabel
                control={<Checkbox checked={startDate === null} onChange={onNoSpecificStartDate} name="noSpecificStartDate" />}
                label={t('button.noSpecificStartDate')}
              />
            </Box>
          </Box>
          <Box className={classes.datePickerBox}>
            <InputDatePicker
              name="endDate"
              onChange={handleEndDateChange}
              value={endDateValue}
              startDate={startDateValue}
              onSelect={handleDoubleSelectionToSingleDate(endDateValue)}
              inline
              className="datepicker__popdown"
              inputDefaultValue={endDateValue || startDateValue}
              locale={lng}
            />
            <Box className={classes.checkBoxMargin}>
              <FormControlLabel
                control={<Checkbox checked={endDate === null} onChange={onNoSpecificEndDate} name="noSpecificEndDate" />}
                label={t('button.noSpecificEndDate')}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Popover>
  );
};

DatePopOver.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
  changeModeDisabled: PropTypes.bool,
  initialStartDate: PropTypes.shape(Date),
  initialEndDate: PropTypes.shape(Date),
  onDatesChange: PropTypes.func.isRequired,
};

DatePopOver.defaultProps = {
  changeModeDisabled: false,
  anchorEl: undefined,
};

export default DatePopOver;
