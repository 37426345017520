import PropTypes from 'prop-types';

const ActionPropTypes = PropTypes.shape({
  key: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  icon: PropTypes.string,
  onClick: PropTypes.func,
  renderWhen: PropTypes.func,
});

export default ActionPropTypes;
