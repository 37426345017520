import moment from 'moment';
import React from 'react';

export const useSecondsPassed = (date) => {
  const [seconds, setSeconds] = React.useState(moment().diff(date, 's'));

  React.useEffect(() => {
    const interval = setTimeout(() => setSeconds(moment().diff(date, 's')), 1000);
    return () => clearInterval(interval);
  }, [seconds, date]);

  return seconds;
};
