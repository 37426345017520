import identifiers from '../Identifiers/__i18n__/en';
import list from '../ListPage/__i18n__/en';

const listHeader = {
  clearFilters: 'Clear Filters',
  count: 'Count',
  search: 'Search',
};

const listContextMenu = {
  open: 'Open',
  openInNewTab: 'Open in new tab',
};

const header = {
  backToList: 'Back to List',
};

const validation = {
  invalidValue: 'Invalid value',
  required: 'Field required',
};

const common = {
  all: 'All',
};

const errorMessage = {
  networkError: 'Cannot reach server. Please contact our technical support',
  noInternet: 'No internet',
  technicalError: 'A technical error has occurred. Please contact our technical support.',
  503: 'The requested operation could not be completed successfully. Please try again later or contact our technical support if the issue keeps occurring.',
};

const button = {
  noSpecificStartDate: 'No Specific Start Date',
  noSpecificEndDate: 'No Specific End Date',
};

const dropDownValues = {
  today: 'Today',
  thisWeek: 'This Week',
  thisMonth: 'This Month',
  allDates: 'All Dates',
  custom: 'Custom',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
};

const layout = {
  appointments: 'My Appointments',
  asCompany: 'As:',
  back: 'Back',
  clickHereToGoToTc3: 'Click here to go to TC3',
  close: 'Close',
  common,
  confirm: 'Confirm',
  english: 'English',
  errorMessage,
  french: 'Français',
  header,
  inventory: 'My Inventory',
  laborAvailabilityTab: 'Labor Availabilities',
  letUsHelpYou: 'Let Us Help You',
  listHeader,
  logout: 'Logout',
  maintenance: 'Maintenance',
  none: 'None',
  needAssistance: 'Need Assistance?',
  ohoh: 'Oh Oh',
  profile: 'My Profile',
  reception: 'Reception',
  reports: 'My Reports',
  shipment: 'Shipment',
  support: 'Support',
  supportPhoneNumber: 'Phone: {{phone}}',
  supportEmailAddress: 'Email: {{email}}',
  thisIsTheClientPortal: 'This is the Client Portal',
  traffic: 'Traffic',
  transaction: 'My Transactions',
  termsAndConditions: 'Terms And Conditions',
  userGuide: 'User Guide',
  validation,
  viewAs: 'View As',
  identifiers,
  listContextMenu,
  list: list,
  button,
  dropDownValues,
};

export default layout;
