import { Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

const OutputCheckbox = ({ checked, label, ...props }) => (
  <>
    <Checkbox checked={checked} disabled {...props} />
    <span>{label}</span>
  </>
);

OutputCheckbox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
};

OutputCheckbox.defaultProps = {
  checked: false,
  label: '',
};

export default OutputCheckbox;
