import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { toLocalDateTime } from '../../../../common/helpers/date';
import useModal from '../../../../hooks/useModal';
import LoadingIndicator from '../../../../layout/loading/LoadingIndicator';
import Output from '../../../../layout/Output';
import { Seal } from '../../types';
import { useGetItemSealHistory } from '../useGetItemSealHistory';

const useStyles = makeStyles((theme) => ({
  root: { margin: 0, padding: theme.spacing(2) },
  divider: { marginBottom: '1rem', marginTop: '1rem' },
}));

const useTitleStyles = makeStyles((theme) => ({
  root: { color: theme.palette.primary.main, padding: theme.spacing(2) },
}));

const DialogActionsStyles = makeStyles({
  root: {
    justifyContent: 'center',
  },
});

const SealRow = ({ i, t, seal, seals, classes }) => (
  <div>
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <Typography color="primary">{t(`item.seal.billable.${seal.isBillable}`)}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Output label={t('item.seal.billableInventory')} defaultValue={seal.billableInventoryName} />
      </Grid>
      <Grid item xs={6}>
        <Output label={t('item.seal.action')} defaultValue={seal.isSealAction ? t('item.seal.seal') : t('item.seal.unseal')} />
      </Grid>
      <Grid item xs={6}>
        <Output label={t('item.seal.sealNumber')} defaultValue={seal.number} />
      </Grid>
      <Grid item xs={6}>
        <Output label={t('item.seal.user')} defaultValue={seal.createdByUserName} />
      </Grid>
      <Grid item xs={6}>
        <Output label={t('item.seal.date')} defaultValue={toLocalDateTime(seal.creationDate)} />
      </Grid>
      <Grid item xs={12}>
        <Output label={t('item.seal.remark')} defaultValue={seal.remark} />
      </Grid>
      <Grid item xs={6}>
        <Output label={t('item.seal.by')} defaultValue={seal.remarkUpdatedBy} />
      </Grid>
    </Grid>
    {i < seals.length - 1 && <Divider className={classes.divider} />}
  </div>
);

SealRow.propTypes = {
  i: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  seal: PropTypes.shape(Seal).isRequired,
  seals: PropTypes.arrayOf(PropTypes.shape(Seal)).isRequired,
  classes: PropTypes.any.isRequired,
};

const SealHistoryModal = ({ inventoryId, itemId, onExited }) => {
  const { t } = useTranslation('inventory');
  const classes = useStyles();
  const titleClasses = useTitleStyles();
  const [getItemSealHistory, sealHistory, isLoading] = useGetItemSealHistory();
  const [seals, updateSeals] = useReducer((state, newSealHistory) => {
    if (!newSealHistory) return [];
    const inventories = newSealHistory.inventories.reduce((acc, inv) => {
      acc[inv.id] = inv;
      return acc;
    }, {});

    return newSealHistory.seals.map((seal) => ({
      ...seal,
      billableInventoryName: inventories[seal.billableInventoryId]?.name,
    }));
  }, []);
  const [isModalOpen, , closeModal] = useModal(true);
  useEffect(() => {
    if (!sealHistory && !isLoading) {
      getItemSealHistory(inventoryId, itemId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => updateSeals(sealHistory), [sealHistory]);

  return (
    <Dialog open={isModalOpen} onExited={onExited}>
      <DialogTitle onClose={closeModal} classes={titleClasses}>
        {t('item.seal.sealContainer')}
      </DialogTitle>
      <DialogContent className={classes.root}>
        <div>
          <LoadingIndicator show={isLoading} />
          {!isLoading &&
            (!seals.length ? t('item.seal.noHistory') : seals.map((seal, i) => <SealRow key={i} i={i} t={t} seal={seal} seals={seals} classes={classes} />))}
        </div>
      </DialogContent>

      <DialogActions classes={DialogActionsStyles()}>
        <Button onClick={closeModal} color="primary" variant="contained">
          {t('item.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SealHistoryModal.propTypes = {
  inventoryId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  onExited: PropTypes.func,
};
export default SealHistoryModal;
