const itemMovementType = {
  Adjustment: 'Adjustment',
  MissingItemResolution: 'MissingItemResolution',
  Move: 'Move',
  OrderTransfer: 'OrderTransfer',
  ReceptionByBarge: 'ReceptionByBarge',
  ReceptionByOther: 'ReceptionByOther',
  ReceptionByRailcar: 'ReceptionByRailcar',
  ReceptionByTruck: 'ReceptionByTruck',
  ReceptionByVessel: 'ReceptionByVessel',
  Rom: 'ROM',
  ShipmentByBarge: 'ShipmentByBarge',
  ShipmentByOther: 'ShipmentByOther',
  ShipmentByRailcar: 'ShipmentByRailcar',
  ShipmentByTruck: 'ShipmentByTruck',
  ShipmentByVessel: 'ShipmentByVessel',
  Som: 'SOM',
  Stuff: 'Stuff',
  StuffForTransfer: 'StuffForTransfer',
  Transfer: 'Transfer',
  TransferredFromTC1: 'TransferredFromTC1',
  Unstuff: 'Unstuff',
  UnstuffForTransfer: 'UnstuffForTransfer',
};

export default itemMovementType;
