import axios from 'axios';
import download from '../common/helpers/download';
import useAgent from '../hooks/useAgent';

const url = (ressourceUrl, id) => `${ressourceUrl}/attachments/${id}/download`;

const getAttachment = async (ressourceUrl, id, filename) => {
  const response = await axios.get(url(ressourceUrl, id), { responseType: 'arraybuffer' });
  const fileBlob = new Blob([response.data]);

  return download(fileBlob, filename);
};

const useGetAttachment = () => useAgent(getAttachment);

export default useGetAttachment;
