/* eslint-disable import/prefer-default-export */
import { Box, Chip } from '@material-ui/core';
import Icon from '@vooban/icon';
import i18n from 'i18next';
import React from 'react';
import btrain from '../../common/icons/trailers/btrain.svg';
import drybox from '../../common/icons/trailers/drybox.svg';
import flat from '../../common/icons/trailers/flat.svg';
import flatCar from '../../common/icons/trailers/flatcar.svg';
import frame from '../../common/icons/trailers/frame.svg';
import gooseneck from '../../common/icons/trailers/gooseneck.svg';
import boxcar from '../../common/icons/trailers/boxcar.svg';
import bulkhead from '../../common/icons/trailers/bulkhead.svg';
import centerbeams from '../../common/icons/trailers/centerbeams.svg';
import curtainside from '../../common/icons/trailers/curtainside.svg';
import dropdeck from '../../common/icons/trailers/dropdeck.svg';
import enddump from '../../common/icons/trailers/enddump.svg';
import goldhofer from '../../common/icons/trailers/goldhofer.svg';
import gondola from '../../common/icons/trailers/gondola.svg';
import gondolaCovered from '../../common/icons/trailers/gondola_covered.svg';
import hopper from '../../common/icons/trailers/hopper.svg';
import liveBottom from '../../common/icons/trailers/livebottom.svg';
import tanker from '../../common/icons/trailers/tanker.svg';
import triAxle from '../../common/icons/trailers/triaxle.svg';
import TrailerIcon from '../../layout/icons/trailers/TrailerTypeIcon';

const iconMap = {
  Barge: 'barge',
  Breakbulk: 'breakbulk',
  BTrain: 'bTrain',
  Bulk: 'bulk',
  DryBox: 'dryBox',
  FlatBed: 'flatBed',
  Frame: 'frame',
  Gooseneck: 'gooseneck',
  Other: 'box',
  Railcar: 'railcar',
  Truck: 'truck',
  pick: 'pick',
  drop: 'drop',
  CurtainSide: 'curtainSide',
  DropDeck: 'dropDeck',
  EndDump: 'endDump',
  Goldhofer: 'goldhofer',
  HopperTruck: 'hopperTruck',
  HopperRailcar: 'hopperRailcar',
  LiveBottom: 'liveBottom',
  TriAxle: 'triAxle',
  Boxcars: 'boxcars',
  Bulkhead: 'bulkhead',
  CenterBeams: 'centerBeams',
  FlatCars: 'flatCars',
  GondolasOpen: 'gondolasOpen',
  GondolasCovered: 'gondolasCovered',
  Tanker: 'tanker',
};

export const getHazardousIcon = (isHazardous) => {
  if (isHazardous) {
    return (
      <div className="rdg-row-formatter badge -center">
        <span className="grid__tooltip badge -error">
          <Icon id="error" className="grid -error" />
        </span>
      </div>
    );
  }
  return <div />;
};

export const ActionTypeIcon = (value, t) => {
  if (value) {
    return (
      <span className="truck-graphic__label">
        <Icon id={value} />
        &nbsp;&nbsp;
        {t(`actionType.${value}`)}
      </span>
    );
  }
};

export const CargoTypeBadgeIcon = (type) => {
  if (type) {
    return <Chip label={<Icon id={iconMap[type]} className="grid -center" />} />;
  }
};

export const TrailerTypeIcon = (type) => {
  switch (type) {
    case iconMap.BTrain:
      return <TrailerIcon alt={i18n.t(iconMap.BTrain)} svg={btrain} />;
    case iconMap.FlatCars:
      return <TrailerIcon alt={i18n.t(iconMap.FlatCars)} svg={flatCar} />;
    case iconMap.DryBox:
      return <TrailerIcon alt={i18n.t(iconMap.DryBox)} svg={drybox} />;
    case iconMap.FlatBed:
      return <TrailerIcon alt={i18n.t(iconMap.FlatBed)} svg={flat} />;
    case iconMap.Frame:
      return <TrailerIcon alt={i18n.t(iconMap.Frame)} svg={frame} />;
    case iconMap.Gooseneck:
      return <TrailerIcon alt={i18n.t(iconMap.Gooseneck)} svg={gooseneck} />;
    case iconMap.Boxcars:
      return <TrailerIcon alt={i18n.t(iconMap.Boxcars)} svg={boxcar} />;
    case iconMap.Bulkhead:
      return <TrailerIcon alt={i18n.t(iconMap.Bulkhead)} svg={bulkhead} />;
    case iconMap.CenterBeams:
      return <TrailerIcon alt={i18n.t(iconMap.CenterBeams)} svg={centerbeams} />;
    case iconMap.CurtainSide:
      return <TrailerIcon alt={i18n.t(iconMap.CurtainSide)} svg={curtainside} />;
    case iconMap.DropDeck:
      return <TrailerIcon alt={i18n.t(iconMap.DropDeck)} svg={dropdeck} />;
    case iconMap.EndDump:
      return <TrailerIcon alt={i18n.t(iconMap.EndDump)} svg={enddump} />;
    case iconMap.Goldhofer:
      return <TrailerIcon alt={i18n.t(iconMap.Goldhofer)} svg={goldhofer} />;
    case iconMap.GondolasOpen:
      return <TrailerIcon alt={i18n.t(iconMap.GondolasOpen)} svg={gondola} />;
    case iconMap.GondolasCovered:
      return <TrailerIcon alt={i18n.t(iconMap.GondolasCovered)} svg={gondolaCovered} />;
    case iconMap.HopperTruck:
    case iconMap.HopperRailcar:
      return <TrailerIcon alt={i18n.t(type)} svg={hopper} />;
    case iconMap.LiveBottom:
      return <TrailerIcon alt={i18n.t(iconMap.LiveBottom)} svg={liveBottom} />;
    case iconMap.Tanker:
      return <TrailerIcon alt={i18n.t(iconMap.Tanker)} svg={tanker} />;
    case iconMap.TriAxle:
      return <TrailerIcon alt={i18n.t(iconMap.TriAxle)} svg={triAxle} />;
    default:
      return <Box className="badge -center">-</Box>;
  }
};
