import { frTranslation } from '@vooban/tc3-core';
import batchActionTypes from '../batchActionTypes/__i18n__/fr';
import item from '../item/__i18n__/fr';

const containerStatuses = {
  Empty: 'Vide',
  Stuffed: 'Rempli',
  Sealed: 'Scellé',
};

const packagings = {
  BarrelDrum: 'Baril / tambour',
  BigBag: 'Gros sac',
  Box: 'Boîtes',
  Crate: 'Caisse',
  Loose: 'En vrac',
  Pallet: 'Palette',
  StrappedBundle: 'Bundle attaché',
  Unit: 'Unité',
  Reel: 'Bobine',
  Tote: 'Fourre-tout',
};

const list = {
  actionNumber: "Numéro d'action",
  barcode: 'Code à barre',
  billOfLading: 'Connaissement',
  booking: 'Réservation',
  branch: 'Branche',
  availableQuantity: 'Quantité',
  bookQuantity: 'Quantité au livre (MT)',
  Breakbulk: 'Cargaisons générales',
  cargoType: 'Type de cargaisons',
  clientInventory: {
    name: 'Inventaire client',
    site: {
      name: 'Site',
    },
  },
  containerStatus: 'Statut de conteneur',
  customerRelease: {
    released: 'Relâche - Client',
  },
  customsRelease: {
    number: 'Relâche - Douanes',
  },
  descriptionLines: 'Lignes de description',
  dimension: 'Dimension',
  description: 'Description',
  diameter: 'Diamètre',
  effectiveDate: 'Date effective',
  financialRelease: {
    released: 'Relâche - Finances',
  },
  footPound: 'Pied livre',
  freightTon: 'Tonne fret',
  hazardousMaterialInformation: {
    class: 'Classe de danger',
    unNumber: 'UN',
    packagingGroup: 'PG',
  },
  heatNumber: 'Numéro de coulée',
  height: 'Hauteur',
  itemLocation: 'Location',
  itemNumber: "Numéro d'Item 1 & 2",
  length: 'Longueur',
  lineNumber: 'Ligne',
  location: 'Emplacement',
  locationHistory: 'Location History',
  lotNumber: 'Numéro de lot',
  mark: 'Marque',
  movedQuantity: 'Quantité déplacée',
  movementDescription: 'Mouvement',
  order: { name: 'Commande', receiver: { name: 'Destinataire' } },
  orderNumber: 'Bon de commande',
  originalVesselProject: 'Projet de navire',
  other: 'Autre',
  originalReceptionInformation: {
    appointmentNumber: 'Numéro de rendez-vous',
    receptionNumber: 'Numéro de réception',
    receptionDate: 'Date de réception',
  },
  packaging: "Type d'emballage",
  priority: 'Priorité',
  productType: {
    name: 'Type de produit',
  },
  productSubtypeName: 'Sous-type de produit',
  poNumber: 'Bon de commande',
  quantity: 'Quantité',
  quantityPerPackage: 'Qté/pqt',
  remark: 'Remarque',
  receivedQuantity: 'Quantité reçue',
  receiver: {
    name: 'Destinataire',
  },
  scope: 'Portée',
  sealNumbers: 'Numéro de sceau',
  serialNumber: 'Numéro de série',
  shippingCondition: "Condition d'expédition",
  shippingLineRelease: {
    released: 'Relâche - Ligne maritime',
  },
  stuffedIn: 'Conteneurisé dans',
  stuffedQuantity: 'Quantité conteneurisée',
  surfaceAreaPerUnit: 'Superficie',
  thickness: 'Épaisseur',
  timestamp: 'Horodatage',
  totalQuantity: 'Quantité au livre',
  totalVolume: 'Volume total',
  totalWeight: 'Poids total',
  username: 'Utilisateur',
  volumePerUnit: 'Volume par unité',
  volumePerUnitInCubicMeters: 'Volume par unité (m³)',
  weightHasBeenVerified: 'Pesé',
  weightPerUnit: 'Poids par unité',
  weightPerUnitInTons: 'Poids par unité (MT)',
  weight: 'Poids',
  width: 'Largeur',
  containerStatuses,
  packagings,
};

const inventory = {
  attachments: 'Pièces jointes',
  batchActionTypes,
  exportToExcel: 'Exporter vers Excel',
  inventory: 'Inventaire',
  inventoryItem: "Item d'inventaire {{itemId}}",
  item,
  noResultToExportMessage: "Il n'y a aucun résultat à exporter.",
  viewAttachments: 'Consulter les pièces jointes',
  viewMediaFile: 'Consulter les fichiers multimédias',
  mediaFiles: 'Fichiers multimédias',
  ...frTranslation,
  containerStatuses,
  packagings,
  list,
  tooManyResultsMessage: 'Vous ne pouvez pas avoir plus de {{number}} résultats de recherche. Veuillez raffiner votre recherche.',
  title_Breakbulk: 'Item {{id}}',
  title_Bulk: 'Pile {{id}}',
};

export default inventory;
