import { AppBar, Button, Drawer, Slide } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Navigation from './Navigation';
import Profile from './Profile';
import useDrawerToggler from './useDrawerToggler';
import useProfileToggler from './useProfileToggler';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { top: 'auto', bottom: '0', boxShadow: 'none', backgroundColor: 'transparent' },
    buttonRoot: {
      backgroundColor: theme.palette.secondary.main,
      width: '48px',
      height: '48px',
      borderRadius: '50%',
      padding: '0',
      minWidth: '48px',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    buttonLabel: { width: 'auto' },
    drawer: { bottom: '48px' },
    drawerPapper: { boxShadow: 'none' },
    drawerBackground: { backgroundColor: 'transparent' },
    drawerContent: { backgroundColor: theme.palette.secondary.main, paddingBottom: '40px' },
    gridItem: {
      display: 'flex',
      justifyContent: 'center',
      '&:nth-child(3n)': {
        justifyContent: 'right',
        paddingRight: '10%',
      },
      '&:nth-child(3n-2)': {
        justifyContent: 'left',
        paddingLeft: '10%',
      },
    },
    homeButton: {
      background: `linear-gradient(transparent 50%,${theme.palette.secondary.main} 50%)`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

const FooterMobile = ({ options, rotate }) => {
  const classes = useStyles();
  const [isDrawerOpen, toggleDrawer] = useDrawerToggler();
  const [isProfileOpen, openProfile, closeProfile] = useProfileToggler();

  const anchor = 'bottom';

  const onClose = useCallback(
    (event) => {
      toggleDrawer(event);
      closeProfile();
    },
    [toggleDrawer, closeProfile]
  );

  return (
    <AppBar position="fixed" classes={{ root: classes.root }}>
      <Slide direction="up" in={!isDrawerOpen} mountOnEnter unmountOnExit>
        <div className={classes.homeButton}>
          <Button onClick={toggleDrawer} classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}>
            <HomeIcon style={{ color: '#fff', fontSize: 24 }} />
          </Button>
        </div>
      </Slide>

      <Drawer
        anchor={anchor}
        open={isDrawerOpen}
        onClose={onClose}
        classes={{ root: classes.drawer, paper: classes.drawerPapper }}
        className={rotate ? 'worker-mobile -landscape-rotate' : null}
        ModalProps={{ BackdropProps: { classes: { root: classes.drawerBackground } } }}>
        {isProfileOpen ? (
          <Profile classes={classes} onBack={closeProfile} />
        ) : (
          <Navigation openProfile={openProfile} toggleDrawer={toggleDrawer} classes={classes} options={options} />
        )}
      </Drawer>
    </AppBar>
  );
};

FooterMobile.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      component: PropTypes.func,
      i18nKey: PropTypes.string,
      icon: PropTypes.object,
      path: PropTypes.string,
    })
  ),
  rotate: PropTypes.bool,
};

export default FooterMobile;
