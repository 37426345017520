import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberWithAccounting } from '../../common/helpers/numbers';
import DataGrid from '../Datagrid/Datagrid';
import useVisibleColumns from '../hooks/useVisibleColumns';
import Toast from './Toast';

const changeVisibility = (key, visible) => (col) => {
  if (col.key === key) return col.withVisible(visible);
  return col;
};

const createMenuAction = (action) => ({
  ...action,
  onClick: (_, { row }) => action.onClick(row),
});

const ListPage = ({
  actions,
  checkable,
  columnSettings,
  columns,
  data,
  fetcher,
  header,
  headerProps,
  onRowClick,
  noRowLink,
  pills,
  rowKey,
  searchParams,
  searchTake,
  setColumnSettings,
  setSearchParams,
  tabs,
  title,
  translater,
  rowIsNavigablePredicate,
}) => {
  const [visibleColumns, setVisibleColumns] = useVisibleColumns(columns);
  const [take, setTake] = useState(searchTake);

  const { t, i18n } = useTranslation();

  const [allData, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setData([]);
    fetcher(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    setData([...allData, ...(data?.items || [])]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const menuOptions = onRowClick
    ? [
        {
          text: t('listContextMenu.open'),
          className: '',
          icon: '',
          onClick: (event, row) => onRowClick(row, event),
          disableWhen: (row) => !rowIsNavigablePredicate(row),
        },
        {
          text: t('listContextMenu.openInNewTab'),
          className: '',
          icon: '',
          onClick: (event, row) => onRowClick(row, { ...event, ctrlKey: true }),
          disableWhen: (row) => !rowIsNavigablePredicate(row),
        },
      ]
    : [];

  const onParamChange = useCallback(
    (params) => {
      const takeFromList = +params.get('take');
      if (takeFromList !== take && takeFromList !== 0) {
        setTake(takeFromList);
        params.delete('take');
        setSearchParams(params.toString());
        params.set('take', searchTake);
        params.set('skip', takeFromList - searchTake);
      } else {
        params.delete('take');
        setSearchParams(params.toString());
        params.set('take', takeFromList === 0 ? take : takeFromList);
        setData([]);
      }

      fetcher(params);
    },
    [fetcher, searchTake, setSearchParams, take]
  );

  useEffect(() => {
    if (data?.totalItems !== undefined && data?.totalItems !== null) setTotalCount(data.totalItems);
  }, [data?.totalItems]);

  useEffect(() => {
    setVisibleColumns(columns);
  }, [columns, setVisibleColumns]);

  const handleColumnVisibilityChange = useCallback(
    (key, visible) => {
      setVisibleColumns(visibleColumns.map(changeVisibility(key, visible)));
    },
    [visibleColumns, setVisibleColumns]
  );

  const defaultColumns = useMemo(() => columns.filter((x) => x.isDefault), [columns]);

  const handleResetDefault = useCallback(() => {
    setVisibleColumns(
      visibleColumns.map((col) => {
        if (defaultColumns.some((x) => x.key === col.key)) return col.withIsVisible();
        return col.withIsInvisible();
      })
    );
  }, [defaultColumns, visibleColumns, setVisibleColumns]);

  const cols = useMemo(() => visibleColumns.map((x) => x.toColumns(translater)), [visibleColumns, translater]);

  searchParams.set('take', take);

  const countPill = `${t('listHeader.count')}: ${formatNumberWithAccounting(allData?.length || 0, 0)}/${formatNumberWithAccounting(totalCount, 0)}`;

  const menuActions = useMemo(() => actions.map(createMenuAction), [actions]);

  return (
    <>
      {React.createElement(header, {
        ...headerProps,
        title,
        onParamChange: onParamChange,
        pills: [countPill, ...pills],
      })}
      <DataGrid
        checkable={checkable}
        data={allData}
        columns={cols}
        defaultColumnKeys={defaultColumns.map((x) => x.key)}
        defaultColumnWidth={150}
        translater={translater}
        onRowClick={onRowClick}
        actions={actions}
        menuOptions={[...menuOptions, ...menuActions]}
        rowKey={rowKey}
        onResetDefault={handleResetDefault}
        hasLoadMore={data?.totalItems > allData.length}
        searchTake={searchTake}
        searchParams={searchParams}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        onSearchParamsChanged={onParamChange}
        columnSettings={columnSettings}
        setColumnSettings={setColumnSettings}
        setSearchParams={setSearchParams}
        noRowLink={noRowLink}
      />
      <Toast shouldOpen={data?.totalItems <= allData.length && data?.pageNumber > 1} />
    </>
  );
};

const configPropTypes = PropTypes.arrayOf(PropTypes.object);

ListPage.propTypes = {
  actions: PropTypes.array,
  checkable: PropTypes.bool,
  header: PropTypes.elementType,
  title: PropTypes.string,
  columns: configPropTypes.isRequired,
  fetcher: PropTypes.func.isRequired,
  data: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.any), totalItems: PropTypes.number, pageNumber: PropTypes.number }),
  onRowClick: PropTypes.func,
  translater: PropTypes.func.isRequired,
  searchTake: PropTypes.number,
  rowKey: PropTypes.string,
  columnSettings: PropTypes.any,
  setColumnSettings: PropTypes.func,
  setSearchParams: PropTypes.func,
  searchParams: PropTypes.any,
  pills: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  headerProps: PropTypes.object,
  rowIsNavigablePredicate: PropTypes.func,
  noRowLink: PropTypes.bool,
  tabs: PropTypes.node,
};

const EmptyComponent = () => <div />;

ListPage.defaultProps = {
  actions: [],
  checkable: false,
  title: '',
  header: EmptyComponent,
  onRowClick: undefined,
  noRowLink: false,
  setColumnSettings: () => undefined,
  setSearchParams: () => undefined,
  columnSettings: {},
  data: {},
  searchTake: 50,
  rowKey: 'id',
  pills: [],
  headerProps: {},
  rowIsNavigablePredicate: () => true,
  tabs: null,
};

export default ListPage;
