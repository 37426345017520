import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const LoadingIndicator = ({ show }) => {
  const classes = useStyles();
  return (
    <>
      <Backdrop className={classes.backdrop} open={show}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
LoadingIndicator.propTypes = {
  show: PropTypes.bool,
};

LoadingIndicator.defaultProps = {
  show: true,
};
export default LoadingIndicator;
