import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useErrorStore from './useErrorStore';
import useIsMounted from './useIsMounted';
import { actionHelpers } from '@vooban/helpers';

export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
export const PUSH_NOTIFICATION_FROM_RESPONSE = 'PUSH_NOTIFICATION_FROM_RESPONSE';
export const REDIRECT = 'REDIRECT';
const statusCode503 = 503;

const errorErrorCodes = [statusCode503, actionHelpers.networkErrorCode, actionHelpers.noInternetErrorCode];

const errorHandler = (error, dispatcher, errorStatusToAction = {}, t) => {
  if (error && error.response) {
    const status = error.response.status;
    const action = errorStatusToAction[status];

    if (action) {
      if (action.type === PUSH_NOTIFICATION) dispatcher.setNotification(action.payload);
      else if (action.type === PUSH_NOTIFICATION_FROM_RESPONSE) {
        const errorData = error.response.data;

        if (errorData) {
          if (typeof errorData === 'string') dispatcher.setNotification(errorData);
          else if (errorData.errors) dispatcher.setNotification(Object.values(errorData.errors));
        }
      } else if (action.type === REDIRECT) dispatcher.redirect(action.payload);
    } else {
      const errorMessage = errorErrorCodes.includes(status) ? status : 'technicalError';
      dispatcher.setNotification(t(`errorMessage.${errorMessage}`));
    }

    // eslint-disable-next-line no-console
    console.warn(error.response);
  }

  throw error;
};

const defaultSuccessHandler = () => {};
const defaultErrorStatusToAction = {};

const useAgent = (
  agent,
  { errorStatusToAction, successHandler } = { errorStatusToAction: defaultErrorStatusToAction, successHandler: defaultSuccessHandler }
) => {
  const [state, setState] = useState({ result: undefined, isPending: false });
  const [, errorsActions] = useErrorStore();
  const { t } = useTranslation();
  const location = useHistory();

  const isMounted = useIsMounted();

  const call = useCallback(
    async (...params) => {
      setState({ isPending: true });
      try {
        const result = await agent(...params);
        const data = result && result.data ? result.data : result;
        if (isMounted()) {
          setState({ result: data, isPending: false });
        }

        successHandler && successHandler(result);
        return result;
      } catch (error) {
        if (isMounted()) {
          setState({ result: undefined, isPending: false });
        }
        errorHandler(error, { setNotification: errorsActions.setErrorMessage, redirect: location.push }, errorStatusToAction, t);

        throw error;
      }
    },
    [agent, isMounted, successHandler, errorsActions.setErrorMessage, location, errorStatusToAction, t]
  );

  return [call, state.result, state.isPending];
};

export default useAgent;
