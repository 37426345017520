import axios from 'axios';
import useAgent from '../../hooks/useAgent';
import { sitesUrl as url } from '../urls';

export const getSites = () => {
  return axios.get(url);
};

const useGetSites = () => useAgent(getSites);

export default useGetSites;
