const reports = {
  inventories: 'Inventaires',
  number: 'Numéro',
  name: 'Nom',
  site: 'Site',
  date: 'Date',
  receiver: 'Destinataire',
  bulk: 'Bulk',
  breakbulk: 'Breakbulk',
  generateReport: 'Générer le rapport',
  dateFrom: 'De',
  dateTo: 'À',
  tabs: {
    inventoryByDate: 'Inventaire par date',
    tailgate: 'Réceptions/expéditions',
  },
  noContentAvailable: 'La requête a été exécutée avec succès, toutefois, les valeurs entrées ont généré un rapport vide.',
};

export default reports;
