import { AppBar } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Container, makeStyles, useMediaQuery } from '@material-ui/core';
import Section from './layout/Section';
import Breakpoint from './layout/mediaQuery';
import ElevationScroll from './layout/navigation/ElevationScroll';
import Footer from './layout/Footer';

const AuthError = ({ title, description }) => {
  const containerStyle = (isTabletOrLarger) =>
    makeStyles({
      root: {
        marginLeft: isTabletOrLarger ? '0' : 'auto',
        paddingLeft: isTabletOrLarger ? '0' : '0.625rem',
        paddingRight: isTabletOrLarger ? '0' : '0.625rem',
        padding: 0,
        marginBottom: '48px',
      },
    });

  const useAppBarStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.default,
    },
  }));

  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);

  return (
    <>
      <ElevationScroll>
        <AppBar classes={useAppBarStyles()} position="sticky" className={`${isTabletOrLarger ? 'pl-14 pr-14' : ''}`}>
          <div className="flex justify-between items-center">
            <img src="/qsl-logo.jpeg" alt="qsl-logo" className="w-30 h-10" />
            <div className="h-20"></div>
          </div>
        </AppBar>
      </ElevationScroll>
      <div className={`min-h-screen flex flex-col ${isTabletOrLarger ? 'pl-14 pr-14' : 'pt-14'}`}>
        <Container classes={containerStyle(isTabletOrLarger)()} maxWidth={false}>
          <Section title={title}>{description}</Section>
        </Container>
      </div>
      <Footer />
    </>
  );
};

AuthError.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default AuthError;
