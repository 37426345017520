import { containerStatusEnum, shipmentStatusEnum, transactionStatusEnum } from '../../constant';
import identifiers from '../../layout/Identifiers/__i18n__/fr';

const list = {
  Adjustment: 'Ajustement',
  DimensionOrWeightModification: 'Modifications des dimensions et du poids',
  HazmatModification: 'Modifications des matières dangereuses',
  IdentifierModification: "Modification d'identifiant",
  MainInformationModification: 'Modification des informations générales',
  Move: 'Déplacer',
  MoveFrom: 'Déplacer de',
  MoveTo: 'Déplacer à',
  Merge: 'Regrouper',
  MergeFrom: 'Regrouper de',
  MergeTo: 'Regrouper à',
  MissingItemResolution: "Résolution d'items manquants",
  ReceptionByBarge: 'Réception par barge',
  ReceptionByContainer: 'Réception par conteneur',
  ReceptionByOther: 'Réception par autre',
  ReceptionByRailcar: 'Réception par train',
  ReceptionByTruck: 'Réception par camion',
  ReceptionByVessel: 'Réception par bateau',
  Relocate: 'Relocalisation',
  Seal: 'Sceller',
  ShipmentByBarge: 'Expédition par barge',
  ShipmentByOther: 'Expédition par autre',
  ShipmentByRailcar: 'Expédition par train',
  ShipmentByTruck: 'Expédition par camion',
  ShipmentByVessel: 'Expédition par bateau',
  Split: 'Diviser',
  SplitFrom: 'Diviser de',
  SplitTo: 'Diviser à',
  Stuff: 'Remplissage',
  Transfer: 'Transfert',
  TransferIn: 'Transfert (Entrée)',
  TransferOut: 'Transfert (Sortie)',
  TransferredIn: 'Transfert (Entrée)',
  TransferredOut: 'Transfert (Sortie)',
  Transform: 'Transformation',
  TransformFrom: 'Transformation de',
  TransformTo: 'Transformation à',
  Unseal: 'Désceller',
  Unstuff: 'Vidage',
  actionDate: "Date de l'action",
  actionType: 'Type',
  actionTypeTranslatedValue: 'Type',
  appointment: 'Rendez-vous',
  carrier: 'Transporteur',
  carrierBill: 'Bordereau du transporteur',
  containerIdentifier: 'Id Conteneur',
  createdByUserName: 'Utilisateur',
  effectiveDate: 'Date effective',
  headerActions: {
    excelFileName: 'Transactions-{{date}}.xlsx',
    exportToExcel: 'Exporter vers Excel',
    getDocuments: 'Obtenir les documents',
    getDocumentsTooManyItemsWarning: 'Un maximum de 100 documents de transaction peuvent être exportés. Veuillez affiner vos critères de recherche.',
    getDocumentsNoDocumentsWarning: "Il n'y a pas de documents disponibles pour ces transactions.",
    noResultsWarning: "Il n'y a aucun résultat.",
    tooManyResultsToExportWarning: 'Vous ne pouvez pas avoir plus de {{number}} résultats de recherche. Veuillez raffiner votre recherche.',
  },
  isBillable: 'Facturable',
  mainInventoriesNames: 'Inventaire principal',
  mainOrdersNames: 'Commande principale',
  number: "Numéro de l'action",
  numberOfItems: 'Items',
  otherInventoriesNames: 'Autre inventaire',
  otherOrdersNames: 'Autre commande',
  productSubtypeNames: 'Sous-type de produit',
  productTypeNames: 'Type de produit',
  projectNumberAndName: 'Numéro de projet',
  quantity: 'Qté totale',
  receiver: 'Destination',
  remarks: 'Remarques',
  site: {
    name: 'Site',
  },
  vehicleIdentifier: 'Id. Véhicule',
  status: 'Statut',
};

const dimensionTypes = {
  height: 'Hauteur',
  weight: 'Poids',
  width: 'Longueur',
  length: 'Largeur',
  footpound: 'Pied livre',
  thickness: 'Épaisseur',
  diameter: 'Diamètre',
  mass: 'Masse',
  surfaceArea: 'Superficie',
};

const packagings = {
  barrelDrum: 'Baril / tambour',
  bigBag: 'Gros sac',
  box: 'Boîtes',
  crate: 'Caisse',
  loose: 'En vrac',
  pallet: 'Palette',
  strappedBundle: 'Bundle attaché',
  unit: 'Unité',
  reel: 'Bobine',
  tote: 'Fourre-tout',
};

const itemFinder = {
  destinationName: 'Destination Name',
  vesselLocation: 'Vessel Location',
  clientName: 'Client Name',
  clientInventory: 'Client Inventory',
  order: 'Order',
  productTypeName: 'Product Type',
  productSubtypeName: 'Product Subtype',
  quantity: 'Quantity',
  parentContainerIdentifier: 'Containerized In',
  containerStatus: `Container Status`,
  sealNumbers: `Seal Numbers`,
  description: 'Description',
  poNumber: 'PO Number',
  lotNumber: 'Lot Number',
  serialNumber: 'Serial Number',
  heatNumber: 'Heat Number',
  mark: 'Mark',
  scope: 'Scope',
  billOfLading: 'Bill Of Lading',
  barcode: 'Barcode',
  booking: 'Booking',
  other: 'Other',
  weightPerUnit: 'Weight Per Unit',
  totalWeight: 'Total Weight',
  unitOfWeight: 'Unit Of Weight',
  volumePerUnit: 'Volume Per Unit',
  totalVolume: 'Total Volume',
  unitOfVolume: 'Unit Of Volume',
  surfaceAreaPerUnit: 'Surface Area Per Unit',
  unitOfSurfaceArea: 'Unit Of Surface Area',
  freightTon: 'Freight Ton',
  dimension: 'Dimension',
  diameter: 'Diameter',
  diameterUnit: 'Diameter Unit',
  footPound: 'Foot Pound',
  footPoundUnit: 'Foot Pound Unit',
  length: 'Length',
  lengthUnit: 'Length Unit',
  width: 'Width',
  widthUnit: 'Width Unit',
  height: 'Height',
  heightUnit: 'Height Unit',
  thickness: 'Thickness',
  thicknessUnit: 'Thickness Unit',
  quantityPerPackage: 'Quantity Per Package',
  packaging: 'Packaging',
  un: 'UN',
  pg: 'PG',
  weightHasBeenVerified: 'As Per Scale',
  customsRelease: 'Customs Release',
  shippingLineRelease: 'Shipping Line Release',
  financialRelease: 'Financial Release',
  customerRelease: 'Customer Release',
  released: 'Released',
  onHold: 'On Hold',
  addedBy: 'Added By',
  addedOn: 'Added On',
  status: 'Status',
  vessel: 'Vessel',
  actualDeckLocation: 'Actual Deck Location',
  loadedQuantity: 'Loaded Quantity',
  yes: 'Yes',
  no: 'No',
};

const report = {
  ...list,
  effectiveDateFrom: 'Date effective du',
  effectiveDateTo: 'à',
  or: 'ou',
  textQuery: 'Requête textuelle',
  min: 'Min',
  max: 'Max',
  any: 'Quelconque',

  actionType: list.actionTypeTranslatedValue,
  site: list.site.name,
  mainInventory: list.mainInventoriesNames,
  mainOrder: list.mainOrdersNames,
  otherInventory: list.otherInventoriesNames,
  otherOrder: list.otherOrdersNames,
  productSubtype: list.productSubtypeNames,
  productType: list.productTypeNames,
  project: list.projectNumberAndName,
};

const containerStatuses = {
  [containerStatusEnum.Empty]: 'Empty',
  [containerStatusEnum.Stuffed]: 'Stuffed',
  [containerStatusEnum.Sealed]: 'Sealed',
};

const shipmentStatus = {
  [shipmentStatusEnum.OnBoard]: 'On Board',
  [shipmentStatusEnum.PartiallyOnBoard]: 'Partially on Board',
  [shipmentStatusEnum.None]: 'None',
};

const transactionStatus = {
  [transactionStatusEnum.Cancelled]: 'Annulée',
  [transactionStatusEnum.Confirmed]: 'Confirmée',
  [transactionStatusEnum.Draft]: 'Brouillon',
  [transactionStatusEnum.InProgress]: 'En cours',
  [transactionStatusEnum.Loaded]: 'Chargé',
  [transactionStatusEnum.PaperworkReceived]: 'Documents reçus',
  [transactionStatusEnum.Rectification]: 'Rectification',
  [transactionStatusEnum.Unloaded]: 'Déchargé',
};

const error = {
  missingExcel: 'Missing Excel',
};

const excelConfirmationModal = {
  title: 'Exporter vers Excel',
  message: 'Le rapport Excel vous sera envoyé par courriel à {{email}}.',
  ok: 'Ok',
};

const transaction = {
  actionDateFrom: "Date de l'action de",
  advancedFilters: 'Filtres avancés',
  attachments: 'Pièces jointes',
  disabledDocumentTooltip: 'Document disponible après la confirmation de la réception complète',
  disabledExcelTooltip: 'Excel disponible après la confirmation de la réception complète',
  effectiveDateFrom: 'Date effective de',
  itemModifications: "Modifications de l'item",
  list,
  search: 'Rechercher',
  to: 'À',
  transactions: 'Transactions',
  viewAttachments: 'Consulter les pièces jointes',
  viewDocument: 'Consulter le document PDF',
  viewExcel: 'Consulter le document Excel',
  viewMediaFile: 'Consulter les fichiers multimédias',
  mediaFiles: 'Fichiers multimédias',
  booleanToYesNo: {
    true: 'Oui',
    false: 'Non',
  },
  error: {
    missingDocument: 'Document non trouvé',
  },
  packagingType: "Type d'emballage",
  packagings,
  partiallyConfirmed: 'partiellement confirmée',
  remark: 'Remarque',
  ...itemFinder,
  containerStatuses,
  shipmentStatus,
  transactionStatus,
  ...error,
  excelConfirmationModal,
  report,
  dimensionTypes,
  identifiers,
};

export default transaction;
