const volumeUnits = {
  BoardFoot: 'FBM',
  CubicBoard: 'CBM',
  CubicFoot: 'cu.ft',
  CubicMeter: 'm³',
};

const surfaceAreaUnits = {
  SquareFoot: 'sq.ft.',
  SquareMeter: 'm²',
};

const weightAndDimensions = {
  'kg/m': 'kg/m',
  'lbs/ft': 'lbs/ft',
  cbm: 'CBM',
  cm: 'cm',
  cubicFoot: 'cu.ft.',
  cubicMeter: 'm³',
  diameter: 'Diameter',
  false: 'No',
  fbm: 'FBM',
  footpound: 'Footpound',
  footPound: 'Foot Pound',
  ft: 'ft',
  height: 'Height',
  in: 'in',
  kg: 'kg',
  lb: 'lb',
  length: 'Length',
  LT: 'LT',
  m: 'm',
  mass: 'Mass',
  mm: 'mm',
  MT: 'MT',
  NT: 'NT',
  squareFoot: 'sq.ft.',
  squareMeter: 'm²',
  surfaceArea: 'Surface Area',
  thickness: 'Thickness',
  true: 'Yes',
  volume: 'Volume',
  weight: 'Weight',
  width: 'Width',
  weightAndDimensions: 'Weight & Dimensions',
  weightAndDimensionsAcronym: 'Wt & Dim',
  weightHasBeenVerified: 'Weight As Per Scale',
  min: 'Min',
  max: 'Max',
  unit: 'Unit',
  stuffedContainerWeight: 'Stuffed Container Weight',
  ...volumeUnits,
  ...surfaceAreaUnits,
};

export default weightAndDimensions;
