import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const UnauthorizedPage = () => {
  const { logout } = useAuth0();
  const { t } = useTranslation('root');

  useEffect(() => {
    setTimeout(() => logout(), 5000);
  }, [logout]);

  return (
    <Box m="auto" sx={{ fontSize: 24, p: 3 }}>
      <h2 style={{ fontWeight: 'bold' }}>{t('accessDenied')}</h2>
      <p>{t('wrongPermissionsDesc')}</p>
    </Box>
  );
};

export default UnauthorizedPage;
