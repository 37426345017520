import { useEffect, useState } from 'react';
import { formatNumber } from '../../../../common/helpers/numbers';

const isUndefinedOrNull = (value) => value === undefined || value === null;

const useGetContainedItemsPills = ({ totalQuantity, totalVolume, totalWeight }, t) => {
  const [quantity, setQuantity] = useState(totalQuantity);

  useEffect(() => {
    if (!isUndefinedOrNull(totalQuantity)) setQuantity(totalQuantity);
  }, [totalQuantity]);

  const totalQuantityPill = `${t('item.quantity')}: ${isUndefinedOrNull(quantity) ? '-' : formatNumber(quantity, 0)}`;

  return [totalQuantityPill];
};

export default useGetContainedItemsPills;
