const itemMovementType = {
  Adjustment: 'Adjustment',
  CancelledReception: 'Cancelled Reception',
  CancelledShipment: 'Cancelled Shipment',
  ContainerTransfer: 'Container transfer',
  Merge: 'Merge',
  MissingItemResolution: 'Missing Item Resolution',
  Move: 'Move',
  OrderTransfer: 'Transfer',
  ReceptionByBarge: 'Reception by barge',
  ReceptionByContainer: 'Reception by container',
  ReceptionByOther: 'Reception by other',
  ReceptionByRailcar: 'Reception by railcar',
  ReceptionByTruck: 'Reception by truck',
  ReceptionByVessel: 'Reception by vessel',
  Seal: 'Seal',
  Split: 'Split',
  ShipmentByBarge: 'Shipment by barge',
  ShipmentByOther: 'Shipment by other',
  ShipmentByRailcar: 'Shipment by railcar',
  ShipmentByTruck: 'Shipment by truck',
  ShipmentByVessel: 'Shipment by vessel',
  Stuff: 'Stuff',
  StuffForTransfer: 'Stuff for transfer',
  Transfer: 'Transfer',
  TransferredFromTC1: 'Transferred From TC1',
  Transform: 'Transform',
  Unseal: 'Unseal',
  Unstuff: 'Unstuff',
  UnstuffForTransfer: 'Unstuff for transfer',
};

export default itemMovementType;
