import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAcceptTermsAndConditions from '../user/useAcceptTermsAndConditions';

const WithAcceptation = ({ onAccept, onRefuse, children }) => {
  const { t } = useTranslation('termsAndConditions');

  const [acceptTermsAndConditions] = useAcceptTermsAndConditions();

  const handleAccept = () => {
    acceptTermsAndConditions().then(() => onAccept());
  };

  return (
    <>
      {children}
      <div className="flex justify-center">
        <span className="pr-3">
          <Button variant="contained" onClick={onRefuse}>
            {t('button.refuse')}
          </Button>
        </span>
        <Button variant="contained" color="primary" onClick={handleAccept}>
          {t('button.accept')}
        </Button>
      </div>
    </>
  );
};

WithAcceptation.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onRefuse: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default WithAcceptation;
