import { MenuItem } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { englishLocalesCode, frenchLocalesCode } from '../../../i18n';
import useChangeLanguage from '../../../user/useChangeLanguage';

const LanguageToggler = React.forwardRef((_, ref) => {
  const [changeUserLanguage] = useChangeLanguage();

  const { t, i18n } = useTranslation();

  return i18n.language === englishLocalesCode ? (
    <MenuItem ref={ref} onClick={() => changeUserLanguage(frenchLocalesCode)} data-testid="menuItem_french">
      {t('french')}
    </MenuItem>
  ) : (
    <MenuItem ref={ref} onClick={() => changeUserLanguage(englishLocalesCode)} data-testid="menuItem_english">
      {t('english')}
    </MenuItem>
  );
});

export default LanguageToggler;
