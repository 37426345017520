import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import getActiveFiltersCount from '../../../common/filters/getActiveFiltersCount';
import Badge from '../../../layout/Badge';
import Collapsible from '../../../layout/Collapsible/Collapsible';
import IdentifiersFilter from '../../../layout/Identifiers/IdentifiersFilter';
import queryStringOption from '../../../layout/ListPage/headers/queryStringOption';
import useAdvancedFilterValidationSchema from './useAdvancedFilterValidationSchema';

const makeRowStyles = makeStyles({
  root: {
    marginBottom: '1rem',
  },
});

const initialValues = (params) => {
  const searchValues = qs.parse(params.toString(), queryStringOption);
  return {
    identifiers: searchValues?.identifiers || [],
  };
};

const ItemsAdvancedFilterSection = ({ onSearch }) => {
  const { t } = useTranslation('inventory');
  const { search } = useLocation();

  const validationSchema = useAdvancedFilterValidationSchema();

  const rowStyles = makeRowStyles();

  return (
    <Formik initialValues={initialValues(new URLSearchParams(search))} onSubmit={onSearch} validationSchema={validationSchema} enableReinitialize>
      {({ submitForm, initialValues }) => (
        <Collapsible title={t('item.advancedFilters')} titleBadge={<Badge content={getActiveFiltersCount(initialValues)} />}>
          <Grid container>
            <Grid classes={rowStyles} item xs={12} md={12}>
              <FieldArray name="identifiers">{({ push, remove }) => <IdentifiersFilter push={push} remove={remove} name="identifiers" />}</FieldArray>
            </Grid>
            <Grid classes={rowStyles} item xs={12} md={12}>
              <Button onClick={submitForm} variant="contained" color="primary">
                {t('item.search')}
              </Button>
            </Grid>
          </Grid>
        </Collapsible>
      )}
    </Formik>
  );
};

ItemsAdvancedFilterSection.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default ItemsAdvancedFilterSection;
