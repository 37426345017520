import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

const withClearFilter = (component) => (props) => {
  const { search } = useLocation();
  const clearFilters = () => {
    const newParams = new URLSearchParams();
    const orderby = new URLSearchParams(search).get('orderby');
    if (orderby) {
      newParams.set('orderby', orderby);
    }
    // eslint-disable-next-line react/prop-types
    return props.onParamChange(newParams);
  };

  return React.createElement(component, { ...props, onClearFilter: clearFilters });
};

withClearFilter.propTypes = {
  onParamChange: PropTypes.func.isRequired,
};

export default withClearFilter;
