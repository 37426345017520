import PropTypes from 'prop-types';
import { Attachments } from '../attachment';
import url from './hooks/url';

const TransactionAttachments = ({ id }) => <Attachments resourceUrl={`${url}/${id}`} />;

TransactionAttachments.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TransactionAttachments;
