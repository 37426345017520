import { useState } from 'react';
import useGetAppointments from './useGetAppointments';
import { formatAppointmentsForExcel } from '../../common/helpers/excelFormatting';
import _get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { generateSpreadsheet, promptGeneratedXlsx } from '../../common/helpers/xlsxHelpers';
import Moment from 'moment';
import { getSites } from '../list/useGetSites';

const useExportAppointmentToExcel = () => {
  const [isGeneratingExcel, setIsGeneratingExcel] = useState(false);
  const [fetchAppointment] = useGetAppointments();
  const { t } = useTranslation('appointment');

  const mapToRow = (item, columns) => columns.reduce((rowAcc, c) => ({ ...rowAcc, [c.key]: _get(item, c.key) }), {});

  const exportAppointmentsToExcel = async (appointments) => {
    const customListColumns = [
      { key: 'siteName', name: t('list.site') },
      { key: 'startDate', name: t('list.date') },
      { key: 'timeSlot', name: t('list.timeSlot') },
      { key: 'number', name: t('list.number') },
      { key: 'actionType', name: t('list.actionType') },
      { key: 'cargoType', name: t('list.cargoType') },
      { key: 'bookerInformation.companyName', name: t('list.booker') },
      { key: 'carrierInformation.companyName', name: t('list.carrierName') },
      { key: 'description', name: t('list.description') },
      { key: 'romSomNumbers', name: t('list.romSomNumbers') },
      { key: 'transportationType', name: t('list.transportationType') },
      { key: 'supplierBooking', name: t('list.supplierBooking') },
      { key: 'carrierInformation.trailerType', name: t('list.trailerType') },
      { key: 'inventories', name: t('list.inventories') },
      { key: 'orders', name: t('list.orders') },
      { key: 'receivers', name: t('list.receivers') },
      { key: 'forWhom', name: t('list.forWhom') },
      { key: 'purchaseOrder', name: t('list.purchaseOrder') },
      { key: 'productTypes', name: t('list.productType') },
      { key: 'productSubtypes', name: t('list.productSubtype') },
      { key: 'quantities', name: t('list.quantities') },
      { key: 'hazardousMaterial', name: t('list.hazardousMaterial') },
      { key: 'specialInstructions', name: t('list.specialInstructions') },
      { key: 'dropDetail.cargoDestination', name: t('list.cargoDestination') },
      { key: 'transportation.driverName', name: t('list.driverName') },
      { key: 'transportation.carrierBill', name: t('list.carrierBill') },
      { key: 'transportation.transportationNumber', name: t('list.vehicleIdentifier') },
      { key: 'parking', name: t('list.parking') },
      { key: 'status', name: t('list.status') },
    ];

    const data = appointments
      .map((x) => mapToRow(x, customListColumns))
      .map((appointment) => ({
        ...appointment,
        romSomNumbers: appointment.romSomNumbers && appointment.romSomNumbers.join('; '),
      }));

    const sheet = generateSpreadsheet(data, customListColumns);
    promptGeneratedXlsx([sheet], ['Report'], `Appointment_Report_${Moment().format('YYYYMMDD_HHMM')}`);
  };

  const exportToExcel = async (params) => {
    setIsGeneratingExcel(true);
    const sites = await getSites();
    const response = await fetchAppointment({ ...Object.fromEntries(params), take: process.env.REACT_APP_MAX_EXCEL_ENTRIES_TO_EXPORT });
    const formattedResponse = formatAppointmentsForExcel(response.data.items, sites.data, t);
    await exportAppointmentsToExcel(formattedResponse);
    setIsGeneratingExcel(false);
  };

  return [exportToExcel, isGeneratingExcel];
};

export default useExportAppointmentToExcel;
