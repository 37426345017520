import { AppBar, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DocumentTitleContext } from './DocumentTitle';

const useStyle = makeStyles({
  root: {
    height: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '1rem',
  },
});

const NavigationTopMobile = () => {
  const [title] = useContext(DocumentTitleContext);
  const styles = useStyle();
  const { goBack } = useHistory();

  return (
    <AppBar position="fixed" classes={styles}>
      <span className="pl-2.5" onClick={goBack}>
        <ArrowBackIcon />
      </span>
      <span className="w-full text-center flex-shrink">{title}</span>
      <span className="mr-2.5 w-6 h-6" />
    </AppBar>
  );
};

export default NavigationTopMobile;
