export const truckTrailerTypesByKey = {
  dryBox: 'dryBox',
  flatBed: 'flatBed',
  frame: 'frame',
  bTrain: 'bTrain',
  gooseneck: 'gooseneck',
  curtainSide: 'curtainSide',
  dropDeck: 'dropDeck',
  endDump: 'endDump',
  goldhofer: 'goldhofer',
  hopperTruck: 'hopperTruck',
  liveBottom: 'liveBottom',
  triAxle: 'triAxle',
};

export const railCarTrailerTypesByKey = {
  boxcars: 'boxcars',
  bulkhead: 'bulkhead',
  centerBeams: 'centerBeams',
  flatCars: 'flatCars',
  gondolasOpen: 'gondolasOpen',
  gondolasCovered: 'gondolasCovered',
  hopperRailcar: 'hopperRailcar',
  tanker: 'tanker',
};
