import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { lngLocalStorageKey } from './i18n';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { injectSpritesheet } from './common/helpers/spriteLoader';
import ReduxProvider from './ReduxProvider';

injectSpritesheet('/static/icons.svg').catch();

const lng = window.localStorage.getItem(lngLocalStorageKey);

// This variable is to ensure a new build changing only config files will generate a new hash to avoid caching problem.
// eslint-disable-next-line no-unused-vars
const gitHash = process.env.REACT_APP_GIT_SHA;

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENTID}
    audience={'https://client.qsl.com/api'}
    redirectUri={window.location.origin}
    scope="openid profile"
    useRefreshTokens={true}
    ui_locales={lng}>
    <DndProvider backend={HTML5Backend}>
      <ReduxProvider>
        <App />
      </ReduxProvider>
    </DndProvider>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
