import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDialogActionsStyles, useModalStyles, useTitleStyles } from './RoutedModal';

const useDialogContentStyle = makeStyles({
  root: { backgroundColor: 'white' },
});

const Modal = ({ open, children, onClose, rotate, title }) => {
  const { t } = useTranslation('layout');
  const style = useDialogContentStyle();
  const titleStyle = useTitleStyles();

  return (
    <Dialog className={rotate ? 'worker-mobile -landscape-rotate' : null} open={open} onClose={onClose} fullWidth maxWidth="lg" classes={useModalStyles()}>
      {title && (
        <DialogTitle onClose={onClose} classes={titleStyle}>
          {title}
        </DialogTitle>
      )}
      <DialogContent classes={style}>{children}</DialogContent>
      <DialogActions classes={useDialogActionsStyles()}>
        {onClose && (
          <Button color="primary" variant="contained" onClick={onClose}>
            {t('close')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  rotate: PropTypes.bool,
};

Modal.defaultProps = {
  onClose: null,
  title: null,
  rotate: false,
};
export default Modal;
