const types = {
  footpound: 'footpound',
  height: 'height',
  width: 'width',
  length: 'length',
  thickness: 'thickness',
  diameter: 'diameter',
  volume: 'volume',
  mass: 'mass',
  surfaceArea: 'surfaceArea',
};

export default types;
