import PropTypes from 'prop-types';

const ItemMovementList = {
  itemId: PropTypes.number.isRequired,
  movedQuantity: PropTypes.number.isRequired,
  effectiveDate: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  movementType: PropTypes.string.isRequired,
  movementSource: PropTypes.shape({
    sourceType: PropTypes.string,
    sourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default ItemMovementList;
