import PropTypes from 'prop-types';

export const InventoryOrder = {
  id: PropTypes.oneOfType([(PropTypes.string, PropTypes.number)]),
  name: PropTypes.string,
  clientInventoryId: PropTypes.number,
  clientInventoryName: PropTypes.string,
  productSubtypeId: PropTypes.number,
  remarks: PropTypes.string,
  quantity: PropTypes.number,
  remainingQuantity: PropTypes.number,
};

export default InventoryOrder;
