import axios from 'axios';
import { CARGO_TYPE } from '../../../../cargoType';
import useAgent from '../../../../hooks/useAgent';

const getUrl = ({ inventoryId, itemId }) => `/api/v1/inventory/${inventoryId}/inventory-items/${itemId}/containers`;

const useGetContainers = ({ inventoryId, itemId }) => {
  return useAgent((params = new URLSearchParams()) => {
    params.set('cargoType', CARGO_TYPE.breakbulk);
    return axios.get(getUrl({ inventoryId, itemId }), { params });
  });
};

export default useGetContainers;
