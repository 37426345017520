import axios from 'axios';
import download from '../../common/helpers/download';
import useAgent from '../../hooks/useAgent';

export const url = `/api/v1/actions/reports`;

export const generateTransactionsExcelReport = async (parameters = new URLSearchParams(), fileName, cargoType) => {
  parameters.set('localTimeZoneId', Intl.DateTimeFormat().resolvedOptions().timeZone);

  const response = await axios.get(`${url}/${cargoType}`, {
    params: parameters,
    responseType: 'blob',
  });

  return download(response.data, fileName);
};

const useGenerateTransactionsExcelReport = () => useAgent(generateTransactionsExcelReport);

export default useGenerateTransactionsExcelReport;
