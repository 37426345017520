import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { Grid, Button, Popover } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { InputDatePicker } from '@vooban/inputs';

const isoDateFormat = 'YYYY-MM-DD';

const DateNavigator = ({ date, displayDate, onDateChange, dateIncrement, locale }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const emitDateChange = (date) => {
    const startDate = Moment(date).startOf(dateIncrement);
    const endDate = Moment(date).endOf(dateIncrement);
    onDateChange(startDate, endDate);
  };

  const increment = () => {
    const newDate = Moment(date).add(1, dateIncrement);
    emitDateChange(newDate);
  };

  const decrement = () => {
    const newDate = Moment(date).subtract(1, dateIncrement);
    emitDateChange(newDate);
  };

  const handleDateChange = (date) => {
    const momentDate = Moment(date);
    emitDateChange(momentDate);
    handleClose();
  };

  const toLocal = (date) => (date ? Moment(date).local() : date);

  const getDateText = (value) => (value ? toLocal(value).format('dddd[,] MMMM D') : '\u221e');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggled = Boolean(anchorEl);
  const id = toggled ? 'simple-popover' : undefined;

  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item>
        <Button style={{ minWidth: 42 }} color="primary" onClick={decrement} aria-label="before" className="focus:outline-none">
          <NavigateBeforeIcon />
        </Button>
        <Button onClick={handleClick}>{displayDate || getDateText(date)}</Button>
        <Popover className="worker-mobile -calendar-landscape-rotate" id={id} open={toggled} anchorEl={anchorEl} onClose={handleClose}>
          <InputDatePicker
            name="startDate"
            onChange={handleDateChange}
            value={Moment(date).format(isoDateFormat)}
            locale={locale}
            inline
            className="datepicker__popdown"
          />
        </Popover>
        <Button style={{ minWidth: 42 }} color="primary" onClick={increment} aria-label="next" className="focus:outline-none">
          <NavigateNextIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

DateNavigator.propTypes = {
  date: PropTypes.instanceOf(Moment),
  displayDate: PropTypes.string,
  onDateChange: PropTypes.func,
  dateIncrement: PropTypes.string,
  locale: PropTypes.string,
};

DateNavigator.defaultProps = {
  displayDate: null,
  dateIncrement: 'd',
  date: null,
  onDateChange: () => undefined,
  locale: 'en',
};

export default DateNavigator;
