import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { isEqual } from 'lodash';

const SelectInput = ({ classes, error, helperText, inputRef, label, name, onChange, options, value, required, ...props }) => (
  <Autocomplete
    {...props}
    classes={classes}
    clearOnBlur
    name={name}
    disableClearable={required}
    value={options.find((o) => isEqual(o.value, value)) || null}
    getOptionLabel={(option) => option?.label || ''}
    getOptionSelected={(option, optionValue) => isEqual(option.value, optionValue.value)}
    onChange={(event, newValue) =>
      onChange({
        target: {
          name: name,
          value: newValue?.value || null,
        },
      })
    }
    options={options}
    renderInput={(params) => <TextField {...params} label={label} error={error} helperText={helperText} inputRef={inputRef} required={required} />}
    renderOption={(option) => <span>{option.label}</span>}
    fullWidth
  />
);

SelectInput.propTypes = {
  classes: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  inputRef: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), value: PropTypes.any, label: PropTypes.string })
  ),
  value: PropTypes.any,
  required: PropTypes.bool,
};

SelectInput.defaultProps = {
  classes: {},
  error: false,
  helperText: null,
  inputRef: null,
  label: null,
  name: null,
  onChange: () => {},
  options: [],
  value: '',
  required: false,
};

export default SelectInput;
