import { useEffect, useState } from 'react';
import { formatNumber } from '../../../common/helpers/numbers';

const isUndefinedOrNull = (value) => value === undefined || value === null;

const useGetBulkListPills = ({ totalQuantity }, t) => {
  const [quantity, setQuantity] = useState(totalQuantity);

  useEffect(() => {
    if (!isUndefinedOrNull(totalQuantity)) setQuantity(totalQuantity);
  }, [totalQuantity]);

  const totalQuantityPill = `${t('item.bookQuantity')}: ${isUndefinedOrNull(quantity) ? '-' : formatNumber(quantity)} MT`;

  return [totalQuantityPill];
};

export default useGetBulkListPills;
