import { TagInputFilter } from '@vooban/datagrid';
import Dimension from '../../../../common/dimension';
import { formatClass, formatPackagingGroup, formatUnNumber } from '../../../../common/hazardous';
import { formatNumber } from '../../../../common/helpers/numbers';
import columnsSetting from '../../../../layout/Datagrid/ColumnSetting';

const round = (value) => value !== null && value !== undefined && formatNumber(value);

const released = (value, _, t) => value && t('item.released');

const volumeFormatter = (volume, _, t) => volume && volume.value !== null && volume.unit && `${round(volume.value)} ${t(`units.${volume.unit}`)}`;

const getDefaultColumns = ({ productTypes = [], productSubtypes = [] }) =>
  [
    columnsSetting('productType.name').withFilter({
      keyFilter: 'productType',
      component: TagInputFilter,
      options: productTypes,
    }),
    columnsSetting('productSubtypeName').withFilter({
      keyFilter: 'productSubtype',
      component: TagInputFilter,
      options: productSubtypes,
    }),
    ...['barcode', 'description', 'mark'].map(columnsSetting),
    ...['totalWeight'].map((x) => columnsSetting(x).withCustomFormat(Dimension.format).withAlignRight().withSortKey('totalWeight')),
    ...['availableQuantity'].map((x) => columnsSetting(x).withAlignRight()),
    columnsSetting('itemLocation').withSortKey('location'),
  ].map((x) => x.withIsDefault());

const otherColumns = ({ clientInventories = [], containerStatuses = [], originalVesselProjects = [], packagingOptions = [], sites = [] }) => [
  columnsSetting('clientInventory.site.name').withSortKey('site').withFilter({ component: TagInputFilter, keyFilter: 'site', options: sites }),
  columnsSetting('dimension').withAlignRight(),
  columnsSetting('clientInventory.name').withFilter({
    keyFilter: 'clientInventory',
    component: TagInputFilter,
    options: clientInventories,
  }),
  columnsSetting('containerStatus')
    .withFilter({
      component: TagInputFilter,
      options: containerStatuses,
    })
    .withTranslationValue('containerStatuses'),
  columnsSetting('originalVesselProject').withFilter({
    component: TagInputFilter,
    options: originalVesselProjects,
  }),
  ...['originalReceptionInformation.appointmentNumber', 'billOfLading', 'booking', 'customsRelease.number'].map(columnsSetting),
  ...['financialRelease.released', 'customerRelease.released'].map((x) => columnsSetting(x).withCustomFormat(released)),
  columnsSetting('freightTon').withCustomFormat(round).withAlignRight(),
  ...['heatNumber', 'lotNumber', 'order.name', 'other', 'stuffedIn', 'poNumber', 'remark', 'scope', 'serialNumber'].map(columnsSetting),
  columnsSetting('packaging')
    .withFilter({
      component: TagInputFilter,
      options: packagingOptions,
    })
    .withTranslationValue('packagings'),
  columnsSetting('shippingLineRelease.released').withCustomFormat(released),
  columnsSetting('weightHasBeenVerified'),
  columnsSetting('originalReceptionInformation.receptionNumber').withSortKey('receptionNumber'),
  columnsSetting('order.receiver.name').withSortKey('receiver'),
  columnsSetting('originalReceptionInformation.receptionDate').withSortKey('receptionDate').withFormatDate(),
  columnsSetting('hazardousMaterialInformation.class').withCustomFormat((_, row) => formatClass(row['hazardousMaterialInformation'])),
  columnsSetting('hazardousMaterialInformation.unNumber').withCustomFormat((_, row) => formatUnNumber(row['hazardousMaterialInformation'])),
  columnsSetting('hazardousMaterialInformation.packagingGroup').withCustomFormat((_, row) => formatPackagingGroup(row['hazardousMaterialInformation'])),

  columnsSetting('totalVolume').withCustomFormat(volumeFormatter).withAlignRight(),
  ...['stuffedQuantity', 'quantityPerPackage'].map((x) => columnsSetting(x).withAlignRight()),
  // columnsSetting('weightPerUnitInTons').withCustomFormat(round).withAlignRight().withSuffix(' MT').withSortKey('weightPerUnitInTons'),
  ...['width', 'length', 'height', 'diameter', 'thickness', 'footPound'].map((x) => columnsSetting(x).withCustomFormat(Dimension.format).withAlignRight()),
];

const getColumns = (options) => [...getDefaultColumns(options), ...otherColumns(options)];

export default getColumns;
