import { makeStyles, Paper, Snackbar, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useSnackBarStyle = makeStyles({
  root: { bottom: '4.5rem' },
});

const usePaperStyle = makeStyles((theme) => ({
  root: { background: '#fff', padding: 10, color: theme.palette.primary.main },
}));

const Toast = ({ shouldOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('layout');
  const stylePaper = usePaperStyle();
  const styleSnackbar = useSnackBarStyle();

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (shouldOpen) setIsOpen(true);
  }, [shouldOpen]);

  return (
    <Snackbar classes={styleSnackbar} open={isOpen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Paper classes={stylePaper}>
        <Typography>{t('list.endOfResult')}</Typography>
      </Paper>
    </Snackbar>
  );
};

Toast.propTypes = {
  shouldOpen: PropTypes.bool,
};

export default Toast;
