import PropTypes from 'prop-types';
import { TrailerTypeIcon } from '../../../appointment/list/IconHelper';
import BTrainSvg from '../../../common/icons/trailers/btrain.svg';
import BoatSvg from '../../../common/icons/transportations/boat.svg';
import OtherSvg from '../../../common/icons/transportations/other.svg';
import TruckSvg from '../../../common/icons/transportations/truck.svg';
import { trailerTypes } from '../../../traffic/dataTypes';
import { transportationTypes } from '../../../transportation';
import Icon from '../Icon';

const DefaultIcon = (svg, alt) => (
  <Icon>
    <img alt={alt} src={svg} style={{ width: 60, display: 'inline' }} />
  </Icon>
);

const TransportationTrafficIcon = ({ transportationType, trailerType }) => {
  let Component;

  if ([transportationTypes.barge, transportationTypes.vessel].includes(transportationType)) Component = DefaultIcon(BoatSvg, 'Boat');
  if (transportationType === transportationTypes.other) Component = DefaultIcon(OtherSvg, 'Other');
  if (transportationType === transportationTypes.railcar) {
    Component = DefaultIcon(BTrainSvg, 'B-Train');
    if (trailerType) {
      Component = TrailerTypeIcon(trailerType);
    }
  }
  if (transportationType === transportationTypes.truck) {
    Component = DefaultIcon(TruckSvg);
    if (trailerType) {
      Component = TrailerTypeIcon(trailerType);
    }
  }

  return Component ? Component : null;
};

TransportationTrafficIcon.propTypes = {
  actionType: PropTypes.oneOf(['drop', 'pick']).isRequired,
  size: PropTypes.number,
  transportationType: PropTypes.oneOf(Object.values(transportationTypes)).isRequired,
  trailerType: PropTypes.oneOf(trailerTypes),
};

TransportationTrafficIcon.defaultProps = {
  size: 50,
  trailerType: undefined,
};

export default TransportationTrafficIcon;
