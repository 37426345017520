import { Dialog, DialogTitle, DialogActions, Button, DialogContentText, DialogContent, Grid, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const ConfirmModal = ({ title, message, handleClose, confirmButtonLabel, open, icon }) => {
  const useStyles = makeStyles((theme) => ({
    icon: {
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
    },
    message: {
      margin: 10,
      display: 'flex',
      justifyContent: 'center',
    },
  }));

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {icon && (
            <Grid item xs={12}>
              <Box className={classes.icon}>{icon}</Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <DialogContentText variant="body1" className={classes.message}>
              {message}
            </DialogContentText>
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
            {confirmButtonLabel}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  confirmButtonLabel: PropTypes.string,
  icon: PropTypes.node,
};

export default ConfirmModal;
