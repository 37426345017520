import { makeStyles, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toLocalDateTime } from '../common/helpers/date';

const useStyles = makeStyles(() => ({
  img: {
    width: 'auto',
  },
}));
const MediaPhoto = ({ thumbnail, authorName, timestamp, note, onClick }) => {
  const classes = useStyles();
  return (
    <Tooltip
      placement="top"
      arrow={true}
      title={
        <div className="p-1 flex flex-col">
          <span className="text-xs">{toLocalDateTime(timestamp)}</span>
          <span className="text-xs">{authorName}</span>
          <span className="text-xs">{note}</span>
        </div>
      }>
      <img className={classes.img} src={`data:image/png;base64,${thumbnail}`} alt={note} onClick={onClick} />
    </Tooltip>
  );
};

MediaPhoto.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  authorName: PropTypes.string,
  timestamp: PropTypes.string,
  note: PropTypes.string,
  onClick: PropTypes.func,
};

export default MediaPhoto;
