const status = {
  cancelled: 'Cancelled',
  confirmed: 'Confirmed',
  draft: 'Draft',
  inProgress: 'In Progress',
  loaded: 'Loaded',
  paperworkReceived: 'Paperwork Received',
  rectification: 'Rectification',
  completed: 'Completed',
};

const transportationType = {
  Barge: 'Barge',
  Other: 'Other',
  Railcar: 'Railcar',
  Truck: 'Truck',
  Vessel: 'Vessel',
};

const destination = {
  linkedInventoriesAndOrders: 'LINKED INVENTORIES & ORDERS',
  inventory: 'Inventory',
  order: 'Order',
  destinations: 'Destinations',
  contact: 'Contact',
};

const shipment = {
  status,
  transportationType,
  ...destination,
  shipmentOfMaterial: 'Shipment of Material',
  shipmentDate: 'Shipment Date',
  confirmedBy: 'Confirmed by',
  confirmation: 'Confirmation',
  billable: 'Billable',
  receiver: 'Receiver',
  orderName: 'Order',
  portOfDischarge: 'Port of Discharge',
  destinationName: 'Destination Name',
  name: 'Name',
  address: 'Address',
  shipper: 'SHIPPER',
  careOf: 'C/O',
  consignee: 'CONSIGNEE',
  notify: 'NOTIFY',
  instructions: 'Instructions',
  mainInformation: 'Main Information',
  internalRemarks: 'Internal Remarks',
  specialInstructions: 'Special Instructions',
  identifier: 'Identifier',
  operationTypeTerms: {
    linesmenOnly: 'Linesmen Only',
    loading: 'Loading',
    unloading: 'Unloading',
    transshipment: 'Transshipment',
  },
  somHasOriginalItemModified:
    'Some item definitions have changed after being shipped. A rectification may be necessary to update these items and produce accurate documentation.',
  vesselProject: 'Vessel Project',
};

export default shipment;
