import { Grid, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MainTitle = ({ children }) => (
  <Typography variant="h3" align="center">
    {children}
  </Typography>
);
MainTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

const SectionTitle = ({ children }) => (
  <Typography variant="h5" color="primary">
    {children}
  </Typography>
);
SectionTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

const Paragraph = ({ children }) => (
  <Typography variant="body1" paragraph>
    {children}
  </Typography>
);
Paragraph.propTypes = {
  children: PropTypes.string.isRequired,
};

const ListTitle = ({ children }) => <Typography variant="body1">{children}</Typography>;
ListTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

const makeColOne = makeStyles({
  root: { width: '100%', whiteSpace: 'pre-line', border: '1px solid black', paddingLeft: '1rem' },
});
const makeColTwo = makeStyles({
  root: { border: '1px solid black', paddingLeft: '1rem' },
});

const TableRow = ({ title, text }) => {
  const colOne = makeColOne();
  const colTwo = makeColTwo();
  return (
    <>
      <Grid item md={3} sm={12} classes={colOne}>
        {title}
      </Grid>
      <Grid item md={9} sm={12} classes={colTwo}>
        {text}
      </Grid>
    </>
  );
};

TableRow.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const ListItems = ({ items }) => {
  return (
    <List>
      {items.map((t) => (
        <ListItem key={t}>
          <Typography>&bull; {t}</Typography>
        </ListItem>
      ))}
    </List>
  );
};

ListItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const useGridStyle = makeStyles({
  root: { border: '1px solid black', marginBottom: '1.5rem' },
});

const TermsAndConditions = () => {
  const { t } = useTranslation('termsAndConditions');
  const styles = useGridStyle();

  return (
    <div className="bg-white p-5">
      <MainTitle>{t('termOfUse')}</MainTitle>
      <MainTitle>{t('effectiveAsOf')}</MainTitle>
      <SectionTitle>{t('definitions')}</SectionTitle>
      <Grid container classes={styles}>
        <TableRow title={t('services')} text={t('servicesDefinition')} />
        <TableRow title={t('qslOrWe')} text={t('qslOrWeDefinition')} />
        <TableRow title={t('informationDataContent')} text={t('informationDataContentDefinition')} />
        <TableRow title={t('youUser')} text={t('youUserDefinition')} />
      </Grid>
      <SectionTitle>{t('object')}</SectionTitle>
      <Paragraph>{t('objectParagraphOne')}</Paragraph>
      <Paragraph>{t('objectParagraphTwo')}</Paragraph>
      <SectionTitle>{t('userAccountAndCredential')}</SectionTitle>
      <Paragraph>{t('userAccountAndCredentialParagraphOne')}</Paragraph>
      <Paragraph>{t('userAccountAndCredentialParagraphTwo')}</Paragraph>
      <SectionTitle>{t('rightOfUseAndProhibitedUse')}</SectionTitle>
      <ListTitle>{t('rightOfUseAndProhibitedUseListTitle')}</ListTitle>
      <ListItems items={[t('rightOfUseAndProhibitedUseListItemOne'), t('rightOfUseAndProhibitedUseListItemTwo')]} />
      <Paragraph>{t('rightOfUseAndProhibitedUseParagraphOne')}</Paragraph>
      <Paragraph>{t('rightOfUseAndProhibitedUseParagraphTwo')}</Paragraph>
      <SectionTitle>{t('informativeContent')}</SectionTitle>
      <Paragraph>{t('informativeContentParagraphOne')}</Paragraph>
      <Paragraph>{t('informativeContentParagraphTwo')}</Paragraph>
      <SectionTitle>{t('warrantyExclusionAndLiabilityExclusion')}</SectionTitle>
      <Paragraph>{t('warrantyExclusionAndLiabilityExclusionParagraphOne')}</Paragraph>
      <Paragraph>{t('warrantyExclusionAndLiabilityExclusionParagraphTwo')}</Paragraph>
      <Paragraph>{t('warrantyExclusionAndLiabilityExclusionParagraphThree')}</Paragraph>
      <SectionTitle>{t('personalInformation')}</SectionTitle>
      <Paragraph>{t('personalInformationParagraphOne')}</Paragraph>
      <SectionTitle>{t('availabilityOfTheService')}</SectionTitle>
      <ListTitle>{t('availabilityOfTheServiceListTitle')}</ListTitle>
      <ListItems
        items={[
          t('availabilityOfTheServiceListItemOne'),
          t('availabilityOfTheServiceListItemTwo'),
          t('availabilityOfTheServiceListItemThree'),
          t('availabilityOfTheServiceListItemFour'),
        ]}
      />
      <SectionTitle>{t('intellectualProperty')}</SectionTitle>
      <Paragraph>{t('intellectualPropertyParagraphOne')}</Paragraph>
      <Paragraph>{t('intellectualPropertyParagraphTwo')}</Paragraph>
      <SectionTitle>{t('term')}</SectionTitle>
      <Paragraph>{t('termParagraphOne')}</Paragraph>
      <SectionTitle>{t('applicableLaw')}</SectionTitle>
      <Paragraph>{t('applicableLawParagraphOne')}</Paragraph>
      <SectionTitle>{t('contact')}</SectionTitle>
      <Paragraph>{t('contactEmail')}</Paragraph>
      <Paragraph>{t('contactParagraph')}</Paragraph>
    </div>
  );
};

export default TermsAndConditions;
