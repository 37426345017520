import { RowFormatter } from '@vooban/datagrid';
import React from 'react';
import { toLocalDateTime } from '../../common/helpers/date';

const columnsSetting = (key) => new DefaultColumnSetting(key);

class DefaultColumnSetting {
  constructor(key) {
    this._key = key;
  }

  get key() {
    return this._key;
  }

  get isDefault() {
    return this._isDefault;
  }

  withFilter({ component, keyFilter = this._key, options }) {
    this._filter = {
      component,
      keyFilter,
      options,
    };
    return this;
  }

  withCustomKey(value) {
    this._key = value;
    return this;
  }

  withColName(value = this._key) {
    this._colName = value;
    return this;
  }

  withSortKey(value = this._key) {
    this._sortKey = value;
    return this;
  }

  withoutSort() {
    this._sortable = false;
    return this;
  }

  withFormatDate() {
    this._isDate = true;
    return this;
  }

  withIsResizable(value) {
    this._resizable = value;
    return this;
  }

  withWidth(value) {
    this._width = value;
    return this;
  }

  withAlignRight() {
    this._alignRight = true;
    return this;
  }

  withTranslationValue(key) {
    this._translateValue = true;
    this._translateKey = key ? `.${key}` : '';
    return this;
  }

  withSuffix(str) {
    this._suffix = str;
    return this;
  }

  /**
   * Personalize your output
   * @param {function} fn (value,row,translater) => {...}
   * @returns this
   */
  withCustomFormat(fn) {
    this._customFormat = fn;
    return this;
  }

  withIsVisible() {
    return this.withVisible(true);
  }

  withIsInvisible() {
    return this.withVisible(false);
  }

  withVisible(value) {
    this._visible = value;
    return this;
  }

  withIsDefault() {
    this._isDefault = true;
    this.withIsVisible();
    return this;
  }

  toColumns(t) {
    return {
      key: this._key,
      name: t(`list.${this._colName || this._key}`),
      formatter: ({ row, value }) => this._toFormatter(t, value, row),
      width: this._width,
      alignRight: this._alignRight,
      sortable: this._sortable,
      sortKey: this._sortKey,
      resizable: this._resizable,
      visible: this._visible || false,
      isDefault: this._isDefault || false,
      filter: this._filter,
    };
  }

  _toFormatter(t, value, row) {
    let result = value;
    if (this._customFormat) result = this._customFormat(value, row, t);
    if (this._isDate) result = toLocalDateTime(value);
    if (this._translateValue) result = value && t(`list${this._translateKey}.${value}`);
    return React.createElement(RowFormatter, { value: this._addSuffix(result) });
  }

  _addSuffix(value) {
    if (this._suffix && value) return `${value}${this._suffix}`;
    return value;
  }
}

export default columnsSetting;
