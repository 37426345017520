import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
const applicationId = process.env.REACT_APP_DATADOG_RUM_APP_ID;
const environmentName = process.env.REACT_APP_ENVIRONMENT_NAME ?? 'local';
const isLocal = environmentName === 'local';

const datadog = { logs: undefined, rum: undefined };
if (clientToken && !isLocal) {
  const datadogBaseConfig = {
    clientToken,
    env: environmentName,
    service: 'tc3-client-portal',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    version: require('../package.json').version,
    allowedTracingUrls: [process.env.REACT_APP_DATADOG_RUM_TRACING_ORIGIN],
  };

  datadogLogs.init(datadogBaseConfig);
  datadog.logs = datadogLogs;

  if (applicationId) {
    datadogRum.init({
      applicationId,
      ...datadogBaseConfig,
    });

    datadog.rum = datadogRum;
  }
}

export default datadog;
