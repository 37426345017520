import { AppBar, Tab, Tabs, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Breakpoint from './mediaQuery';

const PageWithTabs = ({ children, tabs, currentTab }) => {
  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);

  return (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={currentTab}
          variant={isTabletOrLarger ? 'standard' : 'fullWidth'}
          onChange={() => {}}
          classes={{ root: `h-full items-center ${isTabletOrLarger ? 'pb-8' : 'pb-4'}` }}
          textColor="primary"
          indicatorColor="primary">
          {tabs.map((option) => (
            <Tab
              classes={{ root: 'text-xl' }}
              key={option.path}
              value={option.path}
              label={
                <Link to={option.path} key={option.path} replace>
                  {option.label}
                </Link>
              }
            />
          ))}
        </Tabs>
      </AppBar>
      {children}
    </>
  );
};

PageWithTabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, path: PropTypes.string })).isRequired,
  currentTab: PropTypes.string,
};

export default PageWithTabs;
