import { Button, Drawer, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import useModal from '../../hooks/useModal';

const paperStyle = makeStyles({
  root: { minWidth: '95%', paddingLeft: '1.5rem' },
});

const SchwoopMobile = ({ children, open, title, subtitle, onClose }) => {
  const [isModalOpen, openModal, closeModal] = useModal();

  useEffect(() => {
    if (open) openModal();
    else closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const close = () => {
    closeModal();
    onClose();
  };

  return (
    <Drawer anchor="right" open={isModalOpen} PaperProps={{ classes: paperStyle() }}>
      <div className="flex justify-end">
        <Button variant={'text'} onClick={close}>
          <CloseIcon />
        </Button>
      </div>
      <span className="flex items-baseline text-xl pb-2.5">
        {title} {subtitle}
      </span>
      {children}
    </Drawer>
  );
};

SchwoopMobile.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  onClose: PropTypes.func,
};

SchwoopMobile.defaultProps = {
  title: '',
  subtitle: '',
  onClose: () => undefined,
};

export default SchwoopMobile;
