import React from 'react';
import { Grid, FormControl, RadioGroup, FormControlLabel, Radio, Box, Button, makeStyles, FormHelperText } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import TextField from '../../layout/fields/TextField';
import PropTypes from 'prop-types';
import AvailabilityTypeEnum from './enum/AvailabilityTypeEnum';
import { AvailabilityTypes } from '@vooban/tc3-core/laborAvailability';

const LaborAvailabilityModalContent = ({ availabilityTypes }) => {
  const { i18n } = useTranslation();

  const modalContentStyle = makeStyles((theme) => ({
    available: {
      borderColor: '#2E7D32',
      color: '#2E7D32',
    },
    unavailable: {
      borderColor: '#C62828',
      color: '#C62828',
    },
    default: {
      borderColor: '0',
      color: '0',
    },
    marginLeft: { marginLeft: 5 },
    marginBottom: { marginBottom: 15, marginTop: -15 },
    radioLabel: { color: 'rgba(48, 48, 48)' },
    radioSublabel: { color: 'rgba(48, 48, 48, 0.7)' },
  }));

  const getAvailabilityTypeConfigurations = ({ availability }) => {
    const filteredAvailabilityTypes = availabilityTypes.filter((type) => type.availability === availability);
    return filteredAvailabilityTypes.map((availability) => ({
      key: availability.id,
      value: availability.id,
      description: getStringByLanguage(availability.descriptionEn, availability.descriptionFr),
      label: getStringByLanguage(availability.nameEn, availability.nameFr),
    }));
  };

  const getStringByLanguage = (en, fr) => {
    if (i18n.language === 'en' && en) return en;
    if (fr) return fr;
    return en;
  };

  const { values, setFieldValue, handleChange, errors } = useFormikContext();

  const setAvailable = () => {
    const filteredAvailabilityTypes = availabilityTypes.filter((type) => type.availability === AvailabilityTypeEnum.Available);
    setFieldValue('availability', AvailabilityTypeEnum.Available);
    setFieldValue('type', filteredAvailabilityTypes.length === 1 ? filteredAvailabilityTypes[0].id.toString() : null);
    setFieldValue('description', undefined);
  };

  const setUnavailable = () => {
    const filteredAvailabilityTypes = availabilityTypes.filter((type) => type.availability === AvailabilityTypeEnum.NotAvailable);
    setFieldValue('availability', AvailabilityTypeEnum.NotAvailable);
    setFieldValue('type', filteredAvailabilityTypes.length === 1 ? filteredAvailabilityTypes[0].id.toString() : null);
    setFieldValue('description', undefined);
  };
  const classes = modalContentStyle();

  const { t } = useTranslation(['laborAvailability']);

  return (
    <Grid container spacing={2}>
      <Grid xs={8} item>
        <Box>
          <Button
            className={values.availability === AvailabilityTypeEnum.Available ? classes.available : classes.default}
            variant="outlined"
            onClick={setAvailable}>
            {t('available')}
          </Button>
          <Button
            className={
              values.availability === AvailabilityTypeEnum.NotAvailable
                ? `${classes.unavailable} ${classes.marginLeft}`
                : `${classes.default} ${classes.marginLeft}`
            }
            variant="outlined"
            onClick={setUnavailable}>
            {t('unavailable')}
          </Button>
        </Box>
      </Grid>
      <Grid xs={12} item>
        <FormControl component="fieldset" error={!!errors.type}>
          <RadioGroup
            name="type"
            value={values.type}
            onChange={handleChange}
            style={{ overflow: '-moz-scrollbars-vertical', overflowY: 'scroll', maxHeight: '7rem' }}>
            <Grid container>
              {getAvailabilityTypeConfigurations(values).map((availability) => (
                <Grid item xs={6}>
                  <FormControlLabel
                    value={availability.value.toString()}
                    control={<Radio color="primary" />}
                    label={
                      <>
                        <span className={classes.radioLabel}>{`${availability.label}`}</span>
                        {availability.description && <span className={classes.radioSublabel}>{` - ${availability.description}`}</span>}
                      </>
                    }
                    key={availability.key}
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
          {!!errors.type && <FormHelperText>{errors.type}</FormHelperText>}
        </FormControl>
      </Grid>
      {values.availability === AvailabilityTypeEnum.NotAvailable && (
        <Grid item xs={10} className={classes.marginBottom}>
          <TextField
            name="description"
            label={t('reason')}
            value={values.description}
            onChange={handleChange}
            fullWidth
            onKeyPress={(event) => {
              if (event.key === 'Enter') document.activeElement.blur();
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

LaborAvailabilityModalContent.propTypes = {
  availabilityTypes: PropTypes.arrayOf(PropTypes.shape(AvailabilityTypes)).isRequired,
};

export default LaborAvailabilityModalContent;
