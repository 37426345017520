import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Schwoop from './Schwoop';

const RoutedSchwoop = ({ children, title, subtitle }) => {
  const {
    replace,
    location: { pathname, search },
  } = useHistory();

  const handleClose = () => replace(`${pathname}${search}`);

  return (
    <Schwoop open onClose={handleClose} title={title} subtitle={subtitle}>
      {children}
    </Schwoop>
  );
};

RoutedSchwoop.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

RoutedSchwoop.defaultProps = {
  title: '',
  subtitle: '',
};

export default RoutedSchwoop;
