const fr = {
  button: {
    accept: 'Accepter',
    refuse: 'Refuser',
  },
  termOfUse: 'Conditions d’utilisation',
  effectiveAsOf: "En vigueur à compter d'avril 2021",
  definitions: 'DÉFINITIONS',
  services: '« Services »',
  servicesDefinition: 'Les sites Internet, applications mobiles publiés et exploités par QSL et les services offerts et rendus par leur entremise.',
  qslOrWe: '« QSL » or « nous »',
  qslOrWeDefinition: 'QSL Canada inc., QSL America inc. ainsi que leurs filiales et compagnies apparentées',
  informationDataContent: '« Information » ou\n« Données » ou\n« Contenu »',
  informationDataContentDefinition: 'Toute information ou donnée rendue disponible par l’entremise des Services.',
  youUser: '« vous » ou\n« l’Utilisateur »',
  youUserDefinition: 'La personne physique autorisée à accéder et à utiliser les Services et toute organisation pour laquelle il agit.',
  object: 'Objet',
  objectParagraphOne:
    "Ce Contrat entre vous et QSL vous lie et s’applique à compter du moment auquel vous accédez ou utilisez les Services. Vous déclarez et garantissez que vous avez l'autorité d'accepter ce Contrat en votre nom et au nom de toute personne ou société que vous représentez. Si vous n’acceptez pas l’ensemble des dispositions de ce Contrat, vous n’êtes pas autorisé à utiliser les Services.",
  objectParagraphTwo:
    'QSL se réserve le droit de modifier ces Conditions d’utilisation. Toute modification fera l’objet d’une notification. Votre utilisation des Services à la suite d’un telle notification constitue une acceptation de ces modifications.',
  userAccountAndCredential: 'Comptes d’Utilisateurs et Identifiants',
  userAccountAndCredentialParagraphOne:
    'Certains Services sont accessibles uniquement aux Utilisateurs auxquels un nom d’utilisateur et un mot de passe (les « Identifiants ») ont été émis conformément aux modalités applicables. Vous êtes responsable de l’utilisation qui sera faite des Services par l’entremise des Identifiants, et tout accès et utilisation effectuée avec les Identifiants émis sera réputé avoir été effectué par vous.',
  userAccountAndCredentialParagraphTwo:
    "Chaque Utilisateur est entièrement responsable (a) du maintien de la confidentialité et de la sécurité des Identifiants (b) de ne pas les divulguer ou en permettre l’utilisation par des tiers, (c) de la conformité de toute utilisation des Identifiants, et (d) d’aviser immédiatement QSL si vous savez ou soupçonnez que vos Identifiants ont été perdus, volés, sont connus d'une autre personne ou utilisés par une autre personne, en transmettant un message à cet effet à info@qsl.com.",
  rightOfUseAndProhibitedUse: 'Droit d’utilisation et interdictions',
  rightOfUseAndProhibitedUseListTitle: 'Vous êtes autorisé à accéder et à utiliser les Services uniquement :',
  rightOfUseAndProhibitedUseListItemOne: 'dans le cadre des activités de votre entreprise;',
  rightOfUseAndProhibitedUseListItemTwo:
    'conformément aux droits d’accès qui vous sont consentis; et, en conformité avec ce contrat et avec les lois applicables.',
  rightOfUseAndProhibitedUseParagraphOne:
    'Les Utilisateurs doivent s’abstenir de toute action susceptible d’affecter l’accessibilité, la sécurité, l’intégrité et la fonctionnalité des Services et s’abstenir de toute tentative d’accès non autorisé à des Données d’autres utilisateurs.',
  rightOfUseAndProhibitedUseParagraphTwo:
    "QSL pourra sans avis préalable, et sans engager sa responsabilité envers vous ou toute autre personne, résilier ce Contrat ou révoquer votre autorisation d'accéder et d'utiliser les Services dans l’éventualité où i) vous faites défaut de respecter le présent Contrat, ii) en cas d’utilisation inappropriée ou illicite ou iii) si cela s’avère nécessaire afin de prévenir ou éviter l’occurrence d’un préjudice.",
  informativeContent: 'Contenu informatif',
  informativeContentParagraphOne:
    'Les documents et l’Information rendus disponibles par l’entremise des Services sont fournis à titre indicatif seulement, ne constituent pas des originaux, et sont susceptibles de ne pas être exhaustif et de contenir des erreurs. En cas de divergence, les documents et l’information reçus, générés, traités, remis ou expédiés dans le cadre de nos services d’arrimage, de l’opération de terminaux portuaires et de nos solutions de transport prévaudront sur les documents et l’Information rendus disponibles par l’entremise des Services.',
  informativeContentParagraphTwo:
    'Les Services peuvent contenir des liens vers des sites Internet et des renseignements appartenant à des tiers à des fins de commodité. QSL n’exerce aucun contrôle ni aucune influence sur le Contenu de ceux-ci, n’assume aucune responsabilité à l’égard de l’utilisation que vous en faites.',
  warrantyExclusionAndLiabilityExclusion: 'Exclusion de garantie / Exonération de Responsabilité',
  warrantyExclusionAndLiabilityExclusionParagraphOne:
    'QSL s’engage à déployer les ressources, à utiliser les processus et la technologie appropriés, et à faire preuve de diligence raisonnable afin de fournir les Services de façon optimale, et de publier du Contenu intègre et fiable.',
  warrantyExclusionAndLiabilityExclusionParagraphTwo:
    'Étant donnée la nature des Services, QSL exclut expressément toute garantie de quelque nature que ce soit à l’égard des Services et du Contenu, et aucune responsabilité de quelque nature que ce soit ne pourra être imputée à QSL en regard de tout dommage ou préjudice découlant directement ou indirectement de l’utilisation ou de la non disponibilité en tout ou en partie des Services ou de l’inexactitude ou du caractère incomplet du Contenu rendu disponible par l’entremise des Services, sauf en cas de faute intentionnelle ou de négligence grossière de la part d’QSL.',
  warrantyExclusionAndLiabilityExclusionParagraphThree:
    'Vous accédez et utilisez les Services sans aucune garantie et les acceptez « tel quel ». QSL ne sera en aucun cas responsable envers vous ou toute autre personne de quelque préjudice que ce soit, direct ou indirect.',
  personalInformation: 'Renseignements personnels',
  personalInformationParagraphOne:
    'QSL ne recueillera que les renseignements personnels que vous fournirez aux fins de la création d’un compte d’utilisateur et dans le cadre de l’utilisation des Services et ne les utilisera qu’aux seules fins requises pour fournir les Services en conformité avec ce Contrat et les lois applicables.',
  availabilityOfTheService: 'Disponibilité du Service',
  availabilityOfTheServiceListTitle:
    'QSL vise à assurer une disponibilité des Services la plus élevée possible, mais ne peut néanmoins garantir l’accès et la disponibilité sans interruption des Services. QSL se réserve le droit d’interrompre la fourniture des Services et l’accès à ceux-ci, notamment dans les cas suivants:',
  availabilityOfTheServiceListItemOne: 'Pour les besoins d’entretien des Services ;',
  availabilityOfTheServiceListItemTwo: 'Pour l’amélioration et l’installation de nouvelles fonctionnalités ;',
  availabilityOfTheServiceListItemThree: 'Pour la vérification et l’audit du bon fonctionnement des Services ;',
  availabilityOfTheServiceListItemFour: 'En cas de panne ou de risque de panne.',
  intellectualProperty: 'Propriété intellectuelle et droits relatifs aux Services',
  intellectualPropertyParagraphOne:
    "Outre les droits expressément consentis dans ce Contrat, l’accès et l’utilisation des Services ne vous confèrent aucunement la propriété ou tout autre droit quant aux Services, au Contenu ou à la technologie sous-jacente. Il est interdit de copier, de reproduire, de republier, de télécharger, d'afficher, de transmettre, de modifier, d'indexer, de cataloguer, de décompiler, de prendre une copie image miroir ou de distribuer, de quelque façon que ce soit le Contenu des Services en tout ou en partie, sans le consentement écrit de QSL",
  intellectualPropertyParagraphTwo:
    'Il vous est expressément interdit d’utiliser les marques de commerce, les noms commerciaux, les logos, les signatures, et les slogans (les « marques de commerce ») affichés dans les Services ou les autres renseignements ou documents incorporée aux Contenu, sauf dans la mesure permise dans les présentes.',
  term: 'Durée',
  termParagraphOne:
    'Les droits d’accès et d’utilisation des Services sont consentis pour une durée indéterminée débutant à compter de l’accès aux Services, ou le cas échéant, de la création d’un compte d’Utilisateur.',
  applicableLaw: 'Droit applicable',
  applicableLawParagraphOne:
    'Cette Convention est régie et interprétée conformément aux lois applicables dans la province de Québec. Les deux parties conviennent de se soumettre à la juridiction du tribunal compétent siégeant dans le district judiciaire de Québec, province de Québec, en regard de tout différend relatif à cette Convention. La version française aura préséance sur la version anglaise en cas de disparité entre les versions.',
  contact: 'Contact',
  contactEmail: 'Courriel : info@qsl.com',
  contactParagraph:
    'Je confirme avoir lu et accepter les Conditions d’utilisation au nom de l’organisation que je représente. Je reconnais qu’en cas de divergence, les documents et l’information reçus, générés, traités, remis ou expédiés dans le cadre des services d’arrimage, de l’opération de terminaux portuaires et des solutions de transport de QSL, prévaudront sur les documents et l’information rendus disponibles par l’entremise des Services.',
};

export default fr;
