import _get from 'lodash.get';
import {
  formatCustomerRelease,
  formatDeckLocation,
  formatUtcDatetime,
  getGrossWeightOrWeight,
  getHazmatLabels,
  getHoldType,
  i18nInventory,
} from '../helpers/formatDataHelper';

const formatData = (dataList, columnList, t) => {
  const requiredColumnsForReport = [{ key: 'hazardousMaterialInformation' }, { key: 'locationInVesselHoldNumber' }, { key: 'locationInVesselHoldType' }];

  const columnsKeys = [...columnList, ...requiredColumnsForReport];

  const data = dataList.map((item) => {
    const row = {};
    columnsKeys.forEach((c) => {
      if (!c.key) return;
      row[c.key] = _get(item, c.key);
    });

    const { hazardousMaterialInformation: hmi, locationInVesselHoldNumber, locationInVesselHoldType } = row;
    const { ltdClass, un, pg } = getHazmatLabels(hmi);

    const grossWeightPerUnit = getGrossWeightOrWeight(item);

    row.ltdClass = ltdClass;
    row.un = un;
    row.pg = pg;
    row.weightPerUnit = grossWeightPerUnit;
    row.totalWeight = !item.parentContainerId ? (grossWeightPerUnit ?? 0) * row.quantity : '';
    row.totalVolume = !item.parentContainerId ? (row.volumePerUnit ?? 0) * row.quantity : '';
    row.freightTon = !item.parentContainerId ? row.freightTon : '';
    row.containerStatus = row.containerStatus ? t(`containerStatuses.${row.containerStatus}`) : undefined;
    row.shipmentScenarioItemStatus = row.shipmentScenarioItemStatus ? t(`shipmentStatus.${row.shipmentScenarioItemStatus}`) : undefined;
    row.dockLocation = item.formattedDeckLocation || formatDeckLocation(locationInVesselHoldNumber, getHoldType(locationInVesselHoldType));
    row.financialRelease = _get(row, 'financialRelease.released') ? t('financialRelease') : undefined;
    row.shippingLineRelease = _get(row, 'shippingLineRelease.released') ? t('customerRelease') : undefined;
    row.scanDate = formatUtcDatetime(row.scanDate);
    row.weightHasBeenVerified = row.weightHasBeenVerified ? t('yes') : t('no');
    row.actualDeckLocation = item.actualLocationsInVessel
      ? item.actualLocationsInVessel.map((x) => formatDeckLocation(x.locationInVesselHoldNumber, x.locationInVesselHoldType)).join('; ')
      : undefined;
    row.customerRelease = formatCustomerRelease(_get(row, 'customerRelease.released'), i18nInventory, t);

    return row;
  });

  return data;
};

export default formatData;
