export const actionTypes = {
  relocate: 'Relocate',
  stuff: 'Stuff',
  transfer: 'Transfer',
  transferIn: 'TransferIn',
  transferOut: 'TransferOut',
  transferredIn: 'TransferredIn',
  transferredOut: 'TransferredOut',
  unseal: 'Unseal',
  seal: 'Seal',
  unstuff: 'Unstuff',
  receptionByContainer: 'ReceptionByContainer',
  receptionByVessel: 'ReceptionByVessel',
  receptionByTruck: 'ReceptionByTruck',
  receptionByRailcar: 'ReceptionByRailcar',
  receptionByBarge: 'ReceptionByBarge',
  receptionByOther: 'ReceptionByOther',
  shipmentByVessel: 'ShipmentByVessel',
  shipmentByTruck: 'ShipmentByTruck',
  shipmentByRailcar: 'ShipmentByRailcar',
  shipmentByBarge: 'ShipmentByBarge',
  shipmentByOther: 'ShipmentByOther',
  move: 'Move',
  moveFrom: 'MoveFrom',
  moveTo: 'MoveTo',
  merge: 'Merge',
  mergeFrom: 'MergeFrom',
  mergeTo: 'MergeTo',
  split: 'Split',
  splitFrom: 'SplitFrom',
  splitTo: 'SplitTo',
  transform: 'Transform',
  transformFrom: 'TransformFrom',
  transformTo: 'TransformTo',
  mainInformationModification: 'MainInformationModification',
  identifierModification: 'IdentifierModification',
  dimensionOrWeightModification: 'DimensionOrWeightModification',
  hazmatModification: 'HazmatModification',
  missingItemResolution: 'MissingItemResolution',
  adjustment: 'Adjustment',
};

export const romSomActions = [
  actionTypes.receptionByBarge,
  actionTypes.receptionByOther,
  actionTypes.receptionByRailcar,
  actionTypes.receptionByTruck,
  actionTypes.receptionByVessel,
  actionTypes.shipmentByVessel,
  actionTypes.shipmentByBarge,
  actionTypes.shipmentByOther,
  actionTypes.shipmentByRailcar,
  actionTypes.shipmentByTruck,
];
