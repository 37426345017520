import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const toLocalDate = (utcDateTime) => {
  if (!utcDateTime) return undefined;
  return dayjs.utc(utcDateTime).local().format('YYYY-MM-DD');
};

export const toLocalDateTime = (utcDateTime) => {
  if (!utcDateTime) return undefined;
  return dayjs.utc(utcDateTime).local().format('YYYY-MM-DD HH:mm');
};
