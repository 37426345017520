const profile = {
  changePassword: 'Change my password',
  email: 'Email',
  name: 'Full Name',
  passwordChangeEmailMessage: "We've just sent you an email to reset your password.",
  passwordExpired: 'Password Expired',
  passwordExpiredMessage: 'Your password expired. We sent you an email with instructions for changing your password.',
  profile: 'My Profile',
  profileInformation: 'Profile Information',
};

export default profile;
