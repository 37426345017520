export const navigateTo =
  (toUrl, toNavigate) =>
  ({ row }, event) => {
    const url = toUrl(row);
    navigateToRoute(url, toNavigate, event);
  };

export const navigateToRoute = (route, navigate, event) => {
  if (event.ctrlKey || event.metaKey) {
    window.open(route, '_blank');
  } else {
    navigate(route);
  }
};
