import { useAuth0 } from '@auth0/auth0-react';
import { Button, Divider, Menu, MenuItem } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../Router';
import { UserGroupContext } from '../../UserGroupContext';
import LanguageToggler from './Language/LanguageToggler';
import AssistanceModal from './AssistanceModal';
import useAgent from '../../hooks/useAgent';
import userGuideAgents from '../../common/agents/userGuideAgents';

const ProfileMenu = () => {
  const { logout } = useAuth0();
  const { push } = useHistory();
  const { t, i18n } = useTranslation();
  const { groups } = useContext(UserGroupContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const [showAssistanceModal, setShowAssistanceModal] = useState(false);

  const [downloadUserGuide] = useAgent(userGuideAgents.getUserGuide);

  const isWorker = groups.includes('worker');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = useCallback(() => {
    sessionStorage.clear();
    localStorage.removeItem('listType');
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const onProfileClick = useCallback(() => {
    push(ROUTES.profile.path);
  }, [push]);

  return (
    <>
      <Button onClick={handleClick} data-testid="button_profile">
        <PermIdentityIcon color="primary" />
      </Button>
      <Menu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <LanguageToggler />
        <MenuItem onClick={onProfileClick} data-testid="menuItem_myProfile">
          {t('profile')}
        </MenuItem>
        <Divider />
        {!isWorker && (
          <MenuItem onClick={() => downloadUserGuide(i18n.language)} data-testid="menuItem_userGuide">
            {t('userGuide')}
          </MenuItem>
        )}
        <MenuItem onClick={() => setShowAssistanceModal(true)} data-testid="menuItem_support">
          {t('support')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={onLogout} data-testid="menuItem_logout">
          {t('logout')}
        </MenuItem>
      </Menu>
      <AssistanceModal open={showAssistanceModal} onClose={() => setShowAssistanceModal(false)}></AssistanceModal>
    </>
  );
};

export default ProfileMenu;
