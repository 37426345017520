import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ClientInventoriesTable = ({ inventories }) => {
  const { t } = useTranslation('reports');
  const { values, setFieldValue } = useFormikContext();

  const setInventories = (event) => {
    let newInventoriesIds = values.inventoriesIds || [];
    const id = +event.target.id;

    if (event.target.checked) {
      newInventoriesIds.push(id);
    } else {
      newInventoriesIds = newInventoriesIds.filter((inventory) => inventory !== id);
    }

    setFieldValue('inventoriesIds', newInventoriesIds);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={'checkBoxCell'}></TableCell>
            <TableCell align="left" style={{ fontWeight: 'bold' }}>
              {t('number')}
            </TableCell>
            <TableCell align="left" style={{ fontWeight: 'bold' }}>
              {t('name')}
            </TableCell>
            <TableCell align="left" className={'siteCell'} style={{ fontWeight: 'bold' }}>
              {t('site')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inventories &&
            inventories.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="left">
                  <Checkbox id={row.id.toString()} color="primary" checked={values.inventoriesIds.includes(row.id)} onChange={setInventories}></Checkbox>
                </TableCell>
                <TableCell align="left">{String(row.id).padStart(5, '0')}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.site.name}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ClientInventoriesTable.propTypes = {
  inventories: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
};

export default ClientInventoriesTable;
