const volumeUnits = {
  BoardFoot: 'FBM',
  CubicBoard: 'CBM',
  CubicFoot: 'pi.cu.',
  CubicMeter: 'm³',
};

const surfaceAreaUnits = {
  SquareFoot: 'pi.ca.',
  SquareMeter: 'm²',
};

const weightAndDimensions = {
  'kg/m': 'kg/m',
  'lbs/ft': 'lbs/pi',
  cbm: 'CBM',
  cm: 'cm',
  cubicFoot: 'pi.cu.',
  cubicMeter: 'm³',
  diameter: 'Diamètre',
  false: 'Non',
  fbm: 'FBM',
  footpound: 'Pied livre',
  ft: 'pi',
  height: 'Hauteur',
  in: 'po',
  kg: 'kg',
  lb: 'lb',
  length: 'Longueur',
  LT: 'LT',
  m: 'm',
  mass: 'Masse',
  mm: 'mm',
  MT: 'MT',
  NT: 'NT',
  thickness: 'Épaisseur',
  true: 'Oui',
  volume: 'Volume',
  squareFoot: 'pi.ca.',
  squareMeter: 'm²',
  surfaceArea: 'Superficie',
  weight: 'Poids',
  width: 'Largeur',
  weightAndDimensions: 'Poids & Dimensions',
  weightAndDimensionsAcronym: 'Pds & Dim',
  weightHasBeenVerified: 'Poids pesé',
  min: 'Min',
  max: 'Max',
  unit: 'Unité',
  stuffedContainerWeight: 'Poids du contenu',
  ...volumeUnits,
  ...surfaceAreaUnits,
};

export default weightAndDimensions;
