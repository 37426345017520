import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { CompaniesContext } from './CompaniesContextProvider';

const formatCompanyList = (currentCompanies) => {
  if (currentCompanies.length === 0) return '';
  const companyName = currentCompanies[0].name;

  return currentCompanies.length > 1 ? `${companyName} +${currentCompanies.length - 1}` : companyName;
};

const ViewAsCompanyButton = () => {
  const { t } = useTranslation('layout');
  const { currentCompanies, selectCurrentCompanies, viewAsCompany } = useContext(CompaniesContext);
  if (!viewAsCompany) return null;

  return (
    <Button data-testid="button_viewAs" onClick={selectCurrentCompanies}>
      <p className="underline">
        {t('asCompany')} {formatCompanyList(currentCompanies)}
      </p>
    </Button>
  );
};

export default ViewAsCompanyButton;
