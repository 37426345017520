import PropTypes from 'prop-types';
import Title from '../../Title';
import ListPageHeaderLayout from './component/ListPageHeaderLayout';

const SimpleListPageHeader = ({ title, actions }) => {
  return (
    <ListPageHeaderLayout>
      <Title title={title} />
      {actions}
    </ListPageHeaderLayout>
  );
};

SimpleListPageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.object]),
};

SimpleListPageHeader.defaultProps = {
  actions: null,
  title: null,
};

export default SimpleListPageHeader;
