import _uniqWith from 'lodash.uniqwith';
import _isEqual from 'lodash.isequal';
import Moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { isoDateTimeFormat } from '../../constant';

export const getHoldType = (holdType) => {
  switch (holdType) {
    case 0:
      return 'Bay';
    case 1:
      return 'FrontDeck';
    case 2:
      return 'LDeck';
    case 3:
      return 'LowerHold';
    case 4:
      return 'HatchTwinDeck';
    case 5:
      return 'Deck';
    case 6:
      return 'PortSide';
    case 7:
      return 'StarboardSide';
    default:
      return 'Not Selected';
  }
};

export const formatDeckLocation = (holdNumber, holdType) => [holdNumber, holdType].filter(Boolean).join('-') || 'Not selected';

export const formatUtcDatetime = (utcDatetime) => {
  if (!utcDatetime) return '';
  return Moment.tz(utcDatetime, Moment.tz.guess()).format(isoDateTimeFormat);
};

export const formatCustomerRelease = (isReleased, i18nFunction, t) => {
  switch (isReleased) {
    case true:
      return i18nFunction('released', t);
    case false:
      return i18nFunction('onHold', t);
    default:
      return i18nFunction('released', t);
  }
};

export const i18nInventory = (key, t, options) => t(`${key}`, options);

export const getGrossWeightOrWeight = ({ grossWeightPerUnit: grossWeight, weightPerUnit: weight }) => grossWeight || weight;

const formatToHazmatLabel = (values) => {
  const hmiSeparator = ';';
  const uniqueValues = _uniqWith(values, isEqual);
  uniqueValues.sort();
  return uniqueValues.join(hmiSeparator);
};

const isEqual = (item1, item2) => {
  const { id: removed, ...item1WithoutId } = item1;
  const { id: removed2, ...item2WithoutId } = item2;
  return _isEqual(item1WithoutId, item2WithoutId);
};

export const getHazmatLabels = (hmi) => {
  const filteredHmi = _uniqWith(hmi, isEqual);
  const dangerClassLabels = filteredHmi.map((x) => `${x.hasLimitedQuantity ? 'LTD Qty' : ''} ${x.hazardClass}`);
  const unNumbers = filteredHmi.map((x) => x.unNumber);
  const packagingGroups = filteredHmi.map((x) => x.packagingGroup).filter(Boolean);

  return {
    ltdClass: formatToHazmatLabel(dangerClassLabels),
    un: formatToHazmatLabel(unNumbers),
    pg: formatToHazmatLabel(packagingGroups),
  };
};
