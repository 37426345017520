import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from '../fields/SelectField';
import SelectInput from '../fields/SelectInput';
import TextField from '../fields/TextField';
import TextInput from '../fields/TextInput';
import { getIdentifierLabelOptions } from './helpers/optionsHelper';

const baseNewLine = {
  label: null,
  value: '',
};

const makeDeleteButtonStyles = makeStyles(() => ({
  root: {
    paddingBottom: 0,
  },
}));

const makeDeleteIconStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

const makeInputTextStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

const makeInputStyles = makeStyles(() => ({
  root: {
    paddingRight: '1rem',
  },
}));

const makeRowStyles = makeStyles(() => ({
  root: {
    marginTop: '1rem',
  },
}));

const IdentifiersFilter = ({ name, push, remove }) => {
  const labelRef = React.useRef();
  const valueRef = React.useRef();

  const { values } = useFormikContext();
  const { t } = useTranslation();

  const deleteButtonStyles = makeDeleteButtonStyles();
  const deleteIconStyles = makeDeleteIconStyles();
  const inputStyles = makeInputStyles();
  const inputTextStyles = makeInputTextStyles();
  const rowStyles = makeRowStyles();

  const [newLine, setNewLine] = useState(baseNewLine);

  const identifiers = values[name] || [];

  const handleNewRow = useCallback(
    (inputRef) => async (event) => {
      if (event.target.value !== null && event.target.value !== '') {
        await push({ ...baseNewLine, [event.target.name]: event.target.value });
        setNewLine(baseNewLine);
        inputRef.current.focus();
      }
    },
    [push]
  );

  const handleNewLineValueChange = useCallback(
    (name) => (event) => {
      setNewLine({ [name]: event.target.value });
    },
    []
  );

  const availableLabelOptions = getIdentifierLabelOptions({ identifiers, translator: t, hasEmptyOption: true });

  return (
    <Grid container>
      {identifiers.map((identifier, index) => {
        const isLastLine = index === identifiers.length - 1;
        return (
          <Grid classes={rowStyles} key={`${name}.${index}`} container>
            <Grid classes={inputStyles} item xs={5} md={2}>
              <SelectField
                label={t('identifiers.identifiers')}
                name={`${name}.${index}.label`}
                options={getIdentifierLabelOptions({ currentIdentifier: identifier, identifiers, translator: t, hasEmptyOption: true })}
                inputRef={isLastLine ? labelRef : null}
              />
            </Grid>
            <Grid classes={inputStyles} item xs={6} md={3}>
              <TextField classes={inputTextStyles} name={`${name}.${index}.value`} inputRef={isLastLine ? valueRef : null} />
            </Grid>
            <Grid classes={deleteIconStyles} item xs={1} md={1}>
              <IconButton classes={deleteButtonStyles} size="small" aria-label="delete" onClick={() => remove(index)}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
      {!!availableLabelOptions?.length && (
        <Grid container>
          <Grid classes={inputStyles} item xs={5} md={2}>
            <SelectInput
              disableCloseOnSelect
              label={t('identifiers.identifiers')}
              name="label"
              onChange={handleNewRow(labelRef)}
              options={availableLabelOptions}
              value={newLine.label}
            />
          </Grid>
          <Grid classes={inputStyles} item xs={6} md={3}>
            <TextInput
              classes={inputTextStyles}
              name="value"
              onBlur={handleNewRow(valueRef)}
              onChange={handleNewLineValueChange('value')}
              value={newLine.value}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

IdentifiersFilter.propTypes = {
  name: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};

export default IdentifiersFilter;
