import { maxNumberOfIdentifiers } from '../../constant';

const hazardClassComparator = (a, b) => {
  if (a.hasLimitedQuantity && !b.hasLimitedQuantity) {
    return 1;
  }

  if (b.hasLimitedQuantity && !a.hasLimitedQuantity) {
    return -1;
  }

  if (a.value < b.value) return -1;
  if (a.value > b.value) return 1;
  return 0;
};

export const mapHazardousInfoForList = (hmi) => {
  const baseInfo = { hazardClass: [], packagingGroup: [], unNumber: [] };
  if (Array.isArray(hmi)) return baseInfo;

  const hazardInfo = hmi.reduce((info, { hasLimitedQuantity, hazardClass, packagingGroup, unNumber }) => {
    if (hazardClass && !info.hazardClass.some((hc) => hc.value === hazardClass && hc.hasLimitedQuantity === hasLimitedQuantity))
      info.hazardClass.push({ value: hazardClass, hasLimitedQuantity });
    if (unNumber && !info.unNumber.includes(unNumber)) info.unNumber.push(unNumber);
    if (packagingGroup && !info.packagingGroup.includes(packagingGroup)) info.packagingGroup.push(packagingGroup);
    return info;
  }, baseInfo);

  hazardInfo.hazardClass.sort(hazardClassComparator);
  hazardInfo.packagingGroup.sort();
  hazardInfo.unNumber.sort();

  return hazardInfo;
};

const toCamelCase = (label) => {
  const isString = typeof label === 'string' && label.length > 1;
  return isString ? label.charAt(0).toLowerCase() + label.substring(1) : '';
};

export const mapIdentifiersAsProperties = (items) => {
  if (!items) return items;
  return items.map(mapIdentifierAsProperties);
};

const mapIdentifierAsProperties = (item) => {
  const identifierColumns = {};
  for (let i = 1; i <= maxNumberOfIdentifiers; i += 1) {
    const label = item[`identifierLabel${i}`];
    if (label) identifierColumns[toCamelCase(label)] = item[`identifierValue${i}`];
  }
  return { ...item, ...identifierColumns };
};

export const itemToInventoryItemDataList = (item) => ({
  ...item,
  productTypeName: item.productType ? item.productType.name : null,
  sealNumbers: Array.isArray(item.sealNumbers) ? item.sealNumbers.join() : item.sealNumbers,
  ...mapHazardousInfoForList(item.hazardousMaterialInformation),
});
