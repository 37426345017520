import { TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const TextInput = ({ label, name, ...props }) => {
  return <TextField {...props} id={name} name={name} autoComplete="off" fullWidth label={<Typography variant="body2">{label}</Typography>} />;
};

TextInput.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

TextInput.defaultProps = {
  classes: null,
  label: '',
  name: '',
  onBlur: undefined,
  onChange: undefined,
  value: '',
};

export default TextInput;
