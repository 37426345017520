import axios from 'axios';
import download from '../../common/helpers/download';
import { noContentCode } from '../../constant';

export const url = `/api/v1/reports/inventory-by-date`;

export const getClientInventories = async (siteIds, receiverId, cargoType) => {
  const response = await axios.get(`${url}/client-inventories`, {
    params: {
      siteIds,
      receiverId,
      cargoType,
    },
  });
  return response.data;
};

export const headClientOnlyReceiver = async (siteIds, receiverId, cargoType) => {
  const response = await axios.head(`${url}/client-inventories`, {
    params: {
      siteIds,
      receiverId,
      cargoType,
    },
  });
  return response.headers['x-client-only-receiver'] === 'True';
};

const generateFileName = (values) => {
  const date = new Date(values.date);
  const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
  const params = [values.cargoType, dateString].filter(Boolean).join('-');

  return `Inventory-by-date-${params}.xlsx`;
};

export const getReport = async (siteIds, receiverId, date, clientInventoryIds, cargoType) => {
  const response = await axios.get(url, {
    params: {
      siteIds,
      receiverId,
      cargoType,
      date,
      clientInventoryIds,
    },
    responseType: 'blob',
  });

  if (response.status === noContentCode) {
    return undefined;
  }

  download(response.data, generateFileName({ cargoType, date }));
  return response.data;
};

export const getSites = (cargoType) => {
  return axios.get(`${url}/sites`, {
    params: {
      cargoType,
    },
  });
};

export const getReceivers = (params) => {
  return axios.get(`${url}/receivers`, {
    params: params,
  });
};
