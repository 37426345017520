import { Icon as MuiIcon, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getStyleForSize } from './iconHelpers';

export const iconSizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const iconStyles = (size, styles) =>
  makeStyles({
    root: {
      ...getStyleForSize(size),
      ...styles,
    },
  });

const Icon = ({ children, size, styles }) => <MuiIcon classes={iconStyles(size, styles)()}>{children}</MuiIcon>;

Icon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  size: PropTypes.oneOf(Object.values(iconSizes)),
  styles: PropTypes.shape(),
};

Icon.defaultProps = {
  size: iconSizes.medium,
  styles: {},
};

export default Icon;
