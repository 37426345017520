import LayoutPage from '../layout/LayoutPage';
import AppointmentTabSelector from './list/AppointmentTabSelector';

const AppointmentPage = () => {
  return (
    <LayoutPage>
      <AppointmentTabSelector />
    </LayoutPage>
  );
};

export default AppointmentPage;
