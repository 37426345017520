import { UrlBuilder } from '../common/HashedRouter';
import { actionTypes } from './actionTypes';

const onRowClick =
  ({ fetchBatchAction, modificationPattern, location, push }) =>
  (transaction) => {
    if (!transaction) return;

    switch (transaction.actionType) {
      case actionTypes.seal:
      case actionTypes.unseal:
        if (!transaction.inventoryItemId) return;
        push(`/inventory/${transaction.mainInventoryId}/items/${transaction.inventoryItemId}`);
        break;
      case actionTypes.stuff:
      case actionTypes.unstuff:
        fetchBatchAction(transaction.mainInventoryId, transaction.batchActionId).then(({ destinationItem }) => {
          if (!destinationItem) return;
          push(`/inventory/${transaction.mainInventoryId}/items/${destinationItem.id}`);
        });

        break;
      case actionTypes.dimensionOrWeightModification:
      case actionTypes.hazmatModification:
      case actionTypes.mainInformationModification:
      case actionTypes.identifierModification:
        push(
          UrlBuilder.buildUrl(location, modificationPattern, {
            inventoryId: transaction.mainInventoryId,
            itemId: transaction.inventoryItemId,
            date: transaction.effectiveDate,
          })
        );
        break;

      default:
        return;
    }
  };

const TransactionpageHelper = { onRowClick };
export default TransactionpageHelper;
