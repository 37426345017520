const batchActionTypes = {
  Relocate: 'Relocate',
  Stuff: 'Stuff',
  Transfer: 'Transfer',
  Unstuff: 'Unstuff',
  Unseal: 'Unseal',
  Move: 'Move',
  ResolveMissingItem: 'Resolve Missing Item',
};

export default batchActionTypes;
