import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DialogContent, Dialog, DialogTitle, Button, Typography, Grid, Box, makeStyles, useMediaQuery } from '@material-ui/core';
import LaborAvailabilityModalContent from './LaborAvailabilityModalContent';
import { Formik } from 'formik';
import AvailabilityTypeEnum from './enum/AvailabilityTypeEnum';
import { AvailabilityTypes } from '@vooban/tc3-core/laborAvailability';
import { useTranslation } from 'react-i18next';
import LaborAvailabilityModalValidationSchema from './validation/LaborAvailabilityModalValidationSchema';
import Breakpoint from '../../layout/mediaQuery';

const LaborAvailabilityModal = ({ open, handleClose, handleSubmit, availabilityTypes, descriptionRequired, availability }) => {
  const getAvailabilityType = useCallback(() => {
    if (availability?.availabilityTypeNameEn) return availabilityTypes.find(({ nameEn }) => nameEn === availability?.availabilityTypeNameEn);
    else return availabilityTypes.find(({ nameFr }) => nameFr === availability?.availabilityTypeNameFr);
  }, [availability, availabilityTypes]);

  const getInitialValues = useCallback(
    () => ({
      availability: availability?.availability ?? AvailabilityTypeEnum.Available,
      type: getAvailabilityType()?.id.toString() ?? null,
      description: availability?.availabilityReason,
    }),
    [availability, getAvailabilityType]
  );

  const [initialValues, setInitialValues] = React.useState(getInitialValues());

  useEffect(() => {
    if (open) {
      setInitialValues(getInitialValues());
    }
  }, [open, getInitialValues]);

  const useDialogContentStyle = makeStyles({
    buttons: { display: 'flex', flexDirection: 'row', alignContent: 'flex-end' },
    marginLeft: { marginLeft: 10 },
  });

  const classes = useDialogContentStyle();

  const { t } = useTranslation(['laborAvailability']);

  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);

  return (
    open && (
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={LaborAvailabilityModalValidationSchema(availabilityTypes, descriptionRequired, t)}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize>
        {({ submitForm, isValid, dirty }) => (
          <Dialog fullScreen={!isTabletOrLarger} open={open} onClose={handleClose} className="worker-mobile -landscape-rotate">
            <DialogTitle>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography color="primary" variant="h4">
                    {t('selectAvailability')}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Box className={classes.buttons}>
                    <Button onClick={handleClose} color="default" variant="contained">
                      {t('cancel')}
                    </Button>
                    <Button className={classes.marginLeft} disabled={!dirty || (dirty && !isValid)} color="primary" variant="contained" onClick={submitForm}>
                      {t('save')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.modal}>
              <LaborAvailabilityModalContent availabilityTypes={availabilityTypes} descriptionRequired={descriptionRequired} />
            </DialogContent>
          </Dialog>
        )}
      </Formik>
    )
  );
};

LaborAvailabilityModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  availabilityTypes: PropTypes.arrayOf(PropTypes.shape(AvailabilityTypes)).isRequired,
  descriptionRequired: PropTypes.bool.isRequired,
  availability: PropTypes.shape(AvailabilityTypes),
};

export default LaborAvailabilityModal;
