import { MassEnum } from '@vooban/tc3-core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CARGO_TYPE } from '../../../cargoType';
import { formatNumberWithAccounting } from '../../../common/helpers/numbers';
import columnsSetting from '../../../layout/Datagrid/ColumnSetting';
import { StaticListPageWithPersisted } from '../../../layout/ListPage';
import MovementType from './MovementType';

const defaultDisplayWeightDecimalPlaces = 4;

const formatQuantity = (t, itemCargoType) => (totalQuantity) => {
  const isBreakBulk = itemCargoType === CARGO_TYPE.breakbulk;
  return isBreakBulk
    ? formatNumberWithAccounting(totalQuantity, 0)
    : `${formatNumberWithAccounting(totalQuantity, defaultDisplayWeightDecimalPlaces)} ${t(`units.${MassEnum.MT}`)}`;
};

const formatDescription = () => (value) => {
  return <div dangerouslySetInnerHTML={{ __html: value.toString() }} />;
};

const ItemMovementList = (props) => {
  const { t } = useTranslation('inventory');

  const effectiveDate = columnsSetting('effectiveDate').withFormatDate().withWidth(140);
  const movement = columnsSetting('movementDescription').withCustomFormat(formatDescription());
  const actionNumber = columnsSetting('actionNumber').withWidth(170);
  const movedQuantity = columnsSetting('movedQuantity').withCustomFormat(formatQuantity(t, props.cargoType)).withAlignRight().withWidth(170);
  const bookQuantity = columnsSetting('totalQuantity').withCustomFormat(formatQuantity(t, props.cargoType)).withAlignRight().withWidth(170);

  const columns = [effectiveDate, movement, actionNumber, movedQuantity, bookQuantity].map((x) => x.withIsVisible().withoutSort());

  return (
    <StaticListPageWithPersisted
      data={{ items: props.itemMovements, totalItems: props.itemMovements.length }}
      defaultColumns={columns}
      header={props.header}
      height={props.height}
      hasLoadMore={false}
      title={props.title}
      translater={t}
      rowKey="id"
      noRowLink
    />
  );
};

ItemMovementList.propTypes = {
  cargoType: PropTypes.string,
  header: PropTypes.elementType,
  height: PropTypes.number,
  itemMovements: PropTypes.arrayOf(PropTypes.shape(MovementType)).isRequired,
  title: PropTypes.string,
};

ItemMovementList.defaultProps = {
  cargoType: undefined,
  header: undefined,
  height: undefined,
  title: undefined,
};

export default ItemMovementList;
