import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import columnsSetting from '../layout/Datagrid/ColumnSetting';
import DataGrid from '../layout/Datagrid/Datagrid';
import LoadingIndicator from '../layout/loading/LoadingIndicator';
import { useDownloadMediaFile } from './hooks/useGetMediaFiles';

const MediaFilesVideosSection = ({ fetcher, resourceUrl, mediaFiles }) => {
  const { t } = useTranslation('mediaFile');
  const [params, setParam] = useState();
  const [downloadMediaFile, , isLoading] = useDownloadMediaFile();
  const columns = [
    columnsSetting('authorName').withColName('creator'),
    columnsSetting('note').withColName('note').withoutSort(),
    columnsSetting('createdOn').withColName('datetime').withFormatDate(),
    columnsSetting('fileName').withColName('videosFiles'),
  ];

  const fetch = (params) => {
    setParam(params);
    fetcher(params);
  };

  return (
    <div>
      <div className="pb-4 flex justify-between">
        <h1 className="text-lg inline-block">{t('videos')}</h1>
      </div>
      <LoadingIndicator show={isLoading} />
      <DataGrid
        defaultColumnWidth={150}
        defaultColumns={columns.map((x) => x.key)}
        data={mediaFiles}
        columns={columns.map((x) => x.withIsVisible().toColumns(t))}
        searchParams={params}
        onSearchParamsChanged={fetch}
        rowKey="id"
        onRowClick={({ row }) => {
          return downloadMediaFile(resourceUrl, row.id, row.fileName);
        }}
      />
    </div>
  );
};

MediaFilesVideosSection.propTypes = {
  resourceUrl: PropTypes.string.isRequired,
  fetcher: PropTypes.func.isRequired,
  mediaFiles: PropTypes.arrayOf(
    PropTypes.shape({
      authorName: PropTypes.string,
      createdOn: PropTypes.string,
      fileName: PropTypes.string,
      id: PropTypes.string,
      note: PropTypes.string,
    })
  ),
};
export default MediaFilesVideosSection;
