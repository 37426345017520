import PropTypes from 'prop-types';
import React from 'react';

const GridHeaderLabel = ({ label, className }) => <span className={className || 'opacity-30 text-xs'}>{label}</span>;

GridHeaderLabel.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

GridHeaderLabel.defaultProps = {
  className: undefined,
};

export default GridHeaderLabel;
