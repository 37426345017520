import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { CARGO_TYPE } from '../cargoType';
import { UrlBuilder } from '../common/HashedRouter';
import HashedRouter from '../common/HashedRouter/HashedRouter';
import { transactionStatusEnum } from '../constant';
import { BreakbulkItemDiff } from '../inventory/item/breakbulk';
import useGetMyCompanies from '../inventory/item/breakbulk/useGetMyCompanies';
import LayoutPage from '../layout/LayoutPage';
import LoadingIndicator from '../layout/loading/LoadingIndicator';
import { CompaniesContext } from '../layout/navigation/CompaniesContextProvider';
import RoutedModal from '../layout/RoutedModal';
import { RoutedSchwoop } from '../layout/Schwoop';
import { actionTypes, romSomActions } from './actionTypes';
import BreakbulkTransactionList from './breakbulk/BreakbulkTransactionList';
import BulkTransactionList from './bulk/BulkTransactionList';
import { useGetTransactionDocument } from './hooks';
import useGetBatchAction from './hooks/useGetBatchAction';
import TransactionAttachments from './TransactionAttachments';
import TransactionMediaFiles from './TransactionMediaFiles';
import TransactionpageHelper from './TransactionPageHelper';
import useGetTransactionExcel from './hooks/useGetTransactionExcel';

const TransactionPage = () => {
  const attachmentPath = '/attachments';
  const mediaFilePath = '/media-files';
  const bulkPath = '/bulk';
  const breakbulkPath = '/breakbulk';

  const [fetchDocument] = useGetTransactionDocument();
  const [fetchExcel] = useGetTransactionExcel();
  const { t } = useTranslation('transaction');
  const { push } = useHistory();
  const { path } = useRouteMatch();
  const location = useLocation();
  const [fetchBatchAction, , isLoadingBatchAction] = useGetBatchAction();
  const [getMyCompanies, myCompanies = []] = useGetMyCompanies();
  const { currentCompanies } = useContext(CompaniesContext);

  useEffect(() => getMyCompanies(), [getMyCompanies]);

  const mainInformationModificationPath = UrlBuilder.buildPattern('/main-information-modifications/:inventoryId/items/:itemId/:date');

  const onRowClick = TransactionpageHelper.onRowClick({ fetchBatchAction, modificationPattern: mainInformationModificationPath, location, push });

  const shouldBeNavigable = ({ actionType, inventoryItemId }) => {
    switch (actionType) {
      case actionTypes.unseal:
        return !!inventoryItemId;
      case actionTypes.seal:
      case actionTypes.stuff:
      case actionTypes.unstuff:
      case actionTypes.dimensionOrWeightModification:
      case actionTypes.hazmatModification:
      case actionTypes.mainInformationModification:
      case actionTypes.identifierModification:
        return true;
      default:
        return false;
    }
  };

  const shouldDisplayDocument = useCallback(({ actionType, cargoType }) => {
    switch (actionType) {
      case actionTypes.adjustment:
      case actionTypes.merge:
      case actionTypes.receptionByBarge:
      case actionTypes.receptionByOther:
      case actionTypes.receptionByRailcar:
      case actionTypes.receptionByTruck:
      case actionTypes.receptionByVessel:
      case actionTypes.split:
      case actionTypes.transferIn:
      case actionTypes.transferOut:
      case actionTypes.transferredIn:
      case actionTypes.transferredOut:
      case actionTypes.transform:
      case actionTypes.shipmentByVessel:
      case actionTypes.shipmentByBarge:
      case actionTypes.shipmentByOther:
      case actionTypes.shipmentByRailcar:
      case actionTypes.shipmentByTruck:
      case actionTypes.missingItemResolution:
        return true;
      case actionTypes.move:
      case actionTypes.moveFrom:
      case actionTypes.moveTo:
        if (cargoType === CARGO_TYPE.bulk) return true;
        return false;
      default:
        return false;
    }
  }, []);

  const shouldDisplayExcel = useCallback(({ actionType }) => {
    switch (actionType) {
      case actionTypes.shipmentByVessel:
      case actionTypes.shipmentByBarge:
      case actionTypes.shipmentByOther:
      case actionTypes.shipmentByRailcar:
      case actionTypes.shipmentByTruck:
        return true;
      default:
        return false;
    }
  }, []);

  const shouldDisplayAttachments = useCallback(({ attachmentIds }) => attachmentIds && attachmentIds.length > 0, []);

  const shouldDisplayMediaFiles = useCallback(
    ({ actionType, status }) =>
      [
        actionTypes.receptionByBarge,
        actionTypes.receptionByContainer,
        actionTypes.receptionByOther,
        actionTypes.receptionByRailcar,
        actionTypes.receptionByTruck,
        actionTypes.receptionByVessel,
        actionTypes.shipmentByBarge,
        actionTypes.shipmentByOther,
        actionTypes.shipmentByRailcar,
        actionTypes.shipmentByTruck,
        actionTypes.shipmentByVessel,
        actionTypes.stuff,
        actionTypes.seal,
        actionTypes.unseal,
        actionTypes.unstuff,
      ].includes(actionType) && status === transactionStatusEnum.Confirmed,
    []
  );
  const actions = useMemo(
    () => [
      {
        key: 'view-transaction',
        text: t('viewTransaction'),
        icon: 'view',
        onClick: onRowClick,
        renderWhen: shouldBeNavigable,
      },
      {
        key: 'view-document',
        text: (row) => t(row.isPartialConfirmation ? 'disabledDocumentTooltip' : 'viewDocument'),
        icon: 'note',
        onClick: ({ id, actionType }) => {
          fetchDocument(id, `${actionType} - ${id}`);
        },
        renderWhen: shouldDisplayDocument,
        disableWhen: (row) => {
          if (row.isPartialConfirmation || (romSomActions.includes(row.actionType) && row.status !== transactionStatusEnum.Confirmed)) return true;
          if (row.actionType === actionTypes.receptionByVessel) {
            const myCompanyIds = currentCompanies.length !== 0 ? currentCompanies.map(({ id }) => id) : myCompanies.map(({ id }) => id);
            if (myCompanyIds.some((id) => row.mainOrdersReceiverIds.includes(id))) {
              return true;
            }
          }
          return false;
        },
      },
      {
        key: 'view-excel',
        text: (row) => t(row.isPartialConfirmation ? 'disabledExcelTooltip' : 'viewExcel'),
        icon: 'excel',
        onClick: (props) => {
          fetchExcel(props, t);
        },
        renderWhen: shouldDisplayExcel,
        disableWhen: (row) => row.isPartialConfirmation,
      },
      {
        key: 'view-attachments',
        text: t('viewAttachments'),
        icon: 'attachment',
        onClick: ({ id }) => {
          push(`#${attachmentPath}/${id}`);
        },
        renderWhen: shouldDisplayAttachments,
      },
      {
        key: 'view-media-file',
        text: t('viewMediaFile'),
        icon: 'camera',
        onClick: ({ id }) => {
          push(`#${mediaFilePath}/${id}`);
        },
        renderWhen: shouldDisplayMediaFiles,
      },
    ],
    [
      t,
      onRowClick,
      shouldDisplayDocument,
      shouldDisplayExcel,
      shouldDisplayAttachments,
      shouldDisplayMediaFiles,
      fetchDocument,
      fetchExcel,
      push,
      myCompanies,
      currentCompanies,
    ]
  );

  const routes = useMemo(
    () => [
      {
        path: `#${attachmentPath}/:id`,
        // eslint-disable-next-line react/prop-types
        component: ({ params }) => (
          <RoutedModal maxWidth="lg" fullWidth title={t('attachments')}>
            {/* eslint-disable-next-line react/prop-types */}
            <TransactionAttachments id={params.id} />
          </RoutedModal>
        ),
      },
      {
        path: `#${mediaFilePath}/:id`,
        // eslint-disable-next-line react/prop-types
        component: ({ params }) => (
          <RoutedModal maxWidth="lg" fullWidth title={t('mediaFiles')}>
            {/* eslint-disable-next-line react/prop-types */}
            <TransactionMediaFiles id={params.id} />
          </RoutedModal>
        ),
      },
      {
        path: mainInformationModificationPath,
        // eslint-disable-next-line react/prop-types
        component: ({ params }) => (
          <RoutedSchwoop maxWidth="lg" fullWidth title={t('itemModifications')}>
            {/* eslint-disable-next-line react/prop-types */}
            <BreakbulkItemDiff inventoryId={params.inventoryId} itemId={params.itemId} date={params.date} />
          </RoutedSchwoop>
        ),
      },
    ],
    [mainInformationModificationPath, t]
  );

  const getRoute = () => {
    const type = localStorage.getItem('listType');
    if (type === 'bulk') {
      return `${path}${bulkPath}`;
    }
    return `${path}${breakbulkPath}`;
  };

  return (
    <LayoutPage>
      <LoadingIndicator show={isLoadingBatchAction} />
      <Switch>
        <Route path={`${path}${bulkPath}`} render={() => <BulkTransactionList actions={actions} rowIsNavigablePredicate={shouldBeNavigable} />} />
        <Route path={`${path}${breakbulkPath}`} render={() => <BreakbulkTransactionList actions={actions} rowIsNavigablePredicate={shouldBeNavigable} />} />
        <Redirect from="/" to={getRoute()} />
      </Switch>
      <HashedRouter routes={routes} />
    </LayoutPage>
  );
};

export default TransactionPage;
