import { useTranslation } from 'react-i18next';
import BreakbulkIcon from '../layout/icons/BreakbulkIcon';
import BulkIcon from '../layout/icons/BulkIcon';
import ListToggler from '../layout/ListPage/headers/ListToggler';

const CargoTypeListToggler = () => {
  const { t } = useTranslation('cargoType');
  return (
    <ListToggler
      options={[
        { tooltipValue: t('breakbulk'), icon: BreakbulkIcon, value: 'breakbulk' },
        { tooltipValue: t('bulk'), icon: BulkIcon, value: 'bulk' },
      ]}
    />
  );
};

export default CargoTypeListToggler;
