import PropTypes from 'prop-types';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

const HashedRouter = ({ routes }) => {
  const { hash } = useLocation();
  const [path, query] = hash.split('?');

  const [route] = routes.reduce((matchedRoute, r) => {
    const matched = matchPath(path, { path: r.path });
    if (matched) return [...matchedRoute, { ...r, matched }];
    return matchedRoute;
  }, []);

  if (!route) return null;

  return React.createElement(route.component, { params: route.matched.params, query });
};

HashedRouter.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
    })
  ),
};

export default HashedRouter;
