import PropTypes from 'prop-types';

export const BookerInformation = {
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  contactId: PropTypes.string,
  contactName: PropTypes.string,
  contactPhoneNumber: PropTypes.string,
  trailerType: PropTypes.string,
  trackingCargoCode: PropTypes.string,
};

export const BookerInformationFormik = {
  company: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  contact: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  contactId: PropTypes.string,
  contactName: PropTypes.string,
  contactPhoneNumber: PropTypes.string,
  trailerType: PropTypes.string,
  trackingCargoCode: PropTypes.string,
};

export default BookerInformation;
