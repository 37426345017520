import axios from 'axios';
import download from '../../common/helpers/download';
import { noContentCode } from '../../constant';

export const url = `/api/v1/reports/tailgate`;

const generateFileName = (values) => {
  const startDate = new Date(values.startDate);
  const startDateString = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split('T')[0];
  const endDate = new Date(values.endDate);
  const endDateString = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split('T')[0];

  const params = [values.cargoType, startDateString, endDateString].filter(Boolean).join('-');

  return `Tailgate-${params}.xlsx`;
};

export const getReport = async (siteId, startDate, endDate, cargoType) => {
  const response = await axios.get(url, {
    params: {
      siteId,
      cargoType,
      startDate,
      endDate,
    },
    responseType: 'blob',
  });

  if (response.status === noContentCode) {
    return undefined;
  }

  download(response.data, generateFileName({ cargoType, startDate, endDate }));
  return response.data;
};

export const getTailgateReportSites = (cargoType) => {
  return axios.get(`${url}/sites`, {
    params: {
      cargoType,
    },
  });
};
