import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Output from '../../../layout/Output';
import Section from '../../../layout/Section';
import { mapIdentifiersAsArray } from '../helpers/itemHelpers';
import { InventoryItem } from '../types';

const IdentifiersSection = ({ item }) => {
  const { t } = useTranslation('inventory');

  const identifiersArray = useMemo(() => mapIdentifiersAsArray(item), [item]);

  return (
    <Section title={t('item.identifiers')} data-testid="section_identifiers">
      <Grid container spacing={2}>
        {identifiersArray.map((identifier, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12}>
              <Output id={`output-identifier-${index}`} label={t(`item.identifierLabel.${identifier.label}`)} defaultValue={identifier.value} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Section>
  );
};

IdentifiersSection.propTypes = {
  item: PropTypes.shape(InventoryItem),
};

IdentifiersSection.detaultProps = {
  item: {},
};

export default IdentifiersSection;
