import PropTypes from 'prop-types';
import Icon, { iconSizes } from './Icon';
import svg from '../../common/icons/breakbulk.svg';

const BreakbulkIcon = ({ size, styles }) => (
  <Icon size={size} styles={styles}>
    <img alt="" src={svg} />
  </Icon>
);

BreakbulkIcon.propTypes = {
  size: PropTypes.string,
  styles: PropTypes.shape(),
};

BreakbulkIcon.defaultProps = {
  size: iconSizes.medium,
  styles: {},
};

export default BreakbulkIcon;
