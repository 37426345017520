import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { ItemRouter } from './item';

const ROUTES = {
  inventoryItem: {
    component: ItemRouter,
    path: '/:inventoryId/items',
    i18nKey: 'inventory.inventoryItem',
  },
  inventoryItems: {
    component: ItemRouter,
    path: '/items',
    i18nKey: 'inventory.inventoryItem',
  },
};

const InventoryRouter = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      {Object.values(ROUTES).map((route) => {
        return <Route key={route.path} path={`${path}${route.path}`} component={route.component} />;
      })}
      <Redirect from={`${path}`} to={`${path}${ROUTES.inventoryItems.path}`} />
    </Switch>
  );
};

export default InventoryRouter;
