import { itemStuffingCapacity } from '../details/stuffingCapacities';

const maximumNumberOfIdentifiers = 4;

export const mapIdentifiersAsArray = (item) =>
  [...Array(maximumNumberOfIdentifiers).keys()]
    .map((i) => {
      const label = item[`identifierLabel${i + 1}`];
      const value = item[`identifierValue${i + 1}`];
      if (label && value) return { label, value };
      return false;
    })
    .filter(Boolean);

export const formatAsDimension = (dimension) => dimension?.toFixed(4);

export const getLtdQtyOptions = (translator, translationPrefix = 'item') => {
  const yesLabel = translator(`${translationPrefix}.yes`);
  const noLabel = translator(`${translationPrefix}.no`);

  return [
    { key: 'yes', label: yesLabel, value: true },
    { key: 'no', label: noLabel, value: false },
  ];
};

export const getStuffingCapacity = (item) => {
  const { isStuffableContainer, isNonStuffableContainer, canBeContainerized } = item;
  if (isStuffableContainer) return itemStuffingCapacity.stuffable;
  if (isNonStuffableContainer) return itemStuffingCapacity.nonStuffableContainer;
  if (canBeContainerized) return itemStuffingCapacity.containerizable;
  return itemStuffingCapacity.none;
};
