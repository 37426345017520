import PropTypes from 'prop-types';
import { actionTypes, cargoTypes, transportationTypes } from '../dataTypes';
import { BookerInformation } from './BookerInformation';
import { CarrierInformation } from './CarrierInformation';
import Transportation from './Transportation';

const Appointment = {
  id: PropTypes.string.isRequired,
  number: PropTypes.string,
  status: PropTypes.string,
  actionType: PropTypes.oneOf(actionTypes),
  cargoType: PropTypes.oneOf(cargoTypes),
  transportationType: PropTypes.oneOf(transportationTypes),
  site: PropTypes.shape({ id: PropTypes.string.isRequired, name: PropTypes.string.isRequired }).isRequired,
  productTypeId: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  dropDetail: PropTypes.shape({}),
  pickDestinations: PropTypes.arrayOf(PropTypes.shape({})),
  purchaseOrder: PropTypes.string,
  providerCode: PropTypes.string,
  supplierBooking: PropTypes.string,
  forWhom: PropTypes.string,
  bookerInformation: PropTypes.shape(BookerInformation),
  carrierInformation: PropTypes.shape(CarrierInformation),
  parking: PropTypes.string,
  zone: PropTypes.string,
  transportation: PropTypes.shape(Transportation),
  notes: PropTypes.string,
  attachmentIds: PropTypes.arrayOf(PropTypes.string),
  updatedOn: PropTypes.string,
  occurences: PropTypes.number,
  batchStartDate: PropTypes.string,
  batchEndDate: PropTypes.string,
  createdById: PropTypes.string,
  createdByName: PropTypes.string,
  updatedById: PropTypes.string,
  updatedByName: PropTypes.string,
  specialInstructions: PropTypes.string,
  tareWeight: PropTypes.number,
  unitOfMass: PropTypes.string,
};

export { Appointment };
