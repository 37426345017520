import dimension from '../../common/dimension';
import { toOption } from '../../common/helpers/list/options';

export const sortOptionByLabel = (a, b) => a.label.localeCompare(b.label);

export const getWeightAndDimensionsTypeOptions = ({ currentDimension = null, dimensions, translator, translationPrefix = null }) => {
  const selectedTypes = (dimensions || []).map((d) => d.type);
  return Object.values(dimension.types)
    .filter((t) => !selectedTypes.includes(t) || (currentDimension?.type && currentDimension.type === t))
    .map(toOption(translator, translationPrefix))
    .sort(sortOptionByLabel);
};

export const getWeightAndDimensionsUnitOptions = (type, translator, translationPrefix = null) => {
  const { types, units } = dimension;
  switch (type) {
    case types.diameter:
    case types.height:
    case types.length:
    case types.thickness:
    case types.width:
      return Object.values(units.dimension).map(toOption(translator, translationPrefix)).sort(sortOptionByLabel);
    case types.volume:
      return Object.keys(units.volume)
        .map((key) => ({ key: key, label: translationPrefix ? translator(`${translationPrefix}.${key}`) : translator(key), value: units.volume[key] }))
        .sort(sortOptionByLabel);
    case types.surfaceArea:
      return Object.keys(units.surfaceArea)
        .map((key) => ({ key: key, label: translationPrefix ? translator(`${translationPrefix}.${key}`) : translator(key), value: units.surfaceArea[key] }))
        .sort(sortOptionByLabel);
    case types.mass:
      return Object.values(units.mass).map(toOption(translator, translationPrefix)).sort(sortOptionByLabel);
    case types.footpound:
      return Object.values(units.footpound).map(toOption(translator, translationPrefix)).sort(sortOptionByLabel);
    default:
      return [];
  }
};
