const getContentDisposition = (headers) => headers && headers['content-disposition'];

const getFilename = (headers) => {
  if (!headers) return null;
  const contentDisposition = getContentDisposition(headers);
  const keyValue = contentDisposition
    ?.split(';')
    ?.find((x) => x.includes('filename='))
    ?.split('=');

  if (keyValue?.length !== 2) return null;

  const filename = keyValue[1].split('.')[0];

  return filename ? filename : null;
};

const headers = { getFilename };

export default headers;
