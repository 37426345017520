const batchActionTypes = {
  Relocate: 'Relocalisation',
  Stuff: 'Remplissage',
  Transfer: 'Transfert',
  Unstuff: 'Vidage',
  Unseal: 'Descellé',
  ResolveMissingItem: "Résolution d'item manquant",
};

export default batchActionTypes;
