import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toLocalDateTime } from '../../common/helpers/date';
import download from '../../common/helpers/download';
import headers from '../../common/helpers/headers';
import useAgent from '../../hooks/useAgent';
import useErrorStore from '../../hooks/useErrorStore';

export const url = `/api/v1/actions`;
const httpStatusCode = { noContent: 204 };

export const getMergedTransactionDocuments = async (parameters = new URLSearchParams(), cargoType) => {
  parameters.set('localTimezoneId', Intl.DateTimeFormat().resolvedOptions().timeZone);
  parameters.set('cargoType', cargoType);

  const response = await axios.get(`${url}/assembled-pdf`, {
    params: parameters,
    responseType: 'arraybuffer',
  });

  if (response.status === httpStatusCode.noContent) return response;

  const fileBlob = new Blob([response.data], { type: 'application/pdf' });
  download(fileBlob, headers.getFilename(response.headers) || 'actions-documents_' + toLocalDateTime(new Date()) + '.pdf');
  return response;
};

export const getSeparateTransactionDocuments = async (parameters = new URLSearchParams(), cargoType) => {
  parameters.set('localTimezoneId', Intl.DateTimeFormat().resolvedOptions().timeZone);
  parameters.set('cargoType', cargoType);

  const response = await axios.get(`${url}/documents`, {
    params: parameters,
    responseType: 'arraybuffer',
  });

  if (response.status === httpStatusCode.noContent) return response;

  const fileBlob = new Blob([response.data], { type: 'application/zip' });
  download(fileBlob, headers.getFilename(response.headers) || 'actions-documents_' + toLocalDateTime(new Date()) + '.zip');
  return response;
};

export const useGetMergedTransactionDocuments = () => {
  const [, errorsActions] = useErrorStore();
  const { t } = useTranslation('transaction');

  return useAgent(getMergedTransactionDocuments, {
    successHandler: (response) => {
      if (response.status === httpStatusCode.noContent) errorsActions.setErrorMessage(t('list.headerActions.getDocumentsNoDocumentsWarning'));
    },
  });
};

export const useGetSeparateTransactionDocuments = () => {
  const [, errorsActions] = useErrorStore();
  const { t } = useTranslation('transaction');

  return useAgent(getSeparateTransactionDocuments, {
    successHandler: (response) => {
      if (response.status === httpStatusCode.noContent) errorsActions.setErrorMessage(t('list.headerActions.getDocumentsNoDocumentsWarning'));
    },
  });
};
