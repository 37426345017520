const toTupleArray = (item) =>
  [
    item?.identifierLabel1 && [item.identifierLabel1, item.identifierValue1],
    item?.identifierLabel2 && [item.identifierLabel2, item.identifierValue2],
    item?.identifierLabel3 && [item.identifierLabel3, item.identifierValue3],
    item?.identifierLabel4 && [item.identifierLabel4, item.identifierValue4],
  ].filter(Boolean);

const Identifier = {
  toTupleArray,
};

export default Identifier;
