import { TextField as MuiTextField, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const TextField = ({ classes, fullWidth, label, name, ...props }) => {
  const { handleChange, errors, values } = useFormikContext();
  const currentError = _get(errors, name);

  return (
    <MuiTextField
      {...props}
      classes={classes}
      fullWidth={fullWidth}
      label={<Typography variant="body2">{label}</Typography>}
      name={name}
      value={_get(values, name)}
      onChange={handleChange}
      error={Boolean(currentError)}
      helperText={currentError}
    />
  );
};

TextField.propTypes = {
  classes: PropTypes.object,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

TextField.defaultProps = {
  classes: null,
  fullWidth: false,
  label: '',
};

export default TextField;
