import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import CargoTypeListToggler from '../../cargoType/CargoTypeListToggler';
import InventoryByDateExcelReportContent from './InventoryByDateExcelReportContent';
import { Redirect, Route, Switch, useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import TailgateReportsContent from './TailgateReportsContent';
import { CARGO_TYPE } from '../../cargoType';
import { getClientInventoriesHeader, clientOnlyReceiverKey } from '../helpers/reportHelpers';

const ExcelReportsRouter = () => {
  const { t } = useTranslation('reports');
  const { pathname } = useLocation();
  const history = useHistory();
  const ROUTES = {
    invByDateBreakbulkReport: {
      component: InventoryByDateExcelReportContent,
      path: '/reports/inventoryByDate/breakbulk',
    },
    invByDateBulkReport: {
      component: InventoryByDateExcelReportContent,
      path: '/reports/inventoryByDate/bulk',
    },
    tailgateBreakbulkReport: {
      component: TailgateReportsContent,
      path: '/reports/tailgate/breakbulk',
    },
    tailgateBulkReport: {
      component: TailgateReportsContent,
      path: '/reports/tailgate/bulk',
    },
  };

  const tabOptions = [
    {
      label: t('tabs.inventoryByDate'),
      path: '/inventoryByDate',
      accessToReceiverOnly: true,
    },
    {
      label: t('tabs.tailgate'),
      path: '/tailgate',
      accessToReceiverOnly: false,
    },
  ];

  const receiverOnlyTabOptions = tabOptions.filter((option) => option.accessToReceiverOnly);

  const [selectedReportTypeIndex, setSelectedReportTypeIndex] = useState(0);
  const [reportTabs, setReportTypeTabs] = useState(receiverOnlyTabOptions);

  const type = pathname.includes('breakbulk') ? CARGO_TYPE.breakbulk : CARGO_TYPE.bulk;

  useEffect(() => {
    getClientInventoriesHeader(type).then((res) => {
      setReportTypeTabs(res.headers[clientOnlyReceiverKey] === 'True' ? receiverOnlyTabOptions : tabOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const getCargoType = () => {
    return localStorage.getItem('listType') ?? CARGO_TYPE.breakbulk.toLowerCase();
  };

  const handleReportTypeChange = (event, newValue) => {
    const selectedReportIndex = tabOptions.findIndex((option) => option.path === newValue);

    if (selectedReportIndex !== selectedReportTypeIndex) {
      setSelectedReportTypeIndex(selectedReportIndex);
    }

    const cargoType = getCargoType();
    const newLocation = '/reports' + newValue + '/' + cargoType;
    history.push(newLocation);
  };

  let { path } = useRouteMatch();

  const getRoute = () => {
    const cargoType = getCargoType();

    const isTailgateReport = tabOptions[selectedReportTypeIndex].path.includes('tailgate');

    if (cargoType === CARGO_TYPE.bulk.toLowerCase()) {
      const bulkPath = isTailgateReport ? ROUTES.tailgateBulkReport.path : ROUTES.invByDateBulkReport.path;
      return bulkPath;
    }

    const breakbulkPath = isTailgateReport ? ROUTES.tailgateBreakbulkReport.path : ROUTES.invByDateBreakbulkReport.path;
    return breakbulkPath;
  };

  const currentTab = `/${/.*\/(.*)\/.*/.exec(pathname)?.[1]}` ?? '/inventoryByDate';

  return (
    <>
      <CargoTypeListToggler />
      <AppBar position="static" color="transparent" elevation={0}>
        <Tabs
          value={currentTab}
          variant={'standard'}
          classes={{ root: `h-full items-center pb-4` }}
          onChange={handleReportTypeChange}
          textColor="primary"
          indicatorColor="primary">
          {reportTabs.map((option) => (
            <Tab classes={{ root: 'text-xl' }} key={option.path} value={option.path} label={option.label} />
          ))}
        </Tabs>
      </AppBar>
      <Switch>
        {Object.values(ROUTES).map((route) => {
          return <Route key={route.path} path={route.path} component={route.component} />;
        })}
        <Redirect from={`${path}`} to={getRoute()} />
      </Switch>
    </>
  );
};

export default ExcelReportsRouter;
