import { AppBar, Button, Drawer, makeStyles, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from '../../hooks/useModal';
import Breakpoint from '../mediaQuery';
import SchwoopMobile from './SchwoopMobile';

const paperStyle = makeStyles({
  root: { minWidth: '75%', paddingLeft: '2.5rem' },
});

const appBarStyle = makeStyles({
  root: { paddingLeft: '0rem' },
});

const Schwoop = (props) => {
  const { children, open, title, subtitle, onClose } = props;
  const { t } = useTranslation('layout');
  const [isModalOpen, openModal, closeModal] = useModal();

  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);

  useEffect(() => {
    if (open) openModal();
    else closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const paperClasse = paperStyle();

  const close = useCallback(() => {
    closeModal();
    onClose();
  }, [closeModal, onClose]);

  const appBarClasses = appBarStyle();
  return !isTabletOrLarger ? (
    <SchwoopMobile {...props} />
  ) : (
    <Drawer anchor="right" open={isModalOpen} onClose={close} PaperProps={{ classes: paperClasse }}>
      <AppBar position="static" color="inherit" elevation={0}>
        <Toolbar classes={appBarClasses}>
          <div className=" flex flex-grow items-baseline">
            <Typography color="primary" variant="h4">
              {title}
            </Typography>
            <span className={'pl-5'}>
              <Typography color="primary" variant="h6">
                {subtitle}
              </Typography>
            </span>
          </div>
          <Button variant={'outlined'} onClick={close}>
            {t('close')}
          </Button>
        </Toolbar>
      </AppBar>
      {children}
    </Drawer>
  );
};

Schwoop.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

Schwoop.defaultProps = {
  open: false,
  title: '',
  subtitle: '',
  onClose: () => {},
};

export default Schwoop;
