import { Chip, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export const defaultBadgeColor = '#F2F5F7';

const chipStyles = (marginBottom, marginTop, backgroundColor = defaultBadgeColor) =>
  makeStyles((theme) => ({
    root: {
      backgroundColor,
      marginRight: '0.5rem',
      marginBottom,
      marginTop,
      color: theme.palette.text.secondary,
    },
  }));

const OperationalSiteBadge = ({ marginBottom, marginTop, operationalSiteName }) => {
  return <Chip classes={chipStyles(marginBottom, marginTop)()} size="small" label={operationalSiteName} />;
};

OperationalSiteBadge.propTypes = {
  operationalSiteName: PropTypes.string.isRequired,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
};

OperationalSiteBadge.defaultProps = {
  marginBottom: null,
  marginTop: null,
};

export default OperationalSiteBadge;
