import { Button, Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AgnicoEagleFilter from '../../../agnicoEagle/AgnicoEagleFilter';
import getActiveFiltersCount from '../../../common/filters/getActiveFiltersCount';
import { toOption } from '../../../common/helpers/list/options';
import Badge from '../../../layout/Badge';
import Collapsible from '../../../layout/Collapsible/Collapsible';
import { CheckboxField, DateRangePickerField } from '../../../layout/fields';
import SelectField from '../../../layout/fields/SelectField';
import TextField from '../../../layout/fields/TextField';
import IdentifiersFilter from '../../../layout/Identifiers/IdentifiersFilter';
import queryStringOption from '../../../layout/ListPage/headers/queryStringOption';
import WeightAndDimensionsFilter from '../../../weightAndDimensions/WeightAndDimensionsFilter';
import { getLtdQtyOptions } from '../helpers/itemHelpers';
import useAdvancedFilterValidationSchema from './useAdvancedFilterValidationSchema';
import useGetOptions from './useGetOptions';
import useGetMyCompanies from './useGetMyCompanies';

const makeCheckboxStyles = makeStyles({
  root: {
    paddingLeft: 0,
  },
});

const makeRowStyles = makeStyles({
  root: {
    marginBottom: '1rem',
  },
});

const makeHazardousInputStyles = makeStyles({
  root: {
    paddingRight: '0.5rem',
  },
});

const getInitialValues = (params) => {
  const searchValues = qs.parse(params.toString(), queryStringOption);
  return {
    weightAndDimensions: searchValues?.weightAndDimensions || [],
    includeEmptyItems: JSON.parse(searchValues.includeEmptyItems || false),
    includeStuffedItems: JSON.parse(searchValues.includeStuffedItems || false),
    isHazardousMaterial: JSON.parse(searchValues.isHazardousMaterial || false),
    excludeEmptyContainers: JSON.parse(searchValues.excludeEmptyContainers || false),
    hasLimitedQuantity: searchValues.hasLimitedQuantity ? JSON.parse(searchValues.hasLimitedQuantity) : null,
    receptionDateFrom: searchValues?.receptionDateFrom || null,
    receptionDateTo: searchValues?.receptionDateTo || null,
    hazardousClass: searchValues?.hazardousClass || null,
    hazardousPG: searchValues?.hazardousPG || null,
    hazardousUN: searchValues?.hazardousUN || '',
    identifiers: searchValues?.identifiers || [],
    purchaseOrderNumber: searchValues?.purchaseOrderNumber || '',
    itemNumber: searchValues?.itemNumber || '',
    itemDescription: searchValues?.itemDescription || '',
  };
};

const ItemsAdvancedFilterSection = ({ onSearch }) => {
  const { t } = useTranslation('inventory');
  const { search } = useLocation();

  const [getOptions, { hazardousClassOptions = [], hazardousPackagingOptions = [] } = {}] = useGetOptions();
  const [getMyCompanies, myCompanies = []] = useGetMyCompanies();

  const validationSchema = useAdvancedFilterValidationSchema();

  const checkboxStyles = makeCheckboxStyles();
  const hazardousInputStyles = makeHazardousInputStyles();
  const rowStyles = makeRowStyles();
  const isAgnicoContact = myCompanies.some((c) => c.name.toLowerCase().includes('agnico'));

  useEffect(() => {
    getOptions();
    getMyCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik initialValues={getInitialValues(new URLSearchParams(search))} onSubmit={onSearch} validationSchema={validationSchema} enableReinitialize>
      {({ values, submitForm, initialValues }) => (
        <Collapsible title={t('item.advancedFilters')} titleBadge={<Badge content={getActiveFiltersCount(initialValues)} />}>
          <Grid container>
            <Grid container classes={rowStyles} item xs={12} md={5}>
              <DateRangePickerField
                startDateName="receptionDateFrom"
                startDateLabel={t('item.receptionFrom')}
                endDateLabel={t('item.to')}
                endDateName="receptionDateTo"
                fullWidth
              />
            </Grid>
            {isAgnicoContact && <AgnicoEagleFilter name="agnicoEagleFilter" />}
            <Grid classes={rowStyles} item xs={12} md={12}>
              <FieldArray name="weightAndDimensions">
                {({ push, remove }) => <WeightAndDimensionsFilter push={push} remove={remove} name="weightAndDimensions" />}
              </FieldArray>
            </Grid>
            <Grid classes={rowStyles} item xs={12} md={12}>
              <FieldArray name="identifiers">{({ push, remove }) => <IdentifiersFilter push={push} remove={remove} name="identifiers" />}</FieldArray>
            </Grid>
            <Hidden smDown>
              <Grid classes={rowStyles} item md={12} xs={12}>
                <CheckboxField classes={checkboxStyles} label={t('item.includeEmptyItems')} name="includeEmptyItems" />
                <CheckboxField classes={checkboxStyles} label={t('item.includeContainerizedItems')} name="includeStuffedItems" />
                <CheckboxField classes={checkboxStyles} label={t('item.excludeEmptyContainers')} name="excludeEmptyContainers" />
                <CheckboxField classes={checkboxStyles} label={t('item.showOnlyHazmat')} name="isHazardousMaterial" />
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item md={12} xs={12}>
                <CheckboxField classes={checkboxStyles} label={t('item.includeEmptyItems')} name="includeEmptyItems" />
              </Grid>
              <Grid item md={12} xs={12}>
                <CheckboxField classes={checkboxStyles} label={t('item.includeContainerizedItems')} name="includeStuffedItems" />
              </Grid>
              <Grid item md={12} xs={12}>
                <CheckboxField classes={checkboxStyles} label={t('item.excludeEmptyContainers')} name="excludeEmptyContainers" />
              </Grid>
              <Grid item md={12} xs={12}>
                <CheckboxField classes={checkboxStyles} label={t('item.showOnlyHazmat')} name="isHazardousMaterial" />
              </Grid>
            </Hidden>
            {values.isHazardousMaterial && (
              <>
                <Grid classes={rowStyles} item md={1} xs={3}>
                  <SelectField classes={hazardousInputStyles} label={t('item.limitedQuantity')} name="hasLimitedQuantity" options={getLtdQtyOptions(t)} />
                </Grid>
                <Grid classes={rowStyles} item md={1} xs={3}>
                  <SelectField classes={hazardousInputStyles} label={t('item.class')} name="hazardousClass" options={hazardousClassOptions.map(toOption())} />
                </Grid>
                <Grid classes={rowStyles} item md={1} xs={3}>
                  <TextField classes={hazardousInputStyles} label={t('item.un')} name="hazardousUN" fullWidth />
                </Grid>
                <Grid classes={rowStyles} item md={1} xs={3}>
                  <SelectField classes={hazardousInputStyles} label={t('item.pg')} name="hazardousPG" options={hazardousPackagingOptions.map(toOption())} />
                </Grid>
              </>
            )}
            <Grid classes={rowStyles} item xs={12} md={12}>
              <Button onClick={submitForm} variant="contained" color="primary">
                {t('item.search')}
              </Button>
            </Grid>
          </Grid>
        </Collapsible>
      )}
    </Formik>
  );
};

ItemsAdvancedFilterSection.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default ItemsAdvancedFilterSection;
