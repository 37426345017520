import { Chip, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Breakpoint from '../../../mediaQuery';

const chipStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.secondary,
  },
}));

const ListPageHeaderPills = ({ pills }) => {
  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);

  return (
    !!pills.length &&
    pills.map((pill) => (
      <Chip
        key={pill}
        classes={chipStyles()}
        className={`mr-2 ${isTabletOrLarger ? 'mb-4' : 'mb-2'}`}
        size="small"
        label={pill}
        data-testid={`badge_${pill}`}
      />
    ))
  );
};

ListPageHeaderPills.propTypes = {
  pills: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

ListPageHeaderPills.defaultProps = {
  pills: [],
};

export default ListPageHeaderPills;
