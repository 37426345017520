import React from 'react';
import LayoutPage from '../layout/LayoutPage';
import Router from './InventoryRouter';

const InventoryPage = () => {
  return (
    <LayoutPage>
      <Router />
    </LayoutPage>
  );
};

export default InventoryPage;
