const mediaFiles = {
  photos: 'Photos',
  videos: 'Videos',
  empty: 'No data',
  list: {
    creator: 'Creator',
    note: 'Note',
    datetime: 'Date & Time',
    videosFiles: 'Video Files',
  },
};

export default mediaFiles;
