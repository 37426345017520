const dataTypes = {
  actionType: {
    drop: 'Drop',
    pick: 'Pick',
  },
  cargoType: {
    Breakbulk: 'Breakbulk',
    Bulk: 'Bulk',
  },
  hazardClass: {
    class1: 'Class 1',
    class2: 'Class 2',
    class3: 'Class 3',
    class4: 'Class 4',
    class5: 'Class 5',
    class6: 'Class 6',
    class7: 'Class 7',
    class8: 'Class 8',
    class9: 'Class 9',
  },
  volume: {
    cuFt: 'cubic foot',
    cuM: 'cubic meter',
    cuYd: 'cubic yard',
    liter: 'liter',
  },
  mass: {
    kg: 'kg',
    lb: 'lb',
    MT: 'MT',
    NT: 'NT',
  },
  trailerType: {
    bTrain: 'B-Train',
    dryBox: 'Dry Box',
    flatBed: 'Flat Bed',
    frame: 'Frame',
    gooseneck: 'Gooseneck',
    curtainSide: 'Curtain Side',
    dropDeck: 'Drop Deck',
    goldhofer: 'Goldhofer',
    hopper: 'Hopper',
    liveBottom: 'Live Bottom',
    triAxle: 'Tri-Axle',
    endDump: 'End Dump',
    boxcars: 'Boxcars',
    bulkhead: 'Bulkhead',
    centerBeams: 'Center Beams',
    flatCars: 'Flat cars',
    gondolasOpen: 'Gondolas Open',
    gondolasCovered: 'Gondolas Covered',
    tanker: 'Tanker',
  },
  siteConfigurationLimit: {
    cargo: 'Cargo',
    carrier: 'Carrier',
    timeSlot: 'Time Slot',
  },
  packagingGroup: {
    pgI: 'PG I',
    pgII: 'PG II',
    pgIII: 'PG III',
  },
  transportationType: {
    Barge: 'Barge',
    Other: 'Other',
    Railcar: 'Railcar',
    Truck: 'Truck',
  },
};

const traffic = {
  appointmentNumber: 'Appointment Number',
  count: 'Count',
  dataTypes,
  lastUpdated: 'Last updated',
  listCountSingle: 'Count',
  refresh: 'Refresh',
  traffic: 'Traffic',
  vehicleIdentifier: 'Vehicle Identifier',
};

export default traffic;
