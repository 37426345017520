import { TextField, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const DecimalNumberFormat = ({ inputRef, name, onChange, decimalScale, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange({
        target: {
          name: name,
          value: values.value,
        },
      });
    }}
    isNumericString
    decimalScale={decimalScale}
    fixedDecimalScale
  />
);

DecimalNumberFormat.propTypes = {
  decimalScale: PropTypes.number,
  inputRef: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberInput = ({ error, helperText, inputRef, label, name, onBlur, onChange, value, decimalScale = 4 }) => {
  const handleBlur = useCallback(
    (event) => {
      if (onBlur) onBlur({ target: { name, value: event.target.value } });
    },
    [name, onBlur]
  );

  return (
    <TextField
      helperText={helperText}
      error={error}
      id={name}
      inputRef={inputRef}
      autoComplete="off"
      fullWidth
      label={<Typography variant="body2">{label}</Typography>}
      name={name}
      onBlur={handleBlur}
      onChange={onChange}
      value={value}
      InputProps={{
        inputComponent: DecimalNumberFormat,
      }}
      inputProps={{
        decimalScale,
      }}
    />
  );
};

NumberInput.propTypes = {
  decimalScale: PropTypes.number,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  inputRef: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

NumberInput.defaultProps = {
  error: false,
  helperText: null,
  inputRef: null,
  label: '',
  name: '',
  onBlur: undefined,
  onChange: undefined,
  value: '',
};

export default NumberInput;
