import { TagInputFilter } from '@vooban/datagrid';
import React from 'react';
import { railCarTrailerTypesByKey, truckTrailerTypesByKey } from '../appointment/dataTypes/TrailerTypes';
import { truckRailCarTrailerTypeGroupsEnum } from '../constant';

const DistinctTrailerTypesFilter =
  (distinctValues, t) =>
  // eslint-disable-next-line react/prop-types
  ({ initialValues, ...otherProps }) => {
    const buildGroupOptions = (truckOptions, railcarOptions, i18n) => {
      const groupOptions = [];

      if (truckOptions?.length > 0) {
        groupOptions.push({
          label: i18n(`trailerTypeGroup.${truckRailCarTrailerTypeGroupsEnum.truck}`),
          options: truckOptions,
        });
      }

      if (railcarOptions?.length > 0) {
        groupOptions.push({
          label: i18n(`trailerTypeGroup.${truckRailCarTrailerTypeGroupsEnum.railCar}`),
          options: railcarOptions,
        });
      }

      return groupOptions;
    };

    const addOptions = (options, truckOptions, railcarOptions, i18n) => {
      (options || []).forEach((option) => {
        const value = option.value ?? option;
        const isTruck = Object.values(truckTrailerTypesByKey).includes(value);
        if (isTruck) {
          const isAlreadyAddedToTruckOptions = truckOptions.some((truckOption) => truckOption.value === value);
          if (!isAlreadyAddedToTruckOptions) truckOptions.push({ key: value, label: i18n(`trailerTypes.${value}`), value });
          return;
        }

        const isRailcar = Object.values(railCarTrailerTypesByKey).includes(value);
        if (isRailcar) {
          const isAlreadyAddedToRailcarOptions = railcarOptions.some((railcarOption) => railcarOption.value === value);
          if (!isAlreadyAddedToRailcarOptions) railcarOptions.push({ key: value, label: i18n(`trailerTypes.${value}`), value });
        }
      });
    };

    const truckOptions = [];
    const railcarOptions = [];

    if (distinctValues) addOptions(distinctValues, truckOptions, railcarOptions, t);
    if (initialValues) addOptions(initialValues, truckOptions, railcarOptions, t);

    const options = buildGroupOptions(truckOptions, railcarOptions, t);

    return React.createElement(TagInputFilter, {
      ...otherProps,
      initialValues,
      options,
    });
  };

export default DistinctTrailerTypesFilter;
