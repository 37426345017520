import { AppBar, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dateFilterDropDownOptions } from '../../layout/ListPage/headers/component/DateFilterValues';

import LoadingIndicator from '../../layout/loading/LoadingIndicator';
import TrafficPage from '../../traffic/TrafficPage';
import { useAppointmentGetOptions } from './useGetOptions';
import useGetSites from './useGetSites';
import { useLocation, useHistory } from 'react-router-dom';

import AppointmentList from './AppointmentList';
import RoutedModal from '../../layout/RoutedModal';
import AppointmentAttachments from '../AppointmentAttachments';
import HashedRouter from '../../common/HashedRouter';
import { attachmentPath } from '../urls';

const AppointmentTabSelector = () => {
  const { t } = useTranslation('appointment');

  const [isMounting, setIsMounting] = useState(true);
  const [fetchSites, sites = []] = useGetSites();
  const { pathname } = useLocation();
  const persistedParams = useSelector((state) => state.dataGrid.searchParams[pathname]);
  const history = useHistory();

  const params = useMemo(() => {
    if (persistedParams) {
      const params = new URLSearchParams(persistedParams);
      const cleanedParams = new URLSearchParams();
      if (params.get('dropDownValue') !== dateFilterDropDownOptions.AllDate) {
        const startDateValue = params.get('startDateValue');
        const endDateValue = params.get('endDateValue');
        if (startDateValue) cleanedParams.set('startDateValue', startDateValue);
        if (endDateValue) cleanedParams.set('endDateValue', endDateValue);
      }
      return cleanedParams;
    }
  }, [persistedParams]);

  const [
    getOptions,
    options = {
      actionTypes: [],
      cargoTypes: [],
      transportationTypes: [],
      carrierNames: [],
      sites: [],
      status: [],
      trailerTypes: [],
      supplierBookings: [],
      parkings: [],
      productTypes: [],
      productSubtypes: [],
      receiverNames: [],
      inventoryNames: [],
      orderNames: [],
      bookers: [],
    },
  ] = useAppointmentGetOptions(params);

  useEffect(() => {
    Promise.all([fetchSites(), getOptions(params)]).finally(() => {
      setIsMounting(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOptions(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistedParams]);

  const handleTabChange = (_event, selectedTabPath) => {
    history.push(selectedTabPath);
  };

  const tabOptions = [
    {
      label: t('tabs.list'),
      path: '/appointments',
    },
    {
      label: t('tabs.traffic'),
      path: '/appointments/traffic',
    },
  ];

  const shouldDisplayAttachments = useCallback(({ attachmentIds }) => attachmentIds && attachmentIds.length > 0, []);

  const appointmentActions = useMemo(
    () => [
      {
        key: 'view-attachments',
        text: t('viewAttachments'),
        icon: 'attachment',
        onClick: ({ id }) => {
          history.push(`#${attachmentPath}/${id}`);
        },
        renderWhen: shouldDisplayAttachments,
      },
    ],
    [history, shouldDisplayAttachments, t]
  );

  const routes = useMemo(
    () => [
      {
        path: `#${attachmentPath}/:id`,
        component: ({ params }) => (
          <RoutedModal maxWidth="lg" fullWidth title={t('attachments')}>
            <AppointmentAttachments id={params.id} />
          </RoutedModal>
        ),
      },
    ],
    [t]
  );

  const tabs = (
    <AppBar position="static" color="transparent" elevation={0}>
      <Tabs
        value={pathname}
        variant={'standard'}
        onChange={handleTabChange}
        classes={{ root: `h-full items-center pb-4` }}
        textColor="primary"
        indicatorColor="primary">
        {tabOptions.map((option) => (
          <Tab classes={{ root: 'text-xl' }} key={option.path} value={option.path} label={option.label} />
        ))}
      </Tabs>
    </AppBar>
  );

  return isMounting ? (
    <LoadingIndicator />
  ) : (
    <>
      {pathname === tabOptions[0].path && <AppointmentList options={options} actions={appointmentActions} sites={sites} tabs={tabs} />}
      {pathname === tabOptions[1].path && <TrafficPage sites={sites} allowedSites={options.sites} tabs={tabs} />}
      <HashedRouter routes={routes} />
    </>
  );
};

export default AppointmentTabSelector;
