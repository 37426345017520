import memoizeOne from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SET_COLUMN_SETTINGS, SET_SEARCH_PARAMS } from '../../reducers';
import DataGrid from './Datagrid';

const memoizeSearchParams = memoizeOne((search) => new URLSearchParams(search));

// eslint-disable-next-line react/prop-types
const DataGridWithRedux = ({ setDataGridSearchParams, setDataGridColumnSettings, pathname, pathKey, ...props }) => {
  // eslint-disable-next-line react/prop-types

  return <DataGrid {...props} setSearchParams={setDataGridSearchParams} setColumnSettings={setDataGridColumnSettings} />;
};

const mapStateToProps = (state, props) => {
  const { searchParams: propSearchParams, columnSettings: propColumnSettings, location, history } = props || {};

  const { search = '', pathname = '' } = location || {};

  const columnSettings = state.dataGrid.columnSettings[pathname] || {};

  const searchParams = propSearchParams || search || state.dataGrid.searchParams[pathname] || '';

  return {
    pathKey: pathname,

    pathname: pathname,

    searchParams: memoizeSearchParams(searchParams),

    columnSettings: propColumnSettings || columnSettings,

    history,

    location,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setDataGridSearchParams: (payload) => ({ type: SET_SEARCH_PARAMS, payload }),

    setDataGridColumnSettings: (payload) => ({ type: SET_COLUMN_SETTINGS, payload }),
  })(DataGridWithRedux)
);
