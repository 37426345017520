import { AppBar, Typography, Button, Grid, Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import Breakpoint from '../layout/mediaQuery';
import ElevationScroll from '../layout/navigation/ElevationScroll';
import Footer from '../layout/Footer';
import MaintenanceIcon from './icons/maintenance.svg';
import _isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const MaintenancePage = ({ mainMessageKey, secondaryMessageKey, module }) => {
  const { t } = useTranslation('maintenance');

  const history = useHistory();

  useEffect(() => {
    axios.get(`/api/v1/maintenance/${module}`).then(() => {
      window.location.replace('/');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const useAppBarStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.default,
    },
  }));

  const refreshPage = () => {
    window.location.replace('/');
  };

  const isTabletOrLarger = useMediaQuery(Breakpoint.tablet);

  const getSecondaryMessage = () => {
    return !_isEmpty(history.location.state.secondaryMessage) ? history.location.state.secondaryMessage : t(secondaryMessageKey);
  };

  return (
    <>
      <ElevationScroll>
        <AppBar classes={useAppBarStyles()} position="sticky" className={`${isTabletOrLarger ? 'pl-14 pr-14' : ''}`}>
          <div className="flex justify-between items-center">
            <img src="/qsl-logo.jpeg" alt="qsl-logo" className="w-30 h-10" />
            <div className="h-20"></div>
          </div>
        </AppBar>
      </ElevationScroll>
      <Box style={{ margin: 'auto' }}>
        <Grid container spacing={2} direction="column" alignItems="center" justify="center">
          <Grid item>
            <Typography variant="h1">{t(mainMessageKey)}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2">{getSecondaryMessage()}</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={refreshPage}>
              {t('refresh')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <img alt="MaintenanceIcon" src={MaintenanceIcon} style={{ position: 'fixed', right: '10px', bottom: '10px', zIndex: '-1' }} />
      <Footer />
    </>
  );
};

MaintenancePage.propTypes = {
  mainMessageKey: PropTypes.string.isRequired,
  secondaryMessageKey: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
};

export default MaintenancePage;
