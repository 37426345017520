import jwtDecode from 'jwt-decode';

export default class Auth {
  static decodeToken = () => {
    const userToken = window.localStorage.getItem('id_token');
    return userToken ? jwtDecode(userToken) : null;
  };

  static getUserToken = () => {
    const user = Auth.decodeToken();
    return user || { sub: 'global' };
  };
}
