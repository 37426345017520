import axios from 'axios';
import useAgent from '../hooks/useAgent';

const url = '/api/v1/user/terms-and-conditions';

const toUrl = `${url}/accept`;

const acceptTermsAndConditions = () => axios.post(toUrl);

const useAcceptTermsAndConditions = () => useAgent(acceptTermsAndConditions);

export default useAcceptTermsAndConditions;
