import Moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { formatDurationToHours } from './momentFormatHelpers';

export const getTimeSlotToText = (startTime, endTime) => `${formatDurationToHours(startTime)} - ${formatDurationToHours(endTime)}`;

export const getTimeSlotTime = (dateFromProps, timeZone) => {
  if (typeof dateFromProps !== 'string') return undefined;
  const endDate = Moment.tz(dateFromProps, timeZone);
  return Moment.duration({ hours: endDate.hour(), minutes: endDate.minute() }).asMilliseconds();
};
