import DayJsUtils from '@date-io/dayjs';
import { makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import KeyboardDatePickerField from './KeyboardDatePickerField';
import { get as _get } from 'lodash';

const fromDatePickerStyle = makeStyles(() => ({
  root: {
    paddingRight: '1rem',
    maxWidth: '50%',
  },
}));

const toDatePickerStyle = makeStyles(() => ({
  root: {
    paddingRight: '1rem',
    maxWidth: '50%',
  },
}));

const toLabelStyle = makeStyles(() => ({
  root: {
    paddingLeft: '0.25rem',
  },
}));

const DateRangePickerField = ({ endDateLabel, endDateName, startDateLabel, startDateName, fullWidth = false }) => {
  const { values } = useFormikContext();

  return (
    <MuiPickersUtilsProvider utils={DayJsUtils}>
      <KeyboardDatePickerField
        classes={fromDatePickerStyle()}
        label={startDateLabel}
        maxDate={_get(values, endDateName)}
        name={startDateName}
        fullWidth={fullWidth}
      />
      <KeyboardDatePickerField
        classes={toDatePickerStyle()}
        label={endDateLabel}
        labelClasses={toLabelStyle()}
        minDate={_get(values, startDateName)}
        name={endDateName}
        fullWidth={fullWidth}
      />
    </MuiPickersUtilsProvider>
  );
};

DateRangePickerField.propTypes = {
  endDateLabel: PropTypes.string,
  endDateName: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  startDateLabel: PropTypes.string,
  startDateName: PropTypes.string.isRequired,
};

DateRangePickerField.defaultProps = {
  endDateLabel: '',
  startDateLabel: '',
};

export default DateRangePickerField;
