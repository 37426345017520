const dimension = {
  in: 'in',
  mm: 'mm',
  cm: 'cm',
  ft: 'ft',
  m: 'm',
};

const footpound = {
  kgM: 'kg/m',
  lbsFt: 'lbs/ft',
};

const mass = {
  mt: 'MT',
  nt: 'NT',
  lt: 'LT',
  lb: 'lb',
  kg: 'kg',
};

const volume = {
  cubicFoot: 'cu.ft.',
  cbm: 'CBM',
  fbm: 'FBM',
  cubicMeter: 'm³',
};

const surfaceArea = {
  squareFoot: 'sq.ft.',
  squareMeter: 'm²',
};

const units = {
  dimension,
  footpound,
  mass,
  volume,
  surfaceArea,
};

export default units;
