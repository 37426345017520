import { Tooltip as MuiTooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const tooltipStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
  },
}));

// WORKAROUND FOR https://github.com/mui-org/material-ui/issues/8416
const Tooltip = ({ children, spanStyle, ...props }) => (
  <MuiTooltip classes={tooltipStyles()} {...props}>
    {!!spanStyle ? <span style={spanStyle}>{children}</span> : children}
  </MuiTooltip>
);

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  spanStyle: PropTypes.object,
};

export default Tooltip;
