import axios from 'axios';
import { conflictCode, errorCode } from '../../constant';

const url = `/api/v1/sites`;

export const getSites = () => {
  return axios.get(url);
};

export const getSiteConfiguration = (id) => {
  return axios.get(`${url}/${id}`);
};

export const getWorkerAvailability = (startDate, endDate, siteId) => {
  return axios.get(`${url}/${siteId}/worker-availability?startDate=${startDate}&endDate=${endDate}`);
};

export const createOrUpdateWorkerAvailability = async (siteId, availability) => {
  try {
    const response = await axios.post(`${url}/${siteId}/worker-availability`, { ...availability });
    return response;
  } catch (error) {
    if (error.response && (error.response.status === conflictCode || error.response.status === errorCode)) {
      return error.response;
    } else {
      throw error;
    }
  }
};

export const getWorkerByEmail = (email) => {
  return axios.get(`/api/v1/view/workers`, {
    params: {
      email,
    },
  });
};
