import { makeStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

const inputStyle = (valueRight, hightlight) =>
  makeStyles({
    root: {
      '&.Mui-disabled': {
        color: 'black',
      },
    },
    input: {
      textAlign: valueRight ? 'end' : 'start',
      fontWeight: hightlight ? 800 : 400,
    },
    underline: {
      '&:after': {
        border: 'none',
      },
      '&.Mui-disabled:before': {
        border: 'none',
      },
    },
  });

const textFieldStyle = makeStyles({
  root: {
    width: '100%',
  },
});

const Output = ({ highlight, defaultValue, valueRight, ...props }) => {
  const value = useMemo(() => (defaultValue !== 0 && !defaultValue ? '-' : defaultValue), [defaultValue]);
  const makeInputStyle = inputStyle(valueRight, highlight);

  return (
    <TextField
      value={value}
      disabled
      classes={textFieldStyle()}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{ classes: makeInputStyle() }}
      multiline
      {...props}
    />
  );
};

Output.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueRight: PropTypes.bool,
  highlight: PropTypes.bool,
};

Output.defaultProps = {
  defaultValue: '-',
  valueRight: false,
  highlight: false,
};

export default Output;
