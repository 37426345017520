import { iconSizes } from './Icon';

export const getStyleForSize = (size) => {
  switch (size) {
    case iconSizes.small:
      return { width: '0.5rem', height: '0.5rem' };
    case iconSizes.medium:
      return { width: '1rem', height: '1rem' };
    case iconSizes.large:
      return { width: '1.5rem', height: '1.5rem' };
    default:
      return {};
  }
};
