import countries from 'i18n-iso-countries';
import i18n from 'i18next';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

const getCountryOptions = () => {
  const countryNames = countries.getNames(i18n.language || 'en');
  return Object.keys(countryNames).map((code) => ({ value: code, label: countryNames[code] }));
};

export default getCountryOptions;
