import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Output from '../layout/Output';
import Section from '../layout/Section';
import PropTypes from 'prop-types';
import useChangePassword from './useChangePassword';

const ProfilePage = ({ LayoutComponent }) => {
  const { t } = useTranslation('profile');

  const { user } = useAuth0();

  const [sendChangePasswordRequest] = useChangePassword();

  return (
    <LayoutComponent>
      <Section title={t('profileInformation')}>
        <Grid container spacing={2}>
          <Grid item xs={8} md={3}>
            <Output id="output-name" label={t('name')} defaultValue={user.name} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Output id="output-email" label={t('email')} defaultValue={user.email} />
          </Grid>
          <Grid item xs={6} md={6}>
            <Button variant="outlined" color="primary" onClick={() => sendChangePasswordRequest(user)}>
              {t('changePassword')}
            </Button>
          </Grid>
        </Grid>
      </Section>
    </LayoutComponent>
  );
};

ProfilePage.propTypes = {
  LayoutComponent: PropTypes.func.isRequired,
};

export default ProfilePage;
