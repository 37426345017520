import React from 'react';

const useDrawerToggler = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (isOpen) close();
    else open();
  };

  return [isOpen, toggleDrawer];
};

export default useDrawerToggler;
