import { actionTypes as dataGridActionTypes, stateReducer as dataGridStateReducer, storeReducer as dataGridStoreReducer } from '@vooban/datagrid';
import localforage from 'localforage';
import { combineReducers } from 'redux';
import Auth from './Auth';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { dateFilterDropDownOptions, getQueryFiltersFromDateDropdownSelection } from './layout/ListPage/headers/component/DateFilterValues';

export const { SET_SEARCH_PARAMS, SET_COLUMN_SETTINGS } = dataGridActionTypes;
export const CLEAR_PERSISTED_DATA = 'CLEAR_PERSISTED_DATA';

const persistedStorage = localforage.createInstance();
const persistedReducersKeys = ['userSettings', 'dataGrid'];
export const persistConfig = {
  keyPrefix: 'tc3-client-portal:',
  key: Auth.getUserToken().sub,
  whitelist: persistedReducersKeys,
  storage: persistedStorage,
  stateReconciler: autoMergeLevel2,
};

// TODO: TC3-4728 remove once we have all prod users move to newest react-persist store config, module-based instead of a shared store.
const migrateLog = (log) => console.log(`QSL Store Migration: ${log}`);
export async function migrateQslStore() {
  try {
    const { sub: userSub } = Auth.getUserToken();
    const qslStoreName = `qsl:${userSub}`;
    migrateLog(`Trying to get old QSL-store (${qslStoreName})`);

    const store = await localforage.getItem(qslStoreName);
    if (store == null) {
      // user store already migrated, or new user
      migrateLog(`Store was not found. Nothing to migrate.`);
      return;
    }
    migrateLog(`Migrating old QSL-store (${qslStoreName})`);

    const moduleStoreName = `tc3-client-portal:${userSub}`;
    migrateLog(`Moving QSL-store to new store: ${moduleStoreName}`);
    await localforage.setItem(moduleStoreName, store);

    migrateLog(`Testing migrated module-based store: ${moduleStoreName}`);
    const migrated = Boolean(await localforage.getItem(moduleStoreName));
    migrateLog(`Migration ${migrated ? 'succeeded' : 'failed'} on: ${store}`);

    await localforage.removeItem(qslStoreName); // migration succeeded for that user, remove old entry
  } catch (err) {
    console.error(err);
  }
}

const initialState = {
  searchParams: {
    '/appointments': getQueryFiltersFromDateDropdownSelection(dateFilterDropDownOptions.Today),
  },
  loadingRequestSortProperty: '',
};

const UserSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_PARAMS: {
      const { pathname, search } = action.payload || {};
      if (!pathname) return state;
      return { ...state, searchParams: { ...state.searchParams, [pathname]: search } };
    }
    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  dataGrid: dataGridStoreReducer,
  dataGridState: dataGridStateReducer,
  userSettings: UserSettingsReducer,
});

const reducers = (state, action) => {
  let updatedState = state;
  switch (action.type) {
    case CLEAR_PERSISTED_DATA: {
      persistConfig.storage.removeItem('persist:root');
      updatedState = Object.entries(updatedState).reduce((acc, [key, reducer]) => {
        const isPersisted = persistedReducersKeys.includes(key);
        return isPersisted ? acc : { ...acc, [key]: reducer };
      }, {});
      break;
    }
    default:
      break;
  }
  return combinedReducer(updatedState, action);
};

export default reducers;
