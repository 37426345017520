import axios from 'axios';
import { theoreticalMaximumItems } from '../../constant';

const url = '/api/v1/shipment-of-material';

export const getShipmentOfMaterial = (id) => {
  return axios.get(`${url}/${id}`);
};

export const getShippedItems = (shipmentId, destinationId, clientId) => {
  return axios.get(`${url}/${shipmentId}/shipment-items`, {
    params: {
      take: theoreticalMaximumItems,
      includeStuffedItems: true,
      grossWeightAutoRound: false,
      'destinationClient.destinationId': destinationId,
      'destinationClient.clientId': clientId,
    },
  });
};
