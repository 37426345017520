import { Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import getCountryOptions from '../../../common/helpers/countries';
import Output from '../../../layout/Output';
import Section from '../../../layout/Section';
import { InventoryItem } from '../types';

const ProductCharacteristicsSection = ({ item }) => {
  const countryOptions = getCountryOptions();
  const { t } = useTranslation('inventory');
  return (
    <Section title={t('item.productCharacteristics')}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Output id={`output-density`} defaultValue={item.density} label={t('item.density')} valueRight />
        </Grid>
        <Grid item xs={6} md={3}>
          <Output id={`output-densityUnit`} defaultValue={item.densityUnit} label={t('item.unit')} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Output
            id={`output-countryOfOrigin`}
            defaultValue={item.countryOfOrigin ? (countryOptions.find((x) => x.value === item.countryOfOrigin) || {}).label : null}
            label={t('item.countryOfOrigin')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Output id={`output-origin`} defaultValue={item.origin} label={t('item.origin')} />
        </Grid>
      </Grid>
    </Section>
  );
};

ProductCharacteristicsSection.propTypes = {
  item: PropTypes.shape(InventoryItem),
};

ProductCharacteristicsSection.defaultProps = {
  item: {},
};

export default ProductCharacteristicsSection;
