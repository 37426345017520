import PropTypes from 'prop-types';

const AgnicoEagleAssignationType = {
  costCenter: PropTypes.string,
  id: PropTypes.number,
  description1: PropTypes.string,
  description2: PropTypes.string,
  lineNumber: PropTypes.number,
  number2: PropTypes.string,
  number3: PropTypes.string,
  orderNumber: PropTypes.number,
  priority: PropTypes.string,
  quantity: PropTypes.number,
  shippingCondition: PropTypes.string,
  shipToId: PropTypes.number,
};

export default AgnicoEagleAssignationType;
