import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SET_SEARCH_PARAMS } from '../../../reducers';

const withPersistedParameters = (component) => (props) => {
  const location = useLocation();
  const { push } = useHistory();
  const persistedParams = useSelector((state) => state.dataGrid.searchParams[location.pathname] || state.userSettings.searchParams[location.pathname]);

  const dispatch = useDispatch();

  const setSearchParams = useCallback(
    (payload) => {
      push({ ...location, search: payload });
      dispatch({ type: SET_SEARCH_PARAMS, payload: { pathname: location.pathname, search: payload } });
    },
    [dispatch, push, location]
  );

  useEffect(() => {
    if (!location.search && persistedParams) {
      push({ ...location, search: persistedParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchParams = new URLSearchParams(location.search || persistedParams);
  return React.createElement(component, { ...props, setSearchParams: setSearchParams, searchParams: searchParams });
};

export default withPersistedParameters;
